import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Radio, TableFooter } from "@material-ui/core";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PreviewService from "../../services/PreviewService.js";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import * as PreviewComponents from "../Shared/PreviewComponents";
import {
  InputTextMultiLine,
  InputDropDownCheckbox,
  InputText,
  InputDropDown,
  InputDate,
  Header1,
  CustomHeader1,
  InputDropDownLookup,
  InputDropDownCountries,
  InputPassword,
  InputDropDownCheckboxLookup,
} from "../Shared/PreviewComponents";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Keys from "../../json/ResourceStartup.json";
import allActions from "../../actions";
import constants from "../../helpers/constant.helper";
import AlertMessage from "../AlertMessage";
import * as AuthenticationHelper from "../../helpers/authentication.helper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isWithinInterval } from "date-fns";
import PleaseWaitMessage from "../PleaseWaitMessage";
import Link from "@material-ui/core/Link";
import { history, ShowLinkedRecords } from "../../helpers";
import * as LanguageHelper from "../../helpers/language.helper";
import StartupContext from "../../Context/startupContext";
import { logHelper } from "../../helpers/log.helper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    //marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 348,
  },
  formElement: {},
  formLabel: {
    color: "#aaaaa9",
    marginTop: 10,
    "&:hover": {
      background: "#F3F3F2",
    },
  },
  header: {
    backgroundColor: "#F3F3F2",
    borderRadius: 4,
    paddingLeft: 5,
  },
  subHeader: {
    marginTop: 15,
    marginBottom: 1,
    fontWeight: 700,
  },
  buttonStyle: {
    //margin: theme.spacing(1),
    textTransform: "none",
  },
  xs12: {
    width: "50%",
  },
  columnLabel: { width: "10%" },
  columnInput: { width: "70%" },
}));

//samo ednas da se citaat
const keys = LanguageHelper.GetLanguageKeys();
const isLTR = LanguageHelper.isLanguageLTR();
const allignLeft = LanguageHelper.allignLeft().toString();

export default function BenefManage(args) {
  const startupContext = useContext(StartupContext);
  const { ime, prezime, AUTH_USERNAME } = startupContext.user;
  const { count } = startupContext;
  logHelper.log(AUTH_USERNAME);

  //const keys = Keys[0]; //za da naoga textovi od JSON FIle

  const classes = useStyles();

  const dispatch = useDispatch();
  const dataReducer = useSelector((state) => state.BenefReducer);

  //iniicjalno EntityObject e prazen posle dole na useEffect ke se napolni
  const [Record, setRecord] = useState(dataReducer.Record);
  const [LookupData, setLookups] = useState(dataReducer.LookupData);
  const [ScholarshipCountries, setScholarshipCountries] = useState(
    dataReducer.ScholarshipCountries
  );
  const [Nationalities, setNationalities] = useState(dataReducer.Nationalities);

  const [Provinces, setProvinces] = useState(dataReducer.Provinces);
  const [ProvincesByCountry, setProvincesByCountry] = useState([]);

  const lookupTypeIDs = [
    constants.Lookup_SectorOfBusiness,
    constants.Lookup_EmploymentStatus,
    constants.Lookup_Disability,
    constants.Lookup_Gender,
  ];

  const isUser = AuthenticationHelper.IsUser(); //mu treba za drop down za Country da mu ja dade samo negovata zemja
  const isOfficer = AuthenticationHelper.IsOfficer(); //mu treba za drop down za Country da mu ja dade samo negovata zemja i vo DropDownCountries da ne pokazuva Select Countries
  const isFieldEnabled = !dataReducer.Loading && Record.stStartupID === 0; //za countries, email, etc 0 e definiran vo reducer
  const isPreviewOnly = AuthenticationHelper.GetStartupPreview();

  useEffect(() => {
    let payload = { LookupTypeIDs: lookupTypeIDs };

    // //ako e USER ke si raboti so SignedUserId
    // if (isUser) {
    //   dispatch(allActions.BenefActions.Edit(payload));
    // }
    // else {
    //   const startupId = AuthenticationHelper.GetStartUpItemGUID();
    //   payload = { ...payload, "stStartupID": startupId };
    //   dispatch(allActions.BenefActions.Edit(payload));
    // }

    const startupId = AuthenticationHelper.GetStartUpItemGUID();
    payload = { ...payload, stStartupID: startupId };
    dispatch(allActions.BenefActions.Edit(payload));

    return () => {
      dispatch(allActions.BenefActions.Reset()); //vraka na Initial State
    };
  }, []); //default

  //ke se zapali smao pri promena
  useEffect(() => {
    //samo mapiraj data od koga site call-ovi prema api.he4s.eu se zavrseni a toa e koga Loading === False
    if (!dataReducer.Loading) {
      logHelper.log(dataReducer);

      //setiraj go tekovniot Startup
      AuthenticationHelper.SetStartUpItemGUID(dataReducer.Record.stStartupID);

      setLookups(dataReducer.LookupData);
      setScholarshipCountries(dataReducer.ScholarshipCountries);
      setNationalities(dataReducer.Nationalities);
      setProvinces(dataReducer.Provinces);

      //Za User sekogas listata na ScholarshipCountries e samo i samo 1 zemja
      //pa poradi toa najdi go CountryId i sprema nego filtriraj Provinces
      // if (isUser) {
      //   const countryId = dataReducer.ScholarshipCountries[0].Value;
      //   const filterProvinceByCountry = dataReducer.Provinces.filter(a => a.ParentId === countryId.toString());
      //   setProvincesByCountry(filterProvinceByCountry);
      //   setRecord(dataReducer.Record);
      // }
      // else

      // if (isOfficer) {
      //   const countryId = dataReducer.ScholarshipCountries[0].Value;
      //   alert(countryId);
      //   if (countryId != -1) {
      //     const filterProvinceByCountry = dataReducer.Provinces.filter(a => a.ParentId === countryId.toString());
      //     setProvincesByCountry(filterProvinceByCountry);

      //     dataReducer.Record.stCountryID = countryId;
      //     setRecord(dataReducer.Record);
      //   }
      // }
      // else {
      //   const value = dataReducer.Record.stCountryID.toString();
      //   const filterProvinceByCountry = dataReducer.Provinces.filter(a => a.ParentId === value);
      //   setProvincesByCountry(filterProvinceByCountry);
      //   setRecord(dataReducer.Record);
      // }

      //so ogled deka Partnerite moze da kreiraat i vo drugi drzavi ako e setiran AspNetUser.CountryId = -1 dozvoliim da  gledaat i drugi zemji
      //ako e nov record togas filtriraj po prvata zemja provincii
      //vo sprotivno od samiot record ke si najde za koja zemja se raboti
      const value = dataReducer.Record.stCountryID.toString();
      if (value == 0) {
        const countryId = dataReducer.ScholarshipCountries[0].Value;
        const filterProvinceByCountry = dataReducer.Provinces.filter(
          (a) => a.ParentId === countryId.toString()
        );
        setProvincesByCountry(filterProvinceByCountry);

        dataReducer.Record.stCountryID = countryId;
        setRecord(dataReducer.Record);
      } else {
        const filterProvinceByCountry = dataReducer.Provinces.filter(
          (a) => a.ParentId === value
        );
        setProvincesByCountry(filterProvinceByCountry);
      }
      setRecord(dataReducer.Record);
    }
  }, [dataReducer]);

  //Handle Change za Text Box, Select,  i Date Time komponentit
  //https://nikgrozev.com/2019/04/07/reacts-usecallback-and-usememo-hooks-by-example/
  const handleChange = (e) => {
    logHelper.log("od handle change");
    logHelper.log(e.target);
    const { name, value } = e.target;
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeEmail = (e) => {
    logHelper.log(e.target);
    const { name, value } = e.target;
    setRecord((prevState) => ({
      ...prevState,
      [name]: value,
      stUserName: value,
    }));
  };

  const handleChangeMulti = (controlId, options) => {
    logHelper.log(options);
    logHelper.log(controlId);
    let selectValues = "";

    options.map((option) => {
      const selectedValue = option.ldLookupDataID.toString();
      if (selectValues === "") {
        selectValues = selectedValue;
      } else {
        selectValues += "," + selectedValue;
      }
    });

    setRecord((prevState) => ({ ...prevState, [controlId]: selectValues }));
  };

  const handleChangeMultiGeneral = (controlId, options) => {
    logHelper.log(options);
    logHelper.log(controlId);
    let selectValues = "";

    options.map((option) => {
      const selectedValue = option.Value.toString();
      if (selectValues === "") {
        selectValues = selectedValue;
      } else {
        selectValues += "," + selectedValue;
      }
    });

    setRecord((prevState) => ({ ...prevState, [controlId]: selectValues }));
  };

  const handleChangeCountry = (e) => {
    logHelper.log("od handle change");
    logHelper.log(e.target);
    const { name, value } = e.target; //value ==> CountryId

    logHelper.log("value vo handle");
    logHelper.log(value);

    const filterProvinceByCountry = Provinces.filter(
      (a) => a.ParentId === value
    );
    setRecord((prevState) => ({
      ...prevState,
      [name]: value,
      ["stProvinceID"]: "0",
    })); //da se resetira province-ot za validacija i toa vo "0" za da raboti ne 0
    setProvincesByCountry(filterProvinceByCountry);
  };

  //Handle Change za Multiple Select komponeti
  const handleChangeMultiple = (event) => {
    logHelper.log("AAAAAAA");
    logHelper.log(event.target);

    // https://material-ui.com/components/selects/
    const { name, options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        //logHelper.log("SELECTED:" + options[i].value)
        value.push(options[i].value);
      }
    }
    //setControl(prevState => ({ ...prevState, [name]: value }));
  };

  logHelper.log("Record");
  logHelper.log(Record);

  return (
    <TableContainer component={Paper}>
      <PleaseWaitMessage {...dataReducer} />
      <AlertMessage {...dataReducer} />

      <Table
        className={classes.table}
        //aria-label="customized table"
        // style={{ width: "100%", height: "2650", tableLayout: "auto" }}
        style={{ width: "100%", tableLayout: "auto" }}
      >
        <TableHead>
          <TableRow>
            <TableCell colspan={2}>
              <Table style={{ width: "100%", tableLayout: "auto" }}>
                <TableHead>
                  <TableRow>
                    <TableCell width="80%" colspan="2">
                      <Typography variant="h5" component="h5">
                        {keys["HeaderBenefInfo"]}{" "}
                        {Record.stStartupID > 0 &&
                          "(" +
                            keys["SPARKID"] +
                            " - " +
                            Record.stStartupID +
                            ")"}
                      </Typography>
                      <Typography variant="h8" component="h8">
                        {keys["SubHeaderBenefInfo"]} <br />
                        {Record.stSISID > 0 &&
                          keys["ThisProfileTakenFromSIS"]}{" "}
                        <br />
                        {/* {ShowLinkedRecords(Record)} */}
                      </Typography>
                    </TableCell>
                    {!isPreviewOnly && (
                      <TableCell align={allignLeft}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonStyle}
                          onClick={() =>
                            dispatch(allActions.BenefActions.Save(Record))
                          }
                          disabled={dataReducer.Loading}
                        >
                          Save
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
              </Table>
            </TableCell>
          </TableRow>
        </TableHead>

        {InputDropDownCountries(
          classes,
          keys["Country"],
          "stCountryID",
          ScholarshipCountries,
          Record.stCountryID,
          handleChangeCountry,
          !isFieldEnabled,
          dataReducer.ValidationMessages,
          true,
          !isOfficer
        )}

        {InputDropDown(
          classes,
          keys["Province"],
          "stProvinceID",
          ProvincesByCountry,
          Record.stProvinceID,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true,
          isLTR
        )}

        {InputText(
          classes,
          keys["FirstNameEnglish"],
          "stFirstName",
          Record.stFirstName,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true,
          isLTR
        )}
        {InputText(
          classes,
          keys["LastNameEnglish"],
          "stLastName",
          Record.stLastName,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true,
          isLTR
        )}
        {/* {InputDropDownCountries(classes, keys["CountryNationality"], "stCountryNationalityID", Nationalities, Record.stCountryNationalityID, handleChange, false, dataReducer.ValidationMessages, true, true)} */}
        {InputDropDownCheckbox(
          classes,
          keys["CountryNationality"],
          "stCountryNationalityIDs",
          Nationalities,
          Record.stCountryNationalityIDs,
          handleChangeMultiGeneral,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          false,
          isLTR
        )}
        {InputDropDownLookup(
          classes,
          keys["GenderText"],
          "stGenderID",
          LookupData,
          constants.Lookup_Gender,
          Record.stGenderID,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true,
          isLTR
        )}
        {InputDate(
          classes,
          keys["BirthDate"],
          "stBirthDate",
          Record.stBirthDate,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true,
          isLTR
        )}
        {InputText(
          classes,
          keys["Email"],
          "stEmail",
          Record.stEmail,
          handleChangeEmail,
          !isFieldEnabled,
          dataReducer.ValidationMessages,
          true,
          isLTR
        )}
        {InputText(
          classes,
          keys["Address"],
          "stAddress",
          Record.stAddress,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true,
          isLTR
        )}
        {InputText(
          classes,
          keys["City"],
          "stCity",
          Record.stCity,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true,
          isLTR
        )}
        {InputText(
          classes,
          keys["MobilePhone"],
          "stMobilePhone",
          Record.stMobilePhone,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true,
          isLTR
        )}
        {InputDropDownLookup(
          classes,
          keys["EmploymentStatus"],
          "stEmploymentStatusID",
          LookupData,
          constants.Lookup_EmploymentStatus,
          Record.stEmploymentStatusID,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true,
          isLTR
        )}
        {InputDropDownLookup(
          classes,
          keys["Disability"],
          "stDisabilityID",
          LookupData,
          constants.Lookup_Disability,
          Record.stDisabilityID,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true,
          isLTR
        )}
        {InputDropDownCheckboxLookup(
          classes,
          keys["SectorBenef"],
          "stSectorIDs",
          LookupData,
          constants.Lookup_SectorOfBusiness,
          Record.stSectorIDs,
          handleChangeMulti,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          false,
          isLTR
        )}
        {Record.stSISID > 0 &&
          InputText(
            classes,
            keys["SISID"],
            "stSISID",
            Record.stSISID,
            handleChange,
            true,
            dataReducer.ValidationMessages,
            false,
            isLTR
          )}

        {InputTextMultiLine(
          classes,
          "Note",
          "stNote",
          Record.stNote,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          false
        )}

        {/* {!isUser && PanelCredentials(Record, dataReducer.ValidationMessages)}; */}

        {!isPreviewOnly && (
          <TableRow>
            <TableCell colspan="2" align="right">
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
                onClick={() => dispatch(allActions.BenefActions.Save(Record))}
                disabled={dataReducer.Loading}
              >
                Save
              </Button>
            </TableCell>
          </TableRow>
        )}
        {/* <TableRow>
      <TableCell colspan={2}>
        {ShowLinkedRecords(Record)}
      </TableCell>
    </TableRow> */}
      </Table>

      {/* <PleaseWaitMessage {...dataReducer} /> */}
      <AlertMessage {...dataReducer} />
    </TableContainer>
  );
}
