import React, { useState, useEffect } from "react";
import pdfIcon from "../../assets/pdfIcon.png";
//import pptIcon from "../assets/pptIcon.png";
import excelIcon from "../../assets/Excel-icon.png";
import { logHelper } from "../../helpers/log.helper";

export default function ImportTemplate() {
  var url = logHelper.GetBaseUrl() + "/template/ManualDataEntryBTSSystem.xlsx";

  return (
    <div>
      <img src={excelIcon} /> <span>&nbsp;</span>
      <a href={url} target="_blank" style={{ textDecoration: "none" }}>
        Download Template
      </a>
      <p>
        Once you download and fulfill the template with the required BTS Data,
        please send it for verification on support@spark-online.org and ICT Team
        will going to import it for you.
      </p>
    </div>
  );
}
