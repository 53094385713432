import { parse } from "date-fns";
import { logHelper } from '../helpers/log.helper';

export const validatorHelper = {
    validate
};

//Validate Response validira responses dali ima nekakvi greski 
//responses = [] --niza
//Iterira niz nea i ako e TRUE togas vraka responses[]
//ako e FALSE vraka response od ERROR objekt
//return responseHelper.validateResponses(responses);
function validate(inputJSON) {




    logHelper.log(inputJSON)
    //proverka na greska
    let msgOutput = [];

    inputJSON.map((input) => {
        let invalidField = false;

        //valdiraj required fields 
        if (input.type === "required") {
            if (input.value === "" || input.value === null) {
                invalidField = true;
            }
        }
        else if (input.type === "email") {
            if (input.value === "" || input.value === null) {
                invalidField = true;
            }
            else if (input.value.indexOf("@") === -1) {
                invalidField = true;
            }
            else if (input.value.indexOf(".") === -1) {
                invalidField = true;
            }
        }
        else if (input.type === "password") {
            if (input.value === "" || input.value === null) {
                invalidField = true;
            }
            else if (input.value.length < 6) {
                invalidField = true;
            }
        }


        //valdiraj select fields 
        else if (input.type === "SelectEmpty") {
            if (input.value === "0" || input.value === 0 || input.value === null) {
                invalidField = true;
            }
        }

        else if (input.type === "Float") {
            if (input.value === "" || input.value === null) {
                invalidField = true;
            }
            else {
                let value = input.value;
                const floatValue = parseFloat(value);
                if (isNaN(floatValue)) {
                    invalidField = true;
                }
            }
        }


        //valdiraj datetime fields 
        else if (input.type === "DateTimeEmpty") {
            logHelper.log("DATETIME" + input.value)

            if (input.value === '') {

                invalidField = true;
            }
            else {
                const inputDate = new Date(input.value);
                if (inputDate.getFullYear() < 1970) {

                    invalidField = true;
                }
            }
        }

        if (invalidField) {
            msgOutput.push({
                message: input.message,
                controlId: input.element
            });
            //alert(input.message);
            // if (msgOutput === null) {
            //     msgOutput = input.message;
            // }
            // else {
            //     msgOutput += "\n" + input.message;
            // }
        }

    });
    return msgOutput;
}



function validateResponse(response) {
    if (response.error) {
        return false;
    }
    return true;
}