import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Radio } from '@material-ui/core';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PreviewService from '../../services/PreviewService.js';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import * as PreviewComponents from '../Shared/PreviewComponents';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AlertMessage from '../AlertMessage'
import CircularProgress from '@material-ui/core/CircularProgress';
import { history } from '../../helpers';
import { InputTextOnly, InputDropDownOnly, InputDropDownLookupOnly, InputDateOnly } from '../Shared/PreviewComponents';
import constants from '../../helpers/constant.helper';
import Keys from '../../json/ResourceStartup.json';
import allActions from '../../actions';
import DeleteIcon from '@material-ui/icons/Delete';
import * as AuthenticationHelper from '../../helpers/authentication.helper'
import * as LanguageHelper from '../../helpers/language.helper';
import { logHelper } from '../../helpers/log.helper';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 348,
  },
  formElement: {},
  formLabel: {
    color: '#aaaaa9',
    marginTop: 10,
    '&:hover': {
      background: '#F3F3F2',
    }
  },
  header: {
    backgroundColor: '#F3F3F2',
    borderRadius: 4,
    paddingLeft: 5,
  },
  subHeader: {
    marginTop: 15,
    marginBottom: 1,
    fontWeight: 700
  },
  buttonStyle: {
    //margin: theme.spacing(1),
    textTransform: 'none',
  },
  xs12: {
    width: '50%',
  },
  columnLabel: { width: '10%' },
  columnInput: { width: '70%' },
}));


export default function StartupFounderManage(args) {

  const keys = LanguageHelper.GetLanguageKeys();
  const dispatch = useDispatch()

  const classes = useStyles();

  const dataReducer = useSelector(state => state.StartupFounderReducer);
  const [Records, setRecords] = useState(dataReducer.Records);
  const [LookupData, setLookups] = useState(dataReducer.LookupData);
  const StartupID = AuthenticationHelper.GetStartUpItemGUID(); //se koristi samo kako ADMIN i OFFICER
  const isPreviewOnly  =AuthenticationHelper.GetStartupPreview();

  //sekogas mora da go ima pa so PROPTYPES ke treba da frlame exception so ogled deka kolonite se vrzani za toj TableID
  const lookupTypeIDs = [constants.Lookup_SectorOfBusiness, constants.Lookup_IniitialBusinessStage,
  constants.Lookup_Gender, constants.Lookup_Disability, constants.Lookup_EmploymentStatus, constants.Lookup_FoundersNationality];

  let TypeId = 0; //odredi dali e SME ili StartupType
  const currentUrl = args.location.pathname + args.location.search;
  const isStartup = (currentUrl.indexOf("/startup/") > -1);
  const isSME = (currentUrl.indexOf("/sme/") > -1);

  if (isStartup)
  {
    TypeId = constants.TYPEID_STARTUP;
  }
  else {
    TypeId = constants.TYPEID_SME;
  }

  useEffect(() => {
    let payload = { LookupTypeIDs: lookupTypeIDs }
    payload = { ...payload, "stTypeID": TypeId }; //dodadi startup ili pak SME

    if (AuthenticationHelper.IsUser()) {
      dispatch(allActions.StartupFounderActions.Edit(payload));
    }
    else {
      payload = { ...payload, "stItemGUID": StartupID }; //dodadi startup
      dispatch(allActions.StartupFounderActions.Edit(payload));
    }

    return () => {
      dispatch(allActions.StartupFounderActions.Reset()); //vraka na Initial State
    };
  }, []);   //default


  //ke se zapali smao pri promena 
  useEffect(() => {

    setRecords(dataReducer.Records);
    setLookups(dataReducer.LookupData);

  }, [dataReducer]);

  //Handle Change za Text Box, Select,  i Date Time komponentit
  //https://nikgrozev.com/2019/04/07/reacts-usecallback-and-usememo-hooks-by-example/
  const handleChange = e => {
    logHelper.log("od handle change");
    logHelper.log(e.target);
    const { name, value } = e.target;

    //name= 0_sfFirstName 
    const propertyIndex = name.split('_')[0]; //0 
    const propertyName = name.split('_')[1];  //First Name

    let newArr = [...Records]; // copying the old datas array
    //newArr[propertyIndex].sfFirstName = e.target.value; //  [propertyName] == sfFirstName dinamicko pole
    newArr[propertyIndex][propertyName] = e.target.value; // replace e.target.value with whatever you want to change it to
    //newArr[propertyIndex]["StartupItemGUID"] = StartupItemGUID; // replace e.target.value with whatever you want to change it to

    setRecords(newArr);
  };

  logHelper.log("RECORDS");
  logHelper.log(Records);

  return (
    <TableContainer component={Paper}>
      <AlertMessage {...dataReducer} />
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={9}>
              <Typography variant="h5" component="h5">
                {keys["HeaderInfoFounders"]} {StartupID > 0 && "(" + keys["SPARKID"] + ' - ' + StartupID + ")"}
              </Typography>
              <Typography variant="h8" component="h8">
                {keys["SubHeaderInfoFounders"]}
              </Typography>
            </TableCell>
            <TableCell align="center">
              {!isPreviewOnly && <Button variant="contained" color="primary" className={classes.buttonStyle}
                // "StartupItemGUID": StartupItemGUID vazi samo za Admin/Officers ItemGUID
                onClick={() => dispatch(allActions.StartupFounderActions.Save({ "StartupFounders": Records}))}>
                Save
                </Button>
              }
            </TableCell>
          </TableRow>
        </TableHead>


        <TableHead>
          <TableRow>
            <TableCell style={{ "width": "2%" }} align="center"><b>#</b></TableCell>
            <TableCell align="center"><b>{keys["FirstName"]}</b></TableCell>
            <TableCell align="center"><b>{keys["LastName"]}</b></TableCell>
            <TableCell align="center"><b>{keys["NationalityText"]}</b></TableCell>
            <TableCell align="center"><b>{keys["GenderText"]}</b></TableCell>
            <TableCell align="center"><b>{keys["BirthDateText"]}</b></TableCell>
            <TableCell align="center"><b>{keys["SPARKIDText"]}</b></TableCell>
            <TableCell align="center"><b>{keys["Disability"]}</b></TableCell>
            <TableCell align="center"><b>{keys["EmploymentStatus"]}</b></TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Records.map((Record, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell>{InputTextOnly(index + "_sfFirstName", Record.sfFirstName, handleChange)}</TableCell>
              <TableCell>{InputTextOnly(index + "_sfLastName", Record.sfLastName, handleChange)}</TableCell>
              <TableCell>{InputDropDownLookupOnly(index + "_sfNationalityID", LookupData, constants.Lookup_FoundersNationality, Record.sfNationalityID, handleChange)}</TableCell>
              <TableCell>{InputDropDownLookupOnly(index + "_sfGenderID", LookupData, constants.Lookup_Gender, Record.sfGenderID, handleChange)}</TableCell>
              <TableCell>{InputDateOnly(index + "_sfBirthDate", Record.sfBirthDate, handleChange)}</TableCell>
              <TableCell>{InputTextOnly(index + "_sfSISID", Record.sfSISID > 0 ? Record.sfSISID : '', handleChange)}</TableCell>
              <TableCell>{InputDropDownLookupOnly(index + "_sfDisabilityID", LookupData, constants.Lookup_Disability, Record.sfDisabilityID, handleChange)}</TableCell>
              <TableCell>{InputDropDownLookupOnly(index + "_sfEmploymentStatusID", LookupData, constants.Lookup_EmploymentStatus, Record.sfEmploymentStatusID, handleChange)}</TableCell>
              <TableCell>
                {!isPreviewOnly && Record.sfStartUpFounderId > 0 &&
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => dispatch(allActions.StartupFounderActions.Delete(Record))}
                    startIcon={<DeleteIcon />}
                  >Remove</Button>
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
