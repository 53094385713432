import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import allActions from '../../actions';
import AlertMessage from '../AlertMessage'
import constants from '../../helpers/constant.helper';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableFooter } from '@material-ui/core';
import { history } from '../../helpers';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { logHelper } from '../../helpers/log.helper';

// DEFINIRANJE NA STILOVI
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  columnLabel: { width: '10%' },
  columnInput: { width: '70%' },
  spacer: { "padding-right": '20px' },

}));


export default function LookupTypeManage(args) {

  const dispatch = useDispatch()
  const classes = useStyles();
  const dataLookupTypeReducer = useSelector(state => state.LookupTypeReducer);


  //iniicjalno Record e prazen posle dole na useEffect ke se napolni
  const [Record, setRecord] = useState(dataLookupTypeReducer.Record);

  const isNewRecord = (Record.ltLookupTypeId == 0);

  const handleChange = e => {

    const { name, value } = e.target;  //vadi e.target.name i e.target.value kade sto value e vnesenata vrednost!
    logHelper.log(name + " : " + value);
    setRecord(prevState => ({ ...prevState, [name]: value }));
  };

  const handleChangeChecked = e => {

    const { name, value, checked } = e.target;  //vadi e.target.name i e.target.value kade sto value e vnesenata vrednost!
    logHelper.log(name + " : " + value + ' :' + checked);

    //const isChecked = value === "on" ? true : false;
    setRecord(prevState => ({ ...prevState, [name]: checked }));
  };


  //DEFAULT 
  useEffect(() => {
    //postecki record 
    if (args.match.params.id) {
      const RecordId = args.match.params.id;
      dispatch(allActions.LookupTypeActions.Edit(RecordId));
    }

    return () => {
      dispatch(allActions.LookupTypeActions.Reset()); //vraka na Initial State
    };
    //ako ne raboti direktno so toj od Reducer
  }, []);   //default


  //ke se zapali smao pri promena 
  useEffect(() => {
    setRecord(dataLookupTypeReducer.Record);
  }, [dataLookupTypeReducer]);   //default


  return (

    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colspan="2">
              <AlertMessage {...dataLookupTypeReducer} />
              <h1>{isNewRecord ? "Create Lookup Type" : "Manage Lookup Type"}</h1>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell colspan="3" align="right">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => dispatch(allActions.LookupTypeActions.Save(Record))}
              >
                Save
          </Button>

              <label className={classes.spacer}></label>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isNewRecord}
                className={classes.submit}
                onClick={() => history.push('/lookupdata/find/' + Record.ltLookupTypeID)}
              >
                Define Lookup List
          </Button>


              <label className={classes.spacer}></label>

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isNewRecord}
                className={classes.submit}
                onClick={() => dispatch(allActions.EntityActions.Save(Record))}
              >
                Delete
          </Button>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell className={classes.columnLabel}>Name</TableCell>
            <TableCell className={classes.columnInput}> <TextField name="ltName" margin="dense" id="filled-basic" placeholder="Enter Name" type="text" fullWidth variant="outlined" onChange={handleChange} value={Record.ltName} /></TableCell>
            <TableCell>Example: Countries</TableCell>
          </TableRow>


          <TableRow>
            <TableCell>System FL</TableCell>
            <TableCell><input type="checkbox" name="ltSystemFL" checked={Record.ltSystemFL} onClick={handleChangeChecked} />
            </TableCell>
            <TableCell>If is system need it, not visible for public</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

  );
}
