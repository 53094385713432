import React, { useState, useEffect } from "react";
import { history } from "../helpers";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableFooter } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import * as LanguageHelper from "../helpers/language.helper";
import * as AuthenticationHelper from "../helpers/authentication.helper";

import allActions from "../actions";

function LoadEnglish() {
  return (
    <div>
      <p>
        Welcome [<strong>{AuthenticationHelper.GetFullName()} </strong>]&nbsp;
      </p>
      <p>
        Please use the options list on your left side to check and update your{" "}
        <strong>
          <u>Individual profile</u>
        </strong>{" "}
        from there.
      </p>
      <p>
        If you are a Startup founder, please click on the&nbsp;
        <strong>
          <u>Startup profile</u>
        </strong>
        &nbsp;link where you can check and update your company information.
      </p>
      <p>
        If you are a SMEs owner (you started to make a revenue), please click on
        the&nbsp;
        <strong>
          <u>SME profile</u>
        </strong>
        &nbsp;link where you can fill your company information.
      </p>
    </div>
  );
}

function LoadEnglishGeneral() {
  return (
    <div>
      <p>
        Welcome <strong>{AuthenticationHelper.GetFullName()} </strong> &nbsp;
      </p>
      <p>
        Please use the options list on your left side to check and update your{" "}
        <strong>
          <u>Individual / Startup / SME profiles</u>
        </strong>{" "}
        from there.
      </p>
    </div>
  );
}

function LoadArabic() {
  return (
    <div>
      <p>
        مرحبا [<strong>{AuthenticationHelper.GetFullName()} </strong>]&nbsp;
      </p>
      <p>
        يرجى استخدام قائمة الخيارات الموجودة على الطرف الأيمين لتفقد و تحديث{" "}
        <strong>
          <u>ملفك الشخصي</u>
        </strong>{" "}
        من هناك.
      </p>
      <p>
        إذا كنت صاحب شركة ناشئة, يرجى الضغط على{" "}
        <strong>
          <u>ملف الشركة الناشئة </u>
        </strong>
        حيث بإمكانك تفقد و تحديث معلومات شركتك.
      </p>
      <p>
        إذا كنت صاحب شركة صغيرة أو متوسطة ذات دخل مادي, يرجى الضغط على{" "}
        <strong>
          <u>ملف الشركة الصغيرة&nbsp; و المتوسطة </u>
        </strong>
        &nbsp;حيث بإمكانك تعبئة المعلومات الخاصة بشركتك.
      </p>
    </div>
  );
}

function LoadTurkey() {
  return (
    <div>
      <p>
        Hoşgeldiniz [<strong>{AuthenticationHelper.GetFullName()} </strong>
        ]&nbsp;
      </p>
      <p>
        Yararlanıcı profilinizi yerine getirmek için lütfen solunuzdaki veya sağ
        taraftaki (Arapça) seçenekleri kullanın.
      </p>
      <p>
        Zaten bir Başlangıç şirketi olarak kayıtlıysanız, lütfen şirket
        bilgilerinizi girebileceğiniz Başlangıç profili bağlantısını seçin.
      </p>
      <p>
        Zaten bir KOBİ şirketi olarak kayıtlıysanız (gelir elde etmeye
        başladıysanız), lütfen şirket bilgilerinizi girebileceğiniz KOBİ profil
        bağlantısını seçin.
      </p>
    </div>
  );
}
//LanguageID
const languageId = LanguageHelper.GetLanguageID();

export default function Dashboard(args) {
  //ke se zapali smao pri promena

  useEffect(() => {
    async function fetchData() {
      let responses = await allActions.ManageUsersActions.CheckSessionAsync();
      if (responses.error) {
        AuthenticationHelper.SignOut();
        history.push("/signin");
      }
    }
    fetchData();
  }, []);

  if (AuthenticationHelper.IsUser()) {
    let textContent = "";
    if (languageId === "1") {
      textContent = LoadEnglish();
    } else if (languageId === "2") {
      textContent = LoadArabic();
    } else if (languageId === "3") {
      textContent = LoadTurkey();
    } else {
      textContent = LoadEnglish();
    }
    return textContent;
  }
  return LoadEnglishGeneral();
}
