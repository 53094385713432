import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Navigator from './Navigator';
import Content from './Content';
import Header from './Header';
import * as AuthenticationHelper from '../helpers/authentication.helper';
import { Router, Redirect, Switch, Route } from 'react-router-dom';
import BackContainer from '../components/BackContainer';
import { logHelper } from '../helpers/log.helper';

const BackContainerRoute = ({ component: Component, ...rest }) => {
  logHelper.error("ALO");
  logHelper.error(rest);

  //ovdeka definira kako variable DefinedTemplate so BackofficeUI container-ot i so children variabla
  //koja sto ke ja dobie podole  preku component
  {/* <DefinedTemplate>
        <Component {...matchProps} />
  </DefinedTemplate>   
  */}
  const DefinedTemplate = ({ children}) => (BackContainer(children));

  //ovdeka go setira DefinedTemplate so komponentata zadadena od component:LoginUI dodeka na path=se 
  // <BackContainerRoute path="/login2" component={LoginUI} />  
  return (
    <Route {...rest} 
    
    render={matchProps => (
      
      //samo ako e logiran vo sistem togas ke odi na defined template 
      //ako ne ke go odlogira
      AuthenticationHelper.IsSigned()
      
      ?
        
        //{..matchProps} e samata komponenta
        // {...rest} se propertia kade sto e definiran SECTIOn koj sto pak odreduva koj LEFT menu da se pokaze
        <DefinedTemplate>
              <Component {...matchProps}  {...rest}  />
            </DefinedTemplate>

      :

      <Redirect to={{ pathname: '/signin'}} />
    )} />
  )
};
export default BackContainerRoute;