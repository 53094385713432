import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Axios from "axios";
import * as AuthenticationHelper from '../../helpers/authentication.helper'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import allActions from '../../actions';
import { history } from '../../helpers';
import constants from '../../helpers/constant.helper';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AlertMessage from '../AlertMessage'
import CustomAutocomplete from '../Shared/CustomAutocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

// DEFINIRANJE NA STILOVI
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    flexGrow: 1,
  },
  media: {
    height: 140,
  },
  table: {
    minWidth: 650,
  },
}));

export default function TableList() {

  const dispatch = useDispatch()
  //const QuickSearch = "";
  const classes = useStyles();

  const dataReducer = useSelector(state => state.TableReducer);
  const [EntityObjects, setEntityObject] = useState(dataReducer.Records);
  const [QuickSearch, setQuickSearch] = useState('');

  useEffect(() => {
    setEntityObject(dataReducer.Records);
  }, [dataReducer]);

  //DEFAULT 
  useEffect(() => {
    dispatch(allActions.TableActions.Search({ "EntityTypeId": constants.Entity_TABLES }));
    return () => {
      dispatch(allActions.TableActions.Reset()); //vraka na Initial State
    };
  }, []);   //default

  const handleChange = e => {
    const { name, value } = e.target;  //vadi e.target.name i e.target.value kade sto value e vnesenata vrednost!
    setEntityObject(prevState => ({ ...prevState, [name]: value }));
  };

  const onQuickSearchChange = (e) => {
    const { value } = e.target;  //vadi e.target.name i e.target.value kade sto value e vnesenata vrednost!
    dispatch(allActions.TableActions.Search({
      "EntityTypeId": constants.Entity_TABLES,  //mora da odgovaraat na properties od EntitySearchArguments
      "QuickSearch": value
    }));
    setQuickSearch(value); //za da go napise text-ot vo quick search

  }


  function onEditClass(entityId) {
    //Odi na Dashboard
    history.push('/entity/columns/' + entityId);
  }
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>

          {dataReducer.hasError &&
            <TableRow>
              <TableCell colSpan="5">
                <AlertMessage {...dataReducer} />
              </TableCell>
            </TableRow>
          }

          <TableRow>
            <TableCell colSpan="3">
              <h1>List of Objects</h1>
            </TableCell>
            <TableCell>
              <TextField name="QuickSearch" margin="dense" id="filled-basic" placeholder="Quick Find" type="text" fullWidth variant="outlined" onChange={onQuickSearchChange} value={QuickSearch} />
            </TableCell>
            <TableCell align="center">
              <Button size="small" color="primary" onClick={() => history.push('/entity/table/create')}>
                Create Object
                    </Button>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell style={{ 'width': '20%' }}><b>Label</b></TableCell>
            <TableCell align="center" style={{ 'width': '20%' }}><b>Object Description</b></TableCell>
            <TableCell align="center" style={{ 'width': '20%' }}><b> Object API Name</b></TableCell>
            <TableCell align="center"><b>Data Created</b></TableCell>
            <TableCell align="center"><b>Actions</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataReducer.Loading &&
            <TableRow>
              <TableCell colSpan="5">
                <CircularProgress color="inherit" />
              </TableCell>
            </TableRow>
          }

          {!dataReducer.Loading && EntityObjects.map((row, index) => (
            <TableRow key={row.EntityId}>
              <TableCell component="th" scope="row">
                {index + 1}. {row.Name}
              </TableCell>
              <TableCell>{row.Description}</TableCell>
              <TableCell>{row.APIName}</TableCell>
              <TableCell align="right">{new Date(row.CreatedDate).toLocaleDateString()}</TableCell>
              <TableCell align="center">
                <Button size="small" color="primary" onClick={() => history.push('/entity/table/edit/' + row.EntityId)}>Manage Object</Button>
                <Button size="small" color="primary" onClick={() => history.push('/entity/columns/' + row.EntityId)}>Manage Columns</Button>
                <Button size="small" color="primary" onClick={() => window.open('/Preview/' + row.EntityId)}>Preview Page</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  );
}
