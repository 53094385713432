import resourceManager from '../helpers/constant.helper';
import constants from '../helpers/constant.helper';




//sekoe novo properti dodadi go ovde so DEFAULT value
const initialState = {

    Record: {
        stStartupID: 0,
        stTypeID: '',
        stCountryID: 0,
        stProvinceID: 0,
        stCountryNationalityID: 0,
        stName: '',
        stRegistryNumber: '',
        stAddress: '',
        stCity: '',
        stMobilePhone: '',
        stHomePhone: '',
        stEmail: '',
        stWeb: '',
        stSectorIDs: '',
        stStartDate: '2020-06-26',
        stRegistrationDate: '2020-06-26',
        stInitStageID: 0,
        stProvinceOperationID: 0,
        stCurrentStatusID: 0,
        stCurrentStageID: 0,
        stDateCreated: '2020-06-26',
        stCreatedBy: '',
        stInitFullEmployees: '',
        stInitPartEmployees: '',
        stFirstName: '',
        stLastName: '',
        stBirthDate: '2020-06-26',
        stGenderID: 0,
        stEmploymentStatusID: 0,
        stSISID:'',
        stPartnerID:0,
        stCountryNationalityIDs:'',
        stNote:'',

        REF_BENEFID:0,
        REF_STARTUPID:0,
        REF_SMEID:0,
        REF_BENEF_ITEMGUID:'',
        REF_STARTUP_ITEMGUID:'',
        REF_SME_ITEMGUID:'',
        IsBenef:false,
        IsStartup:false,
        IsSME:false,

        stPassword:'SparkBTS2@21',
        stDisabilityID:0
    },

    LookupData: [],  //Table: LookupData
    ScholarshipCountries: [],   //Table: Country
    Provinces: [],   //Table: CountryProvince
    Nationalities:[],
    Records: [],


    ValidationMessages:[],
    Message: '',
    Loading: true,
    hasError: false,
}

const BenefReducer = (state = initialState, action) => {
    switch (action.type) {
        case "BENEF_START":
            return {
                ...state,
                Loading: true,
                hasError: false
            }

        case "BENEF_ERROR":
            return {
                ...state,
                Message: action.error,
                Loading: false,
                hasError: true
            }


        case "BENEF_SAVE_ERROR":
            return {
                ...state,
                Message: action.error,
                ValidationMessages:action.ValidationMessages,
                Record: action.Record,  //istite vrednosti gi vraka tie sto gi vnesol
                Loading: false,
                hasError: true
            }

        case "BENEF_SAVE_SUCCESS":
            return {
                //...state,
                ...state,       //se drugo e vo STATE-ot
                Record: action.Record,  //samo noviot record ako e NOV, ako e update si ostanuva IST
                Message: resourceManager.MSG_SAVE,
                ValidationMessages:[], //resetirj clientski greski
                Loading: false,
                hasError: false
            }


        case "BENEF_EDIT":
            return {
                ...state,
                //ako od server vratil NULL togas setiraj inital state vo sprotivno ne raboti state-ot
                Record: action.Record === null ? initialState.Record : action.Record,
                LookupData: action.LookupData,
                ScholarshipCountries: action.ScholarshipCountries,
                Provinces: action.Provinces,
                Nationalities : action.Nationalities,
                Message: "",
                Loading: false,
                hasError: false
            }

        // case "STARTUP_CREATE":
        //     return {
        //         ...state,
        //         Record: initialState.Record,    //za da se resetira
        //         LookupData: action.LookupData,
        //         ScholarshipCountries: action.ScholarshipCountries,
        //         Provinces: action.Provinces,

        //         Message: "",
        //         Loading: false,
        //         hasError: false
        //     }

        //se koristi vo find
        case "BENEF_SEARCH":
            return {
                ...state,
                Records: action.Records,  //action.payload == List<Entity>
                Message: "",
                Loading: false,
                hasError: false
            }

        //se koristi vo find
        case "BENEF_RESET":
            state = initialState; //go resetira na iniital state
            return {
                ...state
            }

        default:
            return state
    }
}
export default BenefReducer;