import {
    GET_STARTUP,
    SEARCH_STARTUP
} from './types';
import { logHelper } from '../helpers/log.helper';

export default (state, action) => {
    logHelper.log(state)
    switch (action.type) {
        case SEARCH_STARTUP:
            return {
                ...state,
                user: action.payload
            }
        default: return state;
    }
}