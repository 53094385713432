import LoginUserReducer from './LoginUserReducer'
import TableReducer from './TableReducer'
import LookupTypeReducer from './LookupTypeReducer'
import LookupDataReducer from './LookupDataReducer'
import ColumnReducer from './ColumnReducer'
import UserProfileReducer from './UserProfileReducer'
import RegisterUserReducer from './RegisterUserReducer'
import StartupReducer from './StartupReducer'
import StartupFounderReducer from './StartupFounderReducer'
import BenefReducer from './BenefReducer'
import SmeReducer from './SmeReducer'
import SmeFounderReducer from './SmeFounderReducer'
import ImportActivityReducer from './ImportActivityReducer'
import SmeEmployeeReducer from './SmeEmployeeReducer'
import SearchStartupReducer from './SearchStartupReducer'
import ReportReducer from './ReportReducer'
import ManageUsersReducer from './ManageUsersReducer'
import GlobalReducer from './GlobalReducer'

import ExportReducer from './Export/ExportReducer'
import SendSMSReducer from './Export/SendSMSReducer'
import SearchActivityReducer from './SearchActivityReducer'

import { combineReducers } from 'redux'

const rootReducer = combineReducers({
    LoginUserReducer,
    TableReducer,
    LookupTypeReducer,
    ColumnReducer,
    LookupDataReducer,
    UserProfileReducer,
    RegisterUserReducer,
    StartupReducer,
    StartupFounderReducer,
    BenefReducer,
    SmeReducer,
    SmeFounderReducer,
    ImportActivityReducer,
    SmeEmployeeReducer,
    SearchStartupReducer,
    ManageUsersReducer,
    ExportReducer,
    SendSMSReducer,
    ReportReducer,
    GlobalReducer,
    SearchActivityReducer
})

export default rootReducer