import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import * as AuthenticationHelper from '../helpers/authentication.helper';
import allActions from '../actions';
import MuiAlert from '@material-ui/lab/Alert';
import AlertMessage from '../components/AlertMessage'
import { useCookies } from "react-cookie";
import Logo1 from '../assets/AlNouri_logo.jpg'
import Logo2 from '../assets/logo_af.jpg'
import Logo3 from '../assets/Quest_logo.jpg'
import Logo4 from '../assets/QFFD_Logo.jpg'
import Logo5 from '../assets/NLMFA_Logo.jpg'
import Logo6 from '../assets/IsDB_logo.jpg'
import Logo7 from '../assets/AlNouri_logo.jpg'
import Logo8 from '../assets/logo_eu.jpg'
import config from '../config/config';
import PleaseWaitMessage from '../components/PleaseWaitMessage'
import { InputDropDownLanguage } from '../components/Shared/PreviewComponents';
import * as LanguageHelper from '../helpers/language.helper';
import { logHelper } from '../helpers/log.helper';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.spark-online.org/">
        www.spark-online.org
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const rtl = LanguageHelper.setLTR();


const useStyles = makeStyles((theme) => ({
  root: {
    direction: { rtl },   //'rtl' or ltr
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  spiner: {
    marginTop: 20
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SignIn() {
  const classes = useStyles();
  const keys = LanguageHelper.GetLanguageKeys();
  const languageId = LanguageHelper.GetLanguageID();


  // const [cookies, setCookie] = useCookies(['access_token', 'refresh_token'])

  // let expires = new Date()
  // expires.setTime(expires.getTime() + (10000 * 10000))
  // setCookie('access_token', "OK22222222", { path: '/', expires })

  //obezbedi dobar Authentication state...
  AuthenticationHelper.SignOut();


  const dataReducer = useSelector(state => state.LoginUserReducer);

 
  const dispatch = useDispatch();

  const isLoading = dataReducer.Loading;
  const rtl = LanguageHelper.setLTR();
 

  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {

    return () => {
      dispatch(allActions.LoginActions.Reset()); //vraka na Initial State
    };
  }, []);   //default

 
  return (
    <Fragment>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>

          <h1>{config.AppTitle}</h1>

          {InputDropDownLanguage(languageId)}

          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {keys["SignInText"]}
          </Typography>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="userName"
            label={keys["Email"]}
            name="email"
            autoComplete="userName"
            autoFocus
            //onChange={e => dispatch({ type: 'UserName_Change', payload: e.target.value })}
            onChange={(e) => setUserName(e.target.value)}
            value={userName}
            dir={rtl}
          />


          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={keys["Password"]}
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            dir={rtl}
          />

          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => dispatch(allActions.LoginActions.Login(userName, password))}
            disabled={isLoading}
            dir={rtl}
          >
            {keys["SignInText"]}
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="signUp" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
          <PleaseWaitMessage {...dataReducer} />
          <AlertMessage {...dataReducer} />
        </div>
      </Container>
      <Box mt={8} display={{ xs: "none", sm: "block" }}>
        <Grid container justify="center" alignItems="flex-end">
          <Grid item style={{ marginRight: 20 }}>
            <img src={Logo4} alt="website logo" />
          </Grid>
          <Grid item style={{ marginRight: 20 }}>
            <img src={Logo8} alt="website logo" />
          </Grid>
          <Grid item style={{ marginRight: 20 }}>
            <img src={Logo6} alt="website logo" />
          </Grid>
          <Grid item style={{ marginRight: 20 }}>
            <img src={Logo1} alt="website logo" />
          </Grid>
          <Grid item style={{ marginRight: 20 }}>
            <img src={Logo3} alt="website logo" />
          </Grid>
          <Grid item style={{ marginRight: 20 }}>
            <img src={Logo2} alt="website logo" />
          </Grid>
          <Grid item style={{ marginRight: 20 }}>
            <img src={Logo5} alt="website logo" />
          </Grid>
        </Grid>
      </Box>
      <Box mt={8}>
        <Copyright />
      </Box>

    </Fragment>
  );
}
