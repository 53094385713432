import { restHelper } from '../helpers/rest.helper';

const Load = async (payLoad) => {
    let response = await restHelper.post('api/Preview/Load', payLoad);
    return response;
}

const Add = async (payLoad) => {
    let response = await restHelper.post('api/Preview/Add', payLoad);
    return response;
}

const Update = async (payLoad) => {
    let response = await restHelper.post('api/Preview/Update', payLoad);
    return response;
}

export default {
    Load,
    Add,
    Update
}