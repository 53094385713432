module.exports = {
  //baseUrl: 'https://cv.spark-online.org/',
  //siteUrl: 'https://bts.he4s.eu', //'http://localhost:3000/', //www.bts.he4s.eu
  //baseUrl: 'https://api.he4s.eu/',      //baseUrl: 'https://localhost/',
  baseUrl: "https://api.he4s.eu/",
  //baseUrl: "https://localhost/",
  baseUrlDevelopment: "http://localhost:65365/", //baseUrl: 'https://localhost/',
  //imageUrl: 'https://localhost/temp/',
  //baseUrl: 'https://8b56b3e42f73.ngrok.io/',
  cachelookupvalues: false,
  client_id: "BTSApp",
  client_secret: "abc@123",

  ROLE_ADMIN: "1CDE66EE-8CFC-4FF2-B3D6-2B53540ECA9E",
  ROLE_OFFICER: "1ADE66EE-8CFC-4FF2-B3D6-2B53540ECA9E",
  ROLE_BTS_OFFICER: "123E66EE-8CFC-4FF2-B3D6-2B53540ECAAA",
  ROLE_DONOR: "AAAE66EE-8CFC-4FF2-B3D6-2B53540ECA9E",
  ROLE_USER: "54B78423-36D6-4F6B-9E2B-BB358112FC518", //a.k.a startup
  AppTitle: "Business tracking system",
  AppSubTitle: "Business tracking system",
};
