import { restHelper } from '../helpers/rest.helper';


const Search = async (payLoad) => {
    let response = await restHelper.post('api/Lookup/Search', payLoad);
    return response;
}

const Save = async (payLoad) => {
    let response = await restHelper.post('api/Lookup/Save', payLoad);
    return response;
}

const Edit = async (payLoad) => {
    let response = await restHelper.post('api/Lookup/Edit', payLoad);
    return response;
}

export default {
    Search,
    Save,
    Edit
}