import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Axios from "axios";
import * as AuthenticationHelper from "../../helpers/authentication.helper";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import allActions from "../../actions";
import { history } from "../../helpers";
import constants from "../../helpers/constant.helper";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AlertMessage from "../AlertMessage";
import AlertMessageNew from "../AlertMessageNew";

import CustomAutocomplete from "../Shared/CustomAutocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import Keys from "../../json/ResourceStartup.json";
import {
  InputDropDownCheckboxLookupOnlyWithEmailPathFilter,
  InputDropDownPagination,
  InputDate2,
  InputDropDownLookup,
  InputTextOnly,
  InputDropDownOnly2,
  InputDropDownOnly,
  InputDropDownLookupOnly,
  InputDateOnly,
  InputDropDownCheckboxLookupOnly,
  InputDropDownCountries,
} from "./../Shared/PreviewComponents";
import Select from "@material-ui/core/Select";
import CustomModal from "../Shared/CustomModal";
import SendEmail from "../Shared/SendEmail";
import SendSMS from "../Shared/SendSMS";
import ExcelStartupsExport from "../Shared/ExcelStartupsExport";
import { logHelper } from "../../helpers/log.helper";
import { TableFooter } from "@material-ui/core";
import * as localStorageHelper from "../../helpers/localstorage.helper";

// DEFINIRANJE NA STILOVI
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    flexGrow: 1,
  },
  media: {
    height: 140,
  },
  table: {
    minWidth: 650,
    overflow: "hidden",
  },
}));

const RecordDefault = {
  StartupID: "",
  ItemGUID: "",
  OwnerID: "", //ovoj e KLUC za od admin ili officers da bira raznlicni startups
  TypeID: 0,
  CountryID: "0",
  ProvinceID: "0",
  Name: "",
  RegistryNumber: "",
  Address: "",
  City: "",
  MobilePhone: "",
  HomePhone: "",
  Email: "",
  Web: "",
  SectorID: "0",
  StartDate: "2020-06-26",
  RegistrationDate: "2020-06-26",
  InitStageID: "0",
  ProvinceOperationID: "0",
  CurrentStatusID: "0",
  CurrentStageID: "0",
  DateCreated: "2020-06-26",
  CreatedBy: "",
  InitFullEmployees: "",
  InitPartEmployees: "",
  FirstName: "",
  LastName: "",

  SectorIDs: "",
  ActivityCategoryIDs: "",
  ActivityTypeIDs: "",
  DonorIDs: "",
  DonorParentIDs: "",
  DonorReportIDs: "",
  acFrom: "",
  acTo: "",
  stGenderID: "0",
  stDisabilityID: "0",
  stNumberDailyClientID: "0",
  stBusinessInsuranceID: "0",
  stBusinessLoanID: "0",
  stIncomeID: "0",
  stInitStageID: "0",
  stBusinessOperationLocationID: "0",
  stBussinessFinancialID: "0",
  stCurrentBusinessStatusID: "0",
  stEmploymentStatusID: "0",

  //834	1012	Startups with scholarships (from SIS)
  // 835	1012	Startups without scholarships
  HasScholarshipFL: "0",

  ActivityStartDate: "0",
  ActivityEndDate: "0",
  InitialBusinessStage: "0",
  BusinessOperationLocation: "0",
  BusinessFinancial: "0",
  CurrentBusinessStatus: "0",
  Nationality: "0",
  Gender: "0",
  Disability: "0",
  EmploymentStatus: "0",
  NumberDailyClientCustomers: "0",
  BusinessHasInsurance: "0",
  BusinessHasLoan: "0",
  Income: "0",
  FounderName: "",

  PageSize: 50,
  PageIndex: 0,
  TotalPages: 0,
  TotalRecords: 0,
};

export default function SearchStartup(args) {
  const keys = Keys[0]; //za da naoga textovi od JSON FIle
  const dispatch = useDispatch();
  const classes = useStyles();

  //const dataReducer = useSelector(state => state.SearchStartupReducer);

  // logHelper.log("dataReducer");
  // logHelper.log(dataReducer);

  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);
  const [PanelTypeID, setPanelTypeID] = useState(0);
  const [Records, setRecords] = useState([]);
  const [ScholarshipCountries, setScholarshipCountries] = useState([]);
  const [Nationalities, setNationalities] = useState([]);
  const [Partners, setPartners] = useState([]);
  const [IsCheckedFLAll, setIsCheckedFLAll] = useState(false);

  const [ModalParams, setModalExport] = useState({
    ShowModal: false,
    Content: "",
    SelectedExportValue: 0,
  }); //ModalParams e objekt koj sto IsOpen i IsContent ima struktura

  const [Provinces, setProvinces] = useState([]);
  const [ProvincesByCountry, setProvincesByCountry] = useState([]);
  //const [StartupTypes, setStartupTypes] = useState([]);
  const [LookupData, setLookupData] = useState([]);
  const [HeaderTitle, setHeaderTitle] = useState("");

  const isOfficer = AuthenticationHelper.IsOfficer(); //mu treba za drop down za Country da mu ja dade samo negovata zemja i vo DropDownCountries da ne pokazuva Select Countries
  const isBTSOfficer = AuthenticationHelper.IsBTSOfficer(); //mu treba za drop down za Partners
  const isDonor = AuthenticationHelper.IsDonor(); //mu treba za drop down za Partners
  const isSubSuhara = AuthenticationHelper.IsSubSahara();

  useEffect(() => {
    initData();

    //DESTRUCTOR
    return () => {};
  }, []); //default

  const DoCheckUnCheck = (row, checked, propertyIndex) => {
    let newRecords = [...Records]; // copying the old datas array
    newRecords[propertyIndex]["IsCheckedFL"] = checked;
    setRecords(newRecords);
  };

  const DoCheckUnCheckAll = (checked) => {
    //alert(newArr.length);
    let newRecords = [...Records]; // copying the old datas array
    for (let i = 0; i <= newRecords.length - 1; i++) {
      newRecords[i]["IsCheckedFL"] = checked;
    }
    setRecords(newRecords);
    setIsCheckedFLAll(checked);
  };

  const handleChangeExport = (e) => {
    const { name, value } = e.target; //name: SelectedExportValue

    var index = e.nativeEvent.target.selectedIndex;
    var currentOptionText = e.nativeEvent.target[index].text;

    if (parseInt(value) === 1) {
      //daj gi site stiklirani items
      const RecordsChecked = Records.filter((a) => a.IsCheckedFL === true);
      //pripremi argumenti za Send Email
      let payload = { Records: RecordsChecked }; //vo SendEmail odgovara Records na args.Records

      //Povikaj ja controla za Send Email i stavi ja vo variabla
      let controlSendEmail = <SendEmail {...payload} />;

      //pokazi POPUP so Send Email
      setModalExport({
        ShowModal: true,
        Content: controlSendEmail,
        [name]: value,
      });
    } else if (parseInt(value) === 2) {
      //daj gi site stiklirani items
      const RecordsChecked = Records.filter((a) => a.IsCheckedFL === true);
      //pripremi argumenti za Send Email
      let payload = { Records: RecordsChecked }; //vo SendEmail odgovara Records na args.Records

      //Povikaj ja controla za Send Email i stavi ja vo variabla
      let controlSendSMS = <SendSMS {...payload} />;

      //pokazi POPUP so Send Email
      setModalExport({
        ShowModal: true,
        Content: controlSendSMS,
        [name]: value,
      });
    } else if (parseInt(value) === 3) {
      //daj gi site stiklirani items
      const RecordsChecked = Records;

      logHelper.log(RecordsChecked);

      //pripremi argumenti za Send Email  <select> <option value='1' text=2></option>
      //let payload = { Records: RecordsChecked, Header: currentOptionText };  //vo SendEmail odgovara Records na args.Records

      //ke prati argumenti kade sto ke bidat StartupIDs t.e. Record.StartupIDs
      let controlSendSMS = <ExcelStartupsExport {...Record} />;

      //pokazi POPUP so Send Email
      setModalExport({
        ShowModal: true,
        Content: controlSendSMS,
        [name]: value,
      });
    } else {
      resetModalDialog();
    }
  };

  const resetModalDialog = () => {
    setModalExport({ ShowModal: false, Content: "", SelectedExportValue: 0 });
  };
  const handleChange = (e) => {
    if (e.key === "Enter") {
      doSearch();
      return;
    }

    logHelper.log("od handle change");
    logHelper.log(e.target);
    const { name, value } = e.target;

    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeMulti = (controlId, options) => {
    logHelper.log(options);
    logHelper.log(controlId);
    let selectValues = "";

    options.map((option) => {
      const selectedValue = option.ldLookupDataID.toString();
      if (selectValues === "") {
        selectValues = selectedValue;
      } else {
        selectValues += "," + selectedValue;
      }
    });

    setRecord((prevState) => ({ ...prevState, [controlId]: selectValues }));
  };

  const handleChangeCountry = (e) => {
    if (e.key === "Enter") {
      doSearch();
      return;
    }

    logHelper.log("od handle change");
    logHelper.log(e.target);
    const { name, value } = e.target; //value ==> CountryId

    logHelper.log("value vo handle");
    logHelper.log(value);

    const filterProvinceByCountry = Provinces.filter(
      (a) => a.ParentId === value.toString()
    );

    //da se resetira province-ot za validacija i toa vo "0" za da raboti ne 0
    setProvincesByCountry(filterProvinceByCountry);

    setRecord((prevState) => ({
      ...prevState,
      [name]: value,
      ["ProvinceID"]: "0",
    }));
  };

  const onModalClose = () => {
    resetModalDialog();
  };

  const PreviewRecord = (ItemGUID, stTypeID) => {
    //setiraj ITEM GUID za selektiraniot START UP

    AuthenticationHelper.SetStartupPreview(true);
    AuthenticationHelper.SetStartUpItemGUID(ItemGUID);

    if (stTypeID === constants.TYPEID_BENEFECIARY) {
      history.push("/admin/benef/manage");
    } else if (stTypeID === constants.TYPEID_STARTUP) {
      history.push("/admin/startup/manage");
    } else if (stTypeID === constants.TYPEID_SME) {
      history.push("/admin/sme/manage");
    }
  };

  const EditRecord = (ItemGUID, stTypeID) => {
    //setiraj ITEM GUID za selektiraniot START UP

    AuthenticationHelper.SetStartupPreview(false);
    AuthenticationHelper.SetStartUpItemGUID(ItemGUID);

    if (stTypeID === constants.TYPEID_BENEFECIARY) {
      history.push("/admin/benef/manage");
    } else if (stTypeID === constants.TYPEID_STARTUP) {
      history.push("/admin/startup/manage");
    } else if (stTypeID === constants.TYPEID_SME) {
      history.push("/admin/sme/manage");
    }
  };

  const doSearch = () => {
    resetModalDialog();

    //resetiraj index
    Record.PageIndex = 0;
    Record.TotalPages = 0;
    Record.TotalRecords = 0;
    setRecord(Record);
    searchData();
  };

  const initData = (args) => {
    async function fetchData() {
      const isSME = window.location.href.indexOf("admin/SearchSME") > -1;
      const isStartup =
        window.location.href.indexOf("admin/SearchStartup") > -1;
      const isBenef = window.location.href.indexOf("admin/SearchBenef") > -1;
      let TypeID = 0;

      if (isSME) {
        setHeaderTitle("Search SMEs");
        TypeID = constants.TYPEID_SME;
      } else if (isStartup) {
        setHeaderTitle("Search Startup");
        TypeID = constants.TYPEID_STARTUP;
      } else if (isBenef) {
        setHeaderTitle("Search Individual");
        TypeID = constants.TYPEID_BENEFECIARY;
      }
      //mu treba za na Server
      Record.TypeID = TypeID;

      //proveri dali ja ima vo cache
      const storageRecord = localStorageHelper.GetJSON(
        constants.SearchStartupsArgs_LocalStorageKey + TypeID
      );
      if (storageRecord) {
        setRecord(storageRecord);
      }

      //mu treba da kaze koja PANEL ke prikaze
      setPanelTypeID(TypeID);

      setMessageData({ Loading: true });

      let responses = await allActions.SearchStartupActions.InitAsync(Record);
      if (!responses.error) {
        const LookupDataRecords = responses[0].data;
        const CountriesScholarhipsRecords = responses[1].data;
        const CountriesProvincesRecords = responses[2].data;
        const CountriesNationalitiesRecords = responses[3].data;
        const PartnerDataRecords = responses[4].data;

        setLookupData(LookupDataRecords);
        setScholarshipCountries(CountriesScholarhipsRecords);
        setNationalities(CountriesNationalitiesRecords);
        setPartners(PartnerDataRecords);

        if (isOfficer) {
          const countryId = CountriesScholarhipsRecords[0].Value;

          const filterProvinceByCountry = CountriesProvincesRecords.filter(
            (a) => a.ParentId === countryId.toString()
          );
          setProvincesByCountry(filterProvinceByCountry);

          Record.CountryID = countryId;
          setRecord(Record);
        } else {
          setProvincesByCountry(CountriesProvincesRecords);
          setProvinces(CountriesProvincesRecords);
        }

        setMessageData({ Loading: false });
      } else {
        setMessageData({
          HasError: true,
          Message: responses.error_description,
        });
      }
    }

    fetchData();
  };

  const searchData = (args) => {
    async function fetchData() {
      setRecords([]);
      setMessageData({ Loading: true });

      let responses = await allActions.SearchStartupActions.AdvancedSearchAsync(
        Record
      );
      if (!responses.error) {
        const StartupRecords = responses[0].data.Results;
        const ArgsData = responses[0].data.Args; //go vraka samiot record nazad na clietn

        if (ArgsData.StartupID === 0) {
          ArgsData.StartupID = "";
        }

        //stavi gi vo cache za sledno citanje site argumenti ako e Benef / Startup / SME
        localStorageHelper.SetJSON(
          constants.SearchStartupsArgs_LocalStorageKey + PanelTypeID,
          ArgsData
        );

        setMessageData({ Loading: false });

        setRecords(StartupRecords);
        setRecord(ArgsData);
      } else {
        setMessageData({
          HasError: true,
          Message: responses.error_description,
        });
      }
    }

    fetchData();
  };

  const goToNextPage = () => {
    resetModalDialog();

    Record.PageIndex = parseInt(Record.PageIndex) + 1;
    setRecord(Record);
    searchData();
  };

  const goToPrevPage = () => {
    resetModalDialog();
    Record.PageIndex = parseInt(Record.PageIndex - 1);
    setRecord(Record);
    searchData();
  };

  // <!--FIELDS FOR SMES-->
  const ShowSearchFieldsForSME = () => {
    return (
      <React.Fragment>
        <TableRow>
          <TableCell>
            {InputDropDownLookupOnly(
              "NumberDailyClientCustomers",
              LookupData,
              constants.Lookup_NumberDailyClient,
              Record.NumberDailyClientCustomers,
              handleChange,
              MessageData.Loading,
              "- Number of Daily Client/Customers -"
            )}
          </TableCell>
          <TableCell>
            {InputDropDownLookupOnly(
              "BusinessHasInsurance",
              LookupData,
              constants.Lookup_BusinessInsurance,
              Record.BusinessHasInsurance,
              handleChange,
              MessageData.Loading,
              "- Business has Insurance -"
            )}
          </TableCell>
          <TableCell>
            {InputDropDownLookupOnly(
              "BusinessHasLoan",
              LookupData,
              constants.Lookup_BusinessLoan,
              Record.BusinessHasLoan,
              handleChange,
              MessageData.Loading,
              "- Business has a Loan -"
            )}
          </TableCell>
          <TableCell>
            {InputDropDownLookupOnly(
              "Income",
              LookupData,
              constants.Lookup_Income,
              Record.Income,
              handleChange,
              MessageData.Loading,
              "- Income -"
            )}
          </TableCell>
          <TableCell>
            {isSubSuhara &&
              InputTextOnly(
                "FounderName",
                Record.FounderName,
                handleChange,
                "Search by Owner Name",
                MessageData.Loading
              )}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  // <!--FIELDS FOR STARTUPS-->
  const ShowSearchFieldsForSTARTUP = () => {
    return (
      <React.Fragment>
        <TableRow>
          <TableCell>
            {InputDropDownLookupOnly(
              "InitialBusinessStage",
              LookupData,
              constants.Lookup_IniitialBusinessStage,
              Record.InitialBusinessStage,
              handleChange,
              MessageData.Loading,
              "- Initial Business Stage -"
            )}
          </TableCell>
          <TableCell>
            {InputDropDownLookupOnly(
              "BusinessOperationLocation",
              LookupData,
              constants.Lookup_BusinessOperationLocation,
              Record.BusinessOperationLocation,
              handleChange,
              MessageData.Loading,
              "- Business Operation Location -"
            )}
          </TableCell>
          <TableCell>
            {InputDropDownLookupOnly(
              "BusinessFinancial",
              LookupData,
              constants.Lookup_BussinessFinancial,
              Record.BusinessFinancial,
              handleChange,
              MessageData.Loading,
              "- Business Financial -"
            )}
          </TableCell>
          <TableCell>
            {InputDropDownLookupOnly(
              "CurrentBusinessStatus",
              LookupData,
              constants.Lookup_CurrentBusinessStatus,
              Record.CurrentBusinessStatus,
              handleChange,
              MessageData.Loading,
              "- Current Business Status -"
            )}
          </TableCell>

          <TableCell>
            {InputDropDownLookupOnly(
              "Gender",
              LookupData,
              constants.Lookup_Gender,
              Record.Gender,
              handleChange,
              MessageData.Loading,
              "- Gender -"
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            {InputDropDownOnly(
              "Nationality",
              Nationalities,
              Record.Nationality,
              handleChangeCountry,
              "- Nationality -",
              true
            )}
          </TableCell>
          <TableCell>
            {InputDropDownLookupOnly(
              "Disability",
              LookupData,
              constants.Lookup_Disability,
              Record.Disability,
              handleChange,
              MessageData.Loading,
              "- Disability -"
            )}
          </TableCell>
          <TableCell>
            {InputDropDownLookupOnly(
              "EmploymentStatus",
              LookupData,
              constants.Lookup_EmploymentStatus,
              Record.EmploymentStatus,
              handleChange,
              MessageData.Loading,
              " - Employment Status - "
            )}
          </TableCell>
          <TableCell>
            {isSubSuhara &&
              InputTextOnly(
                "FounderName",
                Record.FounderName,
                handleChange,
                "Search by Owner Name",
                MessageData.Loading
              )}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  // <!--FIELDS FOR BENEFICIARY-->
  const ShowSearchFieldsForBENEFICIARY = () => {
    return (
      <React.Fragment>
        <TableRow>
          <TableCell>
            {InputDropDownOnly(
              "Nationality",
              Nationalities,
              Record.Nationality,
              handleChangeCountry,
              "- Nationality -",
              true
            )}
          </TableCell>
          <TableCell>
            {InputDropDownLookupOnly(
              "Gender",
              LookupData,
              constants.Lookup_Gender,
              Record.Gender,
              handleChange,
              MessageData.Loading,
              "- Gender -"
            )}
          </TableCell>
          <TableCell>
            {InputDropDownLookupOnly(
              "Disability",
              LookupData,
              constants.Lookup_Disability,
              Record.Disability,
              handleChange,
              MessageData.Loading,
              "- Disability -"
            )}
          </TableCell>
          <TableCell>
            {InputDropDownLookupOnly(
              "EmploymentStatus",
              LookupData,
              constants.Lookup_EmploymentStatus,
              Record.EmploymentStatus,
              handleChange,
              MessageData.Loading,
              " - Employment Status - "
            )}
          </TableCell>

          <TableCell>
            {InputDropDownLookupOnly(
              "HasScholarshipFL",
              LookupData,
              constants.Lookup_StartupWithScholarships,
              Record.HasScholarshipFL,
              handleChange,
              MessageData.Loading,
              "- All Beneficiaries -"
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colspan={2}>
            {InputDropDownCheckboxLookupOnly(
              "SectorIDs",
              LookupData,
              constants.Lookup_SectorOfBusiness,
              Record.SectorIDs,
              handleChangeMulti,
              MessageData.Loading,
              "- Sector Of Business/Internship -"
            )}
          </TableCell>
        </TableRow>
        {/* <TableRow>
          <TableCell>
            {InputDate2(classes, "Activity start date from", "ActivityStartDate", Record.ActivityStartDate, handleChange, MessageData.Loading, dataReducer.ValidationMessages, false)}
          </TableCell>
          <TableCell>
            {InputDate2(classes, "Activity end date to", "ActivityEndDate", Record.ActivityEndDate, handleChange, MessageData.Loading, dataReducer.ValidationMessages, false)}
          </TableCell>
        </TableRow> */}
      </React.Fragment>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell colSpan="9">
              <AlertMessageNew {...MessageData} />
              <h1>{HeaderTitle}</h1>
            </TableCell>

            <TableCell colSpan="2" align="right">
              {InputDropDownPagination(
                "PageSize",
                LookupData,
                constants.Lookup_Pagination,
                Record.PageSize,
                handleChange,
                MessageData.Loading,
                ""
              )}
              <span> </span>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
                onClick={doSearch}
                disabled={MessageData.Loading}
              >
                Search
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell colspan={11}>
              <Table style={{ width: "100%", tableLayout: "auto" }}>
                <TableRow>
                  <TableCell>
                    {InputDropDownOnly(
                      "CountryID",
                      ScholarshipCountries,
                      Record.CountryID,
                      handleChangeCountry,
                      "- All Countries -",
                      true
                    )}
                  </TableCell>
                  <TableCell>
                    {InputDropDownOnly(
                      "ProvinceID",
                      ProvincesByCountry,
                      Record.ProvinceID,
                      handleChange,
                      "- All Provinces -"
                    )}
                  </TableCell>
                  <TableCell>
                    {InputDropDownOnly(
                      "PartnerID",
                      Partners,
                      Record.PartnerID,
                      handleChangeCountry,
                      "- All Partners -",
                      !isBTSOfficer
                    )}
                  </TableCell>

                  {/* <TableCell>
                    {InputDropDownOnly("TypeID", StartupTypes, Record.TypeID, handleChange, "- All Groups -", MessageData.Loading)}
                  </TableCell> */}
                  <TableCell>
                    {InputTextOnly(
                      "StartupID",
                      Record.StartupID,
                      handleChange,
                      "Search by SPARK ID"
                    )}
                  </TableCell>
                  <TableCell>
                    {InputTextOnly(
                      "Name",
                      Record.Name,
                      handleChange,
                      "Search by Name",
                      MessageData.Loading
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    {InputTextOnly(
                      "Email",
                      Record.Email,
                      handleChange,
                      "Search by Email"
                    )}
                  </TableCell>
                  <TableCell>
                    {InputTextOnly(
                      "MobilePhone",
                      Record.MobilePhone,
                      handleChange,
                      "Search by Phone",
                      MessageData.Loading
                    )}
                  </TableCell>

                  {/* {!isDonor && (
                    <TableCell>
                      {InputDropDownCheckboxLookupOnly(
                        "DonorIDs",
                        LookupData,
                        constants.Lookup_FundedBy,
                        Record.DonorIDs,
                        handleChangeMulti,
                        MessageData.Loading,
                        "- Funded by -"
                      )}
                    </TableCell>
                  )} */}
                  {!isDonor && (
                    <TableCell>
                      {InputDropDownCheckboxLookupOnly(
                        "DonorParentIDs",
                        LookupData,
                        constants.Lookup_ParentDonor,
                        Record.DonorParentIDs,
                        handleChangeMulti,
                        MessageData.Loading,
                        "- Funded by -"
                      )}
                    </TableCell>
                  )}
                  {!isDonor && (
                    <TableCell>
                      {InputDropDownCheckboxLookupOnly(
                        "DonorReportIDs",
                        LookupData,
                        constants.Lookup_ParentDonorReport,
                        Record.DonorReportIDs,
                        handleChangeMulti,
                        MessageData.Loading,
                        "- Reported to -"
                      )}
                    </TableCell>
                  )}

                  <TableCell>
                    {/* {InputDropDownCheckboxLookupOnly("ActivityTypeIDs", LookupData, constants.Lookup_PersonActivityRole, Record.ActivityTypeIDs, handleChangeMulti, MessageData.Loading, "- All Service Types -")} */}
                    {InputDropDownCheckboxLookupOnlyWithEmailPathFilter(
                      "ActivityTypeIDs",
                      LookupData,
                      constants.Lookup_PersonActivityRole,
                      "284",
                      "946",
                      Record.ActivityTypeIDs,
                      handleChangeMulti,
                      MessageData.Loading,
                      "- All Service Types -"
                    )}
                  </TableCell>
                  {/* <TableCell>
                    {InputTextOnly("FounderName", Record.FounderName, handleChange, "Search by Founder Name", MessageData.Loading)}
                  </TableCell>
                  <TableCell>
                    {InputTextOnly("Address", Record.Address, handleChange, "Search by Address", MessageData.Loading)}
                  </TableCell>
                  <TableCell>
                    {InputTextOnly("City", Record.City, handleChange, "Search by City", MessageData.Loading)}
                  </TableCell> */}
                </TableRow>

                {PanelTypeID === constants.TYPEID_BENEFECIARY &&
                  ShowSearchFieldsForBENEFICIARY()}
                {(PanelTypeID === constants.TYPEID_STARTUP ||
                  Record.TypeID === constants.TYPEID_SME) &&
                  ShowSearchFieldsForSTARTUP()}
                {PanelTypeID === constants.TYPEID_SME &&
                  ShowSearchFieldsForSME()}
              </Table>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell colspan={9}>
              <b>Total Records found: {Record.TotalRecords}</b>
            </TableCell>
            <TableCell colspan={2} align="right">
              {!isDonor && (
                <Select
                  fullWidth
                  native
                  id="SelectedExportValue"
                  name="SelectedExportValue"
                  value={ModalParams.SelectedExportValue}
                  onChange={handleChangeExport}
                >
                  <option value={0}>- Select Action -</option>
                  {/* <option value={1}>Send Email to selected Beneficiaries</option>
                <option value={2}>Send SMS to selected Beneficiaries</option> */}
                  <option value={3}>Create Excel with all informations</option>
                  {/* <option value={4}>Create Excel Export Activities to  Excel</option> */}
                </Select>
              )}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell>
              <b>#</b>
              <br />
              <input
                type="checkbox"
                checked={IsCheckedFLAll}
                onClick={() => DoCheckUnCheckAll(!IsCheckedFLAll)}
              />
            </TableCell>
            <TableCell>
              <b>SparkID</b>
            </TableCell>
            <TableCell colspan={3}>
              <b>Name</b>
            </TableCell>
            <TableCell>
              <b>Email</b>
            </TableCell>
            <TableCell>
              <b>Phone</b>
            </TableCell>
            <TableCell>
              <b>Country</b>
            </TableCell>
            <TableCell>
              <b>Partner</b>
            </TableCell>
            <TableCell>
              <b>Date Created</b>
            </TableCell>

            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {MessageData.Loading && <CircularProgress />}

          {Records.map((row, index) => (
            <TableRow key={row.stStartupID}>
              <TableCell component="th" scope="row">
                {Record.PageIndex * Record.PageSize + index + 1}
                <input
                  type="checkbox"
                  checked={row.IsCheckedFL}
                  onClick={() => DoCheckUnCheck(row, !row.IsCheckedFL, index)}
                />
              </TableCell>
              <TableCell>{row.stStartupID}</TableCell>
              <TableCell colspan={3}>{row.CustomName} </TableCell>
              {/* <TableCell >{row.sttName}</TableCell> */}
              <TableCell>{row.stEmail}</TableCell>
              <TableCell>{row.stMobilePhone}</TableCell>
              <TableCell>{row.coName}</TableCell>
              <TableCell>{row.PartnerName} </TableCell>
              <TableCell>
                {moment(row.stDateCreated).format("D/MM/YYYY")}
              </TableCell>

              <TableCell>
                {!isDonor && (
                  <Button
                    size="small"
                    color="primary"
                    onClick={() =>
                      EditRecord(row.stStartupID, row.stTypeID.toString())
                    }
                  >
                    {" "}
                    Edit
                  </Button>
                )}

                {!isDonor && (
                  <Button
                    size="small"
                    color="primary"
                    onClick={() =>
                      PreviewRecord(row.stStartupID, row.stTypeID.toString())
                    }
                  >
                    {" "}
                    Preview
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

        {Record.TotalPages > 1 && (
          <TableFooter>
            <TableRow>
              <TableCell colspan={12} align="center">
                {Record.PageIndex > 0 && (
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => goToPrevPage()}
                  >
                    {" "}
                    &lt; Previous{" "}
                  </Button>
                )}
                {Record.TotalPages > Record.PageIndex + 1 && (
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => goToNextPage()}
                  >
                    {" "}
                    Next &gt;{" "}
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>

      <CustomModal
        IsOpen={ModalParams.ShowModal}
        Content={ModalParams.Content}
        handleModalClose={onModalClose}
      />
    </TableContainer>
  );
}
