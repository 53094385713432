import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import * as AuthenticationHelper from "../helpers/authentication.helper";
import allActions from "../actions";
import MuiAlert from "@material-ui/lab/Alert";
import AlertMessage from "./AlertMessage";
import { useCookies } from "react-cookie";
import { history } from "../helpers";
import Pdf from "../../src/BTSManual.pdf";
import pdfIcon from "../assets/pdfIcon.png";
import pptIcon from "../assets/pptIcon.png";
import { logHelper } from "../helpers/log.helper";
import excelIcon from "../assets/Excel-icon.png";

export default function Manual() {
  var urlManual = logHelper.GetBaseUrl() + "help/BTSManual.pdf";
  var url = logHelper.GetBaseUrl() + "/template/ManualDataEntryBTSSystem.xlsx";

  return (
    <div>
      <div>
        <img src={pdfIcon} /> <span>&nbsp;</span>
        <a href={urlManual} target="_blank" style={{ textDecoration: "none" }}>
          Presentation of BTS system
        </a>
      </div>
      <br />
      <div>
        <img src={excelIcon} /> <span>&nbsp;</span>
        <a href={url} target="_blank" style={{ textDecoration: "none" }}>
          Download Template
        </a>
        <p>
          Once you download and fulfill the template with the required BTS Data,
          please send it for verification to the M&E/country focal point.
        </p>
      </div>
    </div>
  );
}
