import resourceManager from '../helpers/constant.helper';
import { configure } from '@testing-library/react';

//sekoe novo properti dodadi go ovde so DEFAULT value
const initialState = {

    Record: {
        ltLookupTypeId: 0,
        ltName: "",
    },

    Records: [],
    
    Message: '',
    Loading: false,
    hasError: false,
}

const LookupTypeReducer = (state = initialState, action) => {


    switch (action.type) {
        case "LOOKUPTYPE_START":
            return {
                ...state,

                Message: '',    //za da izbrise prethoden error ako go imalo
                Loading: true,
                hasError: false
            }

        case "LOOKUPTYPE_ERROR":
            return {
                ...state,
                Message: action.error,
                Loading: false,
                hasError: true
            }

        case "LOOKUPTYPE_SAVE":
            return {
                //...state,
                ...state,       //se drugo e vo STATE-ot
                Record: action.payload,  //samo noviot record ako e NOV, ako e update si ostanuva IST
                Message: resourceManager.MSG_SAVE,
                Loading: false,
                hasError: false
            }


        case "LOOKUPTYPE_EDIT":
            return {
                ...state,
                Record: action.payload,
                Message: "",
                Loading: false,
                hasError: false
            }

        case "LOOKUPTYPE_CREATE":
            return {
                ...state,
                Record: initialState.Record,    //za da se resetira
                Message: "",
                Loading: false,
                hasError: false
            }

            //se koristi vo find
        case "LOOKUPTYPE_LIST":
            return {
                ...state,
                Records: action.payload,
                Message: "",
                Loading: false,
                hasError: false
            }
        //se koristi vo find
        case "LOOKUPTYPE_RESET":
            state = initialState; //go resetira na iniital state
            return {
                ...state
            }


        default:
            return state
    }
}
export default LookupTypeReducer;