import resourceManager from '../helpers/constant.helper';
import constants from '../helpers/constant.helper';

//sekoe novo properti dodadi go ovde so DEFAULT value
const initialState = {

    Record: {
        EntityId: 0,
        EntityTypeId: constants.Entity_COLUMNS, //TypeId: 2
        ParentId: null,
        Name: "",
        Name2: "",  //Plural
        Description: "",
        APIName: '',

        IsPrimary: false,  //PRIMAREN KLUC
        DefaultValue: '',
        Length: 16,
        DecimalPlaces: 0,
        RequiredFL: false,
        LookupTypeId:null,
        DataType: 'Text',
        PrimaryTableEntityId:null
    },

    Records: [],
    Table:{}, //Tabelata so Entity Record definicija

    Lookups: {
        DataTypes: [],
        LookupTypes:[],
        Tables:[]
    },

    Message: '',
    Loading: false,
    hasError: false,
}

const TableReducer = (state = initialState, action) => {
    switch (action.type) {
        case "COLUMN_START":
            return {
                ...state,
                Message: resourceManager.MSG_PLEASE_WAIT,    //za da izbrise prethoden error ako go imalo
                Loading: true,
                hasError: false
            }

        case "COLUMN_ERROR":
            return {
                ...state,
                Message: action.error,
                Loading: false,
                hasError: true
            }


        case "COLUMN_SAVE_ERROR":
            return {
                ...state,
                Message: action.error,
                Record: action.payload,  //istite vrednosti gi vraka tie sto gi vnesol
                Loading: false,
                hasError: true
            }

        case "COLUMN_SAVE_SUCCESS":
            return {
                //...state,
                ...state,       //se drugo e vo STATE-ot
                Record: action.payload,  //samo noviot record ako e NOV, ako e update si ostanuva IST
                Message: resourceManager.MSG_SAVE,
                Loading: false,
                hasError: false
            }


        case "COLUMN_EDIT":
            return {
                ...state,
                Lookups: { DataTypes: action.payload_DataTypes, LookupTypes:action.payload_LookupTypes, Tables: action.payload_Tables  }, //niza od objekti
                Record: action.payload,
                Table: action.payload_Table,    //ova moze samo na SEARCH da se zeme i da ne se loadira povtorno so ogled deka vo STATE ke si stoi
                Message: "",
                Loading: false,
                hasError: false
            }

        case "COLUMN_CREATE":
            return {
                ...state,
                Record: initialState.Record,    //za da se resetira
                Lookups: { DataTypes: action.payload_DataTypes, LookupTypes:action.payload_LookupTypes, Tables: action.payload_Tables }, //niza od objekti
                Table: action.payload_Table,    //ova moze samo na SEARCH da se zeme i da ne se loadira povtorno so ogled deka vo STATE ke si stoi
                ///Record: action.payload_DataTypes,
                Message: "",
                Loading: false,
                hasError: false
            }


        //se koristi vo find
        case "COLUMN_SEARCH":
            return {
                ...state,
                Records: action.payload, 
                Table: action.payload_Table,
                Message: "",
                Loading: false,
                hasError: false
            }

        //se koristi vo find
        case "COLUMN_RESET":
            state = initialState; //go resetira na iniital state
            return {
                ...state
            }

        default:
            return state
    }
}
export default TableReducer;