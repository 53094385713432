import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Radio, FormHelperText } from "@material-ui/core";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import * as AuthenticationHelper from "../../helpers/authentication.helper";
import { history } from "../../helpers";
import {
  withStyles,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import * as LanguageHelper from "../../helpers/language.helper";
import { logHelper } from "../../helpers/log.helper";

const useStyles = {
  root: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: 348,
  },
  formElement: {},
  formLabel: {
    color: "#aaaaa9",
    marginTop: 10,
    "&:hover": {
      background: "#F3F3F2",
    },
  },
  header: {
    backgroundColor: "#F3F3F2",
    borderRadius: 4,
    paddingLeft: 5,
  },
  subHeader: {
    marginTop: 15,
    marginBottom: 1,
    fontWeight: 700,
  },
  buttonStyle: {
    textTransform: "none",
  },
  xs12: {
    width: "25%",
  },
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.action.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
  "&:hover": {
    background: "#F3F3F2",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.action.hover,
  },
}))(TableRow);

const classes = {};

const keys = LanguageHelper.GetLanguageKeys();
const languageId = LanguageHelper.GetLanguageID();

export const CustomRadioBox = (classes, control, value, handleChange) => {
  // Here we build the form's groups elements dynamically

  return (
    <TableRow>
      <TableCell className={classes.columnLabel}>
        {control.Label}
        <Tooltip title={`Enter your ${control.Label}`} arrow placement="top">
          <InfoOutlinedIcon color="action" fontSize="small" />
        </Tooltip>
      </TableCell>
      <TableCell className={classes.columnInput}>
        {control.Items.map((item) => (
          <React.Fragment>
            <Radio color="primary" id="x" value="1" />
            {item.Text}
          </React.Fragment>
        ))}
      </TableCell>
    </TableRow>
  );
};

export const CustomText2 = React.memo(function CustomText2(
  classes,
  control,
  value,
  handleChange
) {
  logHelper.log("Rendering MainPostTopic...");
  return (
    <TableRow>
      <TableCell component="th" scope="row" className={classes.columnLabel}>
        {control.Label}
        <Tooltip title={`Enter your ${control.Label}`} arrow placement="top">
          <InfoOutlinedIcon color="action" fontSize="small" />
        </Tooltip>
      </TableCell>
      <TableCell component="th" scope="row" className={classes.columnInput}>
        <TextField
          margin="dense"
          id={control.Name}
          name={control.Name}
          type="text"
          fullWidth
          variant="outlined"
          value={value}
          onChange={handleChange}
        />
      </TableCell>
    </TableRow>
  );
});

export const Header1 = (label) => {
  return (
    <TableHead>
      <StyledTableRow>
        <StyledTableCell colSpan={2}>
          <b>{label}</b>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>{CustomSubHeader}</StyledTableRow>
    </TableHead>
  );
};

export const CustomHeader1 = (label) => {
  return (
    <StyledTableRow>
      <StyledTableCell colSpan="2">{label}</StyledTableCell>
    </StyledTableRow>
  );
};

export const InputDropDownLookup = (
  classes,
  label,
  controlId,
  Items,
  LookupTypeId,
  value,
  handleChange,
  disabled,
  errorCollection,
  isRequired,
  hasDefaultItem = true
) => {
  //currentMenu = (MENU_SysAdmin.find(x => x.section === menu_section) || {});  //menu_section kazuva koja sekcija od MENI-to da pokaze
  let filtered = []; //(Items.find(x => x.ldTypeID === LookupTypeId) || {});
  if (Items.length > 0) {
    if (LookupTypeId > 0) {
      //moze i da nema potreba od filter konkretno za Periods
      filtered = Items.filter((x) => x.ldTypeID === LookupTypeId) || [];
    } else {
      filtered = Items;
    }
  }

  let hasError = false;
  let message = "";
  //baraj po Record.ColumnId
  if (errorCollection != null && errorCollection.length > 0) {
    let currentOption = errorCollection.find((x) => x.controlId === controlId); //vidi vo validation.helper.js
    if (currentOption != null) {
      hasError = true;
      message = currentOption.message;
    }
  }

  return (
    <TableRow>
      {/* <TableCell component="th" scope="row" className={classes.columnLabel}>
        {label} {isRequired && <font style={{ color: 'red' }}>*</font>}
        <Tooltip title={`Enter your ${label}`} arrow placement="top">
          <InfoOutlinedIcon color="action" fontSize='small' />
        </Tooltip>
      </TableCell> */}
      <TableCell className={classes.columnInput}>
        {label} {isRequired && <font style={{ color: "red" }}>*</font>}
        <Select
          fullWidth
          native
          id={controlId}
          name={controlId}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          error={hasError}
        >
          {hasDefaultItem && <option value="0">{keys.SelectItemText}</option>}
          {filtered &&
            filtered.map((option) => (
              <option value={option.ldLookupDataID}>
                {GetOptionText(option)}
              </option>
            ))}
        </Select>
        <FormHelperText style={{ color: "red" }}>{message}</FormHelperText>
      </TableCell>
    </TableRow>
  );
};

const GetOptionText = (option) => {
  let text = "";
  if (languageId === "1") {
    text = option.ldValue;
  } else if (languageId === "2") {
    text = option.ldValue2;
  } else if (languageId === "3") {
    text = option.ldValue3;
  } else {
    text = option.ldValue;
  }
  return text;
};

export const InputDropDownCheckboxLookup = (
  classes,
  label,
  controlId,
  Items,
  LookupTypeId,
  values,
  handleChange,
  disabled,
  errorCollection,
  isRequired,
  defaultItemText = ""
) => {
  let hasError = false;
  let message = "";
  //baraj po Record.ColumnId
  if (errorCollection != null && errorCollection.length > 0) {
    let currentOption = errorCollection.find((x) => x.controlId === controlId); //vidi vo validation.helper.js
    if (currentOption != null) {
      hasError = true;
      message = currentOption.message;
    }
  }

  //currentMenu = (MENU_SysAdmin.find(x => x.section === menu_section) || {});  //menu_section kazuva koja sekcija od MENI-to da pokaze
  let filtered = []; //(Items.find(x => x.ldTypeID === LookupTypeId) || {});
  if (Items.length > 0) {
    filtered = Items.filter((x) => x.ldTypeID === LookupTypeId) || [];
  }
  logHelper.log("FILTERED ITEMS");
  // logHelper.log(Items.length);
  // logHelper.log(Items);
  logHelper.log(filtered);

  let selectedValues = [];
  let arrSelectedValues = [];
  if (values) {
    //284, 285
    selectedValues = values.split(","); //Od string 2,3,4 vo niza [2],[3], [4]
    for (let i = 0; i <= selectedValues.length - 1; i++) {
      const currentSelectedValue = selectedValues[i]; //284
      const currentItem = filtered.find(
        (a) => a.ldLookupDataID.toString() === currentSelectedValue.toString()
      );
      if (currentItem) {
        arrSelectedValues.push(currentItem);
      }
    }
  }

  return (
    <TableRow>
      {/* <TableCell component="th" scope="row" className={classes.columnLabel}>
        
        <Tooltip title={`Enter your ${label}`} arrow placement="top">
          <InfoOutlinedIcon color="action" fontSize='small' />
        </Tooltip>
      </TableCell> */}
      <TableCell className={classes.columnInput}>
        {label} {isRequired && <font style={{ color: "red" }}>*</font>}
        <Autocomplete
          multiple
          //onChange={handleChange}
          onChange={(event, selectedValues) => {
            handleChange(controlId, selectedValues);
          }} //selectedValues e niza od Options od LookupData tabelata[]
          id={controlId}
          options={filtered}
          fullWidth={true}
          disableCloseOnSelect
          // getOptionLabel={(option) => option.ldValue}
          getOptionLabel={(option) => GetOptionText(option)}
          value={arrSelectedValues}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {/* {option.ldValue} */}
              {GetOptionText(option)}
            </React.Fragment>
          )}
          // style={{ width: 250 }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={defaultItemText} />
          )}
        />
        <FormHelperText style={{ color: "red" }}>{message}</FormHelperText>
      </TableCell>
    </TableRow>
  );
};

///Ocekuva Value, Text
//[{Value: 1, Text: "Todor"},{Value: 2, Text: "Leon"}]
export const InputDropDownCheckbox = (
  classes,
  label,
  controlId,
  Items,
  values,
  handleChange,
  disabled,
  errorCollection,
  isRequired,
  defaultItemText = ""
) => {
  logHelper.log("InputDropDownCheckbox");
  logHelper.log(Items);

  let hasError = false;
  let message = "";
  //baraj po Record.ColumnId
  if (errorCollection != null && errorCollection.length > 0) {
    let currentOption = errorCollection.find((x) => x.controlId === controlId); //vidi vo validation.helper.js
    if (currentOption != null) {
      hasError = true;
      message = currentOption.message;
    }
  }

  //currentMenu = (MENU_SysAdmin.find(x => x.section === menu_section) || {});  //menu_section kazuva koja sekcija od MENI-to da pokaze
  let filtered = Items;

  logHelper.log("ITEMS");
  logHelper.log(Items.length);
  logHelper.log(Items);
  logHelper.log(filtered);

  let selectedValues = [];
  let arrSelectedValues = [];
  if (values) {
    //284, 285
    selectedValues = values.split(","); //Od string 2,3,4 vo niza [2],[3], [4]
    for (let i = 0; i <= selectedValues.length - 1; i++) {
      const currentSelectedValue = selectedValues[i]; //284
      const currentItem = filtered.find(
        (a) => a.Value.toString() === currentSelectedValue.toString()
      );
      if (currentItem) {
        arrSelectedValues.push(currentItem);
      }
    }
  }

  return (
    <TableRow>
      {/* <TableCell component="th" scope="row" className={classes.columnLabel}>
        
        <Tooltip title={`Enter your ${label}`} arrow placement="top">
          <InfoOutlinedIcon color="action" fontSize='small' />
        </Tooltip>
      </TableCell> */}
      <TableCell className={classes.columnInput}>
        {label} {isRequired && <font style={{ color: "red" }}>*</font>}
        <Autocomplete
          multiple
          //onChange={handleChange}
          onChange={(event, selectedValues) => {
            handleChange(controlId, selectedValues);
          }} //selectedValues e niza od Options od LookupData tabelata[]
          id={controlId}
          options={filtered}
          fullWidth={true}
          disableCloseOnSelect
          // getOptionLabel={(option) => option.ldValue}
          getOptionLabel={(option) => option.Text}
          value={arrSelectedValues}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.Text}
              {/* {GetOptionText(option)} */}
            </React.Fragment>
          )}
          // style={{ width: 250 }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={defaultItemText} />
          )}
        />
        <FormHelperText style={{ color: "red" }}>{message}</FormHelperText>
      </TableCell>
    </TableRow>
  );
};

export const InputDropDownCountries = (
  classes,
  label,
  controlId,
  Items,
  value,
  handleChange,
  disabled,
  errorCollection,
  isRequired = true,
  allowDefaultValue = true
) => {
  console.log("ALLOW DEFAULT VALUE:" + allowDefaultValue);
  let hasError = false;
  let message = "";
  //baraj po Record.ColumnId
  if (errorCollection != null && errorCollection.length > 0) {
    let currentOption = errorCollection.find((x) => x.controlId === controlId); //vidi vo validation.helper.js
    if (currentOption != null) {
      hasError = true;
      message = currentOption.message;
    }
  }

  return (
    <TableRow>
      <TableCell className={classes.columnInput}>
        {label} {isRequired && <font style={{ color: "red" }}>*</font>}
        <br />
        <Select
          fullWidth
          native
          id={controlId}
          name={controlId}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          error={hasError}
        >
          {allowDefaultValue && (
            <option value="0">{keys.SelectItemText}</option>
          )}

          {Items &&
            Items.map((option) => (
              <option value={option.Value}>{option.Text}</option>
            ))}
        </Select>
        <FormHelperText style={{ color: "red" }}>{message}</FormHelperText>
      </TableCell>
    </TableRow>
  );
};

export const InputDropDown = (
  classes,
  label,
  controlId,
  Items,
  value,
  handleChange,
  disabled,
  errorCollection,
  isRequired
) => {
  let hasError = false;
  let message = "";
  //baraj po Record.ColumnId
  if (errorCollection != null && errorCollection.length > 0) {
    let currentOption = errorCollection.find((x) => x.controlId === controlId); //vidi vo validation.helper.js
    if (currentOption != null) {
      hasError = true;
      message = currentOption.message;
    }
  }

  return (
    <TableRow>
      <TableCell className={classes.columnInput}>
        {label} {isRequired && <font style={{ color: "red" }}>*</font>}
        <br />
        <Select
          fullWidth
          native
          id={controlId}
          name={controlId}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          error={hasError}
        >
          <option value="0">{keys.SelectItemText}</option>
          {Items &&
            Items.map((option) => (
              <option value={option.Value}>{option.Text}</option>
            ))}
        </Select>
        <FormHelperText style={{ color: "red" }}>{message}</FormHelperText>
      </TableCell>
    </TableRow>
  );
};

export const InputDate = (
  classes,
  label,
  controlId,
  value,
  handleChange,
  disabled,
  errorCollection,
  isRequired
) => {
  let hasError = false;
  let message = "";
  //baraj po Record.ColumnId
  if (errorCollection != null && errorCollection.length > 0) {
    let currentOption = errorCollection.find((x) => x.controlId === controlId); //vidi vo validation.helper.js
    if (currentOption != null) {
      hasError = true;
      message = currentOption.message;
    }
  }

  //value = Date.now();
  //value = "2017-05-24";
  logHelper.log("DATTTTTEEEEEEE");
  logHelper.log(value);
  if (value !== "") {
    value = moment(value).format("YYYY-MM-DD"); //new Date(value).toISOString().split("T")[0];
  }

  return (
    <TableRow>
      {/* <TableCell component="th" scope="row" className={classes.columnLabel}>
        
        <Tooltip title={`Enter your ${label}`} arrow placement="top">
          <InfoOutlinedIcon color="action" fontSize='small' />
        </Tooltip>
      </TableCell> */}
      <TableCell className={classes.columnInput}>
        {label} {isRequired && <font style={{ color: "red" }}>*</font>}
        <br />
        <TextField
          id={controlId}
          name={controlId}
          // label={control.Label}
          type="date"
          onChange={handleChange}
          defaultValue={value}
          value={value}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={disabled}
          error={hasError}
        />
        <FormHelperText style={{ color: "red" }}>{message}</FormHelperText>
      </TableCell>
    </TableRow>
  );
};

export const InputDate2 = (
  classes,
  label,
  controlId,
  value,
  handleChange,
  disabled,
  errorCollection,
  isRequired
) => {
  let hasError = false;
  let message = "";
  //baraj po Record.ColumnId
  if (errorCollection != null && errorCollection.length > 0) {
    let currentOption = errorCollection.find((x) => x.controlId === controlId); //vidi vo validation.helper.js
    if (currentOption != null) {
      hasError = true;
      message = currentOption.message;
    }
  }

  //value = Date.now();
  //value = "2017-05-24";
  logHelper.log("DATTTTTEEEEEEE");
  logHelper.log(value);
  if (value !== "") {
    value = moment(value).format("YYYY-MM-DD"); //new Date(value).toISOString().split("T")[0];
  }

  return (
    <React.Fragment>
      {/* <TableCell component="th" scope="row" className={classes.columnLabel}>
        
        <Tooltip title={`Enter your ${label}`} arrow placement="top">
          <InfoOutlinedIcon color="action" fontSize='small' />
        </Tooltip>
      </TableCell> */}
      {label} {isRequired && <font style={{ color: "red" }}>*</font>}
      <br />
      <TextField
        id={controlId}
        name={controlId}
        // label={control.Label}
        type="date"
        onChange={handleChange}
        defaultValue={value}
        value={value}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        error={hasError}
      />
      <FormHelperText style={{ color: "red" }}>{message}</FormHelperText>
    </React.Fragment>
  );
};

export const InputDateOnly = (controlId, value, handleChange) => {
  //value = Date.now();
  //value = "2017-05-24";
  logHelper.log("DATTTTTEEEEEEE");
  logHelper.log(value);
  if (value !== "") {
    value = moment(value).format("YYYY-MM-DD"); //new Date(value).toISOString().split("T")[0];
  }

  return (
    <TextField
      id={controlId}
      name={controlId}
      // label={control.Label}
      type="date"
      onChange={handleChange}
      defaultValue={value}
      value={value}
      className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export const InputTextOnly = (
  controlId,
  value,
  handleChange,
  placeHolder = "",
  disabled
) => {
  return (
    <TextField
      margin="dense"
      id={controlId}
      name={controlId}
      type="text"
      fullWidth
      variant="outlined"
      value={value}
      onChange={handleChange}
      onKeyPress={handleChange}
      placeholder={placeHolder}
      disabled={disabled}
    />
  );
};

export const InputDropDownOnly = (
  controlId,
  Items,
  value,
  handleChange,
  defaultText = "- Select Item -",
  allowDefaultValue = true
) => {
  return (
    <Select
      fullWidth
      native
      id={controlId}
      name={controlId}
      value={value}
      onChange={handleChange}
      onKeyPress={handleChange}
    >
      {allowDefaultValue && <option value="0">{defaultText}</option>}
      {Items &&
        Items.map((option) => (
          <option value={option.Value}>{option.Text}</option>
        ))}
    </Select>
  );
};

export const InputDropDownLanguage = (value = "0") => {
  const handleChange = (e) => {
    const { value } = e.target;
    AuthenticationHelper.SetLanguage(value);
    let currentUrl = "?Lang=" + value;

    //let currentUrl2 = window.location.href.replace(window.location.origin, "");
    window.location = window.location.href;
    //history.push(currentUrl);
  };

  return (
    <Select
      native
      id="ddlLangID"
      name="ddlLangID"
      value={value}
      onChange={handleChange}
    >
      <option value="0">- Select Language -</option>
      <option value="1">English</option>
      <option value="2">Arabic</option>
      <option value="3">Turkish</option>
    </Select>
  );
};

export const InputDropDownOnly2 = (
  controlId,
  Items,
  value,
  handleChange,
  defaultText = "- Select Item -",
  disabled
) => {
  return (
    <Select
      fullWidth
      // native
      id={controlId}
      name={controlId}
      value={value}
      // onChange={handleChange}
      labelId="demo-simple-select-outlined-label"
      onChange={handleChange}
      disabled={disabled}
    >
      <MenuItem value="0">{defaultText}</MenuItem>
      {Items &&
        Items.map((option) => (
          <MenuItem value={option.Value}>{option.Text}</MenuItem>
        ))}
    </Select>
  );
};

export const InputDropDownLookupOnly = (
  controlId,
  Items,
  LookupTypeId,
  value,
  handleChange,
  disabled,
  defaultItemText = "- Select Item -"
) => {
  //currentMenu = (MENU_SysAdmin.find(x => x.section === menu_section) || {});  //menu_section kazuva koja sekcija od MENI-to da pokaze
  let filtered = []; //(Items.find(x => x.ldTypeID === LookupTypeId) || {});
  if (Items.length > 0) {
    filtered = Items.filter((x) => x.ldTypeID === LookupTypeId) || [];
  }
  // logHelper.log("ITEMS");
  // logHelper.log(Items.length);
  // logHelper.log(Items);
  // logHelper.log(filtered);

  return (
    <Select
      fullWidth
      native
      id={controlId}
      name={controlId}
      value={value}
      onChange={handleChange}
      onKeyPress={handleChange}
    >
      <option value={0}>{defaultItemText}</option>
      {filtered &&
        filtered.map((option) => (
          <option value={option.ldLookupDataID}>{GetOptionText(option)}</option>
        ))}
    </Select>
  );
};

export const InputDropDownPagination = (
  controlId,
  Items,
  LookupTypeId,
  value,
  handleChange,
  disabled,
  defaultItemText = "- Select Item -"
) => {
  //currentMenu = (MENU_SysAdmin.find(x => x.section === menu_section) || {});  //menu_section kazuva koja sekcija od MENI-to da pokaze
  let filtered = []; //(Items.find(x => x.ldTypeID === LookupTypeId) || {});
  if (Items.length > 0) {
    filtered = Items.filter((x) => x.ldTypeID === LookupTypeId) || [];
  }
  // logHelper.log("ITEMS");
  // logHelper.log(Items.length);
  // logHelper.log(Items);
  // logHelper.log(filtered);

  return (
    <Select
      native
      id={controlId}
      name={controlId}
      value={parseInt(value)}
      onChange={handleChange}
    >
      {filtered &&
        filtered.map((option) => (
          <option value={parseInt(option.ldValue2)}>
            {GetOptionText(option)}
          </option>
        ))}
    </Select>
  );
};

export const InputText = (
  classes,
  label,
  controlId,
  value,
  handleChange,
  disabled,
  errorCollection,
  isRequired,
  ltr = "ltr"
) => {
  let hasError = false;
  let message = "";
  //baraj po Record.ColumnId
  if (errorCollection != null && errorCollection.length > 0) {
    let currentOption = errorCollection.find((x) => x.controlId === controlId); //vidi vo validation.helper.js
    if (currentOption != null) {
      hasError = true;
      message = currentOption.message;
    }
  }

  return (
    <TableRow>
      {/* <TableCell component="th" scope="row" className={classes.columnLabel} >
        
        <Tooltip title={`Enter your ${label}`} arrow placement="top">
          <InfoOutlinedIcon color="action" fontSize='small' />
        </Tooltip>
      </TableCell> */}
      <TableCell
        component="th"
        scope="row"
        className={classes.columnInput}
        align={ltr === "rtl" ? "right" : "left"}
      >
        {label} {isRequired && <font style={{ color: "red" }}>*</font>}
        <br />
        <TextField
          margin="dense"
          id={controlId}
          name={controlId}
          type="text"
          fullWidth
          variant="outlined"
          value={value}
          onChange={handleChange}
          //onBlur={handleChange} //da ne refreshira non-stop
          disabled={disabled}
          helperText={message}
          error={hasError}
        />
      </TableCell>
    </TableRow>
  );
};

export const InputPassword = (
  classes,
  label,
  controlId,
  value,
  handleChange,
  disabled,
  errorCollection,
  isRequired
) => {
  let hasError = false;
  let message = "";
  //baraj po Record.ColumnId
  if (errorCollection != null && errorCollection.length > 0) {
    let currentOption = errorCollection.find((x) => x.controlId === controlId); //vidi vo validation.helper.js
    if (currentOption != null) {
      hasError = true;
      message = currentOption.message;
    }
  }

  return (
    <TableRow>
      {/* <TableCell component="th" scope="row" className={classes.columnLabel} >
        <Tooltip title={`Enter your ${label}`} arrow placement="top">
          <InfoOutlinedIcon color="action" fontSize='small' />
        </Tooltip>
      </TableCell> */}
      <TableCell component="th" scope="row" className={classes.columnInput}>
        {label} {isRequired && <font style={{ color: "red" }}>*</font>}
        <br />
        <TextField
          margin="dense"
          id={controlId}
          name={controlId}
          type="password"
          fullWidth
          variant="outlined"
          value={value}
          onChange={handleChange}
          disabled={disabled}
          helperText={message}
          error={hasError}
        />
      </TableCell>
    </TableRow>
  );
};

export const InputNumber = (
  classes,
  label,
  controlId,
  value,
  handleChange,
  isRequired
) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row" className={classes.columnInput}>
        {label} {isRequired && <font style={{ color: "red" }}>*</font>}
        <TextField
          margin="dense"
          id={controlId}
          name={controlId}
          type="text"
          fullWidth
          variant="outlined"
          value={value}
          onChange={handleChange}
          inputProps={{ style: { textAlign: "right" } }} // the change is here
        />
      </TableCell>
    </TableRow>
  );
};

export const InputTextMultiLine = (
  classes,
  label,
  controlId,
  value,
  handleChange,
  disabled,
  errorCollection,
  isRequired
) => {
  let hasError = false;
  let message = "";
  //baraj po Record.ColumnId
  if (errorCollection != null && errorCollection.length > 0) {
    let currentOption = errorCollection.find((x) => x.controlId === controlId); //vidi vo validation.helper.js
    if (currentOption != null) {
      hasError = true;
      message = currentOption.message;
    }
  }

  return (
    <TableRow>
      {/* <TableCell component="th" scope="row" className={classes.columnLabel} >
        {label} {isRequired && <font style={{ color: 'red' }}>*</font>}
        <Tooltip title={`Enter your ${label}`} arrow placement="top">
          <InfoOutlinedIcon color="action" fontSize='small' />
        </Tooltip>
      </TableCell> */}
      <TableCell component="th" scope="row" className={classes.columnInput}>
        {label} {isRequired && <font style={{ color: "red" }}>*</font>}
        <br />
        <TextField
          margin="dense"
          id={controlId}
          name={controlId}
          multiline
          rows={4}
          type="text"
          fullWidth
          variant="outlined"
          value={value}
          onChange={handleChange}
          disabled={disabled}
          helperText={message}
          error={hasError}
        />
      </TableCell>
    </TableRow>
  );
};

export const CustomText = (classes, control, value, handleChange) => {
  //logHelper.log(control.Name);
  return (
    <TableRow>
      <TableCell component="th" scope="row" className={classes.columnLabel}>
        {control.Label}
        <Tooltip title={`Enter your ${control.Label}`} arrow placement="top">
          <InfoOutlinedIcon color="action" fontSize="small" />
        </Tooltip>
      </TableCell>
      <TableCell component="th" scope="row" className={classes.columnInput}>
        <TextField
          margin="dense"
          id={control.Name}
          name={control.Name}
          type="text"
          fullWidth
          variant="outlined"
          value={value}
          onChange={handleChange}
        />
      </TableCell>
    </TableRow>
  );
};

export const CustomLabel = (classes, control, value, handleChange) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row" className={classes.columnLabel}>
        {control.Label}
        <Tooltip title={`Enter your ${control.Label}`} arrow placement="top">
          <InfoOutlinedIcon color="action" fontSize="small" />
        </Tooltip>
      </TableCell>
      <TableCell component="th" scope="row" className={classes.columnInput}>
        {value}
      </TableCell>
    </TableRow>
  );
};

export const CustomCheckBox = (classes, control, value, handleChange) => {
  // Here we build the form's groups elements dynamically
  return (
    <TableRow>
      <TableCell component="th" scope="row" className={classes.columnLabel}>
        {control.Label}
        <Tooltip title={`Enter your ${control.Label}`} arrow placement="top">
          <InfoOutlinedIcon color="action" fontSize="small" />
        </Tooltip>
      </TableCell>
      <TableCell className={classes.columnInput}>
        {control.Items.map((item) => (
          <React.Fragment>
            <Checkbox color="primary" value="1" />
            {item.Text}
          </React.Fragment>
        ))}
      </TableCell>
    </TableRow>
  );
};

export const CustomDatePicker = (classes, control, value, handleChange) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row" className={classes.columnLabel}>
        {control.Label}
        <Tooltip title={`Enter your ${control.Label}`} arrow placement="top">
          <InfoOutlinedIcon color="action" fontSize="small" />
        </Tooltip>
      </TableCell>
      <TableCell className={classes.columnInput}>
        <TextField
          id={control.Name}
          name={control.Name}
          // label={control.Label}
          type="date"
          onChange={handleChange}
          value={value}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </TableCell>
    </TableRow>
  );
};

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export const CustomRelation = (relation, handleChange) => {
  let i = 0;
  let currentRowsStack = relation.Elements.map((row, index) => {
    i = i + 1;
    return <StyledTableCell>{row.Label}</StyledTableCell>;
  });
  let outputResult = (
    <Box p={1} bgcolor="background.paper">
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          aria-label="customized table"
          style={{ width: "100%", tableLayout: "auto" }}
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell colSpan={10}>{relation.Name}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>{currentRowsStack}</StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.calories}</TableCell>
                <TableCell>{row.fat}</TableCell>
                <TableCell>{row.carbs}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
  return outputResult;
};

export const CustomPickList = (classes, control, value, handleChange) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row" className={classes.columnLabel}>
        {control.Label}
        <Tooltip title={`Enter your ${control.Label}`} arrow placement="top">
          <InfoOutlinedIcon color="action" fontSize="small" />
        </Tooltip>
      </TableCell>
      <TableCell className={classes.columnInput}>
        <Select
          fullWidth
          native
          id={control.Name}
          name={control.Name}
          value={value}
          onChange={handleChange}
        >
          <option value={0}>- Select Data Type -</option>
          {control.Items.map((option) => (
            <option value={option.Value}>{option.Text}</option>
          ))}
        </Select>
      </TableCell>
    </TableRow>
  );
};

export const CustomPickListMultiple = (
  classes,
  control,
  value,
  handleChange
) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row" className={classes.columnLabel}>
        {control.Label}
        <Tooltip title={`Please ${control.Label}`} arrow placement="top">
          <InfoOutlinedIcon color="action" fontSize="small" />
        </Tooltip>
      </TableCell>
      <TableCell className={classes.columnInput}>
        <Select
          id={control.Name}
          name={control.Name}
          fullWidth
          multiple
          native
          value={value}
          onChange={handleChange}
        >
          {control.Items.map((option) => (
            <option key={option.Value} value={option.Value}>
              {option.Text}
            </option>
          ))}
        </Select>
      </TableCell>
    </TableRow>
  );
};

export const CustomHeader = (control) => {
  return (
    <TableHead>
      <StyledTableRow>
        <StyledTableCell colSpan={10}>{control.Label}</StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>{CustomSubHeader}</StyledTableRow>
    </TableHead>
  );
};

export const CustomSubHeader = (control) => {
  return (
    <TableHead>
      <StyledTableRow>
        <StyledTableCell colSpan={10}>{control.Label}</StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>{CustomSubHeader}</StyledTableRow>
    </TableHead>
  );
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const InputDropDownCheckboxLookupOnly = (
  controlId,
  Items,
  LookupTypeId,
  values,
  handleChange,
  disabled,
  defaultItemText = "- Select Item -"
) => {
  //currentMenu = (MENU_SysAdmin.find(x => x.section === menu_section) || {});  //menu_section kazuva koja sekcija od MENI-to da pokaze
  let filtered = []; //(Items.find(x => x.ldTypeID === LookupTypeId) || {});
  if (Items.length > 0) {
    filtered = Items.filter((x) => x.ldTypeID === LookupTypeId) || [];
  }
  // logHelper.log("ITEMS");
  // logHelper.log(Items.length);
  // logHelper.log(Items);
  // logHelper.log(filtered);

  let selectedValues = [];
  let arrSelectedValues = [];
  if (values) {
    //284, 285
    selectedValues = values.split(","); //Od string 2,3,4 vo niza [2],[3], [4]
    for (let i = 0; i <= selectedValues.length - 1; i++) {
      const currentSelectedValue = selectedValues[i]; //284
      const currentItem = filtered.find(
        (a) => a.ldLookupDataID.toString() === currentSelectedValue.toString()
      );
      if (currentItem) {
        arrSelectedValues.push(currentItem);
      }
    }
  }

  return (
    <Autocomplete
      multiple
      //onChange={handleChange}
      onChange={(event, selectedValues) => {
        handleChange(controlId, selectedValues);
      }} //selectedValues e niza od Options od LookupData tabelata[]
      id={controlId}
      options={filtered}
      fullWidth={true}
      disableCloseOnSelect
      getOptionLabel={(option) => option.ldValue}
      value={arrSelectedValues}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.ldValue}
        </React.Fragment>
      )}
      // style={{ width: 250 }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={defaultItemText} />
      )}
    />
  );
};

export const InputDropDownCheckboxLookupOnlyWithEmailPathFilter = (
  controlId,
  Items,
  LookupTypeId,
  ldEmailPath,
  ldEmailPath2,
  values,
  handleChange,
  disabled,
  defaultItemText = "- Select Item -"
) => {
  //currentMenu = (MENU_SysAdmin.find(x => x.section === menu_section) || {});  //menu_section kazuva koja sekcija od MENI-to da pokaze
  let filtered = []; //(Items.find(x => x.ldTypeID === LookupTypeId) || {});
  if (Items.length > 0) {
    filtered =
      Items.filter(
        (x) =>
          x.ldTypeID === LookupTypeId &&
          (x.ldEmailPath === ldEmailPath || x.ldEmailPath === ldEmailPath2)
      ) || [];
    filtered = filtered.sort((a, b) => (a.ldValue > b.ldValue ? 1 : -1));
  }
  // logHelper.log("ITEMS");
  // logHelper.log(Items.length);
  // logHelper.log(Items);
  // logHelper.log(filtered);

  let selectedValues = [];
  let arrSelectedValues = [];
  if (values) {
    //284, 285
    selectedValues = values.split(","); //Od string 2,3,4 vo niza [2],[3], [4]
    for (let i = 0; i <= selectedValues.length - 1; i++) {
      const currentSelectedValue = selectedValues[i]; //284
      const currentItem = filtered.find(
        (a) => a.ldLookupDataID.toString() === currentSelectedValue.toString()
      );
      if (currentItem) {
        arrSelectedValues.push(currentItem);
      }
    }
  }

  return (
    <Autocomplete
      multiple
      //onChange={handleChange}
      onChange={(event, selectedValues) => {
        handleChange(controlId, selectedValues);
      }} //selectedValues e niza od Options od LookupData tabelata[]
      id={controlId}
      options={filtered}
      fullWidth={true}
      disableCloseOnSelect
      getOptionLabel={(option) => option.ldValue}
      value={arrSelectedValues}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.ldValue}
        </React.Fragment>
      )}
      // style={{ width: 250 }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={defaultItemText} />
      )}
    />
  );
};

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { ldValue: "The Shawshank Redemption", year: 1994 },
  { ldValue: "The Godfather", year: 1972 },
  { ldValue: "The Godfather: Part II", year: 1974 },
  { ldValue: "The Dark Knight", year: 2008 },
  { ldValue: "12 Angry Men", year: 1957 },
  { ldValue: "Schindler's List", year: 1993 },
  { ldValue: "Pulp Fiction", year: 1994 },
];
