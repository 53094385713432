import Service from '../services/UserProfileService'
import ServiceLookupType from '../services/LookupTypeService'
import { responseHelper, history } from '../helpers';
import config from '../config/config';
import { logHelper } from '../helpers/log.helper';
// responseHelper.validateResponses(responses);
//Validate Response validira responses dali ima nekakvi greski 
//responses = [] --niza
//Iterira niz nea i ako e TRUE togas gi vraka samite responses[] koj sto bile prateni za inspekcija
//ako e FALSE vraka response od ERROR objekt (ne niza, samo eden objekt)
async function GetAsync() {
    let response  = await Service.Get();
    let responses = [response];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

const Get = () => {

    return dispatch => {
        GetAsync().then((responses) => {
            if (responses.error) {
                dispatch({ type: "USERPROFILE_ERROR", error: responses.error_description });
            }
            else {
                logHelper.log("USER PROFILE ACTION");
                logHelper.log(responses);
                const userProfile = responses[0].data;
                userProfile.IsSysAdmin = false;
                userProfile.IsOfficer = false;
                userProfile.IsUser = false;
                userProfile.IsSigned = true;
                if (userProfile.Role === config.ROLE_ADMIN)
                {
                    userProfile.IsSysAdmin = true;
                }
                else if (userProfile.Role === config.ROLE_OFFICER)
                {
                    userProfile.IsOfficer = true;
                }                
                else if (userProfile.Role === config.ROLE_USER)
                {
                    userProfile.IsUser = true;
                } 

                dispatch({
                    type: "USERPROFILE_SUCCESS",
                    payload: userProfile
                });

                history.push("/admin/dashboard");
            }
        });
    }
}

export default {
    Get
}