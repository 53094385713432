import resourceManager from '../../helpers/constant.helper';
import constants from '../../helpers/constant.helper';

//sekoe novo properti dodadi go ovde so DEFAULT value
const initialState = {

    EmailSubject: '',
    EmailContent: '',
    StartupIDs: '',

    Message: '',
    ValidationMessages: [],
    Loading: false, //so ogled deka ne loadirame nisto od SERVER
    hasError: false,
    ExportedFileName: ''
}

const ExportReducer = (state = initialState, action) => {
    switch (action.type) {
        case "EXPORT_START":
            return {
                ...state,
                Loading: true,
                hasError: false
            }

        case "EXPORT_ERROR":
            return {
                ...state,
                Message: action.error,
                Loading: false,
                hasError: true,
                ValidationMessages: action.ValidationMessages
            }

        case "EXPORT_SENDEMAIL_SUCCESS":
            return {
                //...state,
                ...state,       //se drugo e vo STATE-ot
                Message: resourceManager.MSG_SENDEMAIL_FINISHED,
                ValidationMessages: [], //resetirj clientski greski
                Loading: false,
                hasError: false
            }

        case "EXPORT_SENDSMS_SUCCESS":
            return {
                //...state,
                ...state,       //se drugo e vo STATE-ot
                Message: resourceManager.MSG_SENDSMS_FINISHED,
                ValidationMessages: [], //resetirj clientski greski
                Loading: false,
                hasError: false
            }


        case "EXPORT_EXCEL_SUCCESS":
            return {
                //...state,
                ...state,       //se drugo e vo STATE-ot
                Message: resourceManager.MSG_EXCELEXPORT_FINISHED,
                ValidationMessages: [], //resetirj clientski greski
                ExportedFileName: action.ExportedFileName,
                Loading: false,
                hasError: false
            }

        //se koristi vo find
        case "EXPORT_RESET":
            state = initialState; //go resetira na iniital state
            return {
                ...state
            }

        default:
            return state
    }
}
export default ExportReducer;