import * as localStorageHelper from "./localstorage.helper";
import Cookies from "universal-cookie";
import config from "../config/config";
import { history } from "../helpers";
import { logHelper } from "../helpers/log.helper";

const AUTH_TOKEN = "AUTH_TOKEN";
const AUTH_USERNAME = "AUTH_USERNAME";
const AUTH_FULLNAME = "AUTH_FULLNAME";
const AUTH_ENTITYID = "AUTH_ENTITYID";
const AUTH_REFRESH_TOKEN = "AUTH_REFRESH_TOKEN";
const AUTH_ROLE_TOKEN = "AUTH_ROLE_TOKEN";
const AUTH_TOKEN_EXPIRESIN = "AUTH_TOKEN_EXPIRESIN";
const STARTUP_ITEM_GUID = "STARTUP_ITEM_GUID";
const STARTUP_ITEM_PREVIEW = "STARTUP_ITEM_PREVIEW";
const LANG_KEY = "LANG_KEY";
const COUNTRY_KEY = "COUNTRY_KEY";

// const AUTH_IS_SYSAMDIN = 'AUTH_IS_SYSAMDIN';
// const AUTH_IS_OFFICER = 'AUTH_IS_OFFICER';
// const AUTH_IS_USER = 'AUTH_IS_USER';

const GLOBAL_ERROR = "AUTH_ERROR";

export function IsSigned() {
  return localStorageHelper.GetValueByKey(AUTH_TOKEN) ? true : false;
}

// export function IsAccessTokenValid() {
//   const dtmTokenExpire = new Date(localStorageHelper.GetValueByKey(AUTH_TOKEN_EXPIRESIN));
//   const dtmNow = new Date(new Date().toUTCString());

//   var date = new Date();
//   var now_utc =  new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));
//   //alert(dtmTokenExpire + "\n" + now_utc);

//   logHelper.log("*****************");
//   logHelper.log("dtmTokenExpire:" + dtmTokenExpire);
//   logHelper.log("dtmNow:" + now_utc);
//   logHelper.log(dtmTokenExpire.getTime() > new Date(now_utc).getTime());
//   return true;
// }

export function GetEntityId() {
  return localStorageHelper.GetValueByKey(AUTH_ENTITYID);
}

export function SignIn(
  token,
  userName,
  fullName,
  refreshToken,
  role,
  expiresIn,
  countryId
) {
  localStorageHelper.SetValueByKey(AUTH_TOKEN, token);
  localStorageHelper.SetValueByKey(AUTH_USERNAME, userName);

  //ENCRIPTAJ TUKA
  localStorageHelper.SetValueByKey(AUTH_FULLNAME, fullName);

  localStorageHelper.SetValueByKey(AUTH_REFRESH_TOKEN, refreshToken);
  localStorageHelper.SetValueByKey(AUTH_ROLE_TOKEN, role);
  localStorageHelper.RemoveValueByKey(GLOBAL_ERROR);
  SetCountryKey(countryId);
  // const cookies = new Cookies();
  // let d = new Date();
  // d.setTime(d.getTime() + (20*60*1000));

  //cookies.set(AUTH_TOKEN, token, {path: "/", expires: d});
  // cookies.set(AUTH_FULLNAME, fullName, {path: "/", expires: d});
}

export function SetLanguage(key) {
  localStorageHelper.SetValueByKey(LANG_KEY, key);
}

export function SetCountryKey(key) {
  localStorageHelper.SetValueByKey(COUNTRY_KEY, key);
}

export function GetUserName() {
  return localStorageHelper.GetValueByKey(AUTH_USERNAME); //ova e EMAIL-oT
}

export function GetLanguage() {
  let lang = localStorageHelper.GetValueByKey(LANG_KEY);
  if (!lang) lang = "1"; //raboti default 1: ENGLISH 2:ARAB, 3: TURKEY
  return lang;
}

export function GetCountryKey() {
  let countryKey = localStorageHelper.GetValueByKey(COUNTRY_KEY);
  return countryKey;
}

// export function GetLanguageRTL() {
//   if (localStorageHelper.GetValueByKey(LANG_KEY) === "2")
//   {
//     return "rtl";
//   }
//   return "ltr";
// }

export function SignOutWithGlobalErrorMsg(msg) {
  //IZBRISI GI SITE KLUCEVI
  SignOut();

  //Settiraj global error i redirectiraj na  Global Error
  localStorageHelper.SetValueByKey(GLOBAL_ERROR, msg);
}

export function SignOut() {
  //najdi tekoven jazik
  let lang = GetLanguage();

  //brise s'e
  localStorageHelper.RemoveAllItems();

  //setiraj go pak
  SetLanguage(lang);

  // localStorageHelper.RemoveValueByKey(AUTH_TOKEN);
  // localStorageHelper.RemoveValueByKey(AUTH_USERNAME);
  // localStorageHelper.RemoveValueByKey(AUTH_FULLNAME);
}

export function GetStartUpItemGUID() {
  //DEKRIPTIRUAJ TUKA
  return localStorageHelper.GetValueByKey(STARTUP_ITEM_GUID);
}

export function HasStartupAssigned() {
  const itemGUID = localStorageHelper.GetValueByKey(STARTUP_ITEM_GUID);

  if (!itemGUID) return false;

  if (itemGUID === "" || itemGUID === "undefined") return false;

  return true;
}

export function SetStartUpItemGUID(value) {
  //ENKRITPIRAJ TUKA
  return localStorageHelper.SetValueByKey(STARTUP_ITEM_GUID, value);
}

export function SetStartupPreview(value) {
  //ENKRITPIRAJ TUKA
  return localStorageHelper.SetValueByKey(STARTUP_ITEM_PREVIEW, value);
}

export function GetFullName() {
  //DEKRIPTIRAJ TUKA
  return localStorageHelper.GetValueByKey(AUTH_FULLNAME);
}

export function GetRefreshToken() {
  return localStorageHelper.GetValueByKey(AUTH_REFRESH_TOKEN);
}

export function GetStartupPreview() {
  //ENKRITPIRAJ TUKA
  var value = localStorageHelper.GetValueByKey(STARTUP_ITEM_PREVIEW);
  if (value && (value === true || value === "true")) {
    return true;
  }

  return false;
}

export function GetRoleToken() {
  return localStorageHelper.GetValueByKey(AUTH_ROLE_TOKEN);
}

//Vo zavisnost od koja roles e go nosi na Dashboard
export function GoToDashboard() {
  if (IsSysAdmin()) {
    history.push("/admin/dashboard");
  } else if (IsOfficer()) {
    history.push("/admin/dashboard"); //da se vidi za officer dashboard
  } else if (IsBTSOfficer()) {
    history.push("/admin/dashboard"); //da se vidi za officer dashboard
  } else if (IsDonor()) {
    history.push("/admin/dashboard"); //da se vidi za officer dashboard
  } else if (IsUser()) {
    history.push("/user/dashboard");
  }
}

export function GetTokenWithBarrier() {
  return "bearer " + localStorageHelper.GetValueByKey(AUTH_TOKEN);
}

export function IsSysAdmin() {
  return localStorageHelper.GetValueByKey(AUTH_ROLE_TOKEN) === config.ROLE_ADMIN
    ? true
    : false;
}

export function IsOfficer() {
  return localStorageHelper.GetValueByKey(AUTH_ROLE_TOKEN) ===
    config.ROLE_OFFICER
    ? true
    : false;
}

export function IsBTSOfficer() {
  return localStorageHelper.GetValueByKey(AUTH_ROLE_TOKEN) ===
    config.ROLE_BTS_OFFICER
    ? true
    : false;
}

export function IsDonor() {
  return localStorageHelper.GetValueByKey(AUTH_ROLE_TOKEN) === config.ROLE_DONOR
    ? true
    : false;
}

export function IsUser() {
  return localStorageHelper.GetValueByKey(AUTH_ROLE_TOKEN) === config.ROLE_USER
    ? true
    : false;
}

export function IsSubSahara() {
  if (GetCountryKey() == "239") return true;
  return false;
}

export function GetGlobalErrorMsg(msg) {
  return localStorageHelper.GetValueByKey(GLOBAL_ERROR);
}

// export function IsSysAdmin() { return localStorageHelper.GetValueByKey(AUTH_IS_SYSAMDIN) === "true" ? true : false; }
// export function IsOfficer() { return localStorageHelper.GetValueByKey(AUTH_IS_OFFICER) === "true" ? true : false; }
// export function IsUser() { return localStorageHelper.GetValueByKey(AUTH_IS_USER) === "true" ? true : false; }

// export const SetUserProfile = (response) => {

//   let UserProfile = {
//     IsSigned: true,
//     FullName: response.data.fullName,
//     Email: response.data.email,
//     UserName: response.data.userName,
//     IsSysAdmin: response.data.isSysAdmin === "True",
//     IsOfficer: response.data.isOfficer === "True",
//     IsUser: response.data.isUser=== "True"
//   }
//   React.createContext(UserProfile);
// }

// function GetValueByKey(keyName)
// {
//   return localStorage.getItem(keyName)
// }

// function SetValueByKey(keyName, keyValue)
// {
//   return localStorage.setItem(keyName, keyValue)
// }

// function RemoveValueByKey(keyName)
// {
//   return localStorage.removeItem(keyName)
// }
