import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Navigator from './Navigator';
import Content from './Content';
import Header from './Header';
import * as AuthenticationHelper from '../helpers/authentication.helper';
import { Router, Redirect, Switch, Route } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import PeopleIcon from '@material-ui/icons/People';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import PermMediaOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActual';
import PublicIcon from '@material-ui/icons/Public';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import TimerIcon from '@material-ui/icons/Timer';
import SettingsIcon from '@material-ui/icons/Settings';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
//import ADMIN_MENUS from '../helpers/adminMenu.js';
import { history } from '../helpers';
import * as LanguageHelper from '../helpers/language.helper';
import MENU_SysAdmin from '../json/MENU_SysAdmin.json';
import MENU_OFFICER from '../json/MENU_Officer.json';
import MENU_DONOR from '../json/MENU_Donor.json';
import MENU_USER from '../json/MENU_User.json';
import MENU_USER_ARABIC from '../json/MENU_User_Arabic.json';
import MENU_USER_TURKEY from '../json/MENU_User_Turkey.json';
import { logHelper } from '../helpers/log.helper';

import MENU_Empty from '../json/MENU_Empty.json';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.spark-online.org/">
        www.spark-online.org
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 256;
const rtl = LanguageHelper.setLTR();
//alert(rtl);

let theme = createMuiTheme({
  direction: rtl.toString(),   //'rtl' or ltr
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};


const styles = makeStyles({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: '#eaeff1',
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
});



function BackContainer(content) {
  const classes = styles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const ltr = LanguageHelper.setLTR().toString();
  const menu_section = content.props.menu_section;  //odreduva sto da prikaze za dadenata sekcija (Home/Administration/Reports)

  //ako nemoze da najde role togas setiraj MENU_EMPTY
  let currentMenu = MENU_Empty;
  if (AuthenticationHelper.IsSysAdmin() === true) {
    currentMenu = (MENU_SysAdmin.find(x => x.section === menu_section) || {});  //menu_section kazuva koja sekcija od MENI-to da pokaze
  }
  else if (AuthenticationHelper.IsOfficer() === true) {
    currentMenu = (MENU_OFFICER.find(x => x.section === menu_section) || {});  //menu_section kazuva koja sekcija od MENI-to da pokaze
  }
  else if (AuthenticationHelper.IsBTSOfficer() === true) {
    currentMenu = (MENU_OFFICER.find(x => x.section === menu_section) || {});  //menu_section kazuva koja sekcija od MENI-to da pokaze
  }
  else if (AuthenticationHelper.IsDonor() === true) {
    currentMenu = (MENU_DONOR.find(x => x.section === menu_section) || {});  //menu_section kazuva koja sekcija od MENI-to da pokaze
  }
  
  else if (AuthenticationHelper.IsUser() === true) {
    if (LanguageHelper.isArabic()) {
      currentMenu = (MENU_USER_ARABIC.find(x => x.section === menu_section) || {});  //menu_section kazuva koja sekcija od MENI-to da pokaze
    } else if(LanguageHelper.isTurkey()) {
      currentMenu = (MENU_USER_TURKEY.find(x => x.section === menu_section) || {});  //menu_section kazuva koja sekcija od MENI-to da pokaze
    }
    else {
      currentMenu = (MENU_USER.find(x => x.section === menu_section) || {});  //menu_section kazuva koja sekcija od MENI-to da pokaze
    }
  }
  else {
    //ne e najden nieden role resetiraj se
    history.push('/signin');
    return;
  }

  //const menu = (Object.keys(MENUS).find(x => x.section === "admin_configure") || {});
  logHelper.log("MENUS");
  logHelper.log(currentMenu);

  //logHelper.log(content.props.location);
  //alert(":ALOOOOOOOOO");

  const currentUrl = content.props.location.pathname + content.props.location.search;
  logHelper.log("currentUrl");
  logHelper.log(currentUrl);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>

      <div className={clsx(classes.root)} dir={rtl.toString()}>

        <CssBaseline />

        <nav className={clsx(classes.drawer)}>
          {/* Ovoj se koristi za MOBILNI TELEFONI koga ke minimiziras mnogu!!*/}
          <Hidden smUp implementation="js">
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              currentUrl={currentUrl}
              menu={currentMenu}
            />
          </Hidden>
          <Hidden xsDown implementation="css">
            <Navigator PaperProps={{ style: { width: drawerWidth } }} currentUrl={currentUrl} menu={currentMenu} />
          </Hidden>
        </nav>


        <div className={clsx(classes.app)}>
          <Header onDrawerToggle={handleDrawerToggle}
            currentUrl={currentUrl}
            menu={currentMenu}
          />
          <main className={clsx(classes.main)}>
            {content}
          </main>
          <footer className={clsx(classes.footer)}>
            <Copyright />
          </footer>
        </div>
      </div>
    </ThemeProvider>
  );
}

BackContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default BackContainer;