import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducers'
import thunk from 'redux-thunk';
import { CookiesProvider } from 'react-cookie';
import App from './components/App';
import { ContextOneProvider } from './ContextOne';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import StartupState from './Context/startupState';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const store = createStore(rootReducer, applyMiddleware(thunk))

ReactDOM.render(
    <CookiesProvider>
        <Provider store={store}>
            {/* <ContextOneProvider>
        <App />
    </ContextOneProvider> */}
            <StartupState>
                <StylesProvider jss={jss}>
                    <App />
                </StylesProvider>
            </StartupState>

        </Provider>
    </CookiesProvider>,
    document.getElementById('root'));

