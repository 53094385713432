import axios from 'axios';
import config from '../config/config';
import * as AuthenticationHelper from './authentication.helper';
import { history } from '../helpers';
import { logHelper } from './log.helper';


export const restHelper = {
    login,
    get,
    post,
    put,
    refreshToken,
    deleteDetail,
    postFile
};

function get(apiEndpoint) {
    
    return axios.get(logHelper.GetBaseUrl()  + apiEndpoint, getOptions()).then((response) => {
        return response;
    }).catch((err) => {
        return ParseErrorResponse(err);
        // logHelper.log("Error in response");
        // logHelper.log(err);
    })
}

//Ova variabla e sekogas 0 za sekoj validen request 
//Dokolku se sluci expire access_token, togas ke stane howManyTimes401Err=1 (vidi ParseErrorResponse)
//ako  e SysAdmin ili pak Officer koj sto imaat privlegii da dobijat nov access_token 
//togas istata ke se resetira na sleden post. 
//dokolku nemaat privilegii togas ke vleze vo RECURZIJA i poradi toa proveruvame ako 
//e pogolema od 1 napravi signout
let howManyTimes401Err = 0;

function post(apiEndpoint, payload) {
    logHelper.error(apiEndpoint);

    return axios.post(logHelper.GetBaseUrl() + apiEndpoint, payload, getOptions()).then((response) => {
        howManyTimes401Err = 0; //se e vo red, resetiraj na 0, procitaj pogore komentar
        return response;
    }).catch((err) => {
        //https://stackoverflow.com/questions/39153080/how-can-i-get-the-status-code-from-an-http-error-in-axios
        
        logHelper.log("RESPONSE");
        //alert(err.response.status);

        return ParseErrorResponse(err);
    })
}


function postFile(apiEndpoint, payload) {
    logHelper.error(apiEndpoint);

    return axios.post(logHelper.GetBaseUrl() + apiEndpoint, payload, getFormFileOptions()).then((response) => {
        return response;
    }).catch((err) => {
        return ParseErrorResponse(err);
    })
}




function ParseErrorResponse(err) {
    logHelper.error(err);

    if (err) {
        if (err.response) {
            //sekako se slucila greska ne prevniraj nisto so history ke si otide na drug link 
            //a sekako dole ke ja pokaze greskata na GUI-to no taa nema da se gleda 
            //ne stavaj return so ogled deka ima drugi exceptions
            if (err.response.status === 401)  //od site POST methodi
            {
                    //za sekoj slucaj izbrisi s'e od storage
                AuthenticationHelper.SignOut();

                history.push('/signin');

                //ako nemoze od vtor pat da dobie validen ACCESS_TOKEN  odlogiraj go 
                //ova se slucuva ako nekoj od User/Officer role cepak  po Admin routes  (od pogorno nivo)
                //citaj pogore
                // howManyTimes401Err++;
                // if (howManyTimes401Err > 1) {
                //     //za sekoj slucaj izbrisi s'e od storage
                //     AuthenticationHelper.SignOut();

                //     history.push('/signin');
                // }
                // else {
                //     history.push('/refreshtoken');
                // }
            }
            if (err.response.status === 400) //od refreshToken doaga
            {
                //za sekoj slucaj izbrisi s'e od storage
                AuthenticationHelper.SignOut();

                history.push('/SignIn');
            }

            logHelper.error("err.response");
            logHelper.error(err.response);
            //logHelper.error(err.response.data);
            if (err.response.data) {
                //alertMessage komponentata ocekuva niza od [error: "XXX", error_description:"YYYY"]
                //dokolku response-ot od server nema takvi propertia error, error_description
                //togas vestacki gi dodava dole primerno ako se dobie ova greska
                //ExceptionMessage: "The underlying provider failed on Open."
                //ExceptionType: "System.Data.Entity.Core.EntityException"
                if (err.response.data.ExceptionMessage) {
                    err.response.data.error_description = err.response.data.ExceptionMessage;
                    err.response.data.error = err.response.data.ExceptionType;
                }

                //ovdeka dokolku Authorization Token-ot  e expired 
                else if (err.response.data.Message) {
                    err.response.data.error_description = err.response.data.Message;
                    err.response.data.error = "Authorization issue with token";

                    // AuthenticationHelper.SignOutWithGlobalErrorMsg(err.response.data.error);
                    // history.push('/Error')
                }

                //ovdeka dokolku Refresh Token-ot   e expired 
                else if (err.response.data.error) {
                    
                    err.response.data.error = err.response.data.error;
                    if (err.response.data.error_description) {
                        err.response.data.error_description = err.response.data.error_description;
                    }
                    err.response.data.error = "Authorization issue with refresh tokens";

                    // AuthenticationHelper.SignOutWithGlobalErrorMsg(err.response.data.error);
                    // history.push('/Error')
                }

                logHelper.error("FINALLY");
                logHelper.error(err.response.data);
                return err.response.data;
            }
        }
    }

    logHelper.error(err);


    let outputError = { error: "unknown_error", error_description: "Unable to login - " + String(err) };
    logHelper.error(outputError);
    return outputError;
}

function refreshToken(apiEndpoint, payload) {

    //za SPA applikacii ne se koristi client_secret so ogled deka e public code-ot 
    //pa poradi toa ne se praka i ovoj del ke se iskomentira: 
    //var payloadFormat =   "grant_type={0}&username={1}&password={2}&client_id={3}&client_secret={4}";
    //payloadFormat =  payloadFormat.replace("{4}", config.client_secret);
    //isto taka vo WebAPI-to za ApplicationType se stava: 0 -> MObile Apps 
    //Vidi tabela: Client ako se stavi ApplicationType: 1 
    //togas proveruva logika dali ima client_secret
    //dodeka ako e 1 togas ke mora da go pratime i client_secret 
    //nacelno client_secret se koristi vo Clientski applikacii
    //poveke za citanje
    //https://aaronparecki.com/oauth-2-simplified/

    //custom format for this case
    var payloadFormat = "grant_type={0}&refresh_token={1}&client_id={2}";
    payloadFormat = payloadFormat.replace("{0}", payload.grant_type);
    payloadFormat = payloadFormat.replace("{1}", payload.refresh_Token);
    payloadFormat = payloadFormat.replace("{2}", config.client_id);


    logHelper.log("PAYLOADFORMAT:" + payloadFormat);

    //works with Get From Option
    return axios.post(logHelper.GetBaseUrl() + apiEndpoint, payloadFormat, getFormOptions()).then((response) => {
        logHelper.error("response");
        logHelper.error(response);
        return response;
    }).catch((err) => {
        return ParseErrorResponse(err);
    })
}


function login(apiEndpoint, payload) {
    //return postCustom(apiEndpoint, payload);

    //custom format for this case

    //za SPA applikacii ne se koristi client_secret so ogled deka e public code-ot 
    //pa poradi toa ne se praka i ovoj del ke se iskomentira: 
    //var payloadFormat =   "grant_type={0}&username={1}&password={2}&client_id={3}&client_secret={4}";
    //payloadFormat =  payloadFormat.replace("{4}", config.client_secret);
    //isto taka vo WebAPI-to za ApplicationType se stava: 0 -> MObile Apps 
    //Vidi tabela: Client ako se stavi ApplicationType: 1 
    //togas proveruva logika dali ima client_secret
    //dodeka ako e 1 togas ke mora da go pratime i client_secret 
    //nacelno client_secret se koristi vo Clientski applikacii
    //poveke za citanje
    //https://aaronparecki.com/oauth-2-simplified/

    var payloadFormat = "grant_type={0}&username={1}&password={2}&client_id={3}";
    payloadFormat = payloadFormat.replace("{0}", payload.grant_type);
    payloadFormat = payloadFormat.replace("{1}", payload.username);
    payloadFormat = payloadFormat.replace("{2}", payload.password);
    payloadFormat = payloadFormat.replace("{3}", config.client_id);

    //payloadFormat = "grant_type=password&username=AdminSyria@spark-online.org&password=Aut@mati@n2015";

    logHelper.log("PAYLOADFORMAT:" + payloadFormat);

    //works with Get From Option
    return axios.post(logHelper.GetBaseUrl() + apiEndpoint, payloadFormat, getFormOptions()).then((response) => {
        logHelper.error("response");
        logHelper.error(response);
        return response;
    }).catch((err) => {
        return ParseErrorResponse(err);
    })
}




function put(apiEndpoint, payload) {
    return axios.put(logHelper.GetBaseUrl() + apiEndpoint, payload, getOptions()).then((response) => {
        return response;
    }).catch((err) => {
        logHelper.log(err);
    })
}

function deleteDetail(apiEndpoint) {
    return axios.delete(logHelper.GetBaseUrl() + apiEndpoint, getOptions()).then((response) => {
        return response;
    }).catch((err) => {
        logHelper.log(err);
    })
}

function getOptions() {
    let options = {};

    // if(localStorage.getItem('token')){
    //     options.headers = { 'x-access-token': localStorage.getItem('token') };
    // }
    // else {
    //     options.headers = { 'x-access-token': localStorage.getItem('token'),
    //                         'Content-Type': "application/json",
    //                         'Authorization': 'bearer ' + localStorage.getItem('token'),
    //                         }

    // }

    options.headers = { 'Content-Type': "application/json" }
    //add Authorization key as well
    options.headers["Authorization"] = AuthenticationHelper.GetTokenWithBarrier();


    logHelper.log(options);
    return options;
}

//samo za login action se koristi i nisto poveke
function getFormOptions() {
    let options = {};
    options.headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    logHelper.log(options);
    return options;
}

function getFormFileOptions() {
    let options = {};
    options.headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    options.headers["Authorization"] = AuthenticationHelper.GetTokenWithBarrier();
    logHelper.log(options);
    return options;
}
