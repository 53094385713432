import resourceManager from '../helpers/constant.helper';
import constants from '../helpers/constant.helper';


//sekoe novo properti dodadi go ovde so DEFAULT value
const initialState = {

    Record: {

        UserFullName: '',
        UserName: '',
    },

    LookupData: [],  //Table: LookupData
    ScholarshipCountries: [],   //Table: Country
    Provinces: [],   //Table: CountryProvince
    StartupTypeIDs: [], //SME, BENEF, Startup vo LookupData Model
    Records: [],


    Message: '',
    Loading: true,
    hasError: false,
}

const ManageUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SEARCH_STARTUP_START":
            return {
                ...state,
                Loading: true,
                hasError: false
            }

        case "SEARCH_STARTUP_ERROR":
            return {
                ...state,
                Message: action.error,
                Loading: false,
                hasError: true
            }


        case "SEARCH_STARTUP_INIT":
            return {
                ...state,
                //ako od server vratil NULL togas setiraj inital state vo sprotivno ne raboti state-ot
                LookupData: action.LookupData,
                ScholarshipCountries: action.ScholarshipCountries,
                Provinces: action.Provinces,
                StartupTypeIDs: action.StartupTypeIDs,
                Message: "",
                Loading: false,
                hasError: false
            }

        //se koristi vo find
        case "SEARCH_STARTUP_SEARCH":
            return {
                ...state,
                Records: action.Records,  //action.payload == List<Entity>
                Message: "",
                Loading: false,
                hasError: false
            }

        //se koristi vo find
        case "SEARCH_STARTUP_RESET":
            state = initialState; //go resetira na iniital state
            return {
                ...state
            }

        case "SEARCH_STARTUP_RESULTS":
            return {
                ...state,
                Records: action.Records,
                Record: action.Record,  //argumentite
                Message: "",
                Loading: false,
                hasError: false
            }
        default:
            return state
    }
}
export default ManageUsersReducer;