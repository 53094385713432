import resourceManager from '../helpers/constant.helper';
import constants from '../helpers/constant.helper';


//sekoe novo properti dodadi go ovde so DEFAULT value
const initialState = {

    Record: {
        sfFirstName: '0',
        sfLastName: '',
        sfGenderID: '216',
        sfEmploymentStatusID: '1',
        sfBirthDate: ''
    },

    LookupData: [],  //Table: LookupData
    ScholarshipCountries: [],   //Table: Country
    Provinces: [],   //Table: CountryProvince

    Records: [],


    Message: '',
    Loading: false,
    hasError: false,
}

const SmeFounderReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SMEFOUNDER_START":
            return {
                ...state,
                Message: resourceManager.MSG_PLEASE_WAIT,    //za da izbrise prethoden error ako go imalo
                Loading: true,
                hasError: false
            }

        case "SMEFOUNDER_ERROR":
            return {
                ...state,
                Message: action.error,
                Loading: false,
                hasError: true
            }


        case "SMEFOUNDER_DELETE_ERROR":
            return {
                ...state,
                Message: action.error,
                Record: action.Record,  //istite vrednosti gi vraka tie sto gi vnesol
                Loading: false,
                hasError: true
            }


        case "SMEFOUNDER_SAVE_ERROR":
            return {
                ...state,
                Message: action.error,
                Record: action.Record,  //istite vrednosti gi vraka tie sto gi vnesol
                Loading: false,
                hasError: true
            }

        case "SMEFOUNDER_SAVE_SUCCESS":
            return {
                //...state,
                ...state,       //se drugo e vo STATE-ot
                Records: action.Records,  //samo noviot record ako e NOV, ako e update si ostanuva IST
                Message: resourceManager.MSG_SAVE,
                Loading: false,
                hasError: false
            }

        case "SMEFOUNDER_DELETE_SUCCESS":
            return {
                //...state,
                ...state,       //se drugo e vo STATE-ot
                Records: action.Records,  //samo noviot record ako e NOV, ako e update si ostanuva IST
                Message: resourceManager.MSG_DELETED,
                Loading: false,
                hasError: false
            }

        case "SMEFOUNDER_EDIT":
            return {
                ...state,
                //ako od server vratil NULL togas setiraj inital state vo sprotivno ne raboti state-ot
                Records: action.Records,
                LookupData: action.LookupData,
                ScholarshipCountries: action.ScholarshipCountries,
                Provinces: action.Provinces,
                Message: "",
                Loading: false,
                hasError: false
            }

        //se koristi vo find
        case "SMEFOUNDER_SEARCH":
            return {
                ...state,
                Records: action.payload,  //action.payload == List<Entity>
                Message: "",
                Loading: false,
                hasError: false
            }

        //se koristi vo find
        case "SMEFOUNDER_RESET":
            state = initialState; //go resetira na iniital state
            return {
                ...state
            }

        default:
            return state
    }
}
export default SmeFounderReducer;