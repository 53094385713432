import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Radio, TableFooter } from '@material-ui/core';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PreviewService from '../../services/PreviewService.js';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import * as PreviewComponents from './PreviewComponents';
import { InputText, InputTextMultiLine } from './PreviewComponents';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Keys from '../../json/ResourceStartup.json';
import allActions from '../../actions';
import constants from '../../helpers/constant.helper';
import AlertMessage from '../AlertMessage'
import * as AuthenticationHelper from '../../helpers/authentication.helper'
import CircularProgress from '@material-ui/core/CircularProgress';
import { isWithinInterval } from 'date-fns';
import PleaseWaitMessage from '../PleaseWaitMessage'
import Link from '@material-ui/core/Link';
import { history, ShowLinkedRecords } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 348,
  },
  formElement: {},
  formLabel: {
    color: '#aaaaa9',
    marginTop: 10,
    '&:hover': {
      background: '#F3F3F2',
    }
  },
  header: {
    backgroundColor: '#F3F3F2',
    borderRadius: 4,
    paddingLeft: 5,
  },
  subHeader: {
    marginTop: 15,
    marginBottom: 1,
    fontWeight: 700
  },
  buttonStyle: {
    //margin: theme.spacing(1),
    textTransform: 'none',
  },
  xs12: {
    width: '50%',
  },
  columnLabel: { width: '10%' },
  columnInput: { width: '70%' },
}));


export default function SendEmail(args) {

  const classes = useStyles();
  const dataReducer = useSelector(state => state.ExportReducer);
  const dispatch = useDispatch()
  
  const [Record, setRecord] = useState(dataReducer);
  const handleChange = e => {
    const { name, value } = e.target;
    setRecord(prevState => ({ ...prevState, [name]: value }));
  };

  //na inicijalen modal show setiraj applicanti vo STATE za prakanje na SERVER 
  
  useEffect(() => {
    
    //najdi gi selektirani applikanti, zemi im StartupIDs i stavi gi vo niza
    let startupIDs = '';
    for(let i=0; i<=args.Records.length-1; i++)
    {
      if (startupIDs === '')
      {
        startupIDs = args.Records[i].stStartupID;
      }
      else {
        startupIDs += "," + args.Records[i].stStartupID;
      }
    }
    
    //ovie STARTUPI ke odan a server setiraj gi vo sesija
    setRecord(prevState => ({ ...prevState, "StartupIDs": startupIDs}));

    return () => {
      dispatch(allActions.ExportActions.Reset()); //vraka na Initial State
    };
  }, []);   //default


  return (
    <TableContainer component={Paper}>

      <AlertMessage {...dataReducer} />

      <Table className={classes.table} aria-label="customized table" style={{ width: "100%", tableLayout: "auto" }}>
        <TableBody>
          <TableRow>
            <TableCell colspan="2">
            <h2 id="simple-modal-title">Send Email</h2>
              <p>Total applicants selected: {args.Records.length} </p>{}
            </TableCell>
          </TableRow>

          {InputText(classes, "Enter subject of the email", "EmailSubject", Record.EmailSubject, handleChange, dataReducer.Loading, dataReducer.ValidationMessages, false)}
          {InputTextMultiLine(classes, "Enter content of the email", "EmailContent", Record.EmailContent, handleChange, dataReducer.Loading, dataReducer.ValidationMessages, false)}

          <TableRow>
            <TableCell colspan="2" align="right">
              <Button variant="contained" color="primary" className={classes.buttonStyle}
                onClick={() => dispatch(allActions.ExportActions.SendEmail(Record))}
              >
                Send Email
          </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

