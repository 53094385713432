import resourceManager from '../helpers/constant.helper';
import { configure } from '@testing-library/react';

//sekoe novo properti dodadi go ovde so DEFAULT value
const initialState = {

    Record: {
        ldLookupDataID: 0,
        ldTypeID: 0,
        ldValue: "",
        ldValue2: ""
    },
    
    LookupType:{},  //samiot LookupType

    Records: [],
    
    Message: '',
    Loading: false,
    hasError: false,
}

const LookupDataReducer = (state = initialState, action) => {


    switch (action.type) {
        case "LOOKUPDATA_START":
            return {
                ...state,

                Message: '',    //za da izbrise prethoden error ako go imalo
                Loading: true,
                hasError: false
            }

        case "LOOKUPDATA_ERROR":
            return {
                ...state,
                Message: action.error,
                Loading: false,
                hasError: true
            }

        case "LOOKUPDATA_SAVE":
            return {
                //...state,
                ...state,       //se drugo e vo STATE-ot
                Record: action.payload,  //samo noviot record ako e NOV, ako e update si ostanuva IST
                Message: resourceManager.MSG_SAVE,
                Loading: false,
                hasError: false
            }


        case "LOOKUPDATA_EDIT":
            return {
                ...state,
                Record: action.payload,
                LookupType: action.payload_LookupType,    //ova moze samo na SEARCH da se zeme i da ne se loadira povtorno so ogled deka vo STATE ke si stoi
                Message: "",
                Loading: false,
                hasError: false
            }

        case "LOOKUPDATA_CREATE":
            return {
                ...state,
                Record: initialState.Record,    //za da se resetira
                LookupType: action.payload_LookupType,    //ova moze samo na SEARCH da se zeme i da ne se loadira povtorno so ogled deka vo STATE ke si stoi
                Message: "",
                Loading: false,
                hasError: false
            }

            //se koristi vo find
        case "LOOKUPDATA_LIST":
            return {
                ...state,
                Records: action.payload,
                LookupType: action.payload_LookupType,    //ova moze samo na SEARCH da se zeme i da ne se loadira povtorno so ogled deka vo STATE ke si stoi
                Message: "",
                Loading: false,
                hasError: false
            }

        default:
            return state
    }
}
export default LookupDataReducer;