import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import * as AuthenticationHelper from '../helpers/authentication.helper';
import allActions from '../actions';
import MuiAlert from '@material-ui/lab/Alert';
import AlertMessage from './AlertMessage'
import { useCookies } from "react-cookie";
import { history } from './../helpers';

export default function RefreshToken() {
  
  const dispatch = useDispatch();

  useEffect(() => {
    //od koga ke se refreshira ke go vrati na dashboard ili pak na url.refferer (prethodnata strana)
      dispatch(allActions.LoginActions.refreshToken()); 
  }, []);

  return (
    <Container component="main" maxWidth="xs"/>
  );
}
