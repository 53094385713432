import { restHelper } from "../../helpers/rest.helper";

const SendEmail = async (payload) => {
  let response = await restHelper.post("api/Export/SendEmail", payload);
  return response;
};

const ExportStartupExcel = async (payload) => {
  let response = await restHelper.post("api/Export/ExcelAllStartups", payload);
  return response;
};

const ActivityDownloadFile = async (payload) => {
  let response = await restHelper.post(
    "api/Export/ActivityDownloadFile",
    payload
  );
  return response;
};

const ManualDownloadFile = async (payload) => {
  let response = await restHelper.post(
    "api/Export/ManualDownloadFile",
    payload
  );
  return response;
};

const ExportActivitiesExcel = async (payload) => {
  let response = await restHelper.post(
    "api/Export/ExcelActivitiesStartups",
    payload
  );
  return response;
};

const SendSMS = async (payload) => {
  let response = await restHelper.post("api/Export/SendSMS", payload);
  return response;
};

export default {
  SendEmail,
  ExportStartupExcel,
  SendSMS,
  ExportActivitiesExcel,
  ActivityDownloadFile,
  ManualDownloadFile,
};
