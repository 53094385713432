import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import allActions from '../actions';
import AlertMessage from '../components/AlertMessage'
import { InputTextOnly, InputDropDownOnly2, InputDropDownOnly, InputDropDownLookupOnly, InputDateOnly } from './Shared/PreviewComponents';
import Keys from '../json/ResourceStartup.json';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { logHelper } from '../helpers/log.helper';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const keys = Keys[0]; //za da naoga textovi od JSON FIle

  const dispatch = useDispatch();
  const dataReducer = useSelector(state => state.RegisterUserReducer);

  const [Record, setRecord] = useState(dataReducer.Record);
  const [Countries, setCountries] = useState(dataReducer.Countries);
  logHelper.log(dataReducer);

  //Handle Change
  const handleChange = e => {
    const { name, value } = e.target;
    setRecord(prevState => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {

    //provieri dali e logiran prvo ako ne nosi go nazad na SignIn
    dispatch(allActions.RegisterUserActions.Edit(null));

    return () => {
      dispatch(allActions.RegisterUserActions.Reset()); //vraka na Initial State
    };
  }, []);   //default


  //ke se zapali smao pri promena 
  useEffect(() => {

    setCountries(dataReducer.Countries);

  }, [dataReducer]);



  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Create Startup Profile 
        </Typography>

        <Grid container spacing={2}>

          <Grid item xs={12}>
            <br/>
            {InputDropDownOnly2("CountryID", Countries, Record.CountryID, handleChange, keys["CountrySelect"])}
            <br/><br/>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="FirstName"
              id="FirstName"
              variant="outlined"
              required
              fullWidth
              label="First Name"
              onChange={handleChange}
              value={Record.FirstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="LastName"
              name="LastName"
              label="Last Name"
              autoComplete="LastName"
              onChange={handleChange}
              value={Record.LastName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="Email"
              label="Email Address"
              name="Email"
              autoComplete="Email"
              onChange={handleChange}
              value={Record.Email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="Password"
              label="Password"
              type="password"
              id="Password"
              autoComplete="current-password"
              onChange={handleChange}
              value={Record.Password}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="ConfirmPassword"
              label="Confirm Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
              value={Record.ConfirmPassword}
            />
          </Grid>
          {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to be contacted by SPARK for any news / updates via email."
              />
            </Grid> */}
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => dispatch(allActions.RegisterUserActions.createUser(Record))}
        >
          Create and Sign In
          </Button>
        <Grid container justify="flex-end">
          <Grid item>
            <Link href="/SignIn" variant="body2">
              Already you have Startup profile, go to Sign in
              </Link>
          </Grid>
        </Grid>
        <AlertMessage {...dataReducer} />
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
