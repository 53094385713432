import { restHelper } from '../helpers/rest.helper';
import { logHelper } from '../helpers/log.helper';


const AdvancedSearch = async (payload) => {
    logHelper.log(payload)
    let response = await restHelper.post('api/SystemUser/AdvancedSearch', payload);
    return response;
}

const CheckSession = async (payload) => {
    logHelper.log(payload)
    let response = await restHelper.post('api/SystemUser/CheckSession', payload);
    return response;
}


export default {
    AdvancedSearch,
    CheckSession
}