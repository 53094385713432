import Keys1 from '../json/KeysEnglish.json';
import Keys2 from '../json/KeysArabic.json';
import Keys3 from '../json/KeysTurkey.json';
import * as AuthenticationHelper from './authentication.helper';

export function GetLanguageKeys() {
  
  const  language = AuthenticationHelper.GetLanguage();

  if (language === "1")
  {
    let keysEnglish = Keys1[0]; 
    return keysEnglish;
  }
  else if (language === "2")
  {
    let keysArabic = Keys2[0]; 
    return keysArabic;
  }
  else if (language === "3")
  {
    let keysTurkey = Keys3[0]; 
    return keysTurkey;
  }
  else {
    let keysEnglish = Keys1[0]; 
    return keysEnglish;
  }
}

export function GetLanguageID() {
  
  return AuthenticationHelper.GetLanguage();
}



export function isLanguageLTR() {
  if (AuthenticationHelper.GetLanguage() === "2")
    return false;
  return true; 
}

export function isArabic() {
  if (AuthenticationHelper.GetLanguage() === "2")
    return true;
  return false; 
}

export function isTurkey() {
  if (AuthenticationHelper.GetLanguage() === "3")
    return true;
  return false; 
}

export function setLTR() {
  if (AuthenticationHelper.GetLanguage() === "2")
    return "rtl";
  return "ltr"; 
}


export function allignLeft() {
  if (AuthenticationHelper.GetLanguage() === "2")
    return "right";
  return "left"
}


export function buttonAllignLeft() {
  if (AuthenticationHelper.GetLanguage() === "2")
    return "left";
  return "right"
}