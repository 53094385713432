import { restHelper } from '../helpers/rest.helper';
import * as localStorageHelper from '../helpers/localstorage.helper';

const GetCountryProvincesAll = async () => {

    const url = 'api/CountryProvince/GetCountriesProvincesAll';
    let response = localStorageHelper.GetJSONResponse(url);
    if (!response)
    { 
        response = await restHelper.post(url, null);
        if (!response.error)  //ako nema greska stavi go vo Storage vo sprotivno vrati ja greska nazad kako Response
        {
            localStorageHelper.SetValueByKey(url, JSON.stringify(response.data));
        }
    }
    return response;
}
export default {
    GetCountryProvincesAll
}