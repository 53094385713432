// export const localStorageHelper = {
//   GetValueByKey,
//   SetValueByKey,
//   RemoveValueByKey
// };


export function  ExistByKey(keyName)
{
  return localStorage.getItem(keyName) != null ? true : false;
}

export function  GetValueByKey(keyName)
{
  return localStorage.getItem(keyName)
}


export function  GetJSONResponse(keyName)
{
  if (ExistByKey(keyName))
  {
    //alert("POSTOI VO CACHE");
    let responseCache = GetValueByKey(keyName);
    responseCache = JSON.parse(responseCache);
    let response = {data: responseCache}; //mora vaka za da moze da odi posle niz validacija na nivo na Actions
    return response;
  }
  return null;
}

export function  GetJSON(keyName)
{
  if (ExistByKey(keyName))
  {
    let responseCache = GetValueByKey(keyName);
    responseCache = JSON.parse(responseCache);
    return responseCache;
  }
  return null;
}


export function  SetJSON(keyName, keyValue)
{
  SetValueByKey(keyName, JSON.stringify(keyValue));
}



export function SetValueByKey(keyName, keyValue)
{
  return localStorage.setItem(keyName, keyValue)
}

export function RemoveValueByKey(keyName)
{
  return localStorage.removeItem(keyName)
}

export function RemoveAllItems()
{
  // let i=0;
  // for(i=0;localStorage.length-1; i++)
  // {
  //   //let keyName = localStorage[i];
  //   return localStorage.removeItem(i); 
  // } 
  localStorage.clear();
}
