import LookupDataService from "../services/LookupDataService";
import StartupService from "../services/StartupService";
import ImportActivityService from "../services/ImportActivityService";
import CountryProvinceService from "../services/CountryProvinceService";
import CountryService from "../services/CountryService";
import ServiceLookupData from "../services/LookupDataService";
import ServiceLookupType from "../services/LookupTypeService";
import { validatorHelper, responseHelper, history } from "../helpers";
import Keys from "../json/ResourceStartup.json";
import PartnerDataService from "../services/PartnerDataService";

// responseHelper.validateResponses(responses);
//Validate Response validira responses dali ima nekakvi greski
//responses = [] --niza
//Iterira niz nea i ako e TRUE togas gi vraka samite responses[] koj sto bile prateni za inspekcija
//ako e FALSE vraka response od ERROR objekt (ne niza, samo eden objekt)
async function EditAsync(args) {
  let responseLookupData = await LookupDataService.SearchByTypeIDs({
    LookupTypeIDs: args.LookupTypeIDs,
  });
  let responsePartners = await PartnerDataService.GetAll(null);
  let responses = [responseLookupData, responsePartners];
  return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
}

async function DeleteAsync(args) {
  let response = await LookupDataService.Delete(args);
  let responses = [response];
  return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
}

async function SearchAsync(args) {
  let response = await StartupService.Search(args);
  let responses = [response];
  return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
}

async function SaveAsync(Record) {
  //prvo validiraj pred da pustas za snimanje
  const keys = Keys[0]; //za da naoga textovi od JSON FIle
  let jsonInputData = [
    {
      element: "acCategoryID",
      type: "SelectEmpty", //proverka na select isto kako vo MVC
      value: Record.acCategoryID,
      message: "Category can't be empty",
    },
    {
      element: "acActivityTypeID",
      type: "SelectEmpty", //proverka na select isto kako vo MVC
      value: Record.acActivityTypeID,
      message: "Category can't be empty",
    },
    // {
    //   element: "acDonorID",
    //   type: "SelectEmpty", //proverka na select isto kako vo MVC
    //   value: Record.acDonorID,
    //   message: "Donor can't be empty",
    // },
    {
      element: "acCompletedID",
      type: "SelectEmpty", //proverka na select isto kako vo MVC
      value: Record.acCompletedID,
      message: "Attendance can't be empty",
    },
    {
      element: "acFrom",
      type: "DateTimeEmpty", //proverka na select isto kako vo MVC
      value: Record.acFrom,
      message: "Start Date can't be empty",
    },
    {
      element: "acTo",
      type: "DateTimeEmpty", //proverka na select isto kako vo MVC
      value: Record.acTo,
      message: "End Date can't be empty",
    },
    {
      element: "acName",
      type: "required",
      value: Record.acName,
      message: "Name can't be empty",
    },
    {
      element: "acDescription",
      type: "required",
      value: Record.acDescription,
      message: "Description can't be empty",
    },
    {
      element: "StartupIDs",
      type: "required",
      value: Record.StartupIDs,
      message: "StartupIDs  can't be empty",
    },
    {
      element: "sadDonorParentId",
      type: "required",
      value: Record.sadDonorParentId,
      message: "Fund by can't be empty",
    },
    {
      element: "DonorParentReportId",
      type: "required",
      value: Record.DonorParentReportId,
      message: "Report by  can't be empty",
    },
    {
      element: "acPartnerID",
      type: "SelectEmpty",
      value: Record.acPartnerID,
      message: "Partner  can't be empty",
    },
  ];
  let validationMessages = validatorHelper.validate(jsonInputData);
  if (validationMessages.length > 0) {
    let responses = {
      error: true, //oznaci deka ima greska
      error_description: keys["FormCantBeSumitted"], //napisi generalna greska za vo Alert
      validationMessages: validationMessages, //vrati gi greskite za GUI
    };
    return responses;
  } else {
    let responseEntity = await ImportActivityService.Import(Record);
    let responses = [responseEntity];
    return responseHelper.validateResponses(responses);
  }
}

const Reset = () => {
  return (dispatch) => {
    dispatch({ type: "IMPORTACTIVITY_RESET" });
  };
};
const Edit = (args) => {
  return (dispatch) => {
    dispatch({ type: "IMPORTACTIVITY_START" });

    EditAsync(args).then((responses) => {
      if (responses.error) {
        dispatch({
          type: "IMPORTACTIVITY_ERROR",
          error: responses.error_description,
        });
      } else {
        dispatch({
          type: "IMPORTACTIVITY_EDIT",
          LookupData: responses[0].data,
        });
      }
    });
  };
};

const Delete = (args) => {
  return (dispatch) => {
    dispatch({ type: "IMPORTACTIVITY_START" });

    DeleteAsync(args).then((responses) => {
      if (responses.error) {
        dispatch({
          type: "IMPORTACTIVITY_ERROR",
          error: responses.error_description,
        });
      } else {
        history.push("/lookupdata/find/" + args.ldTypeID);
      }
    });
  };
};

const Search = (args) => {
  return (dispatch) => {
    dispatch({ type: "IMPORTACTIVITY_START" });

    SearchAsync(args).then((responses) => {
      if (responses.error) {
        dispatch({
          type: "IMPORTACTIVITY_ERROR",
          error: responses.error_description,
        });
      } else {
        dispatch({
          type: "IMPORTACTIVITY_SEARCH",
          Records: responses[0].data,
        });
      }
    });
  };
};

//OVOJ SEUSTE NE E NAPRAVEN DA RABOTI SO SERVICE
const Save = (Record) => {
  return (dispatch) => {
    dispatch({ type: "IMPORTACTIVITY_START" }); //da se refreshira GUI-to so SPINNER-ot

    const keys = Keys[0]; //za da naoga textovi od JSON FIle
    let jsonInputData = [
      {
        element: "acCategoryID",
        type: "SelectEmpty", //proverka na select isto kako vo MVC
        value: Record.acCategoryID,
        message: "Category can't be empty",
      },
      {
        element: "acActivityTypeID",
        type: "SelectEmpty", //proverka na select isto kako vo MVC
        value: Record.acActivityTypeID,
        message: "Category can't be empty",
      },
      // {
      //   element: "acDonorID",
      //   type: "SelectEmpty", //proverka na select isto kako vo MVC
      //   value: Record.acDonorID,
      //   message: "Donor can't be empty",
      // },
      {
        element: "acCompletedID",
        type: "SelectEmpty", //proverka na select isto kako vo MVC
        value: Record.acCompletedID,
        message: "Attendance can't be empty",
      },
      {
        element: "acFrom",
        type: "DateTimeEmpty", //proverka na select isto kako vo MVC
        value: Record.acFrom,
        message: "Start Date can't be empty",
      },
      {
        element: "acTo",
        type: "DateTimeEmpty", //proverka na select isto kako vo MVC
        value: Record.acTo,
        message: "End Date can't be empty",
      },
      {
        element: "acName",
        type: "required",
        value: Record.acName,
        message: "Name can't be empty",
      },
      {
        element: "acDescription",
        type: "required",
        value: Record.acDescription,
        message: "Description can't be empty",
      },
      {
        element: "StartupIDs",
        type: "required",
        value: Record.StartupIDs,
        message: "StartupIDs  can't be empty",
      },
      {
        element: "sadParentDonorId",
        type: "required",
        value: Record.sadParentDonorId,
        message: "Parent Donor  can't be empty",
      },
      {
        element: "DonorParentReportId",
        type: "required",
        value: Record.DonorParentReportId,
        message: "Donor Parent Report  can't be empty",
      },
    ];
    let validationMessages = validatorHelper.validate(jsonInputData);

    //validacija
    //da se dodade
    if (validationMessages.length > 0) {
      dispatch({
        type: "IMPORTACTIVITY_SAVE_ERROR",
        ValidationMessages: validationMessages,
        error: keys["FormCantBeSumitted"],
        Record: Record,
      }); //da ne prekucuva, toa sto e od client toa da mu vrati
    } else {
      SaveAsync(Record).then((responses) => {
        if (responses.error) {
          dispatch({
            type: "IMPORTACTIVITY_SAVE_ERROR",
            error: responses.error_description, //od server gresakta
            Record: Record,
          }); //da ne prekucuva, toa sto e od client toa da mu vrati
        } else {
          dispatch({
            type: "IMPORTACTIVITY_SAVE_SUCCESS", //od server gresakta
            Record: Record,
          }); //toj sto go vnesol user-ot vrati mu go nazad as it is
        }
      });
    }
  };
};

export default {
  Save,
  Edit,
  //Create,
  Search,
  Delete,
  Reset,
  EditAsync,
  SaveAsync,
};
