import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import PermMediaOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActual';
import PublicIcon from '@material-ui/icons/Public';
import HelpIcon from '@material-ui/icons/Help';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import TimerIcon from '@material-ui/icons/Timer';
import SettingsIcon from '@material-ui/icons/Settings';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import { history } from '../helpers';
import config from '../config/config';
import * as AuthenticationHelper from '../helpers/authentication.helper';
import { logHelper } from '../helpers/log.helper';
import Header from './Header';
import ExportService from '../services/Export/ExportService';
import allActions from '../actions';

// const categories2 = [

//     {
//         Item: {
//             name: 'Administration',
//             items: [
//                 {
//                     id: 'Object Manager',
//                     children: [
//                         { id: 'List of Objects', icon: <PeopleIcon />, active: false, url: "/entity/tables" },
//                         { id: 'New Object', icon: <MenuBookIcon />, active: false, url: "/entity/table/create" }
//                     ]
//                 },
//                 {

//                     id: 'Relations',
//                     children: [
//                         { id: 'List of Relations', icon: <PeopleIcon />, active: false, url: "/class/manage" },
//                         { id: 'New Relation', icon: <MenuBookIcon />, active: false, url: "/class/find" }
//                     ]
//                 },
//                 {

//                     id: 'Pick List Definitions',
//                     children: [
//                         { id: 'List of Lookup Types', icon: <PeopleIcon />, active: false, url: "/lookuptype/find" },
//                         { id: 'New Lookup Type', icon: <MenuBookIcon />, active: false, url: "/lookuptype/create" }
//                     ]
//                 }
//             ]
//         }
//     }
// ];

const styles = (theme) => ({
    categoryHeader: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white,
    },
    item: {
        paddingTop: 1,
        paddingBottom: 1,
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover,&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
    },
    itemCategory: {
        backgroundColor: '#232f3e',
        boxShadow: '0 -1px 0 #404854 inset',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    firebase: {
        fontSize: 24,
        color: theme.palette.common.white,
    },
    itemActiveItem: {
        color: '#4fc3f7',
    },
    itemPrimary: {
        fontSize: 'inherit',
    },
    itemIcon: {
        minWidth: 'auto',
        marginRight: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
    },
});

function getIconForName(iconName) {

    switch (iconName) {
        case 'PeopleIcon': {
            return <PeopleIcon />;
        }

        case 'MenuBookIcon': {
            return <MenuBookIcon />;
        }
        case 'HelpIcon': {
            return <HelpIcon />;
        }

        default: {
            return <PeopleIcon />;
        }
    }
}


function CreateMenu_ChildItem(classes, childId, icon, active, url, visible, currentUrl) {
    if (visible === false) {
        return;
    }
    logHelper.log(currentUrl);
    logHelper.log(url);
    const isActive = (url.indexOf(currentUrl)) > -1;
    return (
        <ListItem button onClick={() => goToContentLink(url)}
            key={childId}
            className={clsx(classes.item, isActive && classes.itemActiveItem)}
        >
            <ListItemIcon className={classes.itemIcon}>{getIconForName(icon)}</ListItemIcon>
            <ListItemText
                classes={{
                    primary: classes.itemPrimary,
                }}
            >
                {childId}
            </ListItemText>
        </ListItem>
    );
}


function CreateMenu_Header(classes, id) {
    return (
        <ListItem className={classes.categoryHeader}>
            <ListItemText
                classes={{
                    primary: classes.categoryHeaderPrimary,
                }}
            >
                {id}
            </ListItemText>
        </ListItem>

    );
}

function Navigator(props) {
    //currentUrl = e nas URL za da mozam da go setiram koj e aktiven content
    const { classes, menu, currentUrl, ...other } = props;


    logHelper.log("props");
    logHelper.log(props.currentUrl);


    return (
        <Drawer variant="permanent" {...other}>
            <List disablePadding>
                <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)} button onClick={() => AuthenticationHelper.GoToDashboard()}>
                    <ListItemIcon className={classes.itemIcon}>
                        <HomeIcon />
                    </ListItemIcon>
                    {config.AppTitle}
                </ListItem>
                
                <ListItem className={clsx(classes.item, classes.itemCategory)}>
                    <ListItemText
                        classes={{
                            primary: classes.itemPrimary,
                        }}
                    >
                        {menu.name}
                    </ListItemText>
                </ListItem>
                
                {menu.items.map(({ id, children }) => (
                    <React.Fragment key={id}>
                        {(id !== '' && CreateMenu_Header(classes, id))}

                        {children.map(({ id: childId, icon, active, url, visible }) => (
                            CreateMenu_ChildItem(classes, childId, icon, active, url, visible, props.currentUrl)
                        ))}
                        <Divider className={classes.divider} />
                    </React.Fragment>
                ))}
                
            </List>
        </Drawer>
    );
}

function goToContentLink(url) {
    
    //ako e za nov benef/startup/sme brisi s'e od LOCALSTORAGE
    let isNewStartup = (url.indexOf("/admin/startup/new") || url.indexOf("/admin/sme/new") || url.indexOf("/admin/benef/new"))
    if (isNewStartup) {
        AuthenticationHelper.SetStartUpItemGUID(null);
        AuthenticationHelper.SetStartupPreview(false);

    }
    history.push(url);
}

Navigator.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Navigator);