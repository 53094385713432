import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import allActions from '../../actions';
import AlertMessage from '../AlertMessage'
import constants from '../../helpers/constant.helper';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableFooter } from '@material-ui/core';
import { history } from '../../helpers';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';

// DEFINIRANJE NA STILOVI
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  columnLabel: { width: '10%' },
  columnInput: { width: '70%' },
  spacer: { "padding-right": '20px' },

}));


export default function TableManage(args) {

  const dispatch = useDispatch()
  const classes = useStyles();
  const dataTableReducer = useSelector(state => state.TableReducer);


  //iniicjalno EntityObject e prazen posle dole na useEffect ke se napolni
  const [EntityObject, setEntityObject] = useState(dataTableReducer.Record);
  const isNewRecord = (EntityObject.EntityId == 0);
  const isDisabled = (EntityObject.EntityId != 0);

  const handleChange = e => {
    const { name, value } = e.target;  //vadi e.target.name i e.target.value kade sto value e vnesenata vrednost!
    setEntityObject(prevState => ({ ...prevState, [name]: value }));
  };

  const handleChangeName = e => {
    const { name, value } = e.target;  //vadi e.target.name i e.target.value kade sto value e vnesenata vrednost!
    var apiName = value;
    var name2 = value + 's';
    var primaryColumnName = value + 'Id';
    var description = 'Add descriptive information for table:' + value;
    setEntityObject(prevState => ({ ...prevState, 
                                    Name: value,
                                    Name2: name2,
                                  APIName: apiName,
                                  PrimaryColumnName: primaryColumnName,
                                Description: description }));
  };


  //DEFAULT 
  useEffect(() => {


    //postecki record 
    if (args.match.params.id) {
      const currentEntityId = args.match.params.id;
      dispatch(allActions.TableActions.Edit(currentEntityId));
    }
    // else{
    //   dispatch(allActions.TableActions.Create(constants.Entity_TABLES));
    // }    

    return () => {
      //alert("destructor Table Manage");
      dispatch(allActions.TableActions.Reset()); //vraka na Initial State
    };
    //ako ne raboti direktno so toj od Reducer
  }, []);   //default


  //ke se zapali smao pri promena 
  useEffect(() => {
    setEntityObject(dataTableReducer.Record);
  }, [dataTableReducer]);   //default


  return (

    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colspan="3">
              <AlertMessage {...dataTableReducer} />
              <h1>{isNewRecord ? "Create Object" : "Manage Object - " + EntityObject.Name}</h1>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colspan="3" align="right">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => dispatch(allActions.TableActions.Save(EntityObject))}
              >
                Save
          </Button>

              <label className={classes.spacer}></label>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isNewRecord}
                className={classes.submit}
                onClick={() => history.push('/entity/columns/' + EntityObject.EntityId)}
              >
                Define Columns
          </Button>


              <label className={classes.spacer}></label>

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isNewRecord}
                className={classes.submit}
                onClick={() => dispatch(allActions.TableActions.Delete(EntityObject))}
              >
                Delete
          </Button>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell colspan="3">
              <h3>
                <b><i>Custom Object Information</i></b>
              </h3>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell colSpan="3">
              The singular and plural labels are used in tabs, page layouts, and reports.
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.columnLabel}>Label</TableCell>
            <TableCell className={classes.columnInput}> <TextField name="Name" margin="dense" id="filled-basic" placeholder="Enter Label" type="text" fullWidth variant="outlined" onChange={handleChangeName} value={EntityObject.Name} /></TableCell>
            <TableCell>Example: Account</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Plural Label</TableCell>
            <TableCell> <TextField name="Name2" margin="dense" id="filled-basic" placeholder="Enter Plural" type="text" fullWidth variant="outlined" onChange={handleChange} value={EntityObject.Name2} /></TableCell>
            <TableCell>Example: Accounts</TableCell>
          </TableRow>

          <TableRow>
            <TableCell colSpan="3">
              The Object Name is used when referencing the object via API
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell style={{ "width": "10%" }}>API Name</TableCell>
            <TableCell> <TextField name="APIName" disabled={isDisabled}  margin="dense" id="filled-basic" placeholder="Enter API" type="text" fullWidth variant="outlined" onChange={handleChange} value={EntityObject.APIName} /></TableCell>
            <TableCell>Example: Account (do not use space or special characters such as @,/,#, etc), upon creation it can't be changed</TableCell>
          </TableRow>

          <TableRow >
            <TableCell>Description</TableCell>
            <TableCell>
              <TextField
                multiline
                fullWidth
                rows={3}
                name="Description"
                variant="outlined"
                value={EntityObject.Description}
                placeholder="Enter"
                onChange={handleChange}
              />
            </TableCell>
            <TableCell>Example: Following object will be used for storing all the accounts in the system</TableCell>
          </TableRow>

          <TableRow>
            <TableCell colspan="2">
              <h3>
                <b><i>Enter Primary Column Name</i></b>
              </h3>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Primary Column Name</TableCell>
            <TableCell> <TextField name="PrimaryColumnName" disabled={isDisabled} margin="dense" id="filled-basic" placeholder="Enter Primary Column Name" type="text" fullWidth variant="outlined" onChange={handleChange} value={EntityObject.PrimaryColumnName} /></TableCell>
            <TableCell>Example: AccountId, upon creation it can't be changed </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Data Type</TableCell>
            <TableCell>
              <Select
                variant="outlined"
                fullWidth
                native
                value={EntityObject.ParentEntityId}
                onChange={handleChange}
                inputProps={{ name: 'ParentEntityId', id: 'ParentEntityId' }}
              >
                <option value={0}>Int - Auto Number</option>
              </Select>
            </TableCell>
            <TableCell>Primary column name will be always whole numbers<br />
           </TableCell>
          </TableRow>

        </TableBody>
      </Table>
    </TableContainer>

  );
}
