import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Radio, TableFooter } from '@material-ui/core';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PreviewService from '../../services/PreviewService.js';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import * as PreviewComponents from '../Shared/PreviewComponents';
import { InputText, InputDropDown, InputDate, Header1, CustomHeader1, InputDropDownLookup, InputNumber } from '../Shared/PreviewComponents';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Keys from '../../json/ResourceStartup.json';
import allActions from '../../actions';
import constants from '../../helpers/constant.helper';
import AlertMessage from '../AlertMessage';
import PleaseWaitMessage from '../PleaseWaitMessage'
import * as AuthenticationHelper from '../../helpers/authentication.helper';
import * as LanguageHelper from '../../helpers/language.helper';
import { logHelper } from '../../helpers/log.helper';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 348,
  },
  formElement: {},
  formLabel: {
    color: '#aaaaa9',
    marginTop: 10,
    '&:hover': {
      background: '#F3F3F2',
    }
  },
  header: {
    backgroundColor: '#F3F3F2',
    borderRadius: 4,
    paddingLeft: 5,
  },
  subHeader: {
    marginTop: 15,
    marginBottom: 1,
    fontWeight: 700
  },
  buttonStyle: {
    //margin: theme.spacing(1),
    textTransform: 'none',
  },
  xs12: {
    width: '50%',
  },
  columnLabel: { width: '10%' },
  columnInput: { width: '70%' },
}));


export default function SmeEmployeeManage(args) {

  const keys = LanguageHelper.GetLanguageKeys();

  const classes = useStyles();

  const dispatch = useDispatch();
  const dataReducer = useSelector(state => state.SmeEmployeeReducer);

  //iniicjalno EntityObject e prazen posle dole na useEffect ke se napolni
  const [Record, setRecord] = useState(dataReducer.Record);
  const [Periods, setPeriods] = useState(dataReducer.Periods);
  const StartupItemGUID = AuthenticationHelper.GetStartUpItemGUID(); //se koristi samo kako ADMIN i OFFICER


  // useEffect(() => {

  //   let payload = { "stItemGUID": StartupItemGUID }; //dodadi startup item guid
  //   dispatch(allActions.SmeEmployeeActions.GetPeriods(payload));
  // }, []);   //default


  useEffect(() => {

    //ako e USER ke si raboti so SignedUserId
    if (AuthenticationHelper.IsUser()) {
      let payload = { "stItemGUID": null, "PeriodID": 0 }
      dispatch(allActions.SmeEmployeeActions.Edit(payload));
    }
    else {
      //ako e Admin ili Officer ke si raboti so Local Storage: STARTUP_ITEM_GUID
      let payload = { "stItemGUID": StartupItemGUID, "PeriodID": 0 }
      dispatch(allActions.SmeEmployeeActions.Edit(payload));
    }

    return () => {
      dispatch(allActions.SmeEmployeeActions.Reset()); //vraka na Initial State
    };
  }, []);   //default

    //ke se zapali smao pri promena 
    useEffect(() => {

      //samo mapiraj data od koga site call-ovi prema api.he4s.eu se zavrseni a toa e koga Loading === False
      if (!dataReducer.Loading) {
  
        setPeriods(dataReducer.Periods);
        dataReducer.Record = {...dataReducer.Record, "StartupItemGUID" : StartupItemGUID};
        setRecord(dataReducer.Record);
      }
  
    }, [dataReducer]);
  
  
  //Handle Change za Text Box, Select,  i Date Time komponentit
  //https://nikgrozev.com/2019/04/07/reacts-usecallback-and-usememo-hooks-by-example/
  const handleChange = e => {
    logHelper.log("od handle change");
    logHelper.log(e.target);
    const { name, value } = e.target;
    setRecord(prevState => ({ ...prevState, [name]: value })); //sekogas update i na StartupItemGUID
  };

  const handleChangePeriod = e => {
    const { name, value } = e.target;
    let payload = { "stItemGUID": StartupItemGUID, "PeriodID": value }
    dispatch(allActions.SmeEmployeeActions.Edit(payload));
  };


  return (
    <TableContainer component={Paper}>

      <PleaseWaitMessage {...dataReducer} />
      <AlertMessage {...dataReducer} />


      <Table className={classes.table} aria-label="customized table" style={{ width: "100%", tableLayout: "auto" }}>

        <TableHead>
          <TableRow>
            <TableCell colspan={2}>
              <Table style={{ width: "100%", tableLayout: "auto" }}>
                <TableHead>
                  <TableRow>
                    <TableCell width="80%">
                      <Typography variant="h5" component="h5">
                        {keys["EmployeesHeader"]}
                      </Typography>
                      <Typography variant="h8" component="h8">
                        {keys["EmployeesSubHeader"]}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained" color="primary" className={classes.buttonStyle}
                        onClick={() => dispatch(allActions.SmeEmployeeActions.Save(Record))}>
                        Save
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableCell>
          </TableRow>
        </TableHead>

        {InputDropDownLookup(classes, "Reporting Period", "sePeriodID", Periods, 0, Record.sePeriodID, handleChangePeriod, dataReducer.Loading, dataReducer.ValidationMessages, true, false)}
        {InputNumber(classes, keys["NumberEmployees"], "seNumberEmployees", Record.seNumberEmployees, handleChange, dataReducer.Loading, false)}
        {InputNumber(classes, keys["NumberMale"], "seNumberMale", Record.seNumberMale, handleChange, dataReducer.Loading, false)}
        {InputNumber(classes, keys["NumberFemale"], "seNumberFemale", Record.seNumberFemale, handleChange, dataReducer.Loading, false)}
        {InputNumber(classes, keys["NumberDisability"], "seNumberDisability", Record.seNumberDisability, handleChange, dataReducer.Loading, false)}
        {InputNumber(classes, keys["AgeEmployees1"], "seAgeEmployees1", Record.seAgeEmployees1, handleChange, dataReducer.Loading, false)}
        {InputNumber(classes, keys["AgeEmployees2"], "seAgeEmployees2", Record.seAgeEmployees2, handleChange, dataReducer.Loading, false)}
        {InputNumber(classes, keys["AgeEmployees3"], "seAgeEmployees3", Record.seAgeEmployees3, handleChange, dataReducer.Loading, false)}
        {InputNumber(classes, keys["FullTimeContract"], "seFullTimeContract", Record.seFullTimeContract, handleChange, dataReducer.Loading, false)}
        {InputNumber(classes, keys["PartTimeContract"], "sePartTimeContract", Record.sePartTimeContract, handleChange, dataReducer.Loading, false)}

        {InputNumber(classes, "Female full time contract", "seFullTimeContractFemale", Record.seFullTimeContractFemale, handleChange, dataReducer.Loading, false)}
        {InputNumber(classes, "Female part time contract", "sePartTimeContractFemale", Record.sePartTimeContractFemale, handleChange, dataReducer.Loading, false)}

        <TableRow>
          <TableCell colspan="2" align="right">
            <Button variant="contained" color="primary" className={classes.buttonStyle}
              onClick={() => dispatch(allActions.SmeEmployeeActions.Save(Record))}>
              Save
                </Button>
          </TableCell>
        </TableRow>

      </Table>

      <PleaseWaitMessage {...dataReducer} />
      <AlertMessage {...dataReducer} />


    </TableContainer >
  );
}

