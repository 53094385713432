import * as React from "react";

let ContextOne = React.createContext();

let initialState = {
  count: 10,
  currentColor: "#bada55",
  UserProfile: {
    IsSigned: false,
    FullName: '',
    Email: '',
    UserName: '',
    IsSysAdmin: false,
    IsOfficer: false,
    IsUser: false
  }
};

let reducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return initialState;
    case "increment":
      return { ...state, count: state.count + 1 };
    case "decrement":
      return { ...state, count: state.count - 1 };
    case "set-color":
      return { ...state, currentColor: action.payload };
  }
};

function ContextOneProvider(props) {
  // [A]
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };


  // [B]
  return (
    <ContextOne.Provider
      value={{
        count: state.count
      }}
    >
      {props.children}
    </ContextOne.Provider>
  );
}

let ContextOneConsumer = ContextOne.Consumer;

// [C]
export { ContextOne, ContextOneProvider, ContextOneConsumer };
