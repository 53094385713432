import { restHelper } from '../helpers/rest.helper';

const Save = async (payLoad) => {
    let response = await restHelper.post('api/Table/Save', payLoad);
    return response;
}

const Edit = async (payLoad) => {
    let response = await restHelper.post('api/Table/Edit', payLoad);
    return response;
}


const Search = async (payLoad) => {
    let response = await restHelper.post('api/Table/Search', payLoad);
    return response;
}


const Delete = async (payLoad) => {
    let response = await restHelper.post('api/Table/Delete', payLoad);
    return response;
}

export default {
    Save,
    Edit,
    Search,
    Delete
}