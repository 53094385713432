import axios from 'axios';
import config from '../config/config';
import * as AuthenticationHelper from './authentication.helper';
import { history } from '.';

export const logHelper = {
    log,
    error,
    isLocalEnvironment,
    GetBaseUrl
};

function log(msg) {
    if (isLocalEnvironment()) {
        console.log(msg);
    }
}

function error(msg) {
    if (isLocalEnvironment()) {
        console.error(msg);
    }
}

function isLocalEnvironment() {
    const  url  = String(window.location.href); 
    return url.includes("localhost");
}

function GetBaseUrl() {
    if (isLocalEnvironment())
    {
        return config.baseUrlDevelopment;
    }
    return config.baseUrl;
}