import { validatorHelper, responseHelper, history } from "../helpers";
import ExportService from "../services/Export/ExportService";
import ImportActivityService from "../services/ImportActivityService";

async function ListActivitiesAsync(args) {
  let response = await ImportActivityService.Preview(args);
  let responses = [response];
  return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
}

async function ActivityDownloadFileAsync(Record) {
  let responseEntity = await ExportService.ActivityDownloadFile(Record);
  let responses = [responseEntity];
  return responseHelper.validateResponses(responses);
}

async function DownloadManualFileAsync() {
  let responseEntity = await ExportService.ManualDownloadFile();
  let responses = [responseEntity];
  return responseHelper.validateResponses(responses);
}

async function DeleteActivityAsync(Record) {
  let responseEntity = await ImportActivityService.DeleteActivity(Record);
  let responses = [responseEntity];
  return responseHelper.validateResponses(responses);
}

export default {
  DeleteActivityAsync,
  ActivityDownloadFileAsync,
  ListActivitiesAsync,
  DownloadManualFileAsync,
};
