import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Radio } from '@material-ui/core';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PreviewService from '../../services/PreviewService.js';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import * as PreviewComponents from '../Shared/PreviewComponents';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { logHelper } from '../../helpers/log.helper';

import allActions from "../../actions";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 348,
  },
  formElement: {},
  formLabel: {
    color: '#aaaaa9',
    marginTop: 10,
    '&:hover': {
      background: '#F3F3F2',
    }
  },
  header: {
    backgroundColor: '#F3F3F2',
    borderRadius: 4,
    paddingLeft: 5,
  },
  subHeader: {
    marginTop: 15,
    marginBottom: 1,
    fontWeight: 700
  },
  buttonStyle: {
    margin: theme.spacing(1),
    textTransform: 'none'
  },
  xs12: {
    width: '50%',
  },
  columnLabel: { width: '10%' },
  columnInput: { width: '70%' },
}));


export default function Test(args) {

  const classes = useStyles();

  //inicijalno stavi go samo kako niza od objekti[]
  const [initalLoad, setInitialLoad] = useState(false);
  const [EntityRecords, setControl] = useState([]);
  const [jsonData, setJsonData] = useState(null);

  const [MessageData, setMessageData] = useState({});
  const globalHanderOnChange = e => {
    const { name, value } = e.target;
    //EntityRecords[name] = value;

    setControl(prevState => ({ ...prevState, [name]: value }));
    logHelper.log("EntityRecords")
    logHelper.log(EntityRecords)
    setJsonData(false);
  };

  //Event: 1 --> Initial Render
  //Default Event Handler koj sto se pali inicijalno, odi na server i ja zima JSON Strukturata
  //i ja stava vo SetState --> jsonData 
  useEffect(() => {
    logHelper.log("INIITAL RENDER");
    setJsonData(true);

  }, []);   //default

  //Event: 2 --> RenderState
  //Od koga ke se napolni SetState --> jsonData 
  //se proveruva dali e polna i togas se polni SetState --> EntityRecords
  useEffect(() => {

    if (jsonData) {
      logHelper.log("JSON DATA == TRUE");
      setInitialLoad(true);
    }
    else {
      logHelper.log("JSON DATA == FALSE");
    }
  }, [jsonData]);


  logHelper.log(Date.now())
  return (
    <div>
      {
        OURNEWTEXT("x", globalHanderOnChange)
      }
      {
        OURNEWTEXT("y", globalHanderOnChange)
      }
      {
        OURNEWTEXT("y", globalHanderOnChange)
      }
      {
        OURNEWTEXT("y", globalHanderOnChange)
      }
      {
        OURNEWTEXT("y", globalHanderOnChange)
      }
      {
        OURNEWTEXT("y", globalHanderOnChange)
      }
      {
        OURNEWTEXT("y", globalHanderOnChange)
      }
      {
        OURNEWTEXT("y", globalHanderOnChange)
      }
      {
        OURNEWTEXT("y", globalHanderOnChange)
      }
    </div>
  );
}

export const OURNEWTEXT = (label, handleChangeParent) => {

  const [tekovnaVrednost, setTekovnaVrednost] = useState("INITAL");

  //Handle Change za Text Box, Select,  i Date Time komponentit
  const handleCustomChange = e => {
    const { value } = e.target;
    setTekovnaVrednost(value);

    //prati go na parent t.e. handleChange vo parent-ot
    handleChangeParent(e);
  };



  return (

    <TableRow>
      <TableCell component="th" scope="row">
        {label}
        <TextField
          margin="dense"
          id={"XXX"}
          name={"XXX"}
          type="text"
          fullWidth
          variant="outlined"
          value={tekovnaVrednost}
          onChange={handleCustomChange}
        />
      </TableCell>
    </TableRow >
  )
}

