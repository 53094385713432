import Service from '../services/ColumnService' //site callovi od tipot na api/columns/edit ; api/columns/search
import ServiceLookup from '../services/LookupService' //site callovi od tipot na api/columns/edit ; api/columns/search
import ServiceLookupType from '../services/LookupTypeService' //site callovi od tipot na api/columns/edit ; api/columns/search
import ServiceTable from '../services/TableService' //site callovi od tipot na api/columns/edit ; api/columns/search

import { responseHelper, history } from '../helpers';
import constants from '../helpers/constant.helper';

// responseHelper.validateResponses(responses);
//Validate Response validira responses dali ima nekakvi greski 
//responses = [] --niza
//Iterira niz nea i ako e TRUE togas gi vraka samite responses[] koj sto bile prateni za inspekcija
//ako e FALSE vraka response od ERROR objekt (ne niza, samo eden objekt)
async function EditAsync(EntityId, TableId) {
    let responseLoad = await Service.Edit({ "EntityId": EntityId });
    let responseLookup = await ServiceLookup.Search({ "LookupTypeId": constants.Entity_COLUMN_DATATYPE });
    let responseLookupType = await ServiceLookupType.Search({QuickSearch:""});
    let responseTables = await ServiceTable.Search({ "EntityTypeId": constants.Entity_TABLES }); //mu treba za da gi izlista site TABELI za RELATION
    let responseTable = await ServiceTable.Edit({ "EntityId": TableId });   //mu treba za da napise HEADER ZA KOJA TABELA ke se kreiraat podatoci
    let responses = [responseLoad, responseLookup,responseLookupType, responseTables,responseTable];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function CreateAsync(TableId) {
    let responseLookup = await ServiceLookup.Search({ "LookupTypeId": constants.Entity_COLUMN_DATATYPE });
    let responseLookupType = await ServiceLookupType.Search({QuickSearch:""});
    let responseTables = await ServiceTable.Search({ "EntityTypeId": constants.Entity_TABLES }); //mu treba za da gi izlista site TABELI za RELATION
    let responseTable = await ServiceTable.Edit({ "EntityId": TableId });   //mu treba za da napise HEADER ZA KOJA TABELA ke se kreiraat podatoci
    let responses = [responseLookup,responseLookupType, responseTables,responseTable];
    return responseHelper.validateResponses(responses);
};

async function DeleteAsync(EntityObject) {
    let response = await Service.Delete(EntityObject);
    let responses = [response];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function SearchAsync(args) {
    let response = await Service.Search(args);
    let responseTable = await ServiceTable.Edit({ "EntityId": args.ParentId });
    let responses = [response,responseTable];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function SaveAsync(EntityObject) {
    let responseEntity = await Service.Save(EntityObject);
    let responses = [responseEntity];
    return responseHelper.validateResponses(responses);
};

async function SetOrderAsync(args) {
    let response = await Service.SetOrder(args);
    let responseTable = await ServiceTable.Edit({ "EntityId": args.ParentId });
    let responses = [response,responseTable];
    return responseHelper.validateResponses(responses);
};


async function SetVisibleAsync(args) {
    let response = await Service.SetVisible(args);
    let responseTable = await ServiceTable.Edit({ "EntityId": args.ParentId });
    let responses = [response,responseTable];
    return responseHelper.validateResponses(responses);
};




const Delete = (EntityObject) => {

    return dispatch => {
        dispatch({ type: "COLUMN_START" });

        DeleteAsync(EntityObject).then((responses) => {
            if (responses.error) {
                dispatch({ type: "COLUMN_ERROR", error: responses.error_description });
            }
            else {
                history.push("/entity/columns/" + EntityObject.ParentId);
            }
        });
    }
}


const Edit = (EntityId,TableId) => {

    return dispatch => {
        dispatch({ type: "COLUMN_START" });

        EditAsync(EntityId,TableId).then((responses) => {
            if (responses.error) {
                dispatch({ type: "COLUMN_ERROR", error: responses.error_description });
            }
            else {
                dispatch({
                    type: "COLUMN_EDIT",
                    payload: responses[0].data,
                    payload_DataTypes: responses[1].data, //ovoj red treba da bide ist so Edit() samo sto nizata e za -1 clen
                    payload_LookupTypes: responses[2].data,
                    payload_Tables : responses[3].data, //ENTTIY_TABLES
                    payload_Table: responses[4].data //ENTTIY_TABLE za HEADER
                });
            }
        });
    }
}

const Create = (TableId) => {
    return dispatch => {
        dispatch({ type: "COLUMN_START" });

        CreateAsync(TableId).then((responses) => {

            if (responses.error) {
                dispatch({ type: "COLUMN_ERROR", error: responses.error_description });
            }
            else {
                dispatch({
                    type: "COLUMN_CREATE",
                    payload_DataTypes: responses[0].data, //ovoj red treba da bide ist so Edit() samo sto nizata e za -1 clen
                    payload_LookupTypes: responses[1].data, //ovoj red treba da bide ist so Edit() samo sto nizata e za -1 clen
                    payload_Tables : responses[2].data, //ENTTIY_TABLES
                    payload_Table: responses[3].data //ENTTIY_TABLE za HEADER
                });
            }
        });
    }
}

const Search = (args) => {
    return dispatch => {
        dispatch({ type: "COLUMN_START" });

        SearchAsync(args).then((responses) => {

            if (responses.error) {
                dispatch({
                    type: "COLUMN_ERROR",
                    error: responses.error_description
                });
            }
            else {
                dispatch({ type: "COLUMN_SEARCH", 
                        payload: responses[0].data,
                        payload_Table: responses[1].data
                     });
            }
        });
    }
}

const SetOrder = (args) => {

    return dispatch => {
        dispatch({ type: "COLUMN_START" }); //da se refreshira GUI-to so SPINNER-ot

        //validacija
        //da se dodade
        SetOrderAsync(args).then((responses) => {

            if (responses.error) {
                dispatch({
                    type: "COLUMN_ERROR",
                    error: responses.error_description
                });
            }
            else {
                dispatch({ type: "COLUMN_SEARCH", 
                        payload: responses[0].data,
                        payload_Table: responses[1].data
                     });
            }

        })
    }
}

const SetVisible  = (args) => {

    return dispatch => {
        dispatch({ type: "COLUMN_START" }); //da se refreshira GUI-to so SPINNER-ot

        //validacija
        //da se dodade
        SetVisibleAsync(args).then((responses) => {

            if (responses.error) {
                dispatch({
                    type: "COLUMN_ERROR",
                    error: responses.error_description
                });
            }
            else {
                dispatch({ type: "COLUMN_SEARCH", 
                        payload: responses[0].data,
                        payload_Table: responses[1].data
                     });
            }

        })
    }
}


//OVOJ SEUSTE NE E NAPRAVEN DA RABOTI SO SERVICE
const Save = (entityObject) => {

    return dispatch => {
        dispatch({ type: "COLUMN_START" }); //da se refreshira GUI-to so SPINNER-ot

        //validacija
        //da se dodade
        SaveAsync(entityObject).then((responses) => {

            if (responses.error) {
                dispatch({
                    type: "COLUMN_SAVE_ERROR",
                    error: responses.error_description,
                    payload: entityObject,  //vrati gi vnesenite vrednosti od Payload za da ne gi prekucuva pak 
                });
            }
            else {
                //response[0] == Entity Record
                //response[1] == True ili Exception ako ne pomine uploaded 
                dispatch({ type: "COLUMN_SAVE_SUCCESS", payload: responses[0].data });
            }

        })
    }
}


//OVOJ SEUSTE NE E NAPRAVEN DA RABOTI SO SERVICE
const Reset = () => {

    return dispatch => {
        dispatch({ type: "COLUMN_RESET" }); //da se refreshira GUI-to so SPINNER-ot
    }
}


export default {
    Save,
    Edit,
    Create,
    Search,
    Delete,
    Reset,
    SetOrder,
    SetVisible
}