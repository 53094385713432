import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import allActions from '../../actions';
import AlertMessage from '../AlertMessage'
import constants from '../../helpers/constant.helper';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableFooter } from '@material-ui/core';
import { history } from '../../helpers';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { logHelper } from '../../helpers/log.helper';

// DEFINIRANJE NA STILOVI
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  columnLabel: { width: '10%' },
  columnInput: { width: '70%' },
  spacer: { "padding-right": '20px' },

}));


export default function ColumnManage(args) {

  const dispatch = useDispatch()
  const classes = useStyles();
  const dataReducer = useSelector(state => state.ColumnReducer);

  //iniicjalno EntityObject e prazen posle dole na useEffect ke se napolni
  const [EntityObject, setEntityObject] = useState(dataReducer.Record);
  const [LookupsObject, setLookupsObject] = useState(dataReducer.Lookups);    //LookupsObject[0] ==> Teachers, LookupsObject[1] ==> Countries

  const [isVisibleLengthField, setVisibleLengthField] = useState(false);    //LookupsObject[0] ==> Teachers, LookupsObject[1] ==> Countries
  const [isVisibleDecimalPlacesField, setVisibleDecimalPlacesField] = useState(false);    //LookupsObject[0] ==> Teachers, LookupsObject[1] ==> Countries
  const [isVisiblePickList, setVisiblePickList] = useState(false);    //LookupsObject[0] ==> Teachers, LookupsObject[1] ==> Countries
  const [isVisibleMasterRelation, setVisibleMasterRelation] = useState(false);    //LookupsObject[0] ==> Teachers, LookupsObject[1] ==> Countries 

  //definiranje na properties
  const isNewRecord = (EntityObject.EntityId == 0);
  const isDisabled = (EntityObject.EntityId != 0);
  const tableId = args.match.params.tableId;

  const handleChange = e => {
    const { name, value } = e.target;  //vadi e.target.name i e.target.value kade sto value e vnesenata vrednost!
    setEntityObject(prevState => ({ ...prevState, [name]: value }));
  };

  const handleChangeName = e => {
    const { value } = e.target;  //vadi e.target.name i e.target.value kade sto value e vnesenata vrednost!
    const apiName = value.replace(" ", "");
    const name2 = value + "s";
    const description = 'Description for ' + value;
    setEntityObject(prevState => ({
      ...prevState,
      Name: value,
      Name2: name2,
      APIName: apiName,
      Description: description
    }));
  };


  const handleChangeMasterChildRelation = e => {
    logHelper.log(e.target);

    const { name, value } = e.target;  //vadi e.target.name i e.target.value kade sto value e vnesenata vrednost!
    const SelectedEntityId = value;
    logHelper.log(value);
    logHelper.log(LookupsObject.Tables);

    var selectedItem = null;
    LookupsObject.Tables.map(option => {
      if (option.EntityId == SelectedEntityId) {
        selectedItem = option;
      }
    });

    // var selectedItem = LookupsObject.Tables.map(function (item) {return item.EntityId === '1070';});
    logHelper.log(selectedItem);
    var text = selectedItem.Name;


    const fieldLabel = text + 'Id';
    const apiName = text + 'Id';
    const description = 'Some help text for ' + text + 'Id';
    setEntityObject(prevState => ({
      ...prevState,
      [name]: value,
      Name: fieldLabel,
      APIName: apiName,
      Description: description,
    }));
  };


  //DataType changes  
  useEffect(() => {



    if (EntityObject.DataType == 'Date/Time' || EntityObject.DataType == 'Date' || EntityObject.DataType == 'Time' ||
      EntityObject.DataType == 'Checkbox' || EntityObject.DataType == 'Number') {
      setVisibleLengthField(false);
      setVisibleDecimalPlacesField(false);
      setVisiblePickList(false);
      setVisibleMasterRelation(false);
    }
    else if (EntityObject.DataType == 'Currency') {
      setEntityObject(prevState => ({ ...prevState, Length: 18, DecimalPlaces: 2 }));
      setVisibleLengthField(true);
      setVisibleDecimalPlacesField(true);
      setVisiblePickList(false);
      setVisibleMasterRelation(false);
    }
    else if (EntityObject.DataType == 'Text' || EntityObject.DataType == 'Email') {

      if (EntityObject.DataType == 'Email') {
        setEntityObject(prevState => ({ ...prevState, Length: 64, DecimalPlaces: 0 }));
      }
      else {
        setEntityObject(prevState => ({ ...prevState, Length: 256, DecimalPlaces: 0 }));
      }
      setVisibleLengthField(true);
      setVisibleDecimalPlacesField(false);
      setVisiblePickList(false);
      setVisibleMasterRelation(false);
    }
    else if (EntityObject.DataType == 'Text Area') {
      setEntityObject(prevState => ({ ...prevState, Length: 1200, DecimalPlaces: 0 }));
      setVisibleLengthField(true);
      setVisibleDecimalPlacesField(false);
      setVisiblePickList(false);
      setVisibleMasterRelation(false);
    }
    else if (EntityObject.DataType == 'Text Area Long') {
      setEntityObject(prevState => ({ ...prevState, Length: 20000, DecimalPlaces: 0 }));
      setVisibleLengthField(true);
      setVisibleDecimalPlacesField(false);
      setVisiblePickList(false);
      setVisibleMasterRelation(false);
    }
    else if (EntityObject.DataType == 'Pick List' || EntityObject.DataType == 'Pick List (Multi-Select)') {
      setVisibleLengthField(false);
      setVisibleDecimalPlacesField(false);
      setVisiblePickList(true);
      setVisibleMasterRelation(false);
    }

    else if (EntityObject.DataType == 'Master-Detail Relationship') {
      setVisibleLengthField(false);
      setVisibleDecimalPlacesField(false);
      setVisiblePickList(false);
      setVisibleMasterRelation(true);
    }
    else {
      setVisiblePickList(false);
      setVisibleLengthField(true);
      setVisibleDecimalPlacesField(true);
      setVisibleMasterRelation(false);
    }
  }, [EntityObject.DataType]);   //default



  //DEFAULT 
  useEffect(() => {
    //za da moze da go prati na server 1048 kako parentId od samiot url: http://localhost:3000/entity/column/create/1048
    //postecki record 
    if (args.match.params.columnId) {
      const columnId = args.match.params.columnId;
      dispatch(allActions.ColumnActions.Edit(columnId, tableId));
    }
    else {
      dispatch(allActions.ColumnActions.Create(tableId));
    }

    return () => {
      dispatch(allActions.ColumnActions.Reset()); //vraka na Initial State
    };
    //ako ne raboti direktno so toj od Reducer
  }, []);   //default


  //ke se zapali smao pri promena 
  useEffect(() => {
    setLookupsObject(dataReducer.Lookups);
    setEntityObject(dataReducer.Record);
  }, [dataReducer]);   //default


  function onSave() {
    //za da gi mapira na server
    EntityObject.ParentId = tableId;
    dispatch(allActions.ColumnActions.Save(EntityObject))
  }





  return (

    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colspan="2">
              <AlertMessage {...dataReducer} />
              <h1>{dataReducer.Table.Name} - {isNewRecord ? "Create Column" : " Column - " + EntityObject.Name}</h1>
            </TableCell>
            <TableCell align="right">
              <Button size="small" color="primary" onClick={() => history.push('/entity/columns/' + tableId)}>
                List of Columns
                    </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colspan="3" align="right">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => onSave()}
              >
                Save
          </Button>

              <label className={classes.spacer}></label>

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isNewRecord}
                className={classes.submit}
                onClick={() => dispatch(allActions.ColumnActions.Delete(EntityObject))}
              >
                Delete
          </Button>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell colspan="3">
              <h3>
                <b><i>Custom Column Information</i></b>
              </h3>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell colSpan="3">
              Enter the details
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.columnLabel}> Data Type</TableCell>
            <TableCell className={classes.columnInput}>
              <Select
                fullWidth
                disabled={isDisabled}
                native
                value={EntityObject.DataType}
                onChange={handleChange}
                inputProps={{ name: 'DataType', id: 'DataType' }}
              >
                {LookupsObject.DataTypes.map(option => (
                  <option value={option.ldValue}>{option.ldValue}</option>
                ))}
              </Select>
            </TableCell>
            <TableCell>Example: If field needs to be mandatory</TableCell>
          </TableRow>


          {isVisibleMasterRelation &&
            <TableRow>
              <TableCell className={classes.columnLabel}> Master Table:</TableCell>
              <TableCell className={classes.columnInput}>
                <Select
                  fullWidth
                  disabled={isDisabled}
                  native
                  value={EntityObject.Master_Relation}
                  onChange={handleChangeMasterChildRelation}
                  inputProps={{ name: 'PrimaryTableEntityId', id: 'PrimaryTableEntityId' }}
                >
                  <option value={0}>- Select Data Type -</option>
                  {LookupsObject.Tables.map(option => (
                    <option value={option.EntityId} data-item={option.Name}>{option.Name}</option>
                  ))}
                </Select>
              </TableCell>
              <TableCell>Example: Choose Master Table to link this table</TableCell>
            </TableRow>
          }

          <TableRow>
            <TableCell className={classes.columnLabel}>Field Label</TableCell>
            <TableCell className={classes.columnInput}> <TextField name="Name" margin="dense" id="filled-basic" placeholder="Enter Label" type="text" fullWidth variant="outlined" onChange={handleChangeName} value={EntityObject.Name} /></TableCell>
            <TableCell>Example: Account</TableCell>
          </TableRow>


          <TableRow>
            <TableCell className={classes.columnLabel}>Plural Label</TableCell>
            <TableCell className={classes.columnInput}> <TextField name="Name2" margin="dense" id="filled-basic" placeholder="Enter Label" type="text" fullWidth variant="outlined" onChange={handleChange} value={EntityObject.Name2} /></TableCell>
            <TableCell>Example: Accounts</TableCell>
          </TableRow>



          {isVisiblePickList &&
            <TableRow>
              <TableCell className={classes.columnLabel}> Global picklist value set</TableCell>
              <TableCell className={classes.columnInput}>
                <Select
                  fullWidth
                  disabled={isDisabled}
                  native
                  value={EntityObject.LookupTypeId}
                  onChange={handleChange}
                  inputProps={{ name: 'LookupTypeId', id: 'LookupTypeId' }}
                >
                  <option value={0}>- Select Data Type -</option>
                  {LookupsObject.LookupTypes.map(option => (
                    <option value={option.ltLookupTypeID}>{option.ltName}</option>
                  ))}
                </Select>
              </TableCell>
              <TableCell>Example: If field needs to be mandatory</TableCell>
            </TableRow>
          }

          {isVisibleLengthField === true && //ova e DECIMAL
            <TableRow>
              <TableCell className={classes.columnLabel}>Length</TableCell>
              <TableCell className={classes.columnInput}> <TextField name="Length" disabled={isDisabled} margin="dense" id="filled-basic" placeholder="Enter Length" type="text" fullWidth variant="outlined" onChange={handleChange} value={EntityObject.Length} /></TableCell>
              <TableCell>Number of digits to the left of the decimal point</TableCell>
            </TableRow>
          }

          {isVisibleDecimalPlacesField === true && //ova e DECIMAL
            <TableRow>
              <TableCell className={classes.columnLabel}>Decimal Places</TableCell>
              <TableCell className={classes.columnInput}> <TextField name="DecimalPlaces" disabled={isDisabled} margin="dense" id="filled-basic" placeholder="Enter Decimal Places" type="text" fullWidth variant="outlined" onChange={handleChange} value={EntityObject.DecimalPlaces} /></TableCell>
              <TableCell>Number of digits to the right  of the decimal point</TableCell>
            </TableRow>
          }

          <TableRow>
            <TableCell className={classes.columnLabel}> API Name</TableCell>
            <TableCell className={classes.columnInput}> <TextField name="APIName" disabled={isDisabled} margin="dense" id="filled-basic" placeholder="Enter API Name" type="text" fullWidth variant="outlined" onChange={handleChange} value={EntityObject.APIName} /></TableCell>
            <TableCell>Example: BankNo, this is actual column who will be created (do not use space or special characters such as @,/,#, etc)</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.columnLabel}> Description</TableCell>
            <TableCell className={classes.columnInput}> <TextField name="Description" margin="dense" id="filled-basic" placeholder="Enter Description" type="text" fullWidth variant="outlined" onChange={handleChange} value={EntityObject.Description} /></TableCell>
            <TableCell>Example: Enter description for your banking account</TableCell>
          </TableRow>

          {!isVisibleMasterRelation &&
            < TableRow >
              <TableCell className={classes.columnLabel}> Required</TableCell>
              <TableCell className={classes.columnInput}>
                <Select
                  variant="outlined"
                  disabled={isDisabled}
                  fullWidth
                  native
                  value={EntityObject.RequiredFL}
                  onChange={handleChange}
                  inputProps={{ name: 'RequiredFL', id: 'RequiredFL' }}
                >
                  <option value='0'>False</option>
                  <option value='1'>True</option>
                </Select>
              </TableCell>
              <TableCell>Example: If field needs to be mandatory</TableCell>
            </TableRow>
          }

          {!isVisibleMasterRelation &&
            <TableRow>
              <TableCell className={classes.columnLabel}> IsUniqueFL</TableCell>
              <TableCell className={classes.columnInput}>
                <Select
                  variant="outlined"
                  disabled={isDisabled}
                  fullWidth
                  native
                  value={EntityObject.IsUniqueFL}
                  onChange={handleChange}
                  inputProps={{ name: 'IsUniqueFL', id: 'IsUniqueFL' }}
                >
                  <option value='0'>False</option>
                  <option value='1'>True</option>
                </Select>
              </TableCell>
              <TableCell>Example: If field needs to be unique accross whole table</TableCell>
            </TableRow>
          }
          {!isVisibleMasterRelation &&
            <TableRow>
              <TableCell className={classes.columnLabel}> Default Value</TableCell>
              <TableCell className={classes.columnInput}> <TextField name="DefaultValue" disabled={isDisabled} margin="dense" id="filled-basic" placeholder="Enter default value" type="text" fullWidth variant="outlined" onChange={handleChange} value={EntityObject.DefaultValue} /></TableCell>
              <TableCell>Example: Enter default value who will appear in the screen</TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer >

  );
}
