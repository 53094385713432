import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import HelpIcon from "@material-ui/icons/Help";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { history } from "../helpers";
import { browserHistory } from "react-router";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import config from "../config/config";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import DirectionsSharpIcon from "@material-ui/icons/DirectionsSharp";
import VpnKeySharpIcon from "@material-ui/icons/VpnKeySharp";
import * as AuthenticationHelper from "../helpers/authentication.helper";
import { InputDropDownLanguage } from "../components/Shared/PreviewComponents";
import * as LanguageHelper from "../helpers/language.helper";
import { logHelper } from "../helpers/log.helper";
import allActions from "../actions";

const lightColor = "rgba(255, 255, 255, 0.7)";

const styles = (theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: "none",
    color: lightColor,
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
});

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function CreateTab(label, url, indexOfTab, hasStartupRecord) {
  //if (!hasStartupRecord && indexOfTab > 0) return;

  return <Tab textColor="inherit" label={label} value={url} />;
}

function GetUrlWithoutKeys(newUrl) {
  if (newUrl.indexOf("tab") > -1) {
    //ako ima tab-ovi vo url-ot trgni gi
    let counter = 0;
    while (true) {
      newUrl = newUrl.replace("/tab" + counter, "");
      counter++;
      if (counter >= 10) break;
    }
  }


  if (newUrl.indexOf("?CreateStartupFromBenef") > -1) {
    newUrl = newUrl.replace("?CreateStartupFromBenef", "");
  }

  if (newUrl.indexOf("?CreateSMEFromStartup") > -1) {
    newUrl = newUrl.replace("?CreateSMEFromStartup", "");
  }
  return newUrl;
}
function Header(props) {
  //TABOVITE SE POJAVUVAAT SAMO I SAMO AKO IMA STARTUP RECORD
  //A TOA SE ODLUCUVA SAMO I SAMO PREKU Edit i isto taka na SAVE kade sto se vika GLOBAL_REFRESH
  //koj pak go osvezuva ovoj modul Header.jS
  const { classes, menu, currentUrl, onDrawerToggle } = props;
  const keys = LanguageHelper.GetLanguageKeys();
  const languageId = LanguageHelper.GetLanguageID();

  const dispatch = useDispatch();
  const dataReducer = useSelector((state) => state.GlobalReducer);
  const [HasStartupRecord, setHasStartupRecord] = useState(false);

  const [MessageData, setMessageData] = useState({});

  //ke se zapali smao pri promena
  useEffect(() => {
    return () => {
      dispatch({ type: "GLOBAL_RESET" }); //vraka na Initial State
    };
  }, []);

  //ke se zapali smao pri promena
  useEffect(() => {
    //samo mapiraj data od koga site call-ovi prema api.he4s.eu se zavrseni a toa e koga Loading === False
    logHelper.log(dataReducer);
    if (!dataReducer.Loading) {
      //alert("HEADER" + "\n" + dataReducer.Loading + '\n' + dataReducer.HasStartupRecord)

      setHasStartupRecord(dataReducer.HasStartupRecord);
    }
  }, [dataReducer]);

  logHelper.log("PROPS");
  logHelper.log(props);

  let headerTitle = ""; //"N/A";
  let tabIndex = "0";
  let selectedMenu = {};
  let childMenu = {};
  childMenu.tabs = [];
  let FirstLetter = AuthenticationHelper.GetFullName();
  let isUser = AuthenticationHelper.IsUser();

  //vrz osnova na ova strukturaa pravi tabs
  //sekogas parent-ot ne treba da ima urlKey: Tab
  //"url": "/user/startup"
  // {
  //     "id": "Statup information",
  //     "children": [
  //       {
  //         "id": "Startup Profile",
  //         "icon": "PeopleIcon",
  //         "active": false,
  //         "url": "/user/startup",
  //         "tabs": [
  //           {"id": "Startup Information","active": false, "url": "/user/startup?Tab=0"},
  //           {"id": "Founders","active": false, "url": "/user/startup?Tab=1"},
  //           {"id": "Services","active": false, "url": "/user/startup?Tab=2"}
  //         ]
  //       },
  //       {
  //         "id": "Log out",
  //         "icon": "PeopleIcon",
  //         "active": false,
  //         "url": "/signIn"
  //       }
  //     ]
  //   }
  let currentUrlWithoutKeys = GetUrlWithoutKeys(currentUrl);
  selectedMenu = menu.items.find((x) =>
    x.children.find(
      (a) => a.url === currentUrlWithoutKeys || a.url2 === currentUrlWithoutKeys
    )
  ); //menu_section kazuva koja sekcija od MENI-to da pokaze
  if (!selectedMenu) {
    selectedMenu = menu.items.find(
      (x) => x.children.find((a) => a.hasTabs === true) || {}
    );
  }

  if (selectedMenu) {
    // "id": "Create Startup",
    // "url": "/admin/startup/new",

    // "id2": "Manage Startup",         //EXTERNAL
    // "url2": "/admin/startup/manage",

    //1.Obidi se prvo da najdes preku prviot Url  i procitaj title: id
    //2. Obidide s'e ako ne najdes po prviot Url da odi po vtoriot (EXTERNAL) Url i procitaj title: id2
    let foundFromExternal = false;
    childMenu = selectedMenu.children.find(
      (a) => a.url === currentUrlWithoutKeys
    ); //menu_section kazuva koja sekcija od MENI-to da pokaze
    if (!childMenu) {
      childMenu = selectedMenu.children.find(
        (a) => a.url2 === currentUrlWithoutKeys
      ); //menu_section kazuva koja sekcija od MENI-to da pokaze
      if (childMenu) {
        foundFromExternal = true;
      }
    }

    //alert(childMenu + "\n" + currentUrlWithoutKeys);
    if (childMenu) {
      logHelper.log("selectedMenu");
      logHelper.log(selectedMenu);
      logHelper.log(childMenu);

      headerTitle = childMenu.id;
      if (foundFromExternal) {
        //ako e najden od EXTERNAL
        headerTitle = childMenu.id2;
      }

      tabIndex = 0;

      //ako ima tab url vo URL-to baraj po tabovi
      if (currentUrl.indexOf("?Tab=") > -1) {
        const tabMenu = childMenu.tabs.find((a) => a.url === currentUrl); //menu_section kazuva koja sekcija od MENI-to da pokaze
        if (tabMenu) {
          headerTitle += " - " + tabMenu.id;
          tabIndex = tabMenu.url.substring(currentUrl.length - 1);
        }
      }
      logHelper.log(headerTitle);
      logHelper.log(tabIndex);
    } else {
      //ako e klasicen url obidi s'e da go najdes po tabs
      selectedMenu.children.find((a) => a.url === currentUrlWithoutKeys);
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //najdi informacija za header-ot

  const [isModalOpen, setModalOpen] = React.useState(false);

  const handleClickOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleChangeTab = (event, newValue) => {
    //let newUrl = GetUrlWithoutKeys(currentUrl);
    //newUrl = newUrl + "?Tab=" + newValue;
    //alert(newValue);
    return history.push(newValue);
    //browserHistory.push(newUrl);
  };

  const DownloadManualDoc = () => {
    async function fetchData() {
      setMessageData({ Loading: true });

      //let Record = { acStartupActivityID: StartupActivityID, NumberOfFile: NumberOfFile }; //prati go na server NumberOfFile 1 ili 2
      let responses =
        await allActions.StartupActivityActions.DownloadManualFileAsync();
      if (!responses.error) {
        const fileForDownload = responses[0].data; //imeto na fajlot
        setMessageData({ Loading: false });
        const win = window.open(fileForDownload, "_new");
        win.focus();
      } else {
        setMessageData({
          HasError: true,
          Message: responses.error_description,
        });
      }
    }

    //tuka se izvrsuva funkcijata
    fetchData();
  };

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            {/* <Grid item>
                            <Fab color="primary" size="small" aria-label="add" onClick={handleClickOpen}>
                                <AddIcon />
                            </Fab>
                        </Grid> */}
            {/* <Grid item>
                            <Tooltip title="Alerts • No alerts">
                                <IconButton color="inherit">
                                    <NotificationsIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid> */}
            <Grid item>
              {isUser && InputDropDownLanguage(languageId)}

              <IconButton
                color="inherit"
                className={classes.iconButtonAvatar}
                onClick={handleClick}
              >
                <Avatar src="/static/images/avatar/2.jpg" alt="My Avatar">
                  {FirstLetter.substring(0, 1)}
                </Avatar>
              </IconButton>

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {/* <StyledMenuItem button onClick={() => window.open("https://www.he4s.eu", "_blank")}>
                                    <ListItemIcon><DirectionsSharpIcon fontSize="small" /></ListItemIcon>
                                    <ListItemText primary="Go to SIS" />
                                </StyledMenuItem>                                
                                <StyledMenuItem button onClick={() => history.push("/dashboard")}>
                                    <ListItemIcon><VpnKeySharpIcon fontSize="small" /></ListItemIcon>
                                    <ListItemText primary="Change Password" />
                                </StyledMenuItem>
                                                                                                 */}

                <StyledMenuItem button onClick={() => history.push("/SignIn")}>
                  <ListItemIcon>
                    <ExitToAppIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </StyledMenuItem>
              </StyledMenu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {headerTitle}
              </Typography>
            </Grid>
            {/* <Grid item>
                            <Button className={classes.button} variant="outlined" color="inherit" size="small">
                                Web setup
              </Button>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Help">
                                <IconButton color="inherit">
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid> */}
          </Grid>
        </Toolbar>
      </AppBar>

      {/* samo ako ima tabs togas data  */}
      {childMenu && childMenu.tabs && (
        <AppBar
          component="div"
          className={classes.secondaryBar}
          color="primary"
          position="static"
          elevation={1}
        >
          <Tabs
            value={currentUrl}
            textColor="inherit"
            onChange={handleChangeTab}
          >
            {/* <Tab textColor="inherit" label="Startup Information" value="/xxx" />
                    <Tab textColor="inherit" label="Founders" value="xxx" />
                    <Tab textColor="inherit" label="Services" value="xxx" />
                         */}
            {childMenu.tabs.map(({ id, icon, active, url }, indexOfTab) =>
              CreateTab(id, url, indexOfTab, HasStartupRecord)
            )}
          </Tabs>
        </AppBar>
      )}
    </React.Fragment>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(Header);
