export const responseHelper = {
    validateResponse,
    validateResponses
};

//Validate Response validira responses dali ima nekakvi greski 
//responses = [] --niza
//Iterira niz nea i ako e TRUE togas vraka responses[]
//ako e FALSE vraka response od ERROR objekt
//return responseHelper.validateResponses(responses);
function validateResponses(responses) {
    //proverka na greska
    let errorData = null;
    responses.map((currentResponse) => {
        if (currentResponse) { //mozno e currentResponse da bide NULL 
            if (!responseHelper.validateResponse(currentResponse)) {
                errorData = currentResponse;
                return true;
            }
        }
    });

    if (errorData !== null) {
        return errorData;
    }
    return responses;
}



function validateResponse(response) {
    if (response.error) {
        return false;
    }
    return true;
}