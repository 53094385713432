import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Radio, TableFooter } from "@material-ui/core";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PreviewService from "../../services/PreviewService.js";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import * as PreviewComponents from "../Shared/PreviewComponents";
import {
  InputText,
  InputDropDown,
  InputDate,
  Header1,
  CustomHeader1,
  InputDropDownLookup,
  InputTextMultiLine,
  InputDropDownOnly,
  InputDropDownCountries,
  InputDropDownCheckboxLookup,
} from "../Shared/PreviewComponents";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Keys from "../../json/ResourceStartup.json";
import allActions from "../../actions";
import constants from "../../helpers/constant.helper";
import AlertMessage from "../AlertMessage";
import * as AuthenticationHelper from "../../helpers/authentication.helper";
//import * as LanguageHelper from "../../helpers/language.helper";
import { logHelper } from "../../helpers/log.helper";
import AlertMessageNew from "../AlertMessageNew.js";
import * as LanguageHelper from "../../helpers/language.helper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    //marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 348,
  },
  formElement: {},
  formLabel: {
    color: "#aaaaa9",
    marginTop: 10,
    "&:hover": {
      background: "#F3F3F2",
    },
  },
  header: {
    backgroundColor: "#F3F3F2",
    borderRadius: 4,
    paddingLeft: 5,
  },
  subHeader: {
    marginTop: 15,
    marginBottom: 1,
    fontWeight: 700,
  },
  buttonStyle: {
    //margin: theme.spacing(1),
    textTransform: "none",
  },
  xs12: {
    width: "50%",
  },
  columnLabel: { width: "10%" },
  columnInput: { width: "70%" },
}));

const isLTR = LanguageHelper.isLanguageLTR();

const RecordDefault = {
  acCategoryID: "0",
  acActivityTypeID: "0",
  sadDonorParentId: "",
  DonorParentReportId: "",
  acDonorID: "0", //ovoj e KLUC za od admin ili officers da bira raznlicni startups
  acPartnerID: "0",
  acName: "",
  acDescription: "",
  acComment: "",
  acFrom: "2022-04-24",
  acTo: "2022-04-24",
  StartupIDs: "",
  acDoc1: null,
  acDoc2: null,
};

export default function ImportActivity(args) {
  const keys = LanguageHelper.GetLanguageKeys();

  const classes = useStyles();

  const dispatch = useDispatch();

  //iniicjalno EntityObject e prazen posle dole na useEffect ke se napolni
  const [Record, setRecord] = useState(RecordDefault);
  const [LookupData, setLookups] = useState([]);
  const [LookupActivityTypes, setActivityTypes] = useState([]);

  const [Partners, setPartners] = useState([]);
  const [MessageData, setMessageData] = useState({});

  const lookupTypeIDs = [
    constants.Lookup_PersonActivity,
    constants.Lookup_PersonActivityRole,
    constants.Lookup_FundedBy,
    constants.Lookup_ParentDonor,
    constants.Lookup_ParentDonorReport,
    constants.Lookup_ActivityAttendance,
  ];

  const isBTSOfficer = AuthenticationHelper.IsBTSOfficer(); //mu treba za drop down za Partners

  const initData = (args) => {
    async function fetchData() {
      setMessageData({ Loading: true });

      let payload = { LookupTypeIDs: lookupTypeIDs };
      let responses = await allActions.ImportActivityActions.EditAsync(payload);
      if (!responses.error) {
        const LookupData = responses[0].data;
        const PartnerDataRecords = responses[1].data;

        setLookups(LookupData);
        setPartners(PartnerDataRecords);

        setMessageData({ Loading: false });
      } else {
        setMessageData({
          HasError: true,
          Message: responses.error_description,
        });
      }
    }

    fetchData();
  };

  useEffect(() => {
    // let payload = { LookupTypeIDs: lookupTypeIDs }
    // dispatch(allActions.ImportActivityActions.Edit(payload));
    initData();
  }, []); //default

  const handleImport = (e) => {
    async function fetchData() {
      setMessageData({ Loading: true });

      let responses = await allActions.ImportActivityActions.SaveAsync(Record);

      if (!responses.error) {
        setMessageData({
          HasError: false,
          Message: constants.MSG_IMPORT_FINISHED,
        });

        setRecord(RecordDefault);
        setRecord((prevState) => ({ ...prevState, ValidationMessages: [] })); //za da se reflekgiraat po ZSNText,
      } else {
        if (responses.validationMessages) {
          setRecord((prevState) => ({
            ...prevState,
            ValidationMessages: responses.validationMessages,
          })); //za da se reflekgiraat po ZSNText,
        }
        setMessageData({
          HasError: true,
          Message: responses.error_description,
        });
      }
    }

    fetchData();
  };

  //Handle Change za Text Box, Select,  i Date Time komponentit
  //https://nikgrozev.com/2019/04/07/reacts-usecallback-and-usememo-hooks-by-example/
  const handleChange = (e) => {
    logHelper.log("od handle change");
    logHelper.log(e.target);
    const { name, value } = e.target;
    logHelper.log("value vo handle");
    logHelper.log(value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeMulti = (controlId, options) => {
    logHelper.log(options);
    logHelper.log(controlId);
    let selectValues = "";

    var index = 0;
    options.map((option) => {
      const selectedValue = option.ldLookupDataID.toString();
      if (selectValues === "") {
        selectValues = selectedValue;
      } else {
        selectValues += "," + selectedValue;
      }
      index = index + 1;
    });

    if (index > 3) {
      alert("Maximum 3 Donors allowed");
      return;
    }
    setRecord((prevState) => ({ ...prevState, [controlId]: selectValues }));
  };

  const handleChangeCategory = (e) => {
    logHelper.log("od handle change");
    logHelper.log(e.target);
    const { name, value } = e.target; //value ==> CountryId

    logHelper.log("value vo handle");
    logHelper.log(value);

    const filterActivitiesTypes = LookupData.filter(
      (a) =>
        a.ldTypeID === constants.Lookup_PersonActivityRole &&
        a.ldEmailPath === value
    );
    setRecord((prevState) => ({
      ...prevState,
      [name]: value,
      ["acActivityTypeID"]: "0",
    })); //da se resetira province-ot za validacija i toa vo "0" za da raboti ne 0
    setActivityTypes(filterActivitiesTypes);
  };

  const handleFileChange = (e) => {
    const { files, name } = e.target;
    let reader = new FileReader();

    //proveri dali ima fajl
    if (files[0]) {
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        logHelper.log(files[0]);
        setRecord((prevState) => ({ ...prevState, [name]: files[0] }));
      };
    }
  };

  logHelper.log("Record");
  logHelper.log(Record);

  return (
    <TableContainer component={Paper}>
      <AlertMessageNew {...MessageData} />

      <Table
        className={classes.table}
        aria-label="customized table"
        style={{ width: "100%", tableLayout: "auto" }}
      >
        <TableHead>
          <TableRow>
            <TableCell colspan={2}>
              <Table style={{ width: "100%", tableLayout: "auto" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h5" component="h5">
                        Import Activities
                      </Typography>
                      <Typography variant="h8" component="h8">
                        Import the activities with entering the SPARK IDs in
                        every new row:
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                        onClick={() => handleImport()}
                        disabled={MessageData.Loading}
                      >
                        Save
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableCell>
          </TableRow>
        </TableHead>

        {InputDropDownLookup(
          classes,
          "Activity Category",
          "acCategoryID",
          LookupData,
          constants.Lookup_PersonActivity,
          Record.acCategoryID,
          handleChangeCategory,
          MessageData.Loading,
          Record.ValidationMessages,
          true
        )}
        {InputDropDownLookup(
          classes,
          "Activity Type",
          "acActivityTypeID",
          LookupActivityTypes,
          constants.Lookup_PersonActivityRole,
          Record.acActivityTypeID,
          handleChange,
          MessageData.Loading,
          Record.ValidationMessages,
          true
        )}
        {/* {InputDropDownLookup(
          classes,
          "Donor",
          "acDonorID",
          LookupData,
          constants.Lookup_FundedBy,
          Record.acDonorID,
          handleChange,
          MessageData.Loading,
          Record.ValidationMessages,
          true
        )} */}

        {InputDropDownCheckboxLookup(
          classes,
          "Fund by (maximum 3 Donors allowed)",
          "sadDonorParentId",
          LookupData,
          constants.Lookup_ParentDonor,
          Record.sadDonorParentId,
          handleChangeMulti,
          MessageData.Loading,
          Record.ValidationMessages,
          true,
          isLTR
        )}

        {InputDropDownCheckboxLookup(
          classes,
          "Reported to (maximum 3 Donors allowed)",
          "DonorParentReportId",
          LookupData,
          constants.Lookup_ParentDonorReport,
          Record.DonorParentReportId,
          handleChangeMulti,
          MessageData.Loading,
          Record.ValidationMessages,
          true,
          isLTR
        )}

        {InputDropDownCountries(
          classes,
          "Partner",
          "acPartnerID",
          Partners,
          Record.acPartnerID,
          handleChange,
          false,
          Record.ValidationMessages,
          true,
          true
        )}

        {InputDropDownLookup(
          classes,
          "Attendance",
          "acCompletedID",
          LookupData,
          constants.Lookup_ActivityAttendance,
          Record.acCompletedID,
          handleChange,
          MessageData.Loading,
          Record.ValidationMessages,
          true
        )}
        {InputDate(
          classes,
          "Start Date",
          "acFrom",
          Record.acFrom,
          handleChange,
          MessageData.Loading,
          Record.ValidationMessages,
          true
        )}
        {InputDate(
          classes,
          "End Date",
          "acTo",
          Record.acTo,
          handleChange,
          MessageData.Loading,
          Record.ValidationMessages,
          true
        )}
        {InputText(
          classes,
          "Name",
          "acName",
          Record.acName,
          handleChange,
          MessageData.Loading,
          Record.ValidationMessages,
          true
        )}
        {InputText(
          classes,
          "Description",
          "acDescription",
          Record.acDescription,
          handleChange,
          MessageData.Loading,
          Record.ValidationMessages,
          true
        )}
        {InputTextMultiLine(
          classes,
          "Comment",
          "acComment",
          Record.acComment,
          handleChange,
          MessageData.Loading,
          Record.ValidationMessages,
          false
        )}
        {InputTextMultiLine(
          classes,
          "Enter the ID's of SMEs, Startup's and Beneficiaries",
          "StartupIDs",
          Record.StartupIDs,
          handleChange,
          MessageData.Loading,
          Record.ValidationMessages,
          true
        )}

        <TableRow>
          <TableCell>
            Upload File Doc #1:
            <br />
            <input
              name="acDoc1"
              onChange={handleFileChange}
              id="acDoc1"
              type="file"
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Upload File Doc #2:
            <br />
            <input
              name="acDoc2"
              onChange={handleFileChange}
              id="acDoc2"
              type="file"
            />
          </TableCell>
        </TableRow>

        <TableFooter>
          <TableRow>
            <TableCell colspan="2" align="right">
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
                onClick={() => handleImport()}
                disabled={MessageData.Loading}
              >
                Save
              </Button>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>

      <AlertMessageNew {...MessageData} />
    </TableContainer>
  );
}
