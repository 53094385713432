import { restHelper } from '../helpers/rest.helper';

const Save = async (payLoad) => {
    let response = await restHelper.post('api/Column/Save', payLoad);
    return response;
}

const Edit = async (payLoad) => {
    let response = await restHelper.post('api/Column/Edit', payLoad);
    return response;
}


const Search = async (payLoad) => {
    let response = await restHelper.post('api/Column/Search', payLoad);
    return response;
}

const Delete = async (payLoad) => {
    let response = await restHelper.post('api/Column/Delete', payLoad);
    return response;
}

const SetOrder = async (payLoad) => {
    let response = await restHelper.post('api/Column/SetOrder', payLoad);
    return response;
}

const SetVisible = async (payLoad) => {
    let response = await restHelper.post('api/Column/SetVisible', payLoad);
    return response;
}

export default {
    Save,
    Edit,
    Search,
    Delete,
    SetOrder,
    SetVisible
}