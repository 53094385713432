import LookupDataService from '../services/LookupDataService'
import StartupService from '../services/StartupService'
import CountryProvinceService from '../services/CountryProvinceService'
import CountryService from '../services/CountryService'
import ServiceLookupData from '../services/LookupDataService'
import ServiceLookupType from '../services/LookupTypeService'
import { validatorHelper, responseHelper, history } from '../helpers';
import Keys from '../json/ResourceStartup.json'
import allServices from '../services'
import { logHelper } from '../helpers/log.helper';

async function InitAsync(args) {
    let responseLookupData = await LookupDataService.SearchByTypeIDs({ "LookupTypeIDs": args.LookupTypeIDs });
    let responseCountriesScholarhips = await CountryService.GetScholarshipCountries();
    let responseCountriesProvinces = await CountryProvinceService.GetCountryProvincesAll();
    let responseStartupTypes = await LookupDataService.SearchByStartupTypeIDs(null); //Benef, StartUp, SMEs
    let responses = [responseLookupData, responseCountriesScholarhips, responseCountriesProvinces, responseStartupTypes];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function CheckSessionAsync(args) {
    let response = await allServices.ManageUsersService.CheckSession(args);
    let responses = [response];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function AdvancedSearchAsync(args) {
    let response = await allServices.ManageUsersService.AdvancedSearch(args);
    let responses = [response];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};


const AdvancedSearch = (args) => {
    logHelper.log(args)
    return dispatch => {
        dispatch({ type: "SEARCH_STARTUP_START" });

        AdvancedSearchAsync(args).then((responses) => {

            if (responses.error) {
                dispatch({ type: "SEARCH_STARTUP_ERROR", error: responses.error_description });
            }
            else {
                dispatch({
                    type: "SEARCH_STARTUP_RESULTS",
                    Records: responses[0].data,
                    Record: args
                }); //samite argumenti za prebaruvanje
            }
        });
    }
}


const Reset = () => {
    return dispatch => {
        dispatch({ type: "SEARCH_STARTUP_RESET" });
    }
}
const Init = (args) => {

    return dispatch => {
        dispatch({ type: "SEARCH_STARTUP_START" });

        InitAsync(args).then((responses) => {
            if (responses.error) {
                dispatch({ type: "SEARCH_STARTUP_ERROR", error: responses.error_description });
            }
            else {
                dispatch({
                    type: "SEARCH_STARTUP_INIT",
                    LookupData: responses[0].data,
                    ScholarshipCountries: responses[1].data, //vidi gore vo komentar za definicija na niza
                    Provinces: responses[2].data,
                    StartupTypeIDs: responses[3].data
                });
            }
        });
    }
}

export default {
    Init,
    Reset,
    AdvancedSearch,
    CheckSessionAsync
}