import LoginActions from './LoginActions'
import TableActions from './TableActions'
import ColumnActions from './ColumnActions'
import LookupTypeActions from './LookupTypeActions'
import LookupDataActions from './LookupDataActions'
import UserProfileActions from './UserProfileActions'
import RegisterUserActions from './RegisterUserActions'
import StartupActions from './StartupActions'
import StartupActivityActions from './StartupActivityActions'

import StartupFounderActions from './StartupFounderActions'
import BenefActions from './BenefActions'
import SmeActions from './SmeActions'
import SmeFounderActions from './SmeFounderActions'
import ImportActivityActions from './ImportActivityActions'
import SmeEmployeeActions from './SmeEmployeeActions'
import SearchStartupActions from './SearchStartupActions'
import ManageUsersActions from './ManageUsersActions'
import ReportActions from './ReportActions'
import ExportActions from './Export/ExportActions'
import SearchActivityActions from './SearchActivityActions'


const allActions = {
    LoginActions,
    TableActions,
    LookupTypeActions,
    LookupDataActions,
    ColumnActions,
    UserProfileActions,
    RegisterUserActions,
    StartupActions,
    StartupFounderActions,
    BenefActions,
    SmeActions,
    SmeFounderActions,
    ImportActivityActions,
    SmeEmployeeActions,
    SearchStartupActions,
    ManageUsersActions,
    ExportActions,
    ReportActions,
    SearchActivityActions,
    StartupActivityActions
}

export default allActions