import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Radio } from "@material-ui/core";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PreviewService from "../../services/PreviewService.js";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import * as PreviewComponents from "../Shared/PreviewComponents";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AlertMessage from "../AlertMessage";
import CircularProgress from "@material-ui/core/CircularProgress";
import { history } from "../../helpers";
import {
  InputTextOnly,
  InputDropDownOnly,
  InputDropDownLookupOnly,
  InputDateOnly,
} from "../Shared/PreviewComponents";
import constants from "../../helpers/constant.helper";
import Keys from "../../json/ResourceStartup.json";
import allActions from "../../actions";
import DeleteIcon from "@material-ui/icons/Delete";
import * as AuthenticationHelper from "../../helpers/authentication.helper";
import moment from "moment";
import * as LanguageHelper from "../../helpers/language.helper";
import { logHelper } from "../../helpers/log.helper";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AlertMessageNew from "../AlertMessageNew";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    //marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 348,
  },
  formElement: {},
  formLabel: {
    color: "#aaaaa9",
    marginTop: 10,
    "&:hover": {
      background: "#F3F3F2",
    },
  },
  header: {
    backgroundColor: "#F3F3F2",
    borderRadius: 4,
    paddingLeft: 5,
  },
  subHeader: {
    marginTop: 15,
    marginBottom: 1,
    fontWeight: 700,
  },
  buttonStyle: {
    //margin: theme.spacing(1),
    textTransform: "none",
  },
  xs12: {
    width: "50%",
  },
  columnLabel: { width: "10%" },
  columnInput: { width: "70%" },
}));

export default function StartupActivityManage(args) {
  logHelper.log(args);

  const keys = LanguageHelper.GetLanguageKeys();

  const classes = useStyles();

  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);

  logHelper.log(Records);

  const startupID = AuthenticationHelper.GetStartUpItemGUID(); //se koristi samo kako ADMIN i OFFICER

  const currentUrl = args.location.pathname + args.location.search;
  const isBenef = currentUrl.indexOf("/benef/") > -1;
  const isStartup = currentUrl.indexOf("/startup/") > -1;
  const isSME = currentUrl.indexOf("/sme/") > -1;
  const isPreviewOnly = AuthenticationHelper.GetStartupPreview();

  const initData = (args) => {
    async function fetchData() {
      const isSME = window.location.href.indexOf("admin/SearchSME") > -1;
      const isStartup =
        window.location.href.indexOf("admin/SearchStartup") > -1;
      const isBenef = window.location.href.indexOf("admin/SearchBenef") > -1;

      let TypeID = 0;

      let Record = {};
      if (isBenef) {
        Record = {
          StartupTypeID: constants.TYPEID_BENEFECIARY,
          acStartupID: startupID,
        }; //2
      } else if (isStartup) {
        Record = {
          StartupTypeID: constants.TYPEID_STARTUP,
          acStartupID: startupID,
        }; //1
      } else if (isSME) {
        Record = {
          StartupTypeID: constants.TYPEID_SME,
          acStartupID: startupID,
        }; //3
      }

      setMessageData({ Loading: true });

      let responses =
        await allActions.StartupActivityActions.ListActivitiesAsync(Record);
      if (!responses.error) {
        const activtiesPerStartup = responses[0].data;

        setRecords(activtiesPerStartup);
        setMessageData({ Loading: false });
      } else {
        setMessageData({
          HasError: true,
          Message: responses.error_description,
        });
      }
    }

    fetchData();
  };

  useEffect(() => {
    initData();
  }, []); //default

  const DownloadDoc = (StartupActivityID, NumberOfFile) => {
    //NumberOfFile == 1 ili 2 vo zavisnost od acDoc1 ili acDoc2

    async function fetchData() {
      setMessageData({ Loading: true });

      let Record = {
        acStartupActivityID: StartupActivityID,
        NumberOfFile: NumberOfFile,
      }; //prati go na server NumberOfFile 1 ili 2
      let responses =
        await allActions.StartupActivityActions.ActivityDownloadFileAsync(
          Record
        );
      if (!responses.error) {
        const fileForDownload = responses[0].data; //imeto na fajlot
        setMessageData({ Loading: false });
        const win = window.open(fileForDownload, "_new");
        win.focus();
      } else {
        setMessageData({
          HasError: true,
          Message: responses.error_description,
        });
      }
    }

    //tuka se izvrsuva funkcijata
    fetchData();
  };

  const DeleteActivity = (StartupActivityID) => {
    //NumberOfFile == 1 ili 2 vo zavisnost od acDoc1 ili acDoc2

    async function fetchData() {
      //setRecords([]);
      setMessageData({ Loading: true });

      let Record = { acStartupActivityID: StartupActivityID }; //prati go na server NumberOfFile 1 ili 2
      let responses =
        await allActions.StartupActivityActions.DeleteActivityAsync(Record);
      if (!responses.error) {
        initData(); //ako s'e e vo ed refreshiraj
      } else {
        setMessageData({
          HasError: true,
          Message: responses.error_description,
        });
      }
    }

    //tuka se izvrsuva funkcijata
    fetchData();
  };

  return (
    <TableContainer component={Paper}>
      <AlertMessageNew {...MessageData} />

      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={8}>
              <Typography variant="h5" component="h5">
                {keys["HeaderInfoAvtivities"]}{" "}
                {startupID > 0 &&
                  "(" + keys["SPARKID"] + " - " + startupID + ")"}
              </Typography>
              <Typography variant="h8" component="h8">
                {keys["SubHeaderActivityInfo"]}
                <br />
                Please use the Import Activities option (on right) for inserting
                new activities.
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell>
              <b>#</b>
            </TableCell>
            <TableCell>
              <b>
                {keys["CategoryNameText"]} / {keys["ActivityNameText"]}
              </b>
            </TableCell>
            <TableCell>
              <b>Activity Name / Description</b>
            </TableCell>
            <TableCell>
              <b>{keys["DonorParentNameText"]}</b>
            </TableCell>
            <TableCell>
              <b>{keys["DonorReportNameText"]}</b>
            </TableCell>
            <TableCell>
              <b>{keys["StartDateText"]}</b>
            </TableCell>
            <TableCell>
              <b>{keys["EndDateText"]}</b>
            </TableCell>
            <TableCell>
              <b>Download</b>
            </TableCell>
            <TableCell>
              <b>Delete</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {MessageData.Loading && <CircularProgress />}
          {Records.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell>
                {row.CategoryName}
                <br />
                {row.ActivityName}
              </TableCell>
              <TableCell>
                {row.acName}
                <br />
                {row.acDescription}
              </TableCell>
              <TableCell>{row.acDonorFundNames}</TableCell>
              <TableCell>{row.acDonorReportNames}</TableCell>
              <TableCell>{moment(row.acFrom).format("D/MM/YYYY")}</TableCell>
              <TableCell>{moment(row.acTo).format("D/MM/YYYY")}</TableCell>

              <TableCell>
                {row.acDoc1 !== "" && (
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => DownloadDoc(row.acStartupActivityID, 1)}
                    startIcon={<CloudDownloadIcon />}
                  >
                    {" "}
                    Download File #1
                    <br />
                  </Button>
                )}
                {row.acDoc2 !== "" && (
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => DownloadDoc(row.acStartupActivityID, 2)}
                    startIcon={<CloudDownloadIcon />}
                  >
                    {" "}
                    Download File #2
                  </Button>
                )}
              </TableCell>
              <TableCell>
                {!isPreviewOnly && (
                  <Button
                    size="small"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={() => DeleteActivity(row.acStartupActivityID, 1)}
                  >
                    {" "}
                    Delete
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
