import resourceManager from "../helpers/constant.helper";
import constants from "../helpers/constant.helper";

{
  //drzi sekogas up-to date za da znaes sto validiras
  /* stStartupID	int	Unchecked
stTypeID	int	Unchecked
stCountryID	int	Unchecked
stProvinceID	int	Unchecked
stName	nvarchar(256)	Unchecked
stRegistryNumber	nvarchar(64)	Unchecked
stAddress	nvarchar(256)	Unchecked
stCity	nvarchar(256)	Unchecked
stMobilePhone	nvarchar(256)	Unchecked
stHomePhone	nvarchar(256)	Unchecked
stEmail	nvarchar(256)	Unchecked
stWeb	nvarchar(256)	Unchecked
stSectorID	int	Unchecked
stStartDate	datetime	Unchecked
stRegistrationDate	datetime	Unchecked
stInitStageID	int	Unchecked
stProvinceOperationID	int	Unchecked
stCurrentStatusID	int	Unchecked
stCurrentStageID	int	Unchecked
stDateCreated	datetime	Unchecked
stCreatedBy	int	Unchecked 
*/
}

//sekoe novo properti dodadi go ovde so DEFAULT value
const initialState = {
  Record: {
    stStartupID: 0,
    stItemGUID: "",
    stOwnerID: "", //ovoj e KLUC za od admin ili officers da bira raznlicni startups
    stTypeID: 0,
    stCountryID: 0,
    stProvinceID: 0,
    stName: "",
    stOfficialNameBusiness: "",
    stRegistryNumber: "",
    stAddress: "",
    stCity: "",
    stMobilePhone: "",
    stHomePhone: "",
    stEmail: "",
    stWeb: "",
    stSectorIDs: "",
    stStartDate: "",
    stRegistrationDate: "",
    stInitStageID: 0,
    stCurrentBusinessStatusID: 0,
    stBusinessOperationLocationID: 0,
    stBussinessFinancialID: 0,
    stProvinceOperationID: 0,
    stCurrentStatusID: 0,
    stCurrentStageID: 0,
    stDateCreated: "2020-06-26",
    stCreatedBy: "",
    stInitFullEmployees: "",
    stInitPartEmployees: "",
    stFirstName: "",
    stLastName: "",
    stPartnerID: 0,
    stPassword: "SparkBTS2@21",
    stBussinessFinancialIDs: "",
    stNote: "",
    stNetProfit: "0",

    stSubSectorID: 0,
    stBDAName: "",
    stCurrTurnover: 0,
    stEstimatedTurnover: 0,
    stEstimatedTurnover: 0,
    stAttendedTrainingID: 0,
    stBusinessKeepingRecordID: 0,
    stNumSessionComplete: 0,
    stNumSessionGrow: 0,
    stBusinessPlanStatusID: 0,
    stLoanStatusID: 0,
    stAmountApllied: "0",
    stAmountAccessed: "0",
    stCurrentNumberFullEmployees: 0,
    stCurrentNumberPartEmployees: 0,
    stCurrentNumberOfAgents: 0,
    stYMonthAppliedForLoan: "",
    stYMonthLoanWasReceived: "",
    stHaveBankAccountID: 0,
    stIsLegallyRegistered: 0,
  },

  LookupData: [], //Table: LookupData
  ScholarshipCountries: [], //Table: Country
  Provinces: [], //Table: CountryProvince

  Records: [],

  Message: "",
  ValidationMessages: [],
  Loading: true,
  hasError: false,
};

const StartupReducer = (state = initialState, action) => {
  switch (action.type) {
    case "STARTUP_START":
      return {
        ...state,
        Loading: true,
        hasError: false,
      };

    case "STARTUP_ERROR":
      return {
        ...state,
        Message: action.error,
        Loading: false,
        hasError: true,
      };

    case "STARTUP_SAVE_ERROR":
      return {
        ...state,
        Message: action.error,
        ValidationMessages: action.ValidationMessages,
        Record: action.Record, //istite vrednosti gi vraka tie sto gi vnesol
        Loading: false,
        hasError: true,
      };

    case "STARTUP_SAVE_SUCCESS":
      return {
        //...state,
        ...state, //se drugo e vo STATE-ot
        Record: action.Record, //samo noviot record ako e NOV, ako e update si ostanuva IST
        Message: resourceManager.MSG_SAVE,
        ValidationMessages: [], //resetirj clientski greski
        Loading: false,
        hasError: false,
      };

    case "STARTUP_EDIT":
      return {
        ...state,
        //ako od server vratil NULL togas setiraj inital state vo sprotivno ne raboti state-ot
        Record: action.Record === null ? initialState.Record : action.Record,
        LookupData: action.LookupData,
        ScholarshipCountries: action.ScholarshipCountries,
        Provinces: action.Provinces,
        Message: "",
        Loading: false,
        hasError: false,
      };

    // case "STARTUP_CREATE":
    //     return {
    //         ...state,
    //         Record: initialState.Record,    //za da se resetira
    //         LookupData: action.LookupData,
    //         ScholarshipCountries: action.ScholarshipCountries,
    //         Provinces: action.Provinces,

    //         Message: "",
    //         Loading: false,
    //         hasError: false
    //     }

    //se koristi vo find
    case "STARTUP_SEARCH":
      return {
        ...state,
        Records: action.Records, //action.payload == List<Entity>
        Message: "",
        Loading: false,
        hasError: false,
      };

    //se koristi vo find
    case "STARTUP_RESET":
      state = initialState; //go resetira na iniital state
      return {
        ...state,
      };

    default:
      return state;
  }
};
export default StartupReducer;
