import { restHelper } from '../helpers/rest.helper';


const CreateUser = async (payLoad) => {
    let response = await restHelper.post('api/Register/CreateUser', payLoad);
    return response;
}

const GetScholarshipCountries = async (payLoad) => {
    let response = await restHelper.post('api/Register/GetScholarshipCountries', null);
    return response;
}

export default {
    CreateUser,
    GetScholarshipCountries
}