import resourceManager from '../helpers/constant.helper';
import constants from '../helpers/constant.helper';


    //sekoe novo properti dodadi go ovde so DEFAULT value
    const initialState = {

        Record: {
            StartupID: '',
            ItemGUID: '',
            OwnerID: '',      //ovoj e KLUC za od admin ili officers da bira raznlicni startups
            TypeID: 0,
            CountryID: '0',
            ProvinceID: '0',
            Name: '',
            RegistryNumber: '',
            Address: '',
            City: '',
            MobilePhone: '',
            HomePhone: '',
            Email: '',
            Web: '',
            SectorID: '0',
            StartDate: '2020-06-26',
            RegistrationDate: '2020-06-26',
            InitStageID: '0',
            ProvinceOperationID: '0',
            CurrentStatusID: '0',
            CurrentStageID: '0',
            DateCreated: '2020-06-26',
            CreatedBy: '',
            InitFullEmployees: '',
            InitPartEmployees: '',
            FirstName: '',
            LastName: '',

            SectorIDs:'',
            ActivityCategoryIDs:'',
            ActivityTypeIDs:'',
            DonorIDs:'',
            acFrom: '',
            acTo: '',
            stGenderID: '0',
            stDisabilityID: '0',
            stNumberDailyClientID: '0',
            stBusinessInsuranceID: '0',
            stBusinessLoanID: '0',
            stIncomeID: '0',
            stInitStageID: '0',
            stBusinessOperationLocationID: '0',
            stBussinessFinancialID: '0',
            stCurrentBusinessStatusID: '0',
            stEmploymentStatusID: '0',
            

            //834	1012	Startups with scholarships (from SIS)
            // 835	1012	Startups without scholarships        
            HasScholarshipFL:'0',

            ActivityStartDate : "0",
            ActivityEndDate : "0",
            InitialBusinessStage : "0",
            BusinessOperationLocation : "0",
            BusinessFinancial : "0",
            CurrentBusinessStatus : "0",
            Nationality : "0",
            Gender : "0",
            Disability : "0",
            EmploymentStatus : "0",
            NumberDailyClientCustomers : "0",
            BusinessHasInsurance : "0",
            BusinessHasLoan : "0",
            Income : "0",
            
            PageSize: 10,
            PageIndex: 1,
            TotalPages:0
        },

        LookupData: [],  //Table: LookupData
        ScholarshipCountries: [],   //Table: Country
        Nationalities: [],   //Table: Country
        Provinces: [],   //Table: CountryProvince
        StartupTypeIDs: [], //SME, BENEF, Startup vo LookupData Model
        Records: [],
        Partners: [],

        Message: '',
        Loading: true,
        hasError: false,
        type:''
    }

const SearchStartupReducer = (state = initialState, action) => {
    switch (action.type) {

        case "SEARCH_STARTUP_START":
            return {
                ...state,
                Loading: true,
                hasError: false,
                type: action.type
            }

        case "SEARCH_STARTUP_ERROR":
            return {
                ...state,
                Message: action.error,
                Loading: false,
                hasError: true,
                type: action.type
            }

        case "SEARCH_STARTUP_INIT":
            return {
                ...state,
                //ako od server vratil NULL togas setiraj inital state vo sprotivno ne raboti state-ot
                LookupData: action.LookupData,
                ScholarshipCountries: action.ScholarshipCountries,
                Provinces: action.Provinces,
                Nationalities: action.Nationalities,
                Partners: action.Partners,
                StartupTypeIDs: action.StartupTypeIDs,
                Message: "",
                Loading: false,
                hasError: false,
                type: action.type,

                //resetiraj records
                Records:[]
            }

        //se koristi vo find
        case "SEARCH_STARTUP_RESET":
            //alert("SearchStartupReducer: SEARCH_STARTUP_RESET");
            state = initialState; //go resetira na iniital state
            return {
                ...state
           }

        case "SEARCH_STARTUP_RESULTS":
            //alert("OVA E OD REDUCER:SEARCH_STARTUP_RESULTS");
            return {
                ...state,
                Message: "",
                Loading: false,
                hasError: false,
                type: action.type,

                //pokazi records
                Records: action.Records,  
            }
        default:
            return state
    }
}
export default SearchStartupReducer;