import * as AuthenticationHelper from '../helpers/authentication.helper';
import { validatorHelper, responseHelper, history } from '../helpers';
import Service from '../services/RegisterService'
import LoginService from '../services/LoginService'
import allServices from '../services';
import Keys from '../json/ResourceStartup.json'

const Reset = () => {
    return dispatch => {
        dispatch({ type: "REGISTERUSER_RESET" }); //da se refreshira GUI-to so SPINNER-ot
    }
}


async function EditAsync(payload) {
    let response = await allServices.CountryService.GetScholarshipCountries();
    let responses = [response];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function CreateUserAsync(args) {
    let response = await Service.CreateUser(args);
    let responses = [response];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function SignInUserAsync(args) {
    const response = await LoginService.SignIn(args.Email, args.Password);
    let responses = [response];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

const Edit = (payload) => {
    return dispatch => {
        dispatch({ type: "REGISTERUSER_START" });

        EditAsync(payload).then((responses) => {
            if (responses.error) {
                dispatch({ type: "REGISTERUSER_ERROR", error: responses.error_description });
            }
            else {
                dispatch({
                    type: "REGISTERUSER_EDIT",
                    Countries: responses[0].data
                });
            }
        });
    }
}



const createUser = (payload) => {

    return dispatch => {
        dispatch({ type: "REGISTERUSER_START" }); //da se refreshira GUI-to so SPINNER-ot

        const keys = Keys[0]; //za da naoga textovi od JSON FIle
        let jsonInputData = [

            {
                "type": "required",
                "value": payload.FirstName,
                "message": keys["FirstNameEmpty"]
            },
            {
                "type": "required",
                "value": payload.LastName,
                "message": keys["LastNameEmpty"]
            },
            {
                "type": "required",
                "value": payload.Email,
                "message": keys["EmailCantBeEmpty"]
            },
            {
                "type": "required",
                "value": payload.Password,
                "message": keys["PasswordEmpty"]
            },
            {
                "type": "required",
                "value": payload.ConfirmPassword,
                "message": keys["ConfirmPasswordEmpty"]
            },
            {
                "type": "SelectEmpty",      //proverka na select isto kako vo MVC
                "value": payload.CountryID,
                "message": keys["CountryCantBeEmpty"]
            },

        ]
        let errMsg = validatorHelper.validate(jsonInputData);
        let msg = ""
        let hasError = false;

        if (payload.Password !== payload.ConfirmPassword) {
            hasError = true;
            msg += "Password & Confirm Password are not the same";
        }

        if (errMsg !== null) {
            dispatch({
                type: "REGISTERUSER_ERROR",
                error: errMsg,
                payload: payload
            }); //da ne prekucuva, toa sto e od client toa da mu vrati


        } else if (hasError) {
            dispatch({ type: "REGISTERUSER_ERROR", error: msg });
        }
        else {


            // let msg = "";
            // const { userName, password, confirmPassword } = payload;

            // //validacija
            //let hasError = false;

            // if (!payload.Email) {
            //     hasError = true;
            //     msg = "User Name can't be empty<br/>";
            // }

            // if (!payload.Password) {
            //     hasError = true;
            //     msg = "Password can't be empty<br/>";
            // }

            // if (!payload.ConfirmPassword) {
            //     hasError = true;
            //     msg += "Confirm Password can't be empty<br/>";
            // }

            // if (payload.Password !== payload.ConfirmPassword) {
            //     hasError = true;
            //     msg += "Password & Confirm Password are not the same<br/>";
            // }


            // if (hasError) {
            //     dispatch({ type: "REGISTERUSER_ERROR", error: msg });
            // }

            //se e vo red odi na proverka
            ///const payload = { "username": userName, "password": password, grant_type: "password" }


            CreateUserAsync(payload).then(responseCreateUser => {
                if (responseCreateUser.error) {
                    dispatch({ type: "REGISTERUSER_ERROR", error: responseCreateUser.error_description });
                }
                else {
                    SignInUserAsync(payload).then(responses => {
                        if (responses.error) {
                            dispatch({ type: "REGISTERUSER_ERROR", error: responses.error_description });
                        }
                        else {
                            const response = responses[0];
                            AuthenticationHelper.SignIn(response.data.access_token,
                                response.data.userName,
                                response.data.fullName,
                                response.data.refresh_token,
                                response.data.role,
                                response.data.ExpiresIn);

                            //user dashboard
                            history.push("/user/dashboard");
                        }
                    });
                }
            })
        }
    }
}

export default {
    createUser,
    Edit,
    Reset
}