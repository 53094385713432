import resourceManager from "../helpers/constant.helper";
import constants from "../helpers/constant.helper";

//sekoe novo properti dodadi go ovde so DEFAULT value
const initialState = {
  Record: {
    stStartupID: 0,
    stTypeID: 0,
    stCountryID: 0,
    stProvinceID: 0,
    stName: "",
    stOfficialNameBusiness: "",
    stRegistryNumber: "",
    stAddress: "",
    stCity: "",
    stMobilePhone: "",
    stHomePhone: "",
    stEmail: "",
    stWeb: "",
    stSectorIDs: "",
    stStartDate: "",
    stRegistrationDate: "",
    stProvinceOperationID: 0,
    stCurrentStatusID: 0,
    stCurrentStageID: 0,
    stDateCreated: "2020-06-26",
    stCreatedBy: "",
    stInitFullEmployees: 0,
    stInitPartEmployees: 0,
    stFirstName: "",
    stLastName: "",
    stBirthDate: "2020-06-26",
    stGenderID: 0,
    stEmploymentStatusID: 0,
    stBusinessOperationLocationID: 0,
    stBussinessFinancialID: 0,
    stNumberDailyClientID: 0,
    stFinancialYearClosingBusinessID: 0,
    stBusinessLoanID: 0,
    stBusinessInsuranceID: 0,
    stBusinessRevenueEnoughID: 0,
    stIncomeID: 0,
    stCurrentBusinessStatusID: 0,

    stPassword: "SparkBTS2@21",
    stNetLoss: "0",
    stNetProfit: "0",
    stPartnerID: 0,
    stProvinceOperationIDs: "",
    stBussinessFinancialIDs: "",
    stNote: "",
    stSubSectorID: 0,
    stBDAName: "",
    stCurrTurnover: 0,
    stEstimatedTurnover: 0,
    stEstimatedTurnover: 0,
    stAttendedTrainingID: 0,
    stBusinessKeepingRecordID: 0,
    stNumSessionComplete: 0,
    stNumSessionGrow: 0,
    stBusinessPlanStatusID: 0,
    stLoanStatusID: 0,
    stAmountApllied: "0",
    stAmountAccessed: "0",
    stCurrentNumberFullEmployees: 0,
    stCurrentNumberPartEmployees: 0,
    stCurrentNumberOfAgents: 0,
    stYMonthAppliedForLoan: "",
    stYMonthLoanWasReceived: "",
    stHaveBankAccountID: 0,
    stIsLegallyRegistered: 0,
  },

  LookupData: [], //Table: LookupData
  ScholarshipCountries: [], //Table: Country
  Provinces: [], //Table: CountryProvince

  Records: [],

  Message: "",
  ValidationMessages: [],
  Loading: true,
  hasError: false,
};

const SmeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SME_START":
      return {
        ...state,
        Loading: true,
        hasError: false,
      };

    case "SME_ERROR":
      return {
        ...state,
        Message: action.error,
        Loading: false,
        hasError: true,
      };

    case "SME_SAVE_ERROR":
      return {
        ...state,
        Message: action.error,
        ValidationMessages: action.ValidationMessages,
        Record: action.Record, //istite vrednosti gi vraka tie sto gi vnesol
        Loading: false,
        hasError: true,
      };

    case "SME_SAVE_SUCCESS":
      return {
        //...state,
        ...state, //se drugo e vo STATE-ot
        Record: action.Record, //samo noviot record ako e NOV, ako e update si ostanuva IST
        Message: resourceManager.MSG_SAVE,
        ValidationMessages: [], //resetirj clientski greski
        Loading: false,
        hasError: false,
      };

    case "SME_EDIT":
      return {
        ...state,
        //ako od server vratil NULL togas setiraj inital state vo sprotivno ne raboti state-ot
        Record: action.Record === null ? initialState.Record : action.Record,
        LookupData: action.LookupData,
        ScholarshipCountries: action.ScholarshipCountries,
        Provinces: action.Provinces,
        Message: "",
        Loading: false,
        hasError: false,
      };

    // case "STARTUP_CREATE":
    //     return {
    //         ...state,
    //         Record: initialState.Record,    //za da se resetira
    //         LookupData: action.LookupData,
    //         ScholarshipCountries: action.ScholarshipCountries,
    //         Provinces: action.Provinces,

    //         Message: "",
    //         Loading: false,
    //         hasError: false
    //     }

    //se koristi vo find
    case "SME_SEARCH":
      return {
        ...state,
        Records: action.Records, //action.payload == List<Entity>
        Message: "",
        Loading: false,
        hasError: false,
      };

    //se koristi vo find
    case "SME_RESET":
      state = initialState; //go resetira na iniital state
      return {
        ...state,
      };

    default:
      return state;
  }
};
export default SmeReducer;
