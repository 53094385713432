import LookupDataService from '../services/LookupDataService'
import SmeService from '../services/SmeService'
import CountryProvinceService from '../services/CountryProvinceService'
import CountryService from '../services/CountryService'
import ServiceLookupData from '../services/LookupDataService'
import ServiceLookupType from '../services/LookupTypeService'
import { validatorHelper, responseHelper, history } from '../helpers';
import Keys from '../json/ResourceStartup.json'
import * as LanguageHelper from '../helpers/language.helper';
import ReportService from '../services/ReportService'

async function GetOverAllResultsAsync(args) {
    let response = await ReportService.GetOverAllResults(args);
    let responses = [response];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function GetQuarterResultsInitAsync(args) {
    let responseLookupData = await LookupDataService.SearchByTypeIDs({ "LookupTypeIDs": args.LookupTypeIDs });
    let responseCountriesScholarhips = await CountryService.GetScholarshipCountries();
    let responseYears = [{Value:2015, Text:2015}, {Value:2016, Text:2016}, {Value:2017, Text:2017}, {Value:2018, Text:2018}, {Value:2019, Text:2019}, {Value:2020, Text:2020}]
    let responses = [responseLookupData, responseCountriesScholarhips, {data: responseYears}]; //bidejki se zavrsuva so .data
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function GetQuarterResultsSearchAsync(args) {
    let response = await ReportService.GetQuarterResults(args);
    let responses = [response];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

const GetOverAllResults = (args) => {

    return dispatch => {
        dispatch({ type: "REPORT_START" });

        GetOverAllResultsAsync(args).then((responses) => {
            if (responses.error) {
                dispatch({ type: "REPORT_ERROR", error: responses.error_description });
            }
            else {
                dispatch({
                    type: "REPORT_SUCCESS",
                    Records: responses[0].data
                });
            }
        });
    }
}

const GetQuarterResultsInit  = (args) => {

    return dispatch => {
        dispatch({ type: "REPORT_START" });

        GetQuarterResultsInitAsync(args).then((responses) => {
            if (responses.error) {
                dispatch({ type: "REPORT_ERROR", error: responses.error_description });
            }
            else {
                dispatch({
                    type: "REPORT_SEARCH_ARGS",
                    LookupData: responses[0].data,
                    ScholarshipCountries: responses[1].data, //vidi gore vo komentar za definicija na niza
                    Years: responses[2].data,
                });
            }
        });
    }
}


const GetQuarterResultsSearch  = (args) => {

    return dispatch => {
        dispatch({ type: "REPORT_START" });

        GetQuarterResultsSearchAsync(args).then((responses) => {
            if (responses.error) {
                dispatch({ type: "REPORT_ERROR", error: responses.error_description });
            }
            else {
                dispatch({
                    type: "REPORT_SUCCESS",
                    Records: responses[0].data
                });
            }
        });
    }
}


export default {
    GetOverAllResults,
    GetQuarterResultsSearch,
    GetQuarterResultsInit
}