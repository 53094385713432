import { history } from "../helpers";
import * as AuthenticationHelper from "../helpers/authentication.helper";
import { restHelper } from "../helpers/rest.helper";
import { logHelper } from "../helpers/log.helper";

const Reset = () => {
  return (dispatch) => {
    dispatch({ type: "LOGIN_RESET" }); //da se refreshira GUI-to so SPINNER-ot
  };
};

const Login = (userName, password) => {
  return (dispatch) => {
    logHelper.log("PRAKAM KON REDUCER");

    dispatch({ type: "LOGIN_START" }); //da se refreshira GUI-to so SPINNER-ot

    //validacija
    let hasError = false;
    if (!userName && !password) {
      hasError = true;
      dispatch({
        type: "LOGIN_ERROR",
        error: "User Name and Password can't be empty",
      });
    } else if (!userName) {
      hasError = true;
      dispatch({ type: "LOGIN_ERROR", error: "User Name can't be empty" });
    } else if (!password) {
      hasError = true;
      dispatch({ type: "LOGIN_ERROR", error: "Password can't be empty" });
    }
    if (hasError) return;

    //se e vo red odi na proverka
    const payload = {
      username: userName,
      password: password,
      grant_type: "password",
    };
    restHelper.login("token", payload).then((response) => {
      if (response.error) {
        dispatch({ type: "LOGIN_ERROR", error: response.error_description });
      } else {
        logHelper.log(response);
        console.log("VVVVVVVVVV");
        console.log(response.data.countryId);
        AuthenticationHelper.SignIn(
          response.data.access_token,
          response.data.userName,
          response.data.fullName,
          response.data.refresh_token,
          response.data.role,
          response.data.ExpiresIn,
          response.data.countryId
        );

        AuthenticationHelper.GoToDashboard();
        // if (AuthenticationHelper.IsSysAdmin())
        // {
        //     history.push("/admin/dashboard");
        // }
        // if (AuthenticationHelper.IsOfficer())
        // {
        //     history.push("/admin/dashboard"); //da se vidi za officer dashboard
        // }
        // if (AuthenticationHelper.IsUser())
        // {
        //     history.push("/user/dashboard");
        // }
      }
    });
  };
};

const refreshToken = () => {
  return (dispatch) => {
    logHelper.log("PRAKAM KON REDUCER");

    //dispatch({ type: "LOGIN_START" }); //da se refreshira GUI-to so SPINNER-ot

    //se e vo red odi na proverka
    let payload = {
      grant_type: "refresh_token",
      refresh_Token: AuthenticationHelper.GetRefreshToken(),
    };

    restHelper.refreshToken("token", payload).then((response) => {
      if (response.error) {
        dispatch({ type: "LOGIN_ERROR", error: response.error_description });
      } else {
        logHelper.log(response);

        AuthenticationHelper.SignIn(
          response.data.access_token,
          response.data.userName,
          response.data.fullName,
          response.data.refresh_token,
          response.data.role,
          response.data.ExpiresIn
        );

        AuthenticationHelper.GoToDashboard();
      }
    });
  };
};

export default {
  refreshToken,
  Login,
  Reset,
};
