import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Radio, TableFooter } from "@material-ui/core";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PreviewService from "../../services/PreviewService.js";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import * as PreviewComponents from "../Shared/PreviewComponents";
import {
  InputTextMultiLine,
  InputText,
  InputDropDown,
  InputDate,
  Header1,
  CustomHeader1,
  InputDropDownLookup,
  InputDropDownCountries,
  InputPassword,
  InputDropDownCheckboxLookup,
} from "../Shared/PreviewComponents";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import * as LanguageHelper from "../../helpers/language.helper";
import allActions from "../../actions";
import constants from "../../helpers/constant.helper";
import AlertMessage from "../AlertMessage";
import * as AuthenticationHelper from "../../helpers/authentication.helper";
import PleaseWaitMessage from "../PleaseWaitMessage";
import { history, ShowLinkedRecords } from "../../helpers";
import { logHelper } from "../../helpers/log.helper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    //marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 348,
  },
  formElement: {},
  formLabel: {
    color: "#aaaaa9",
    marginTop: 10,
    "&:hover": {
      background: "#F3F3F2",
    },
  },
  header: {
    backgroundColor: "#F3F3F2",
    borderRadius: 4,
    paddingLeft: 5,
  },
  subHeader: {
    marginTop: 15,
    marginBottom: 1,
    fontWeight: 700,
  },
  buttonStyle: {
    //margin: theme.spacing(1),
    textTransform: "none",
  },
  xs12: {
    width: "50%",
  },
  columnLabel: { width: "10%" },
  columnInput: { width: "70%" },
}));

//JAZICI EDNAS DA SE VCITAAT
const keys = LanguageHelper.GetLanguageKeys();

export default function StartupManage(args) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const dataReducer = useSelector((state) => state.StartupReducer);

  //iniicjalno EntityObject e prazen posle dole na useEffect ke se napolni
  const [Record, setRecord] = useState(dataReducer.Record);
  const [LookupData, setLookups] = useState(dataReducer.LookupData);
  const [ScholarshipCountries, setScholarshipCountries] = useState(
    dataReducer.ScholarshipCountries
  );
  const [Provinces, setProvinces] = useState(dataReducer.Provinces);
  const [ProvincesByCountry, setProvincesByCountry] = useState([]);

  const lookupTypeIDs = [
    constants.Lookup_SectorOfBusiness,
    constants.Lookup_IniitialBusinessStage,
    constants.Lookup_BussinessFinancial,
    constants.Lookup_BusinessOperationLocation,
    constants.Lookup_CurrentBusinessStatus,
    constants.Lookup_SubSector,
    constants.Lookup_FinancialYearClosingBusiness,
    constants.Lookup_AttendedTraining,
    constants.Lookup_BusinessKeepingRecord,
    constants.Lookup_BusinessPlanStatus,
    constants.Lookup_LoanStatus,
    constants.Lookup_CurrentBusinessStatusSubSahara,
    constants.Lookup_HaveBankAccount,
    constants.Lookup_IsLegallyRegistered,
  ];
  const isUser = AuthenticationHelper.IsUser(); //mu treba za drop down za Country da mu ja dade samo negovata zemja
  const isOfficer = AuthenticationHelper.IsOfficer(); //mu treba za drop down za Country da mu ja dade samo negovata zemja i vo DropDownCountries da ne pokazuva Select Countries
  const currentUrl = args.location.pathname + args.location.search;
  const isSubSuhara = AuthenticationHelper.IsSubSahara();
  const isUrlForCreateStartupFromBenef =
    currentUrl.indexOf("CreateStartupFromBenef") > -1;
  const isPreviewOnly = AuthenticationHelper.GetStartupPreview();
  const isFieldEnabled = !dataReducer.Loading && Record.stStartupID === 0; //za countries, email, etc 0 e definiran vo reducer
  const isEmailEnabled =
    !dataReducer.Loading && Record.stStartupID === 0 && !isUser; //enabliraj enty za email samo i smao za Officers i za Admin

  useEffect(() => {
    let payload = { LookupTypeIDs: lookupTypeIDs };

    // //ako e USER ke si raboti so SignedUserId
    // if (isUser) {
    //   if (isUrlForCreateStartupFromBenef) {
    //     dispatch(allActions.StartupActions.CreateStartupFromBenef(payload));
    //   }
    //   else {
    //     payload = { ...payload, "stStartupID": startupId };
    //     dispatch(allActions.StartupActions.Edit(payload));
    //   }
    // }
    // else {
    //   const itemGUID = AuthenticationHelper.GetStartUpItemGUID();
    //   payload = { ...payload, "stItemGUID": itemGUID };

    //   if (isUrlForCreateStartupFromBenef) {
    //     dispatch(allActions.StartupActions.CreateStartupFromBenef(payload));
    //   }
    //   else {
    //     dispatch(allActions.StartupActions.Edit(payload));
    //   }
    // }

    const startupId = AuthenticationHelper.GetStartUpItemGUID();
    payload = { ...payload, stStartupID: startupId };
    dispatch(allActions.StartupActions.Edit(payload));

    return () => {
      dispatch(allActions.StartupActions.Reset()); //vraka na Initial State
    };
  }, []); //default

  //ke se zapali smao pri promena
  useEffect(() => {
    //samo mapiraj data od koga site call-ovi prema api.he4s.eu se zavrseni a toa e koga Loading === False
    if (!dataReducer.Loading) {
      logHelper.log(dataReducer);

      //setiraj go tekovniot Startup
      AuthenticationHelper.SetStartUpItemGUID(dataReducer.Record.stStartupID);

      setLookups(dataReducer.LookupData);
      setScholarshipCountries(dataReducer.ScholarshipCountries);
      setProvinces(dataReducer.Provinces);

      // if (isOfficer) {
      //   const countryId = dataReducer.ScholarshipCountries[0].Value;
      //   const filterProvinceByCountry = dataReducer.Provinces.filter(a => a.ParentId === countryId.toString());
      //   setProvincesByCountry(filterProvinceByCountry);

      //   dataReducer.Record.stCountryID = countryId;
      //   setRecord(dataReducer.Record);
      // }
      // else {
      //   const value = dataReducer.Record.stCountryID.toString();
      //   const filterProvinceByCountry = dataReducer.Provinces.filter(a => a.ParentId === value);
      //   setProvincesByCountry(filterProvinceByCountry);
      //   setRecord(dataReducer.Record);
      // }

      //so ogled deka Partnerite moze da kreiraat i vo drugi drzavi ako e setiran AspNetUser.CountryId = -1 dozvoliim da  gledaat i drugi zemji
      //ako e nov record togas filtriraj po prvata zemja provincii
      //vo sprotivno od samiot record ke si najde za koja zemja se raboti
      const value = dataReducer.Record.stCountryID.toString();
      if (value == 0) {
        const countryId = dataReducer.ScholarshipCountries[0].Value;
        const filterProvinceByCountry = dataReducer.Provinces.filter(
          (a) => a.ParentId === countryId.toString()
        );
        setProvincesByCountry(filterProvinceByCountry);

        dataReducer.Record.stCountryID = countryId;
        setRecord(dataReducer.Record);
      } else {
        const filterProvinceByCountry = dataReducer.Provinces.filter(
          (a) => a.ParentId === value
        );
        setProvincesByCountry(filterProvinceByCountry);
      }
      setRecord(dataReducer.Record);

      //Za User sekogas listata na ScholarshipCountries e samo i samo 1 zemja
      //pa poradi toa najdi go CountryId i sprema nego filtriraj Provinces
      // if (isUser) {
      //   const countryId = dataReducer.ScholarshipCountries[0].Value;
      //   const filterProvinceByCountry = dataReducer.Provinces.filter(a => a.ParentId === countryId.toString());

      //   //bez razlika ako e user EMAIL-ot ke mu bude a.k.a USER NAME
      //   dataReducer.Record.stEmail = AuthenticationHelper.GetUserName();

      //   setProvincesByCountry(filterProvinceByCountry);
      //   setRecord(dataReducer.Record);
      // }
    }
  }, [dataReducer]);

  //Handle Change za Text Box, Select,  i Date Time komponentit
  //https://nikgrozev.com/2019/04/07/reacts-usecallback-and-usememo-hooks-by-example/
  const handleChange = (e) => {
    logHelper.log("od handle change");
    logHelper.log(e.target);
    const { name, value } = e.target;
    logHelper.log("value vo handle");
    logHelper.log(value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeEmail = (e) => {
    logHelper.log(e.target);
    const { name, value } = e.target;
    setRecord((prevState) => ({
      ...prevState,
      [name]: value,
      stUserName: value,
    }));
  };

  const handleChangeMulti = (controlId, options) => {
    logHelper.log(options);
    logHelper.log(controlId);
    let selectValues = "";

    options.map((option) => {
      const selectedValue = option.ldLookupDataID.toString();
      if (selectValues === "") {
        selectValues = selectedValue;
      } else {
        selectValues += "," + selectedValue;
      }
    });

    setRecord((prevState) => ({ ...prevState, [controlId]: selectValues }));
  };

  const handleChangeCountry = (e) => {
    logHelper.log("od handle change");
    logHelper.log(e.target);
    const { name, value } = e.target; //value ==> CountryId

    logHelper.log("value vo handle");
    logHelper.log(value);

    const filterProvinceByCountry = Provinces.filter(
      (a) => a.ParentId === value
    );
    setRecord((prevState) => ({
      ...prevState,
      [name]: value,
      ["stProvinceID"]: "0",
    })); //da se resetira province-ot za validacija i toa vo "0" za da raboti ne 0
    setProvincesByCountry(filterProvinceByCountry);
  };

  logHelper.log("Record");
  logHelper.log(Record);

  return (
    <TableContainer component={Paper}>
      <PleaseWaitMessage {...dataReducer} />
      <AlertMessage {...dataReducer} />

      <Table
        className={classes.table}
        aria-label="customized table"
        style={{ width: "100%", tableLayout: "auto" }}
      >
        <TableHead>
          <TableRow>
            <TableCell colspan={2}>
              <Table style={{ width: "100%", tableLayout: "auto" }}>
                <TableHead>
                  <TableRow>
                    <TableCell width="80%">
                      <Typography variant="h5" component="h5">
                        {keys["HeaderInfo"]}{" "}
                        {Record.stStartupID > 0 &&
                          "(" +
                            keys["SPARKID"] +
                            " - " +
                            Record.stStartupID +
                            ")"}
                      </Typography>
                      <Typography variant="h8" component="h8">
                        {keys["SubHeaderInfo"]}
                        <br />
                        {/* <i>{isUrlForCreateStartupFromBenef && "All informations are copied from Benef. profile"}</i>
                        {ShowLinkedRecords(Record)} */}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {!isPreviewOnly && (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonStyle}
                          onClick={() =>
                            dispatch(
                              !isSubSuhara
                                ? allActions.StartupActions.Save(Record)
                                : allActions.StartupActions.SaveSubSahara(
                                    Record
                                  )
                            )
                          }
                          disabled={dataReducer.Loading}
                        >
                          Save
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableCell>
          </TableRow>
        </TableHead>
        {InputDropDownCountries(
          classes,
          keys["Country"],
          "stCountryID",
          ScholarshipCountries,
          Record.stCountryID,
          handleChangeCountry,
          !isFieldEnabled,
          dataReducer.ValidationMessages,
          true,
          !isOfficer
        )}
        {/* //ovoj se polni pri change na Country vidi gore a inicijalno s'e vcituvaat site Provinces*/}
        {InputDropDown(
          classes,
          keys["Province"],
          "stProvinceID",
          ProvincesByCountry,
          Record.stProvinceID,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true
        )}
        {InputText(
          classes,
          keys["NameOfBusinessEnglish"],
          "stName",
          Record.stName,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true
        )}
        {InputText(
          classes,
          keys["OfficialNameBusiness"],
          "stOfficialNameBusiness",
          Record.stOfficialNameBusiness,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true
        )}
        {InputText(
          classes,
          keys["RegistryNumber"],
          "stRegistryNumber",
          Record.stRegistryNumber,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          false
        )}
        {InputText(
          classes,
          keys["Address"],
          "stAddress",
          Record.stAddress,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true
        )}
        {InputText(
          classes,
          keys["Email"],
          "stEmail",
          Record.stEmail,
          handleChangeEmail,
          !isEmailEnabled,
          dataReducer.ValidationMessages,
          true
        )}
        {InputText(
          classes,
          keys["City"],
          "stCity",
          Record.stCity,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true
        )}
        {InputText(
          classes,
          keys["MobilePhone"],
          "stMobilePhone",
          Record.stMobilePhone,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true
        )}
        {InputText(
          classes,
          keys["BusinessHomePhone"],
          "stHomePhone",
          Record.stHomePhone,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          false
        )}
        {/* {isSubSuhara &&
          InputText(
            classes,
            keys["Email"],
            "stEmail",
            Record.stEmail,
            handleChangeEmail,
            !isEmailEnabled,
            dataReducer.ValidationMessages,
            true
          )} */}
        {InputText(
          classes,
          keys["Web"],
          "stWeb",
          Record.stWeb,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          false
        )}
        {/* {isSubSuhara &&
          InputText(
            classes,
            keys["MobilePhone"],
            "stMobilePhone",
            Record.stMobilePhone,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )} */}
        {/* {isSubSuhara &&
          InputText(
            classes,
            keys["BusinessHomePhone"],
            "stHomePhone",
            Record.stHomePhone,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            false
          )} */}
        {InputDropDownCheckboxLookup(
          classes,
          keys["Sector"],
          "stSectorIDs",
          LookupData,
          constants.Lookup_SectorOfBusiness,
          Record.stSectorIDs,
          handleChangeMulti,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true
        )}

        {!isSubSuhara &&
          InputDropDownLookup(
            classes,
            keys["InitialBusinessStage"],
            "stInitStageID",
            LookupData,
            constants.Lookup_IniitialBusinessStage,
            Record.stInitStageID,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {!isSubSuhara &&
          InputDropDownLookup(
            classes,
            keys["CurrentBusinessStatus"],
            "stCurrentBusinessStatusID",
            LookupData,
            constants.Lookup_CurrentBusinessStatus,
            Record.stCurrentBusinessStatusID,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {isSubSuhara &&
          InputDropDownLookup(
            classes,
            keys["CurrentBusinessStatus"],
            "stCurrentBusinessStatusID",
            LookupData,
            constants.Lookup_CurrentBusinessStatusSubSahara,
            Record.stCurrentBusinessStatusID,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {InputText(
          classes,
          keys["InitFullEmployees"],
          "stInitFullEmployees",
          Record.stInitFullEmployees,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true
        )}
        {isSubSuhara &&
          InputText(
            classes,
            keys["CurrFullEmployees"],
            "stCurrentNumberFullEmployees",
            Record.stCurrentNumberFullEmployees,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {isSubSuhara &&
          InputText(
            classes,
            keys["CurrPartEmployees"],
            "stCurrentNumberPartEmployees",
            Record.stCurrentNumberPartEmployees,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {isSubSuhara &&
          InputText(
            classes,
            keys["CurrentNumberOfAgents"],
            "stCurrentNumberOfAgents",
            Record.stCurrentNumberOfAgents,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {isSubSuhara &&
          InputText(
            classes,
            keys["TotalCapitalAmountInvestedText"],
            "stNetProfit",
            Record.stNetProfit,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {!isSubSuhara &&
          InputText(
            classes,
            keys["InitPartEmployees"],
            "stInitPartEmployees",
            Record.stInitPartEmployees,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {InputDate(
          classes,
          keys["StartDate"],
          "stStartDate",
          Record.stStartDate,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          true
        )}
        {InputDate(
          classes,
          keys["RegistrationDate"],
          "stRegistrationDate",
          Record.stRegistrationDate,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          false
        )}
        {!isSubSuhara &&
          InputDropDownLookup(
            classes,
            keys["BusinessOperationLocation"],
            "stBusinessOperationLocationID",
            LookupData,
            constants.Lookup_BusinessOperationLocation,
            Record.stBusinessOperationLocationID,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {/* {InputDropDownLookup(classes, keys["BussinessFinancial"], "stBussinessFinancialID", LookupData, constants.Lookup_BussinessFinancial, Record.stBussinessFinancialID, handleChange, dataReducer.Loading, dataReducer.ValidationMessages, true)} */}
        {!isSubSuhara &&
          InputDropDownCheckboxLookup(
            classes,
            keys["BussinessFinancial"],
            "stBussinessFinancialIDs",
            LookupData,
            constants.Lookup_BussinessFinancial,
            Record.stBussinessFinancialIDs,
            handleChangeMulti,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            false
          )}

        {isSubSuhara &&
          InputDropDownLookup(
            classes,
            keys["SubSector"],
            "stSubSectorID",
            LookupData,
            constants.Lookup_SubSector,
            Record.stSubSectorID,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {isSubSuhara &&
          InputText(
            classes,
            keys["BDAName"],
            "stBDAName",
            Record.stBDAName,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}

        {isSubSuhara &&
          InputText(
            classes,
            keys["AnnualTurnoverText"],
            "stCurrTurnover",
            Record.stCurrTurnover,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {/* {isSubSuhara &&
          InputDropDownLookup(
            classes,
            keys["TrainingParticipationText"],
            "stAttendedTrainingID",
            LookupData,
            constants.Lookup_AttendedTraining,
            Record.stAttendedTrainingID,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )} */}
        {isSubSuhara &&
          InputText(
            classes,
            keys["AttendedTrainingText"],
            "stAttendedTrainingID",
            Record.stAttendedTrainingID,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {isSubSuhara &&
          InputDropDownLookup(
            classes,
            keys["BusinessKeepingRecordText"],
            "stBusinessKeepingRecordID",
            LookupData,
            constants.Lookup_BusinessKeepingRecord,
            Record.stBusinessKeepingRecordID,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {isSubSuhara &&
          InputText(
            classes,
            keys["NumberSessionsToCompleteText"],
            "stNumSessionComplete",
            Record.stNumSessionComplete,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {/* {isSubSuhara &&
          InputDropDownLookup(
            classes,
            keys["CoachingParticipationText"],
            "stBusinessKeepingRecordID",
            LookupData,
            constants.Lookup_BusinessKeepingRecord,
            Record.stBusinessKeepingRecordID,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )} */}

        {isSubSuhara &&
          InputText(
            classes,
            keys["NumberCoachingParticipationText"],
            "stNumSessionGrow",
            Record.stNumSessionGrow,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {isSubSuhara &&
          InputDropDownLookup(
            classes,
            keys["StatusBusinessPlanText"],
            "stBusinessPlanStatusID",
            LookupData,
            constants.Lookup_BusinessPlanStatus,
            Record.stBusinessPlanStatusID,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {isSubSuhara &&
          InputDropDownLookup(
            classes,
            keys["RequestLoanStatusRUFIText"],
            "stLoanStatusID",
            LookupData,
            constants.Lookup_LoanStatus,
            Record.stLoanStatusID,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {isSubSuhara &&
          InputText(
            classes,
            keys["AmountAplliedText"],
            "stAmountApllied",
            Record.stAmountApllied,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            false
          )}
        {isSubSuhara &&
          InputDate(
            classes,
            keys["YMonthAppliedForLoan"],
            "stYMonthAppliedForLoan",
            Record.stYMonthAppliedForLoan,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            false
          )}
        {isSubSuhara &&
          InputText(
            classes,
            keys["AmountAccessedText"],
            "stAmountAccessed",
            Record.stAmountAccessed,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            false
          )}
        {isSubSuhara &&
          InputDate(
            classes,
            keys["YMonthLoanWasReceived"],
            "stYMonthLoanWasReceived",
            Record.stYMonthLoanWasReceived,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            false
          )}
        {isSubSuhara &&
          InputDropDownLookup(
            classes,
            keys["HaveBankAccount"],
            "stHaveBankAccountID",
            LookupData,
            constants.Lookup_HaveBankAccount,
            Record.stHaveBankAccountID,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {isSubSuhara &&
          InputDropDownLookup(
            classes,
            keys["IsLegallyRegistered"],
            "stIsLegallyRegistered",
            LookupData,
            constants.Lookup_IsLegallyRegistered,
            Record.stIsLegallyRegistered,
            handleChange,
            dataReducer.Loading,
            dataReducer.ValidationMessages,
            true
          )}
        {InputTextMultiLine(
          classes,
          "Comment",
          "stNote",
          Record.stNote,
          handleChange,
          dataReducer.Loading,
          dataReducer.ValidationMessages,
          false
        )}

        {/* {!isUser && PanelCredentials(Record, dataReducer.ValidationMessages)}; */}
        <TableRow>
          <TableCell colspan="2" align="right">
            {!isPreviewOnly && (
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
                onClick={() =>
                  dispatch(
                    //allActions.StartupActions.Save(Record)
                    !isSubSuhara
                      ? allActions.StartupActions.Save(Record)
                      : allActions.StartupActions.SaveSubSahara(Record)
                  )
                }
                disabled={dataReducer.Loading}
              >
                Save
              </Button>
            )}
          </TableCell>
        </TableRow>
        {/* <TableRow>
          <TableCell colspan={2}>
            {ShowLinkedRecords(Record)}
          </TableCell>
        </TableRow> */}
      </Table>

      {/* <PleaseWaitMessage {...dataReducer} /> */}
      <AlertMessage {...dataReducer} />
    </TableContainer>
  );
}
