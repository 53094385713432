import React, { useState, useEffect,useCallback, useMemo } from 'react';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Radio } from '@material-ui/core';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PreviewService from '../../services/PreviewService.js';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import * as PreviewComponents from '../Shared/PreviewComponents';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { logHelper } from '../../helpers/log.helper';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 348,
  },
  formElement: {},
  formLabel: {
    color: '#aaaaa9',
    marginTop: 10,
    '&:hover': {
      background: '#F3F3F2',
    }
  },
  header: {
    backgroundColor: '#F3F3F2',
    borderRadius: 4,
    paddingLeft: 5,
  },
  subHeader: {
    marginTop: 15,
    marginBottom: 1,
    fontWeight: 700
  },
  buttonStyle: {
    //margin: theme.spacing(1),
    textTransform: 'none',
  },
  xs12: {
    width: '50%',
  },
  columnLabel: { width: '10%' },
  columnInput: { width: '70%' },
}));






function CustomText({controlLabel, controlName, controlValue, handleChange, memo}) {
  logHelper.log(memo ? "MEMO-" + controlName : "REGULAR-" + controlName);
  return (

    <TableRow>
      <TableCell component="th" scope="row"  >
        {controlLabel}
        <Tooltip title={`Enter your ${controlLabel}`} arrow placement="top">
          <InfoOutlinedIcon color="action" fontSize='small' />
        </Tooltip>
      </TableCell>
      <TableCell component="th" scope="row" >
        <TextField
          margin="dense"
          id={controlName}
          name={controlName}
          type="text"
          fullWidth
          variant="outlined"
          value={controlValue}
          onChange={handleChange}
        />
      </TableCell>
    </TableRow >
  )
}
const CustomTextMemo = React.memo(CustomText);

function Movie({ title, releaseDate, memo }) {
  logHelper.log(`${memo ? "<MemoizedMovie>" : "<Movie>"} rendered`);
  return (
    <div>
      <div>Movie title: {title}</div>
      <div>Release date: {releaseDate}</div>
    </div>
  );
}



const MemoizedMovie = React.memo(Movie);

export default function ClassicPreview(args) {

  const classes = useStyles();

  //inicijalno stavi go samo kako niza od objekti[]
  const [EntityRecords, setControl] = useState(null);
  const [jsonData, setJsonData] = useState(null);

  //Handle Change za Text Box, Select,  i Date Time komponentit
  //https://nikgrozev.com/2019/04/07/reacts-usecallback-and-usememo-hooks-by-example/
  const handleChange = useCallback(e => {
    logHelper.log("od handle change");
    logHelper.log(e.target);
    const { name, value } = e.target;
    logHelper.log("value vo handle");
    logHelper.log(value);
    setControl(prevState => ({ ...prevState, [name]: value }));
  });

  //Handle Change za Multiple Select komponeti
  const handleChangeMultiple = event => {
    logHelper.log("AAAAAAA");
    logHelper.log(event.target);

    // https://material-ui.com/components/selects/
    const { name, options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        //logHelper.log("SELECTED:" + options[i].value)
        value.push(options[i].value);
      }
    }
    setControl(prevState => ({ ...prevState, [name]: value }));
  };

  //Event: 1 --> Initial Render
  //Default Event Handler koj sto se pali inicijalno, odi na server i ja zima JSON Strukturata
  //i ja stava vo SetState --> jsonData 
  useEffect(() => {

    //procitaj prvo od URL http://localhost:3000/Preview/6200 dali ima primerno: 6200
    //ako nema togas setiraj racno 1156
    let TableId = args.match.params.id;
    if (!TableId) TableId = 1156;
    const payLoad = { EntityId: TableId }  //1060: Startup

    //povikaj servis za renderiranje na data
    PreviewService.Load(payLoad).then((response) => {
      let jsonData = response.data;
      logHelper.log(jsonData);
      setJsonData(jsonData);
      // jsonData = response.data;
      // logHelper.log(response.data);  
    });
  }, []);   //default

  //Event: 2 --> RenderState
  //Od koga ke se napolni SetState --> jsonData 
  //se proveruva dali e polna i togas se polni SetState --> EntityRecords
  useEffect(() => {
    if (jsonData) {
      RenderState();
      // {
      //   FirstName: "xxxx",
      //   LastName: "xxxx",
      // }
    }
  }, jsonData);



  function DoSave() {

    //TODO: napravi validacija na polinjata ili pak nosi na server pa tamu pravi validacija
    // Here we build the form's groups elements dynamically
    jsonData.Elements.map(control => {
      //najdi go value-to na element-ot od jsonData vo State: EntityRecords
      const currentValue = EntityRecords[control.Name];

      //pred da odi na server od NIZA vrati go vo join lista so "," so ogled deka ne ja  parsira [] nizata vo objekt t.e. vraka NULL za cel jsonData
      if (control.DataType === 'Pick List (Multi-Select)') {
        let value = "";
        for (let i = 0, l = currentValue.length; i < l; i += 1) {
          if (currentValue[i].selected) {
            //logHelper.log("SELECTED:" + options[i].value)
            if (value === "") {
              value = currentValue[i].value;
            }
            else {
              value += "," + currentValue[i].value;
            }
          }
        }
        control.Value = value;
      }
      else {
        //i setiraj go vo jsonData za soodvetniot element za da go prakas na server za snimanje
        control.Value = currentValue;
      }
    });

    let payLoad = JSON.stringify(jsonData);
    //povikaj servis za renderiranje na data
    PreviewService.Add(payLoad).then((response) => {
      let jsonData = response.data;
      logHelper.log(jsonData);
      setJsonData(jsonData);
      // jsonData = response.data;
      // logHelper.log(response.data);  
    });
  }


  function RenderHeaders() {
    // Here we build the form's groups elements dynamically
    return jsonData.Headers.map(control => {
      if (control.DataType === "Header") {
        return PreviewComponents.CustomHeader(control);
      }
      else if (control.DataType === "SubHeader") {
        return PreviewComponents.CustomSubHeader(control);
      };
    })
  }


  function RenderState() {
    let Records = [];
    jsonData.Elements.map(control => {
      Records = { ...Records, [control.Name]: control.Value }
    })
    logHelper.log(Records);

    //NAPOLNI GO STATE-OT ZA EntityRecords so Records (gornata niza)
    setControl(Records);
  }

  function RenderSchema() {
    return (
      <React.Fragment>
        <input type="text" id="TableName" value={jsonData.Schema.TableName} />
        <input type="text" id="Action" value={jsonData.Schema.Action} />
        <input type="text" id="PrimaryKeyName" value={jsonData.Schema.PrimaryKeyName} />
        <input type="text" id="PrimaryKeyValue" value={jsonData.Schema.PrimaryKeyValue} />
      </React.Fragment>
    )
  }

  function RenderRelations() {
    return jsonData.Relations.map(relation => {
      return PreviewComponents.CustomRelation(relation);
    })
  }


  function RenderElements() {

    // Here we build the form's groups elements dynamically
    return jsonData.Elements.map(control => {

      //najdi ja database vrednosta na HTML Element-ot
      const currentValue = EntityRecords[control.Name];

      //return PreviewComponents.CustomLabel(classes, control, currentValue, handleChange)
      //handleChange e samiot event handler koj sto ke slusa za promeni

      if (control.DataType === 'Pick List') {
        return PreviewComponents.CustomPickList(classes, control, currentValue, handleChange);
      }
      else if (control.DataType === 'Pick List (Multi-Select)') {
        return PreviewComponents.CustomPickListMultiple(classes, control, currentValue, handleChangeMultiple);
      } else if (control.DataType === 'DateTime' || control.DataType === 'Date' || control.DataType === 'Time') {
        return PreviewComponents.CustomDatePicker(classes, control, currentValue, handleChange);
      } else if (control.DataType === "CheckBox") {
        return PreviewComponents.CustomCheckBox(classes, control, currentValue, handleChange);
      } else if (control.DataType === "RadioBox") {
        return PreviewComponents.CustomRadioBox(classes, control, currentValue, handleChange);
      }
      else if (control.DataType === "Nvarchar" || control.DataType === "Text" ||
        control.DataType === "Number" || control.DataType === "Email") {
          return PreviewComponents.CustomText(classes, control, currentValue, handleChange);
          // if (control.Name === "FirstName")
          // { 
          //   return <CustomTextMemo controlLabel={control.Label} controlName={control.Name} controlValue={control.Value}  handleChange={handleChange} memo={true}/>
          // }
          // else {
          //   return <CustomText controlLabel={control.Label} controlName={control.Name} controlValue={control.Value} handleChange={handleChange} memo={false}/>
          // }

          // if (control.Name === "FirstName")
          // {
          //   return <MemoizedMovie title="Heat" releaseDate="December 15, 1995" memo={true}/>
          // }
          // return <Movie title="Heat" releaseDate="December 15, 1995" memo={false}/>
           
         //const MemoizedMovie = React.memo(PreviewComponents.CustomText); //(classes, control, currentValue, handleChange);
          // const MemoizedMovie2 = React.memo(MemoizedMovie);
          // return MemoizedMovie2;
          //return MemoizedMovie(classes, control, currentValue, handleChange);
          //return <MemoizedMovie title="Heat" releaseDate="December 15, 1995" memo={true}/>
      }
    })
  }


  //EntityRecords e state-ot na recorditite
  //jsonData e samiot content od server
  if (!jsonData || !EntityRecords) {
    return <span>Please wait</span>;
  }

  return (
    <Grid container spacing={2} justify="center" alignItems="center" direction="column" xs>
      <Grid item className={classes.xs12}>


        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table" style={{ width: "100%", tableLayout: "auto" }}>

            {/* Renderiraj HEADER */}
            {
              RenderHeaders()
            }

            {/* Renderiraj HTML ELEMENTI */}
            {
              RenderElements()
            }

            <TableRow>

              <TableCell>
                <Button variant="contained" color="primary" className={classes.buttonStyle}
                  onClick={() => DoSave()}
                >
                  Save
                </Button>
              </TableCell>
              <TableCell>
                <Button variant="outlined" color="primary" className={classes.buttonStyle}>Cancel</Button>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colspan="2">
                {RenderRelations()}
              </TableCell>
            </TableRow>

          </Table>

        </TableContainer>

      </Grid>

    </Grid>
  );
}
