import ImportActivityService from './ImportActivityService'
import LoginService from './LoginService'
import LookupDataService from './LookupDataService'
import LookupService from './LookupService'
import LookupTypeService from './LookupTypeService'
import PreviewService from './PreviewService'
import RegisterService from './RegisterService'
import SmeEmployeeService from './SmeEmployeeService'
import SmeFounderService from './SmeFounderService'
import SmeService from './SmeService'
import StartupFounderService from './StartupFounderService'
import StartupService from './StartupService'
import UserProfileService from './UserProfileService'
import CountryService from './CountryService'
import SearchStartupService from './SearchStartupService'
import ManageUsersService from './ManageUsersService'
import ExportService from './Export/ExportService'
import SearchActivityService from './SearchActivityService'

const allServices = {
    CountryService,
    ImportActivityService,
    LoginService,
    LookupDataService,
    LookupService,
    LookupTypeService,
    PreviewService,
    RegisterService,
    SmeEmployeeService,
    SmeFounderService,
    SmeService,
    StartupFounderService,
    StartupService,
    UserProfileService,
    SearchStartupService,
    ManageUsersService,
    ExportService,
    SearchActivityService
}

export default allServices



