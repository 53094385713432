import { restHelper } from '../helpers/rest.helper';

const GetOverAllResults = async () => {
    let response = await restHelper.post('api/Report/OverAllResults', null);
    return response;
}

const GetQuarterResults = async (args) => {
    let response = await restHelper.post('api/Report/QuarterResults', args);
    return response;
}

export default {
    GetOverAllResults,
    GetQuarterResults
}