
//sekoe novo properti dodadi go ovde so DEFAULT value
const initialState = {
    Message: '',
    Loading: false,
    hasError: false
}

const LoginUserReducer = (state = initialState, action) => {

    switch (action.type) {
        case "LOGIN_START":
            return {
                ...state,
                Message: '',    //za da izbrise prethoden error ako go imalo
                Loading: true,
                hasError: false
            }
        case "LOGIN_ERROR":
            return {
                ...state,
                Message: action.error,
                Loading: false,
                hasError: true
            }
        case "ALERT":
            return {
                ...state,
                Message: '',
                hasError: false
            }
        //se koristi vo find
        case "LOGIN_RESET":
            state = initialState; //go resetira na iniital state
            return {
                ...state
            }            
        default:
            return state
    }
}
export default LoginUserReducer;