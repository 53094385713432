import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Axios from "axios";
import * as AuthenticationHelper from "../../helpers/authentication.helper";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import allActions from "../../actions";
import { history } from "../../helpers";
import constants from "../../helpers/constant.helper";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Keys from "../../json/ResourceStartup.json";
import {
  InputDropDownPagination,
  InputDropDownCheckboxLookupOnlyWithEmailPathFilter,
  InputDate2,
  InputDate,
  InputTextOnly,
  InputDropDownOnly2,
  InputDropDownOnly,
  InputDropDownLookupOnly,
  InputDateOnly,
  InputDropDownCheckboxLookupOnly,
} from "../Shared/PreviewComponents";
import Select from "@material-ui/core/Select";
import CustomModal from "../Shared/CustomModal";
import ExcelActivitiesExport from "../Shared/ExcelActivitiesExport";
import { logHelper } from "../../helpers/log.helper";
import * as localStorageHelper from "../../helpers/localstorage.helper";
import AlertMessageNew from "../AlertMessageNew";
import { TableFooter } from "@material-ui/core";

// DEFINIRANJE NA STILOVI
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    flexGrow: 1,
  },
  media: {
    height: 140,
  },
  table: {
    minWidth: 650,
    overflow: "hidden",
  },
}));

const RecordDefault = {
  StartupID: "",
  ItemGUID: "",
  OwnerID: "", //ovoj e KLUC za od admin ili officers da bira raznlicni startups
  TypeID: "",
  CountryID: "0",
  ProvinceID: "0",
  Name: "",
  RegistryNumber: "",
  Address: "",
  City: "",
  MobilePhone: "",
  HomePhone: "",
  Email: "",
  Web: "",
  SectorID: "0",
  StartDate: "2020-06-26",
  RegistrationDate: "2020-06-26",
  InitStageID: "0",
  ProvinceOperationID: "0",
  CurrentStatusID: "0",
  CurrentStageID: "0",
  DateCreated: "2020-06-26",
  CreatedBy: "",
  InitFullEmployees: "",
  InitPartEmployees: "",
  FirstName: "",
  LastName: "",
  SectorIDs: "",
  ActivityCategoryIDs: "",
  ActivityTypeIDs: "",
  DonorIDs: "",
  DonorParentIDs: "",
  DonorReportIDs: "",
  acFrom: "",
  acTo: "",
  acDonorFundNames: "",
  acDonorReportNames: "",

  // 834	1012	Startups with scholarships (from SIS)
  // 835	1012	Startups without scholarships
  HasScholarshipFL: "0",

  PageSize: 50,
  PageIndex: 0,
  TotalPages: 0,
  TotalRecords: 0,
  TotalUniqueProfile: 0,
};

export default function SearchActivity(args) {
  const keys = Keys[0]; //za da naoga textovi od JSON FIle
  const dispatch = useDispatch();
  const classes = useStyles();

  const dataReducer = useSelector((state) => state.SearchActivityReducer);

  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);
  const [Records, setRecords] = useState([]);
  const [ScholarshipCountries, setScholarshipCountries] = useState([]);
  const [Nationalities, setNationalities] = useState([]);
  const [Partners, setPartners] = useState([]);
  const [IsCheckedFLAll, setIsCheckedFLAll] = useState(false);

  const [ModalParams, setModalExport] = useState({
    ShowModal: false,
    Content: "",
    SelectedExportValue: 0,
  }); //ModalParams e objekt koj sto IsOpen i IsContent ima struktura

  // const [Provinces, setProvinces] = useState([]);
  // const [ProvincesByCountry, setProvincesByCountry] = useState([]);
  //const [StartupTypes, setStartupTypes] = useState([]);
  //const [LookupData, setLookupData] = useState([]);

  // const [MessageData, setMessageData] = useState({});
  // const [Record, setRecord] = useState(RecordDefault);
  // const [Records, setRecords] = useState([]);

  // const [ScholarshipCountries, setScholarshipCountries] = useState(dataReducer.ScholarshipCountries);
  // const [Partners, setPartners] = useState(dataReducer.Partners);
  // const [Nationalities, setNationalities] = useState(dataReducer.Nationalities);
  //const [IsCheckedFLAll, setIsCheckedFLAll] = useState(false);

  const isOfficer = AuthenticationHelper.IsOfficer(); //mu treba za drop down za Country da mu ja dade samo negovata zemja i vo DropDownCountries da ne pokazuva Select Countries
  const isBTSOfficer = AuthenticationHelper.IsBTSOfficer(); //mu treba za drop down za Partners
  const isDonor = AuthenticationHelper.IsDonor(); //mu treba za drop down za Partners

  //const [ModalParams, setModalExport] = useState({ ShowModal: false, Content: "", SelectedExportValue: 0 }); //ModalParams e objekt koj sto IsOpen i IsContent ima struktura

  const [Provinces, setProvinces] = useState([]);
  const [ProvincesByCountry, setProvincesByCountry] = useState([]);
  const [StartupTypes, setStartupTypes] = useState([]);
  const [LookupData, setLookupData] = useState([]);
  const [LookupActivityTypes, setActivityTypes] = useState([]);
  const [TotalUniqueProfile, setTotalUniqueProfile] = useState(0);

  const lookupTypeIDs = [
    constants.Lookup_SectorOfBusiness,
    constants.Lookup_PersonActivity,
    constants.Lookup_PersonActivityRole,
    constants.Lookup_FundedBy,
    constants.Lookup_ActivityAttendance,
    constants.Lookup_FundedBy,
    constants.Lookup_ParentDonor,
    constants.Lookup_ParentDonorReport,
    constants.Lookup_StartupWithScholarships,
  ];

  const initData = (args) => {
    async function fetchData() {
      //proveri dali ja ima vo cache
      const storageRecord = localStorageHelper.GetJSON(
        constants.SearchStartupsArgs_LocalStorageKey
      );
      if (storageRecord) {
        setRecord(storageRecord);
      }

      setMessageData({ Loading: true });

      let responses = await allActions.SearchActivityActions.InitAsync(Record);
      if (!responses.error) {
        const LookupDataRecords = responses[0].data;
        const CountriesScholarhipsRecords = responses[1].data;
        const CountriesProvincesRecords = responses[2].data;
        const CountriesNationalitiesRecords = responses[3].data;
        const PartnerDataRecords = responses[4].data;

        setLookupData(LookupDataRecords);
        setScholarshipCountries(CountriesScholarhipsRecords);
        setNationalities(CountriesNationalitiesRecords);
        setPartners(PartnerDataRecords);

        if (isOfficer) {
          const countryId = CountriesScholarhipsRecords[0].Value;

          const filterProvinceByCountry = CountriesProvincesRecords.filter(
            (a) => a.ParentId === countryId.toString()
          );
          setProvincesByCountry(filterProvinceByCountry);

          Record.CountryID = countryId;
          setRecord(Record);
        } else {
          setProvincesByCountry(CountriesProvincesRecords);
          setProvinces(CountriesProvincesRecords);
        }

        setMessageData({ Loading: false });
      } else {
        setMessageData({
          HasError: true,
          Message: responses.error_description,
        });
      }
    }

    fetchData();
  };

  const searchData = (args) => {
    async function fetchData() {
      setRecords([]);
      setMessageData({ Loading: true });

      let responses =
        await allActions.SearchActivityActions.AdvancedSearchAsync(Record);
      if (!responses.error) {
        const StartupRecords = responses[0].data.Results;
        const ArgsData = responses[0].data.Args; //go vraka samiot record nazad na clietn

        if (ArgsData.StartupID === 0) {
          ArgsData.StartupID = "";
        }

        //stavi gi vo cache za sledno citanje site argumenti ako e Benef / Startup / SME
        localStorageHelper.SetJSON(
          constants.SearchStartupsActivityArgs_LocalStorageKey,
          ArgsData
        );

        // const uniqueSales = [...new Set(StartupRecords.map(item => item.stStartupID))];
        // setTotalUniqueProfile(uniqueSales.length);

        setMessageData({ Loading: false });

        setRecords(StartupRecords);
        setRecord(ArgsData);
      } else {
        setMessageData({
          HasError: true,
          Message: responses.error_description,
        });
      }
    }

    fetchData();
  };

  const goToNextPage = () => {
    resetModalDialog();

    Record.PageIndex = parseInt(Record.PageIndex) + 1;
    setRecord(Record);
    searchData();
  };

  const goToPrevPage = () => {
    resetModalDialog();
    Record.PageIndex = parseInt(Record.PageIndex - 1);
    setRecord(Record);
    searchData();
  };

  useEffect(() => {
    initData();
    return () => {};
  }, []); //default

  const DoCheckUnCheck = (row, checked, propertyIndex) => {
    //alert(newArr.length);
    let newRecords = [...Records]; // copying the old datas array
    newRecords[propertyIndex]["IsCheckedFL"] = checked;
    setRecords(newRecords);
  };

  const DoCheckUnCheckAll = (checked) => {
    //alert(newArr.length);
    let newRecords = [...Records]; // copying the old datas array
    for (let i = 0; i <= newRecords.length - 1; i++) {
      newRecords[i]["IsCheckedFL"] = checked;
    }
    setRecords(newRecords);
    setIsCheckedFLAll(checked);
  };

  const handleChangeExport = (e) => {
    const { name, value } = e.target; //name: SelectedExportValue

    var index = e.nativeEvent.target.selectedIndex;
    var currentOptionText = e.nativeEvent.target[index].text;

    if (parseInt(value) === 4) {
      //daj gi site stiklirani items
      //const RecordsChecked = Records;

      //logHelper.log(RecordsChecked);
      //pripremi argumenti za Send Email
      //let payload = { Records: RecordsChecked, Header: currentOptionText };  //vo SendEmail odgovara Records na args.Records

      //Vo Record ke ima
      //model.ActivityIDs = String.Join(",", output.Select(a => a.acStartupActivityID)); //mu treba za EXPORT NA AKTIVNOSTI posle
      let controlSendSMS = <ExcelActivitiesExport {...Record} />;

      //pokazi POPUP so Send Email
      setModalExport({
        ShowModal: true,
        Content: controlSendSMS,
        [name]: value,
      });
    } else {
      resetModalDialog();
    }
  };

  const resetModalDialog = () => {
    setModalExport({ ShowModal: false, Content: "", SelectedExportValue: 0 });
  };
  const handleChange = (e) => {
    if (e.key === "Enter") {
      doSearch();
      return;
    }

    logHelper.log("od handle change");
    logHelper.log(e.target);
    const { name, value } = e.target;
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeMulti = (controlId, options) => {
    logHelper.log(options);
    logHelper.log(controlId);
    let selectValues = "";

    options.map((option) => {
      const selectedValue = option.ldLookupDataID.toString();
      if (selectValues === "") {
        selectValues = selectedValue;
      } else {
        selectValues += "," + selectedValue;
      }
    });

    setRecord((prevState) => ({ ...prevState, [controlId]: selectValues }));
  };

  const handleChangeMultiActivity = (controlId, options) => {
    logHelper.log("JAAA SUMMMMMM");
    logHelper.log(options);
    logHelper.log(controlId);
    let selectValues = "";

    let filterActivitiesTypes = [];
    //od niza pretvara vo sting
    options.map((option) => {
      const selectedValue = option.ldLookupDataID.toString();

      if (selectValues === "") {
        selectValues = selectedValue;
      } else {
        selectValues += "," + selectedValue;
      }

      //za vtoriot drop down za selektiranata vrednost najdi ITEMS i stavi gi vo ARRAY za da mozes doli da go mapiras
      const items = LookupData.filter(
        (a) =>
          a.ldTypeID === constants.Lookup_PersonActivityRole &&
          a.ldEmailPath === 284
      );
      if (items) {
        filterActivitiesTypes.push(...items);
      }
    });

    //const filterActivitiesTypes = LookupData.filter(a => a.ldTypeID === constants.Lookup_PersonActivityRole && a.ldEmailPath === value);
    setRecord((prevState) => ({
      ...prevState,
      [controlId]: selectValues, //SENDERot koj sto ja vika controlata
      ["ActivityTypeIDs"]: "",
    })); //da se resetira province-ot za validacija i toa vo "0" za da raboti ne 0
    setActivityTypes(filterActivitiesTypes);
  };

  const handleChangeCountry = (e) => {
    if (e.key === "Enter") {
      dispatch(allActions.SearchActivityActions.AdvancedSearch(Record));
      return;
    }

    logHelper.log("od handle change");
    logHelper.log(e.target);
    const { name, value } = e.target; //value ==> CountryId

    logHelper.log("value vo handle");
    logHelper.log(value);

    const filterProvinceByCountry = Provinces.filter(
      (a) => a.ParentId === value.toString()
    );

    //da se resetira province-ot za validacija i toa vo "0" za da raboti ne 0
    setProvincesByCountry(filterProvinceByCountry);
    setRecord((prevState) => ({
      ...prevState,
      [name]: value,
      ["ProvinceID"]: "0",
    }));
  };

  const onModalClose = () => {
    resetModalDialog();
  };

  const handleChangeCategory = (e) => {
    logHelper.log("od handle change");
    logHelper.log(e.target);
    const { name, value } = e.target; //value ==> CountryId

    logHelper.log("value vo handle");
    logHelper.log(value);

    const filterActivitiesTypes = LookupData.filter(
      (a) =>
        a.ldTypeID === constants.Lookup_PersonActivityRole &&
        a.ldEmailPath === value
    );
    setRecord((prevState) => ({
      ...prevState,
      [name]: value,
      ["acActivityTypeID"]: "0",
    })); //da se resetira province-ot za validacija i toa vo "0" za da raboti ne 0
    setActivityTypes(filterActivitiesTypes);
  };

  const EditRecord = (ItemGUID, stTypeID, isPreview) => {
    //setiraj ITEM GUID za selektiraniot START UP
    AuthenticationHelper.SetStartupPreview(isPreview);
    AuthenticationHelper.SetStartUpItemGUID(ItemGUID);

    if (stTypeID === constants.TYPEID_BENEFECIARY) {
      history.push("/admin/benef/manage");
    } else if (stTypeID === constants.TYPEID_STARTUP) {
      history.push("/admin/startup/manage");
    } else if (stTypeID === constants.TYPEID_SME) {
      history.push("/admin/sme/manage");
    }
  };

  const doSearch = () => {
    resetModalDialog();

    //resetiraj index
    Record.PageIndex = 0;
    Record.TotalPages = 0;
    Record.TotalRecords = 0;
    setRecord(Record);
    searchData();
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell colSpan="15">
              <AlertMessageNew {...MessageData} />
              <h1>Search for Activities</h1>
            </TableCell>
            <TableCell colSpan="2" align="right">
              {InputDropDownPagination(
                "PageSize",
                LookupData,
                constants.Lookup_Pagination,
                Record.PageSize,
                handleChange,
                MessageData.Loading,
                ""
              )}
              <span> </span>

              <Button
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
                onClick={doSearch}
                disabled={MessageData.Loading}
              >
                Search
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell colspan={17}>
              <Table style={{ width: "100%", tableLayout: "auto" }}>
                <TableRow>
                  <TableCell>
                    {InputDropDownOnly(
                      "CountryID",
                      ScholarshipCountries,
                      Record.CountryID,
                      handleChangeCountry,
                      "- All Countries -",
                      !isOfficer
                    )}
                  </TableCell>
                  <TableCell>
                    {InputDropDownOnly(
                      "ProvinceID",
                      ProvincesByCountry,
                      Record.ProvinceID,
                      handleChange,
                      "- All Provinces -",
                      true
                    )}
                  </TableCell>
                  <TableCell>
                    {InputDropDownOnly(
                      "PartnerID",
                      Partners,
                      Record.PartnerID,
                      handleChangeCountry,
                      "- All Partners -",
                      !isBTSOfficer
                    )}
                  </TableCell>

                  <TableCell colspan={2}>
                    {InputTextOnly(
                      "StartupID",
                      Record.StartupID,
                      handleChange,
                      "Search by SPARK ID",
                      MessageData.Loading
                    )}
                  </TableCell>
                  <TableCell colspan={2}>
                    {InputTextOnly(
                      "Email",
                      Record.Email,
                      handleChange,
                      "Search by Email",
                      MessageData.Loading
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    {InputDropDownLookupOnly(
                      "HasScholarshipFL",
                      LookupData,
                      constants.Lookup_StartupWithScholarships,
                      Record.HasScholarshipFL,
                      handleChange,
                      MessageData.Loading,
                      "- All Beneficiaries -"
                    )}
                  </TableCell>

                  <TableCell>
                    {InputTextOnly(
                      "MobilePhone",
                      Record.MobilePhone,
                      handleChange,
                      "Search by Phone",
                      MessageData.Loading
                    )}
                  </TableCell>

                  {/* {!isDonor && (
                    <TableCell>
                      {InputDropDownCheckboxLookupOnly(
                        "DonorIDs",
                        LookupData,
                        constants.Lookup_FundedBy,
                        Record.DonorIDs,
                        handleChangeMulti,
                        MessageData.Loading,
                        "- Funded by -"
                      )}
                    </TableCell>
                  )} */}
                  {!isDonor && (
                    <TableCell colspan={2}>
                      {InputDropDownCheckboxLookupOnly(
                        "DonorParentIDs",
                        LookupData,
                        constants.Lookup_ParentDonor,
                        Record.DonorParentIDs,
                        handleChangeMulti,
                        MessageData.Loading,
                        "- Funded by -"
                      )}
                    </TableCell>
                  )}
                  {!isDonor && (
                    <TableCell colspan={2}>
                      {InputDropDownCheckboxLookupOnly(
                        "DonorReportIDs",
                        LookupData,
                        constants.Lookup_ParentDonor,
                        Record.DonorReportIDs,
                        handleChangeMulti,
                        MessageData.Loading,
                        "- Reported to -"
                      )}
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>
                    {InputDropDownLookupOnly(
                      "Disability",
                      LookupData,
                      constants.Lookup_Disability,
                      Record.Disability,
                      handleChange,
                      MessageData.Loading,
                      "- Disability -"
                    )}
                  </TableCell>
                  <TableCell>
                    {InputDropDownOnly(
                      "Nationality",
                      Nationalities,
                      Record.Nationality,
                      handleChangeCountry,
                      "- Nationality -",
                      true
                    )}
                  </TableCell>
                  <TableCell>
                    {InputDropDownLookupOnly(
                      "Gender",
                      LookupData,
                      constants.Lookup_Gender,
                      Record.Gender,
                      handleChange,
                      MessageData.Loading,
                      "- Gender -"
                    )}
                  </TableCell>
                  <TableCell>
                    {InputDate2(
                      classes,
                      "Start Date",
                      "acFrom",
                      Record.acFrom,
                      handleChange,
                      MessageData.Loading,
                      dataReducer.ValidationMessages,
                      false
                    )}
                  </TableCell>
                  <TableCell>
                    {InputDate2(
                      classes,
                      "End Date",
                      "acTo",
                      Record.acTo,
                      handleChange,
                      MessageData.Loading,
                      dataReducer.ValidationMessages,
                      false
                    )}
                  </TableCell>
                  <TableCell colspan={2}>
                    {InputDropDownCheckboxLookupOnlyWithEmailPathFilter(
                      "ActivityTypeIDs",
                      LookupData,
                      constants.Lookup_PersonActivityRole,
                      "284",
                      "946",
                      Record.ActivityTypeIDs,
                      handleChangeMulti,
                      MessageData.Loading,
                      "- All Service Types -"
                    )}
                  </TableCell>
                </TableRow>
              </Table>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell colspan={15}>
              <b>
                Total Records found: {Record.TotalRecords}, unique SPARK IDs:{" "}
                {Record.TotalUniqueProfile}
              </b>
            </TableCell>
            <TableCell colspan={2} align="right">
              {!isDonor && (
                <Select
                  fullWidth
                  native
                  id="SelectedExportValue"
                  name="SelectedExportValue"
                  value={ModalParams.SelectedExportValue}
                  onChange={handleChangeExport}
                >
                  <option value={0}>- Select Option -</option>
                  <option value={4}>Export Activities to Excel</option>
                </Select>
              )}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell colspan={2}>
              <b>#</b>
              <br />
              <input
                type="checkbox"
                checked={IsCheckedFLAll}
                onClick={() => DoCheckUnCheckAll(!IsCheckedFLAll)}
              />
            </TableCell>
            <TableCell colspan={2}>
              <b>SparkID</b>
            </TableCell>
            <TableCell colspan={2}>
              <b>Full Name / Business Name</b>
            </TableCell>
            <TableCell>
              <b>Profile Type</b>
            </TableCell>
            <TableCell>
              <b>Partner</b>
            </TableCell>
            <TableCell colspan={2}>
              <b>Country</b>
            </TableCell>
            <TableCell colspan={2}>
              <b>Service Type</b>
            </TableCell>
            <TableCell colspan={3}>
              <b>Name</b>
            </TableCell>
            <TableCell>
              <b>Funded by</b>
            </TableCell>
            <TableCell>
              <b>Reported to</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {MessageData.Loading && <CircularProgress />}

          {Records.map((row, index) => (
            <TableRow key={row.acStartupActivityID}>
              <TableCell component="th" scope="row" colspan={2}>
                {Record.PageIndex * Record.PageSize + index + 1}
                <input
                  type="checkbox"
                  checked={row.IsCheckedFL}
                  onClick={() => DoCheckUnCheck(row, !row.IsCheckedFL, index)}
                />
              </TableCell>
              <TableCell colspan={2}>
                {!isDonor && (
                  // <Button
                  //   size="small"
                  //   color="primary"
                  //   onClick={() =>
                  //     EditRecord(
                  //       row.stStartupID,
                  //       row.stTypeID.toString(),
                  //       false
                  //     )
                  //   }
                  // >
                  //   {" "}
                  //   {row.stStartupID}
                  // </Button>
                  <Link
                    size="small"
                    color="primary"
                    onClick={() =>
                      EditRecord(
                        row.stStartupID,
                        row.stTypeID.toString(),
                        false
                      )
                    }
                  >
                    {" "}
                    {row.stStartupID}
                  </Link>
                  // <a
                  //   id="myLink"
                  //   target="_blank"
                  //   href="#"
                  //   onClick={() =>
                  //     EditRecord(
                  //       row.stStartupID,
                  //       row.stTypeID.toString(),
                  //       false
                  //     )
                  //   }
                  // >
                  //   {row.stStartupID}
                  // </a>
                )}
                {/* 
                {!isDonor && (
                  <Button
                    size="small"
                    color="primary"
                    onClick={() =>
                      EditRecord(row.stStartupID, row.stTypeID.toString(), false)
                    }
                  >
                    {" "}
                    Edit  
                  </Button>
                )} */}
              </TableCell>

              <TableCell colspan={2}>{row.StartupName}</TableCell>
              <TableCell>{row.StartupTypeName}</TableCell>
              <TableCell>{row.PartnerName}</TableCell>
              <TableCell colspan={2}>{row.peCountryOfResidencyName}</TableCell>
              <TableCell colspan={2}>{row.activityType} </TableCell>
              <TableCell colspan={3}>{row.acName}</TableCell>
              <TableCell>{row.acDonorFundNames}</TableCell>
              <TableCell>{row.acDonorReportNames}</TableCell>
            </TableRow>
          ))}
        </TableBody>

        {Record.TotalPages > 1 && (
          <TableFooter>
            <TableRow>
              <TableCell colspan={16} align="center">
                {Record.PageIndex > 0 && (
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => goToPrevPage()}
                  >
                    {" "}
                    &lt; Previous{" "}
                  </Button>
                )}
                {Record.TotalPages > Record.PageIndex + 1 && (
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => goToNextPage()}
                  >
                    {" "}
                    Next &gt;{" "}
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>

      <CustomModal
        IsOpen={ModalParams.ShowModal}
        Content={ModalParams.Content}
        handleModalClose={onModalClose}
      />
    </TableContainer>
  );
}
