import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import allActions from '../../actions';
import AlertMessage from '../AlertMessage'
import constants from '../../helpers/constant.helper';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableFooter } from '@material-ui/core';
import { history } from '../../helpers';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { logHelper } from '../../helpers/log.helper';
// DEFINIRANJE NA STILOVI
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  columnLabel: { width: '10%' },
  columnInput: { width: '70%' },
  spacer: { "padding-right": '20px' },

}));


export default function LookupDataManage(args) {

  const dispatch = useDispatch()
  const classes = useStyles();
  const dataReducer = useSelector(state => state.LookupDataReducer);
  

  //iniicjalno Record e prazen posle dole na useEffect ke se napolni
  const [Record, setRecord] = useState(dataReducer.Record);
  const [LookupType, setLookupType] = useState(dataReducer.LookupType);

  const isNewRecord = (Record.ldLookupDataID == 0);

  //sekogas mora da go ima pa so PROPTYPES ke treba da frlame exception so ogled deka kolonite se vrzani za toj TableID
  const LookupTypeId = args.match.params.lookupTypeId;

  
  const handleChange = e => {

    const { name, value } = e.target;  //vadi e.target.name i e.target.value kade sto value e vnesenata vrednost!
    logHelper.log(name + " : " + value);
    setRecord(prevState => ({ ...prevState, [name]: value,
                            ldTypeId: LookupTypeId //hardcodirano
    }));
  };
  
  //DEFAULT 
  useEffect(() => {
    //postecki record 
    if (args.match.params.lookupDataId) {
      const LookupDataId = args.match.params.lookupDataId;
      dispatch(allActions.LookupDataActions.Edit({"ldTypeID" : LookupTypeId, 
                                                  "ldLookupDataID": LookupDataId}));
    }
    else {
      
      dispatch(allActions.LookupDataActions.Create({"ldTypeID": LookupTypeId}));
    }
    //ako ne raboti direktno so toj od Reducer
  }, []);   //default


  //ke se zapali smao pri promena 
  useEffect(() => {
    setRecord(dataReducer.Record);
    setLookupType(dataReducer.LookupType);
  }, [dataReducer]);   //default


  return (

    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colspan="2">
              <AlertMessage {...dataReducer} />
              <h1>{LookupType.ltName} - {isNewRecord ? "Create Lookup Data" : "Manage Lookup Data"}</h1>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell colspan="3" align="right">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => dispatch(allActions.LookupDataActions.Save(Record))}
              >
                Save
          </Button>

              <label className={classes.spacer}></label>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => history.push('/lookupdata/find/' + LookupTypeId)}
              >
                List of Lookup Types
          </Button>


              <label className={classes.spacer}></label>

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isNewRecord}
                className={classes.submit}
                onClick={() => dispatch(allActions.LookupDataActions.Delete(Record))}
              >
                Delete
          </Button>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell className={classes.columnLabel}>Description</TableCell>
            <TableCell className={classes.columnInput}> <TextField name="ldValue" margin="dense" id="filled-basic" placeholder="Enter Name on English" type="text" fullWidth variant="outlined" onChange={handleChange} value={Record.ldValue} /></TableCell>
            <TableCell>Example: English language of the pick option</TableCell>
          </TableRow>


          <TableRow>
            <TableCell className={classes.columnLabel}>Description (arab)</TableCell>
            <TableCell className={classes.columnInput}> <TextField name="ldValue2" margin="dense" id="filled-basic" placeholder="Enter description on arabic" type="text" fullWidth variant="outlined" onChange={handleChange} value={Record.ldValue2} /></TableCell>
            <TableCell>Example: Arabic language of the pick option</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

  );
}
