import ExportService from '../../services/Export/ExportService'
import { validatorHelper, responseHelper, history } from '../../helpers';
import Keys from '../../json/ResourceStartup.json'

async function SendEmailAsync(Record) {
    let responseEntity = await ExportService.SendEmail(Record);
    let responses = [responseEntity];
    return responseHelper.validateResponses(responses);
};

async function SendSMSAsync(Record) {
    let responseEntity = await ExportService.SendSMS(Record);
    let responses = [responseEntity];
    return responseHelper.validateResponses(responses);
};


async function ExcelStartupExportAsync(Record) {
    let responseEntity = await ExportService.ExportStartupExcel(Record);
    let responses = [responseEntity];
    return responseHelper.validateResponses(responses);
};



async function ExcelActivitiesExportAsync(Record) {
    let responseEntity = await ExportService.ExportActivitiesExcel(Record);
    let responses = [responseEntity];
    return responseHelper.validateResponses(responses);
};

const Reset = () => {
    return dispatch => {
        dispatch({ type: "EXPORT_RESET" });
    }
}
const SendEmail = (args) => {

    return dispatch => {
        dispatch({ type: "EXPORT_START" });

        //napravi VALIDACIJA!!!
        let jsonInputData = [
            {
                "element": "EmailSubject",
                "type": "required",
                "value": args.EmailSubject,
                "message": "Subject can't be empty"
            },
            {
                "element": "EmailContent",
                "type": "required",
                "value": args.EmailContent,
                "message": "Content can't be empty"
            },
            {
                "element": "StartupIDs",
                "type": "required",
                "value": args.StartupIDs,
                "message": "No Startups selected"
            }
        ]

        //validacija
        let validationMessages = validatorHelper.validate(jsonInputData);

        //validacija
        //da se dodade
        if (validationMessages.length > 0) {
            dispatch({
                type: "EXPORT_ERROR",
                ValidationMessages: validationMessages,
                error: "Unable to sent email"
            }); //da ne prekucuva, toa sto e od client toa da mu vrati
        }
        else {
            SendEmailAsync(args).then((responses) => {
                if (responses.error) {
                    dispatch({ type: "EXPORT_ERROR", error: responses.error_description });
                }
                else {
                    dispatch({
                        type: "EXPORT_SENDEMAIL_SUCCESS"
                    });
                }
            });
        }
    }
}


// const ExportExcel = (args) => {

//     return dispatch => {
//         dispatch({ type: "EXPORT_START" });

//         //napravi VALIDACIJA!!!
//         let jsonInputData = [
//             {
//                 "element": "StartupIDs",
//                 "type": "required",
//                 "value": args.StartupIDs,
//                 "message": "No Startups selected for export"
//             }
//         ]

//         //validacija
//         let validationMessages = validatorHelper.validate(jsonInputData);

//         //validacija
//         //da se dodade
//         if (validationMessages.length > 0) {
//             dispatch({
//                 type: "EXPORT_ERROR",
//                 ValidationMessages: validationMessages,
//                 error: "No Startups selected for export"
//             }); //da ne prekucuva, toa sto e od client toa da mu vrati
//         }
//         else {
//             ExcelExportAsync(args).then((responses) => {
//                 if (responses.error) {
//                     dispatch({ type: "EXPORT_ERROR", error: responses.error_description });
//                 }
//                 else {
//                     dispatch({
//                         type: "EXPORT_EXCEL_SUCCESS",
//                         ExportedFileName: responses[0].data //vraka ime na FAJL od https://api.he4s.eu/temp/
//                     });
//                 }
//             });
//         }
//     }
// }

// const ActivityDownloadFile = (args) => {

//     return dispatch => {
//         dispatch({ type: "EXPORT_START" });

    
//         ActivityDownloadFileAsync(args).then((responses) => {
//             if (responses.error) {
//                 dispatch({ type: "EXPORT_ERROR", error: responses.error_description }); //da se vidi dali treba nov action dispatch vo reducer-ot da preceka
//             }
//             else {
//                 dispatch({
//                     type: "EXPORT_EXCEL_SUCCESS",  //da se vidi dali treba nov action dispatch vo reducer-ot da preceka
//                     ExportedFileName: responses[0].data //vraka ime na FAJL od https://api.he4s.eu/temp/
//                 });
//             }
//         });
//     }
// }


const ExportActivitiesExcel = (args) => {

    return dispatch => {
        dispatch({ type: "EXPORT_START" });

        //napravi VALIDACIJA!!!
        let jsonInputData = [
            {
                "element": "StartupIDs",
                "type": "required",
                "value": args.StartupIDs,
                "message": "No Startups selected for export"
            }
        ]

        //validacija
        let validationMessages = validatorHelper.validate(jsonInputData);

        //validacija
        //da se dodade
        if (validationMessages.length > 0) {
            dispatch({
                type: "EXPORT_ERROR",
                ValidationMessages: validationMessages,
                error: "No Startups selected for export"
            }); //da ne prekucuva, toa sto e od client toa da mu vrati
        }
        else {
            ExcelActivitiesExportAsync(args).then((responses) => {
                if (responses.error) {
                    dispatch({ type: "EXPORT_ERROR", error: responses.error_description });
                }
                else {
                    dispatch({
                        type: "EXPORT_EXCEL_SUCCESS",
                        ExportedFileName: responses[0].data //vraka ime na FAJL od https://api.he4s.eu/temp/
                    });
                }
            });
        }
    }
}


const SendSMS = (args) => {

    return dispatch => {
        dispatch({ type: "EXPORT_START" });

        //napravi VALIDACIJA!!!
        let jsonInputData = [
            {
                "element": "PromotionalCode",
                "type": "required",
                "value": args.PromotionalCode,
                "message": "Promotional Code can't be empty"
            },
            {
                "element": "SMSContent",
                "type": "required",
                "value": args.SMSContent,
                "message": "SMS Content can't be empty"
            },
            {
                "element": "StartupIDs",
                "type": "required",
                "value": args.StartupIDs,
                "message": "No Startups selected"
            }
        ]

        //validacija
        let validationMessages = validatorHelper.validate(jsonInputData);

        //validacija
        //da se dodade
        if (validationMessages.length > 0) {
            dispatch({
                type: "EXPORT_ERROR",
                ValidationMessages: validationMessages,
                error: "Unable to sent SMS"
            }); //da ne prekucuva, toa sto e od client toa da mu vrati
        }
        else {
            SendSMSAsync(args).then((responses) => {
                if (responses.error) {
                    dispatch({ type: "EXPORT_ERROR", error: responses.error_description });
                }
                else {
                    dispatch({
                        type: "EXPORT_SENDSMS_SUCCESS"
                    });
                }
            });
        }
    }
}


export default {
    SendEmail,
    //ExportExcel,
    Reset,
    SendSMS,
    ExportActivitiesExcel,
    ExcelActivitiesExportAsync,
    ExcelStartupExportAsync
}