import React, { useReducer } from 'react';
import axios from 'axios';
import StartupContext from './startupContext';
import startupReducer from './startupReducer';
import {
    GET_STARTUP,
    SEARCH_STARTUP
} from './types';

import allServices from '../services';
import * as LanguageHelper from '../helpers/language.helper';
import * as localStorageHelper from '../helpers/localstorage.helper';
import { logHelper } from '../helpers/log.helper';

const StartupState = props => {
    const initialState = {
        startups: [],
        startup: {},
        user: { ime: "", prezime: "", AUTH_USERNAME: '' },
        count: 10,
        currentColor: "#bada55",

    }

    const [state, dispatch] = useReducer(startupReducer, initialState)
    const AUTH_USERNAME = 'AUTH_USERNAME';
    let response = localStorageHelper.GetValueByKey(AUTH_USERNAME);
    logHelper.log(response);

    if (state.user != null) {
        state.user = { AUTH_USERNAME: response }
    }

    // async function AdvancedSearchAsync(args) {
    //     alert("ide liiii")
    //     let response = await allServices.ManageUsersService.AdvancedSearch(args);
    //     let responses = [response];

    //     dispatch({
    //         type: SEARCH_STARTUP,
    //         payload: responses
    //     })
    // };



    return <StartupContext.Provider
        value={{
            startups: state.startups,
            startup: state.startup,
            user: state.user,
            count: state.count,
            languageId: state.languageId,
        }}
    >
        {props.children}
    </StartupContext.Provider>
}

export default StartupState;