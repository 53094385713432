import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Axios from "axios";
import * as AuthenticationHelper from '../../helpers/authentication.helper'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import allActions from '../../actions';
import { history } from '../../helpers';
import constants from '../../helpers/constant.helper';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AlertMessage from '../AlertMessage'
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { logHelper } from '../../helpers/log.helper';

// DEFINIRANJE NA STILOVI
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    flexGrow: 1,
  },
  media: {
    height: 140,
  },
  table: {
    minWidth: 650,
  },
}));

export default function ColumnList(args) {

  const dispatch = useDispatch()

  const classes = useStyles();

  const dataReducer = useSelector(state => state.ColumnReducer);

  const [EntityObjects, setEntityObject] = useState(dataReducer.Records);
  const [QuickSearch, setQuickSearch] = useState('');

  const [isBackDropOpen, setBackDrop] = useState(false);

  //sekogas mora da go ima pa so PROPTYPES ke treba da frlame exception so ogled deka kolonite se vrzani za toj TableID
  const TableId = args.match.params.tableId;

  //DEFAULT 
  useEffect(() => {
    dispatch(allActions.ColumnActions.Search({
      "ParentId": TableId,
      "EntityTypeId": constants.Entity_COLUMNS
    })); //loadiraj samo KOLONI
  }, []);   //default

  useEffect(() => {
    setEntityObject(dataReducer.Records);

    if (!dataReducer.Loading) {
      setBackDrop(false);
    }
  }, [dataReducer]);

  logHelper.log("isBackDropOpen");
  logHelper.log(isBackDropOpen);

  const onQuickSearchChange = (e) => {
    setBackDrop(true);
    const { value } = e.target;  //vadi e.target.name i e.target.value kade sto value e vnesenata vrednost!
    dispatch(allActions.ColumnActions.Search({
      "EntityTypeId": constants.Entity_COLUMNS,  //mora da odgovaraat na properties od EntitySearchArguments
      "QuickSearch": value,
      "ParentId": TableId
    }));

    setQuickSearch(value); //za da go napise text-ot vo quick search

  }


  function DoCheckUnCheck(row, checked) {
    dispatch(allActions.ColumnActions.SetVisible({
      "EntityId": row.EntityId, 
      "IsVisibleFL": checked,
      "ParentId": row.ParentId,
      "EntityTypeId": constants.Entity_COLUMNS
    }));
  }  
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
         
        <TableHead>

        {dataReducer.hasError &&
            <TableRow>
              <TableCell colSpan="12">
                <AlertMessage {...dataReducer} />
              </TableCell>
            </TableRow>
          }
                   
          <TableRow>
            <TableCell colSpan="5">
              <h1>
                {dataReducer.Table.Name} - List of Columns
                </h1>
            </TableCell>
            <TableCell colSpan="3">
              <TextField name="QuickSearch" margin="dense" id="filled-basic" placeholder="Quick Find" type="text" fullWidth variant="outlined" onChange={onQuickSearchChange} value={QuickSearch} />
            </TableCell>

            <TableCell>
              <Button size="small" color="primary" onClick={() => history.push('/entity/column/create/' + TableId)}>
                Create Column
               </Button>

              <Button size="small" color="primary" onClick={() => window.open('/Preview/' + TableId)}>Preview Page</Button>
            </TableCell>
          </TableRow>
        </TableHead>


        <TableHead>
          <TableRow>
            <TableCell><b>Field Label</b> </TableCell>
            <TableCell align="center" style={{ 'width': '12%' }}><b>Field Name</b></TableCell>
            <TableCell align="center" style={{ 'width': '15%' }}><b>Field Description</b></TableCell>
            <TableCell align="center" style={{ 'width': '8%' }}><b>Data Type</b></TableCell>
            <TableCell align="center"><b>Data Created</b></TableCell>
            <TableCell align="center"><b>Primary Key</b></TableCell>
            <TableCell align="center"><b>Unique</b></TableCell>
            <TableCell align="center" style={{ 'width': '5%' }}><b>Visible</b></TableCell>
            <TableCell align="center" style={{ 'width': '20%' }}><b>Actions</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataReducer.Loading &&
            <TableRow>
              <TableCell colSpan="7">
                <CircularProgress color="inherit" />
              </TableCell>
            </TableRow>
          }

          {!dataReducer.Loading && EntityObjects.map((row, index) => (
            <TableRow key={row.EntityId}>
              <TableCell component="th" scope="row">
                {index + 1}. {row.Name}
              </TableCell>
              <TableCell>{row.Name}</TableCell>
              <TableCell>{row.Description}</TableCell>
              <TableCell>{row.DataType}</TableCell>
              <TableCell>{new Date(row.CreatedDate).toLocaleDateString()}</TableCell>
              <TableCell align="center">{row.IsPrimaryKey == 1 ? "Yes" : "No"}</TableCell>
              <TableCell align="center">{row.IsUniqueFL ? "Yes" : "No"}</TableCell>

              <TableCell>
                  <input type="checkbox"
                    checked={row.IsVisibleFL}
                    onClick={() => DoCheckUnCheck(row, !row.IsVisibleFL)}
                  />
              </TableCell>

              <TableCell align="center">
                {/* <BackContainerRoute path="/entity/column/edit/:tableId/:columnId" component={ColumnManage} /> */}
                <Button size="small" color="primary" onClick={() => history.push('/entity/column/edit/' + TableId + '/' + row.EntityId)}>Edit Column</Button>

                <Button size="small" color="primary"
                  onClick={() => dispatch(allActions.ColumnActions.SetOrder({
                    "EntityId": row.EntityId, "IsUp": "1",
                    "ParentId": row.ParentId,
                    "EntityTypeId": constants.Entity_COLUMNS
                  }))}>Up</Button>

                <Button size="small" color="primary"
                  onClick={() => dispatch(allActions.ColumnActions.SetOrder({
                    "EntityId": row.EntityId, "IsUp": "0",
                    "ParentId": row.ParentId,
                    "EntityTypeId": constants.Entity_COLUMNS
                  }))}>Down</Button>

              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
