import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Radio } from '@material-ui/core';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import { logHelper } from '../helpers/log.helper';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 348,
  },
  formElement: {},
  formLabel: {
    color: '#aaaaa9',
    marginTop: 10,
    '&:hover': {
      background: '#F3F3F2',
    }
  },
  header: {
    backgroundColor: '#F3F3F2',
    borderRadius: 4,
    paddingLeft: 5,
  },
  subHeader: {
    marginTop: 15,
    marginBottom: 1,
    fontWeight: 700
  },
  buttonStyle: {
    margin: theme.spacing(1),
    textTransform: 'none'
  },
  xs12: {
    width: '25%',
  },

}));

export default function Page1() {

  const classes = useStyles();

  const jsonData = {

    //niza od headers
    Headers: [
      { DataType: 'Header', Label: "Startups" },
      { DataType: 'SubHeader', Label: "Details" }
    ],

    //Elemetni od SchemaDefinition
    Schema: { TableName: 'StartUp', Action: 'Update', PrimaryKeyName: 'StartupId', PrimaryKeyValue: '5' },

    //STATE
    Relations: [
      {
        Name: "XXX1",
        Elements: []
      },
      {
        Name: "XXX2",
        Elements: []
      }
    ],
    //KEY--> ELEMENTID 
    //VALUE-->ELEMENTVALUE 

    //niza od HTML Elementi 
    Elements: [
      { DataType: 'Text', id: 'FirstName', Name: 'FirstName', Value: 'dww', PlaceHolder: 'Enter Text', Label: "First Name" },
      { DataType: 'Text', id: 'LastName', Name: 'LastName', Value: 'Kodovski', PlaceHolder: 'Enter Text', Label: "Last Name" },
      { DataType: 'DateTime', id: 'DateOfBirth', Name: 'DateOfBirth', Value: '2016-08-18', PlaceHolder: 'Enter Date', Label: "Date Created" },
      {
        DataType: 'PickList', id: 'Gender', Name: 'Gender', Value: 'F', Label: "Sex",
        Items: [
          { Value: 'M', Text: 'Male' },
          { Value: 'F', Text: 'Female' }
        ]
      },
      {
        DataType: 'PickList (Multi-Select)', id: 'Countries', Name: 'Countries', Value: 'F', Label: "Select Country",
        Items: [
          { Value: 'M', Text: 'Burundi' },
          { Value: 'F', Text: 'Somalia' }
        ]
      },
      {
        DataType: 'CheckBox', Name: 'Gender2', Value: 'L', Label: "Gender2",
        Items: [
          { Value: 'M', Text: 'Male' },
          { Value: 'F', Text: 'Female' },
          { Value: 'O', Text: 'Other' }
        ]
      },
      {
        DataType: 'RadioBox', Name: 'Gender3', Value: 'L', Label: "Sex",
        Items: [
          { Value: 'M', Text: 'Male 2 ' },
          { Value: 'F', Text: 'Female 2' },
          { Value: 'O', Text: 'Other 2' }
        ]
      }
    ]
  }

  //da se stavi hooks [], i da se vikne Fetch a na server 

  //const [control, setControl] = useState(null);
  const [EntityRecords, setControl] = useState([]);

  // logHelper.log("posle hooks");
  // logHelper.log(control);

  const handleChange = e => {
    logHelper.log("od handle change");
    logHelper.log(e.target);
    const { name, value } = e.target;

    logHelper.log("value vo handle");
    logHelper.log(value);

    setControl(prevState => ({ ...prevState, [name]: value }));
  };


  //DEFAULT 
  useEffect(() => {
    RenderState();
  }, []);   //default


  function RadioBox(control) {

    // Here we build the form's groups elements dynamically

    return (
      <React.Fragment>
        <div className={classes.formLabel}>
          {control.Label}
        </div>
        <div className={classes.FormElement}>
          {control.Items.map(item => (
            <React.Fragment>
              <Radio color="primary" id="x" value="1" />
              {item.Text}
            </React.Fragment>
          ))}
        </div>
      </React.Fragment>
    )
  }

  function Text(control) {

    //logHelper.log("prebaruvam Vo niza od EntityRecords");

    // const [FirstName] = Object.keys(EntityRecords);
    // logHelper.log(Object.keys(EntityRecords));
    // logHelper.log(Object.keys(EntityRecords)["FirstName"]);
    // logHelper.log("prebaruvam Vo niza od EntityRecords");
    // logHelper.log(EntityRecords);

    // const indexOfElement = (Object.keys(EntityRecords).find(x => x === control.id) || {});
    //  logHelper.log("indexOfElement:" + indexOfElement);
    // let jsonRecord = (EntityRecords.find(x => x.name === control.id) || {});
    // logHelper.log("Founded Record");
    // logHelper.log(jsonRecord);
    // alert(jsonRecord.value);

    const currenttValue = EntityRecords[control.id];
    logHelper.log(currenttValue);

    return (

      <React.Fragment>
        <div className={classes.formLabel}>
          {control.Label}
          <Tooltip title={`Enter your ${control.Label}`} arrow placement="top">
            <InfoOutlinedIcon color="action" fontSize='small' />
          </Tooltip>
        </div>
        <div className={classes.FormElement}>
          <TextField
            margin="dense"
            id={control.id}
            name={control.Name}
            type="text"
            fullWidth
            variant="outlined"
            value={currenttValue}
            onChange={handleChange}
          />

        </div>
      </React.Fragment>
    )
  }

  function CheckBox(control) {

    // Here we build the form's groups elements dynamically
    return (
      <React.Fragment>
        <div className={classes.formLabel}>
          {control.Label}
        </div>
        <div className={classes.FormElement}>
          {control.Items.map(item => (
            <React.Fragment>
              <Checkbox color="primary" value="1" />
              {item.Text}
            </React.Fragment>
          ))}
        </div>
      </React.Fragment>
    )

    // return <Container component="main" maxWidth="lg" >
    //   <FormControl component="fieldset">
    //     <FormGroup aria-label="position" row>
    //       {control.Items.map(item => (
    //         <FormControlLabel
    //           value={item.Value}
    //           control={<Checkbox color="primary" />}
    //           label={item.Text}
    //           labelPlacement="end"
    //         />
    //       ))}

    //     </FormGroup>
    //   </FormControl>
    // </Container>
  }

  function DatePicker(control) {
    // Here we build the form's groups elements dynamically
    const currenttValue = EntityRecords[control.id];

    return (
      <React.Fragment>
        <div className={classes.formLabel}>
          {control.Label}
        </div>
        <div className={classes.FormElement}>
          <TextField
            id={control.id}
            name={control.Name}
            // label={control.Label}
            type="date"
            onChange={handleChange}

            value={currenttValue}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </React.Fragment>
    )
  }

  function PickList(control) {

    // Here we build the form's groups elements dynamically
    const currenttValue = EntityRecords[control.id];

    return (
      <React.Fragment>
        <div className={classes.formLabel}>
          {control.Label}
        </div>
        <div className={classes.FormElement}>
          <Select
            fullWidth
            native
            id={control.id}
            name={control.Name}
            value={currenttValue}
            onChange={handleChange}
          >
            <option value={0}>- Select Data Type -</option>
            {control.Items.map(option => (
              <option value={option.Value}>{option.Text}</option>
            ))}

          </Select>
        </div>
      </React.Fragment>
    )
  }

  function PickListMultiple(control) {
    return (
      <React.Fragment>
        <div className={classes.formLabel}>
          {control.Label}
          <Tooltip title={`Please ${control.Label}`} arrow placement="top">
            <InfoOutlinedIcon color="action" fontSize='small' />
          </Tooltip>
        </div>
        <div className={classes.FormElement}>
          <Select
            fullWidth
            multiple
            native
            value={control.Value}
          >
            {control.Items.map(option => (
              <option value={option.Value}>{option.Text}</option>
            ))}

          </Select>
        </div>
      </React.Fragment>
    )
  }

  function Header(control) {
    return (
      <React.Fragment>
        <div className={classes.header}>
          <Typography variant="h5">{control.Label}</Typography>
        </div>
      </React.Fragment>
    )
  }



  function SubHeader(control) {
    return (
      <React.Fragment >
        <div className={classes.subHeader}>
          <Typography variant="h6">{control.Label}</Typography>
        </div>
        <Box borderBottom={1} color="#F3F3F2" />
      </React.Fragment>
    )
  }


  function RenderHeaders() {
    // Here we build the form's groups elements dynamically
    return jsonData.Headers.map(control => {
      if (control.DataType === "Header") {
        return Header(control);
      }
      else if (control.DataType === "SubHeader") {
        return SubHeader(control);
      };
    })
  }


  function RenderState() {
    let Records = [];

    // 1: {name: "LastName", value: "Kodovski"}
    // 2: {name: "DateOfBirth", value: "2014-08-18"}
    // 3: {name: "Gender", value: "M"}
    // 4: {name: "Countries", value: "M"}
    // 5: {name: "Gender", value: "L"}
    // 6: {name: "Gender", value: "L"}

    jsonData.Elements.map(control => {
      //Records.push({ name: control.Name, value: control.Value });
      Records = { ...Records, [control.Name]: control.Value }
    })
    //logHelper.log("AAAAAAAAAAAA");
    //logHelper.log(Records);

    //NAPOLNI GO STATE-OT ZA EntityRecords so Records (gornata niza)
    setControl(Records);
    //[Record, setState] = useState(Record)
    //const [EntityRecords, setControl] = useState(Records);
    //logHelper.log(EntityRecords);
  }

  function RenderSchema() {
    // Schema: { TableName: 'StartUp', Action: 'Update', PrimaryKeyName: 'StartupId', PrimaryKeyValue: '5' },

    return (
      <React.Fragment>
        <input type="text" id="TableName" value={jsonData.Schema.TableName} />
        <input type="text" id="Action" value={jsonData.Schema.Action} />
        <input type="text" id="PrimaryKeyName" value={jsonData.Schema.PrimaryKeyName} />
        <input type="text" id="PrimaryKeyValue" value={jsonData.Schema.PrimaryKeyValue} />
      </React.Fragment>
    )
  }


  function RenderElements() {
    // Here we build the form's groups elements dynamically
    return jsonData.Elements.map(control => {
      if (control.DataType === 'PickList') {
        return PickList(control);
      }
      else if (control.DataType === 'PickList (Multi-Select)') {
        return PickListMultiple(control);
      } else if (control.DataType === 'DateTime' || control.DataType === 'Time') {
        return DatePicker(control);
      } else if (control.DataType === "CheckBox") {
        return CheckBox(control);
      } else if (control.DataType === "RadioBox") {
        return RadioBox(control);
      }
      else if (control.DataType === "Text") {
        return Text(control);
      }
    })
  }




  return (
    <Grid container spacing={2} justify="center" alignItems="center" direction="column" xs>
      <Grid item className={classes.xs12}>

        {RenderSchema()}

        {/* Renderiraj HEADER */}
        {
          RenderHeaders()
        }

        {/* Renderiraj HTML ELEMENTI */}
        {
          RenderElements()
        }

      </Grid>
      <Grid item>
        <Button variant="outlined" color="primary" className={classes.buttonStyle}>
          Cancel
      </Button>
        <Button variant="contained" color="primary" className={classes.buttonStyle}>
          Save
      </Button>
      </Grid>
    </Grid>
  );
}
