import LookupDataService from '../services/LookupDataService'
import SmeService from '../services/SmeService'
import SmeEmployeeService from '../services/SmeEmployeeService'
import { validatorHelper, responseHelper, history } from '../helpers';
import Keys from '../json/ResourceStartup.json'
import { logHelper } from '../helpers/log.helper';

// responseHelper.validateResponses(responses);
//Validate Response validira responses dali ima nekakvi greski 
//responses = [] --niza
//Iterira niz nea i ako e TRUE togas gi vraka samite responses[] koj sto bile prateni za inspekcija
//ako e FALSE vraka response od ERROR objekt (ne niza, samo eden objekt)
async function EditAsync(args) {
    let response = await SmeEmployeeService.Edit({ "stItemGUID": args.stItemGUID, "PeriodID": args.PeriodID });
    let responses = [response];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};


async function DeleteAsync(args) {
    let response = await LookupDataService.Delete(args);
    let responses = [response];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function SearchAsync(args) {
    let response = await SmeService.Search(args);
    let responses = [response];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function SaveAsync(Record) {
    let responseEntity = await SmeEmployeeService.Save(Record);
    let responses = [responseEntity];
    return responseHelper.validateResponses(responses);
};

const Reset = () => {
    return dispatch => {
        dispatch({ type: "SME_EMPLOYEE_RESET" });
    }
}

const Edit = (args) => {

    return dispatch => {
        dispatch({ type: "SME_EMPLOYEE_START" });

        EditAsync(args).then((responses) => {
            if (responses.error) {
                dispatch({ type: "SME_EMPLOYEE_ERROR", error: responses.error_description });
            }
            else {
                dispatch({
                    type: "SME_EMPLOYEE_EDIT",
                    Record: responses[0].data.StartupEmployee,
                    Periods: responses[0].data.Periods
                });
            }
        });
    }
}

const Delete = (args) => {

    return dispatch => {
        dispatch({ type: "SME_EMPLOYEE_START" });

        DeleteAsync(args).then((responses) => {
            if (responses.error) {
                dispatch({ type: "SME_EMPLOYEE_ERROR", error: responses.error_description });
            }
            else {
                history.push("/lookupdata/find/" + args.ldTypeID);
            }
        });
    }
}


const Search = (args) => {
    return dispatch => {
        dispatch({ type: "SME_EMPLOYEE_START" });

        SearchAsync(args).then((responses) => {

            if (responses.error) {
                dispatch({ type: "SME_EMPLOYEE_ERROR", error: responses.error_description });
            }
            else {
                dispatch({
                    type: "SME_EMPLOYEE_SEARCH",
                    Records: responses[0].data,
                });
            }
        });
    }
}


//OVOJ SEUSTE NE E NAPRAVEN DA RABOTI SO SERVICE
const Save = (Record) => {

    return dispatch => {
        dispatch({ type: "SME_EMPLOYEE_START" }); //da se refreshira GUI-to so SPINNER-ot


        //validacija
        SaveAsync(Record).then((responses) => {

            if (responses.error) {
                dispatch({
                    type: "SME_EMPLOYEE_SAVE_ERROR",
                    error: responses.error_description, //od server gresakta
                    Record: Record
                }); //da ne prekucuva, toa sto e od client toa da mu vrati
            }
            else {

                logHelper.log("ALOOOO");
                logHelper.log(responses[0].data);

                //response[0] == Entity Record
                //response[1] == True ili Exception ako ne pomine uploaded 
                dispatch({
                    type: "SME_EMPLOYEE_SAVE_SUCCESS",
                    Record: responses[0].data.StartupEmployee,
                    Periods: responses[0].data.Periods
                });
            }

        });
    }
}

export default {
    Save,
    Edit,
    //Create,
    Search,
    Delete,
    Reset
}