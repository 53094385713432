import LookupDataService from "../services/LookupDataService";
import StartupService from "../services/StartupService";
import CountryProvinceService from "../services/CountryProvinceService";
import CountryService from "../services/CountryService";
import ServiceLookupData from "../services/LookupDataService";
import ServiceLookupType from "../services/LookupTypeService";
import { validatorHelper, responseHelper, history } from "../helpers";
import Keys from "../json/ResourceStartup.json";
import * as LanguageHelper from "../helpers/language.helper";
import { logHelper } from "../helpers/log.helper";
import * as AuthenticationHelper from "../helpers/authentication.helper";

// responseHelper.validateResponses(responses);
//Validate Response validira responses dali ima nekakvi greski
//responses = [] --niza
//Iterira niz nea i ako e TRUE togas gi vraka samite responses[] koj sto bile prateni za inspekcija
//ako e FALSE vraka response od ERROR objekt (ne niza, samo eden objekt)
async function EditAsync(args) {
  //najdi go StartupId i po nego povleci data
  let startupId = parseInt(args.stStartupID);
  if (isNaN(startupId)) startupId = 0;

  let responseStartup = null;
  if (startupId === 0) {
    responseStartup = await StartupService.New(args);
  } else {
    responseStartup = await StartupService.Edit(args);
  }

  //let responseStartup = await StartupService.Edit({ "stTypeID": args.stTypeID, "stItemGUID": args.stItemGUID });
  let responseLookupData = await LookupDataService.SearchByTypeIDs({
    LookupTypeIDs: args.LookupTypeIDs,
  });
  let responseCountriesScholarhips =
    await CountryService.GetScholarshipCountries();
  let responseCountriesProvinces =
    await CountryProvinceService.GetCountryProvincesAll();
  let responses = [
    responseStartup,
    responseLookupData,
    responseCountriesScholarhips,
    responseCountriesProvinces,
  ];
  return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
}

async function DeleteAsync(args) {
  let response = await LookupDataService.Delete(args);
  let responses = [response];
  return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
}

async function SearchAsync(args) {
  let response = await StartupService.Search(args);
  let responses = [response];
  return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
}

async function SaveAsync(Record) {
  let responseEntity = await StartupService.Save(Record);
  let responses = [responseEntity];
  return responseHelper.validateResponses(responses);
}

async function CreateStartupFromBenefAsync(args) {
  let responseStartup = await StartupService.CreateStartupFromBenef(args);
  let responseLookupData = await LookupDataService.SearchByTypeIDs({
    LookupTypeIDs: args.LookupTypeIDs,
  });
  let responseCountriesScholarhips =
    await CountryService.GetScholarshipCountries();
  let responseCountriesProvinces =
    await CountryProvinceService.GetCountryProvincesAll();
  let responses = [
    responseStartup,
    responseLookupData,
    responseCountriesScholarhips,
    responseCountriesProvinces,
  ];
  return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
}

const Reset = () => {
  return (dispatch) => {
    dispatch({ type: "STARTUP_RESET" });
    //dispatch({ type: "GLOBAL_RESET"});    //GLOBALEN CACHE ZA HEADERS DALI DA SE POKAZUVAAT ILI NE
  };
};
const Edit = (args) => {
  return (dispatch) => {
    dispatch({ type: "STARTUP_START" });

    EditAsync(args).then((responses) => {
      if (responses.error) {
        dispatch({ type: "STARTUP_ERROR", error: responses.error_description });
      } else {
        dispatch({
          type: "STARTUP_EDIT",
          Record: responses[0].data,
          LookupData: responses[1].data,
          ScholarshipCountries: responses[2].data, //vidi gore vo komentar za definicija na niza
          Provinces: responses[3].data,
        });

        dispatch({ type: "GLOBAL_REFRESH", Record: responses[0].data }); //GLOBALEN CACHE ZA HEADERS DALI DA SE POKAZUVAAT ILI NE
      }
    });
  };
};

const Delete = (args) => {
  return (dispatch) => {
    dispatch({ type: "STARTUP_START" });

    DeleteAsync(args).then((responses) => {
      if (responses.error) {
        dispatch({ type: "STARTUP_ERROR", error: responses.error_description });
      } else {
        history.push("/lookupdata/find/" + args.ldTypeID);
      }
    });
  };
};

const Search = (args) => {
  return (dispatch) => {
    dispatch({ type: "STARTUP_START" });

    SearchAsync(args).then((responses) => {
      if (responses.error) {
        dispatch({ type: "STARTUP_ERROR", error: responses.error_description });
      } else {
        dispatch({
          type: "STARTUP_SEARCH",
          Records: responses[0].data,
        });
      }
    });
  };
};

//OVOJ SEUSTE NE E NAPRAVEN DA RABOTI SO SERVICE
const Save = (Record) => {
  return (dispatch) => {
    dispatch({ type: "STARTUP_START" }); //da se refreshira GUI-to so SPINNER-ot

    const keys = LanguageHelper.GetLanguageKeys();

    let jsonInputData = [
      {
        element: "stCountryID",
        type: "SelectEmpty", //proverka na select isto kako vo MVC
        value: Record.stCountryID,
        message: keys["CountryCantBeEmpty"],
      },
      {
        element: "stProvinceID",
        type: "SelectEmpty", //proverka na select isto kako vo MVC
        value: Record.stProvinceID,
        message: keys["ProvinceCantBeEmpty"],
      },
      {
        element: "stName",
        type: "required",
        value: Record.stName,
        message: keys["NameCantBeEmpty"],
      },
      {
        element: "stAddress",
        type: "required",
        value: Record.stAddress,
        message: keys["AddressCantBeEmpty"],
      },
      {
        element: "stCity",
        type: "required",
        value: Record.stCity,
        message: keys["CityCantBeEmpty"],
      },
      {
        element: "stInitFullEmployees",
        type: "required",
        value: Record.stInitFullEmployees,
        message: keys["InitFullEmployeesEmpty"],
      },
      {
        element: "stInitPartEmployees",
        type: "required",
        value: Record.stInitPartEmployees,
        message: keys["InitPartEmployeesEmpty"],
      },
      {
        element: "stMobilePhone",
        type: "required",
        value: Record.stMobilePhone,
        message: keys["MobilePhoneCantBeEmpty"],
      },
      {
        element: "stEmail",
        type: "email",
        value: Record.stEmail,
        message: keys["EmailCantBeEmpty"],
      },
      {
        element: "stSectorIDs",
        type: "required",
        value: Record.stSectorIDs,
        message: keys["SectorCantBeEmpty"],
      },
      {
        element: "stInitStageID",
        type: "SelectEmpty",
        value: Record.stInitStageID,
        message: keys["InitialBusinessStageCantbeEmpty"],
      },
      {
        element: "stStartDate",
        type: "DateTimeEmpty", //proverka na select isto kako vo MVC
        value: Record.stStartDate,
        message: keys["StartDateEmpty"],
      },
      {
        element: "stPassword",
        type: "password",
        value: Record.stPassword,
        message: keys["PasswordEmptyWith6Chars"],
      },
      {
        element: "stBusinessOperationLocationID",
        type: "SelectEmpty",
        value: Record.stBusinessOperationLocationID,
        message: keys["BusinessOperationLocationEmpty"],
      },
      {
        element: "stBussinessFinancialIDs",
        type: "required",
        value: Record.stBussinessFinancialIDs,
        message: keys["BusinessFinancialEmpty"],
      },
      {
        element: "stCurrentBusinessStatusID",
        type: "SelectEmpty",
        value: Record.stCurrentBusinessStatusID,
        message: keys["CurrentBusinessStatusEmpty"],
      },
    ];

    //validacija
    let validationMessages = validatorHelper.validate(jsonInputData);

    //validacija
    //da se dodade
    if (validationMessages.length > 0) {
      dispatch({
        type: "STARTUP_SAVE_ERROR",
        ValidationMessages: validationMessages,
        error: keys["FormCantBeSumitted"],
        Record: Record,
      }); //da ne prekucuva, toa sto e od client toa da mu vrati
    } else {
      SaveAsync(Record).then((responses) => {
        if (responses.error) {
          dispatch({
            type: "STARTUP_SAVE_ERROR",
            error: responses.error_description, //od server gresakta
            Record: Record,
          }); //da ne prekucuva, toa sto e od client toa da mu vrati
        } else {
          //response[0] == Entity Record
          //response[1] == True ili Exception ako ne pomine uploaded
          dispatch({ type: "STARTUP_SAVE_SUCCESS", Record: responses[0].data });
          dispatch({ type: "GLOBAL_REFRESH", Record: responses[0].data }); //GLOBALEN CACHE ZA HEADERS DALI DA SE POKAZUVAAT ILI NE
        }
      });
    }
  };
};

const CreateStartupFromBenef = (args) => {
  return (dispatch) => {
    dispatch({ type: "STARTUP_START" });

    CreateStartupFromBenefAsync(args).then((responses) => {
      if (responses.error) {
        dispatch({ type: "STARTUP_ERROR", error: responses.error_description });
      } else {
        dispatch({
          type: "STARTUP_EDIT",
          Record: responses[0].data,
          LookupData: responses[1].data,
          ScholarshipCountries: responses[2].data, //vidi gore vo komentar za definicija na niza
          Provinces: responses[3].data,
        });

        dispatch({ type: "GLOBAL_REFRESH", Record: responses[0].data }); //GLOBALEN CACHE ZA HEADERS DALI DA SE POKAZUVAAT ILI NE
      }
    });
  };
};

const SaveSubSahara = (Record) => {
  return (dispatch) => {
    dispatch({ type: "STARTUP_START" }); //da se refreshira GUI-to so SPINNER-ot

    const keys = LanguageHelper.GetLanguageKeys();

    let jsonInputData = [
      {
        element: "stName",
        type: "required",
        value: Record.stName,
        message: keys["NameCantBeEmpty"],
      },
      {
        element: "stCountryID",
        type: "SelectEmpty", //proverka na select isto kako vo MVC
        value: Record.stCountryID,
        message: keys["CountryCantBeEmpty"],
      },

      {
        element: "stProvinceID",
        type: "SelectEmpty", //proverka na select isto kako vo MVC
        value: Record.stProvinceID,
        message: keys["ProvinceCantBeEmpty"],
      },
      {
        element: "stAddress",
        type: "required",
        value: Record.stAddress,
        message: keys["AddressCantBeEmpty"],
      },
      {
        element: "stMobilePhone",
        type: "required",
        value: Record.stMobilePhone,
        message: keys["MobilePhoneCantBeEmpty"],
      },
      {
        element: "stSectorIDs",
        type: "required",
        value: Record.stSectorIDs,
        message: keys["SectorCantBeEmpty"],
      },
      // {
      //   element: "stStartDate",
      //   type: "DateTimeEmpty", //proverka na select isto kako vo MVC
      //   value: Record.stStartDate,
      //   message: keys["StartDateEmpty"],
      // },
      {
        element: "stInitFullEmployees",
        type: "required",
        value: Record.stInitFullEmployees,
        message: keys["InitFullEmployeesEmpty"],
      },
      {
        element: "stInitFullEmployees",
        type: "Float",
        value: Record.stInitFullEmployees,
        message: keys["InitFullEmployeesNumber"],
      },
      // {
      //     "element": "stInitPartEmployees",
      //     "type": "required",
      //     "value": Record.stInitPartEmployees,
      //     "message": keys["InitPartEmployeesEmpty"]
      // },
      // {
      //     "element": "stInitPartEmployees",
      //     "type": "Float",
      //     "value": Record.stInitPartEmployees,
      //     "message": keys["InitPartEmployeesNumber"]
      // },
      // {
      //     "element": "stFinancialYearClosingBusinessID",
      //     "type": "SelectEmpty",
      //     "value": Record.stFinancialYearClosingBusinessID,
      //     "message": keys["FinancialYearClosingBusinessEmpty"]
      // },
      // {
      //   element: "stBusinessLoanID",
      //   type: "SelectEmpty",
      //   value: Record.stBusinessLoanID,
      //   message: keys["BusinessLoanEmpty"],
      // },
      {
        element: "stCurrentBusinessStatusID",
        type: "SelectEmpty",
        value: Record.stCurrentBusinessStatusID,
        message: keys["CurrentBusinessStatusEmpty"],
      },
      // {
      //     "element": "stProvinceOperationID",
      //     "type": "SelectEmpty",
      //     "value": Record.stProvinceOperationID,
      //     "message": keys["ProvincesOfBusinessOperationEmpty"]
      // },
      // {
      //     "element": "stNetProfit",
      //     "type": "Float",
      //     "value": Record.stNetProfit,
      //     "message": keys["NetProfitTextRequired"]
      // },
      // {
      //     "element": "stNetLoss",
      //     "type": "Float",
      //     "value": Record.stNetLoss,
      //     "message": keys["NetLossTextRequired"]
      // },
      {
        element: "stPassword",
        type: "password",
        value: Record.stPassword,
        message: keys["PasswordEmptyWith6Chars"],
      },
      {
        element: "stSubSectorID",
        type: "SelectEmpty", //proverka na select isto kako vo MVC
        value: Record.stSubSectorID,
        message: keys["SubSectorCantBeEmpty"],
      },
      {
        element: "stBDAName",
        type: "required",
        value: Record.stBDAName,
        message: keys["BDANameCantBeEmpty"],
      },
      {
        element: "stNetProfit",
        type: "Float",
        value: Record.stNetProfit,
        message: keys["TotalCapitalAmountInvestedNumber"],
      },
      {
        element: "stCurrTurnover",
        type: "Float",
        value: Record.stCurrTurnover,
        message: keys["AnnualTurnoverNumber"],
      },
      {
        element: "stAttendedTrainingID",
        type: "SelectEmpty", //proverka na select isto kako vo MVC
        value: Record.stAttendedTrainingID,
        message: keys["TrainingParticipationCantBeEmpty"],
      },
      {
        element: "stAttendedTrainingID",
        type: "SelectEmpty", //proverka na select isto kako vo MVC
        value: Record.stAttendedTrainingID,
        message: keys["CoachingParticipationCantBeEmpty"],
      },
      {
        element: "stNumSessionComplete",
        type: "Float",
        value: Record.stNumSessionComplete,
        message: keys["NumSessionCompleteNumber"],
      },
      {
        element: "stNumSessionGrow",
        type: "Float",
        value: Record.stNumSessionGrow,
        message: keys["NumSessionGrowNumber"],
      },
      {
        element: "stBusinessPlanStatusID",
        type: "SelectEmpty", //proverka na select isto kako vo MVC
        value: Record.stBusinessPlanStatusID,
        message: keys["BusinessPlanStatusCantBeEmpty"],
      },
      {
        element: "stLoanStatusID",
        type: "SelectEmpty", //proverka na select isto kako vo MVC
        value: Record.stLoanStatusID,
        message: keys["LoanStatusCantBeEmpty"],
      },
      {
        element: "stAmountApllied",
        type: "required",
        value: Record.stAmountApllied,
        message: keys["AmountAplliedCantBeEmpty"],
      },
      {
        element: "stAmountApllied",
        type: "Float",
        value: Record.stAmountApllied,
        message: keys["AmountAplliedNumber"],
      },
      {
        element: "stAmountAccessed",
        type: "required",
        value: Record.stAmountAccessed,
        message: keys["AmountAccessedCantBeEmpty"],
      },
      {
        element: "stAmountAccessed",
        type: "Float",
        value: Record.stAmountAccessed,
        message: keys["AmountAccessedNumber"],
      },
    ];

    //validacija
    let validationMessages = validatorHelper.validate(jsonInputData);

    //validacija
    //da se dodade
    if (validationMessages.length > 0) {
      dispatch({
        type: "STARTUP_SAVE_ERROR",
        ValidationMessages: validationMessages,
        error: keys["FormCantBeSumitted"],
        Record: Record,
      }); //da ne prekucuva, toa sto e od client toa da mu vrati
    } else {
      SaveAsync(Record).then((responses) => {
        if (responses.error) {
          dispatch({
            type: "STARTUP_SAVE_ERROR",
            error: responses.error_description, //od server gresakta
            Record: Record,
          }); //da ne prekucuva, toa sto e od client toa da mu vrati
        } else {
          //response[0] == Entity Record
          //response[1] == True ili Exception ako ne pomine uploaded
          dispatch({ type: "STARTUP_SAVE_SUCCESS", Record: responses[0].data });
          dispatch({ type: "GLOBAL_REFRESH", Record: responses[0].data }); //GLOBALEN CACHE ZA HEADERS DALI DA SE POKAZUVAAT ILI NE
        }
      });
    }
  };
};

export default {
  Save,
  SaveSubSahara,
  Edit,
  //Create,
  Search,
  Delete,
  Reset,
  CreateStartupFromBenef,
};
