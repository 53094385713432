import resourceManager from '../helpers/constant.helper';
import constants from '../helpers/constant.helper';


//sekoe novo properti dodadi go ovde so DEFAULT value
const initialState = {

    Record: {
        seStartupEmployeeID:0, 
        seNumberEmployees: 0,
        seNumberMale: 0,
        seNumberFemale: 0,
        seNumberDisability: 0,
        seAgeEmployees1: 0,
        seAgeEmployees2: 0,
        seAgeEmployees3: 0,
        seFullTimeContract: 0,
        sePartTimeContract: 0,
        sePeriodID: 0,
        StartupItemGUID:'',
        seFullTimeContractFemale:0,
        sePartTimeContractFemale:0
    },

    LookupData: [],  //Table: LookupData
    Periods: [],  //Table: LookupData
    ScholarshipCountries: [],   //Table: Country
    Provinces: [],   //Table: CountryProvince

    Records: [],


    Message: '',
    Loading: false,
    hasError: false,
}

const SmeEmployeeReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SME_EMPLOYEE_START":
            return {
                ...state,
                Message: resourceManager.MSG_PLEASE_WAIT,    //za da izbrise prethoden error ako go imalo
                Loading: true,
                hasError: false
            }

        case "SME_EMPLOYEE_ERROR":
            return {
                ...state,
                Message: action.error,
                Loading: false,
                hasError: true
            }


        case "SME_EMPLOYEE_DELETE_ERROR":
            return {
                ...state,
                Message: action.error,
                Record: action.Record,  //istite vrednosti gi vraka tie sto gi vnesol
                Loading: false,
                hasError: true
            }


        case "SME_EMPLOYEE_SAVE_ERROR":
            return {
                ...state,
                Message: action.error,
                Record: action.Record,  //istite vrednosti gi vraka tie sto gi vnesol
                Loading: false,
                hasError: true
            }

        case "SME_EMPLOYEE_SAVE_SUCCESS":
            return {
                //...state,
                ...state,       //se drugo e vo STATE-ot
                Message: resourceManager.MSG_SAVE,
                Loading: false,
                hasError: false,
                Record: action.Record,
                Periods: action.Periods,
            }

        case "SME_EMPLOYEE_DELETE_SUCCESS":
            return {
                //...state,
                ...state,       //se drugo e vo STATE-ot
                Records: action.Records,  //samo noviot record ako e NOV, ako e update si ostanuva IST
                Message: resourceManager.MSG_DELETED,
                Loading: false,
                hasError: false
            }

        case "SME_EMPLOYEE_EDIT":
            return {
                ...state,
                //ako od server vratil NULL togas setiraj inital state vo sprotivno ne raboti state-ot
                Record: action.Record,
                Periods: action.Periods,
                ScholarshipCountries: action.ScholarshipCountries,
                Provinces: action.Provinces,
                Message: "",
                Loading: false,
                hasError: false
            }

        //se koristi vo find
        case "SME_EMPLOYEE_SEARCH":
            return {
                ...state,
                Records: action.payload,  //action.payload == List<Entity>
                Message: "",
                Loading: false,
                hasError: false
            }

        //se koristi vo find
        case "SME_EMPLOYEE_RESET":
            state = initialState; //go resetira na iniital state
            return {
                ...state
            }

        default:
            return state
    }
}
export default SmeEmployeeReducer;