import Service from '../services/TableService'
import { responseHelper, history } from '../helpers';
import { logHelper } from '../helpers/log.helper';

// responseHelper.validateResponses(responses);
//Validate Response validira responses dali ima nekakvi greski 
//responses = [] --niza
//Iterira niz nea i ako e TRUE togas gi vraka samite responses[] koj sto bile prateni za inspekcija
//ako e FALSE vraka response od ERROR objekt (ne niza, samo eden objekt)
async function EditAsync(EntityId) {
    let responseLoad = await Service.Edit({ "EntityId": EntityId });
    let responses = [responseLoad];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function CreateAsync(EntityTypeId) {
    let response = await Service.List({ "EntityTypeId": EntityTypeId });
    let responses = [response];
    return responseHelper.validateResponses(responses);
};

async function DeleteAsync(EntityObject) {
    let response = await Service.Delete(EntityObject);
    let responses = [response];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function SearchAsync(args) {
    let response = await Service.Search(args);
    let responses = [response];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function SaveAsync(EntityObject) {
    let responseEntity = await Service.Save(EntityObject);
    let responses = [responseEntity];
    return responseHelper.validateResponses(responses);
};


const Edit = (EntityId) => {

    return dispatch => {
        dispatch({ type: "TABLE_START" });

        EditAsync(EntityId).then((responses) => {
            if (responses.error) {
                dispatch({ type: "TABLE_ERROR", error: responses.error_description });
            }
            else {
                dispatch({
                    type: "TABLE_EDIT",
                    payload: responses[0].data
                });
            }
        });
    }
}


const Delete = (EntityObject) => {

    return dispatch => {
        dispatch({ type: "TABLE_START" });
        
        DeleteAsync(EntityObject).then((responses) => {
            if (responses.error) {
                dispatch({ type: "TABLE_ERROR", error: responses.error_description });
            }
            else {
                history.push("/admin/dashboard");
            }
        });
    }
}

const Create = (EntityTypeId) => {
    return dispatch => {
        dispatch({ type: "TABLE_START" });

        CreateAsync(EntityTypeId).then((responses) => {

            if (responses.error) {
                dispatch({ type: "TABLE_ERROR", error: responses.error_description });
            }
            else {
                dispatch({ type: "TABLE_CREATE", Teachers: responses[0].data });
            }
        });
    }
}

const Search = (args) => {
    return dispatch => {
        dispatch({ type: "TABLE_START" });

        SearchAsync(args).then((responses) => {

            if (responses.error) {
                dispatch({ type: "TABLE_ERROR", 
                            error: responses.error_description });
            }
            else {
                logHelper.log("TABLE_SEARCH!!!!!!!!!!!!!!!!!!!!!!!!")
                logHelper.log(responses);
                dispatch({ type: "TABLE_SEARCH", payload: responses[0].data });
            }
        });
    }
}


//OVOJ SEUSTE NE E NAPRAVEN DA RABOTI SO SERVICE
const Save = (entityObject) => {

    return dispatch => {
        dispatch({ type: "TABLE_START" }); //da se refreshira GUI-to so SPINNER-ot

        //validacija
        //da se dodade
        SaveAsync(entityObject).then((responses) => {

            if (responses.error) {
                dispatch({ type: "TABLE_SAVE_ERROR", 
                           error: responses.error_description,
                           payload: entityObject,  //vrati gi vnesenite vrednosti od Payload za da ne gi prekucuva pak 
                        });
            }
            else {
                //response[0] == Entity Record
                //response[1] == True ili Exception ako ne pomine uploaded 
                dispatch({ type: "TABLE_SAVE_SUCCESS", payload: responses[0].data });
            }

        })
    }
}


//OVOJ SEUSTE NE E NAPRAVEN DA RABOTI SO SERVICE
const Reset = () => {

    return dispatch => {
        dispatch({ type: "TABLE_RESET" }); //da se refreshira GUI-to so SPINNER-ot
    }
}


export default {
    Save,
    Edit,
    Create,
    Search,
    Delete,
    Reset
}