import { restHelper } from '../helpers/rest.helper';

const Save = async (payload) => {
    let response = await restHelper.post('api/Startup/Save', payload);
    return response;
}

const Edit = async (payload) => {
    let response = await restHelper.post('api/Startup/Edit', payload);
    return response;
}


const New = async (payload) => {
    let response = await restHelper.post('api/Startup/New', payload);
    return response;
}

const Search = async (payload) => {
    let response = await restHelper.post('api/Startup/Search', payload);
    return response;
}

const EditFounders = async (payload) => {
    let response = await restHelper.post('api/StartupFounder/Edit', payload);
    return response;
}



const CreateStartupFromBenef = async (payload) => {
    let response = await restHelper.post('api/Startup/CreateStartupFromBenef', payload);
    return response;
}


export default {
    Edit,
    New,
    EditFounders,
    Save,
    Search,
    CreateStartupFromBenef
}