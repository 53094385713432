import { restHelper } from '../helpers/rest.helper';
import * as localStorageHelper from '../helpers/localstorage.helper';

const Search = async (payLoad) => {
    let response = await restHelper.post('api/LookupData/Search', payLoad);
    return response;
}

const Save = async (payLoad) => {
    let response = await restHelper.post('api/LookupData/Save', payLoad);
    return response;
}

const Edit = async (payLoad) => {
    let response = await restHelper.post('api/LookupData/Edit', payLoad);
    return response;
}

const SearchByTypeIDs = async (payLoad) => {
    let response = await restHelper.post('api/LookupData/SearchByTypeIDs', payLoad);
    return response;
}   

const GetAll = async (payLoad) => {
    const url = 'api/LookupData/GetAll';
    let response = localStorageHelper.GetJSONResponse(url);
    if (!response)
    { 
        response = await restHelper.post(url, payLoad);
        if (!response.error)  //ako nema greska stavi go vo Storage vo sprotivno vrati ja greska nazad kako Response
        {
            localStorageHelper.SetValueByKey(url, JSON.stringify(response.data));
        }
    }
    return response;
    // let response = await restHelper.post('api/LookupData/GetAll', payLoad);
    // return response;
}   


const SearchByStartupTypeIDs = async (payLoad) => {
    let response = await restHelper.post('api/LookupData/SearchByStartupTypeIDs', payLoad);
    return response;
}   



const Delete = async (payLoad) => {
    let response = await restHelper.post('api/LookupData/Delete', payLoad);
    return response;
}


export default {
    Search,
    Save,
    Edit,
    Delete,
    SearchByTypeIDs,
    SearchByStartupTypeIDs,
    GetAll
}