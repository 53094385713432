import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Axios from "axios";
import * as AuthenticationHelper from '../../helpers/authentication.helper'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import allActions from '../../actions';
import { history } from '../../helpers';
import constants from '../../helpers/constant.helper';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AlertMessage from '../AlertMessage'
import CustomAutocomplete from '../Shared/CustomAutocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import Keys from '../../json/ResourceStartup.json';
import { InputTextOnly, InputDropDownOnly2, InputDropDownOnly, InputDropDownLookupOnly, InputDateOnly } from '../Shared/PreviewComponents';

import StartupContext from '../../Context/startupContext';
import { logHelper } from '../../helpers/log.helper';



// DEFINIRANJE NA STILOVI
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    flexGrow: 1,
  },
  media: {
    height: 140,
  },
  table: {
    minWidth: 650,
  },
}));

export default function ManageUsers(args) {

  logHelper.log("LEEEEOOOONN");
  logHelper.log(args);
  const startupContext = useContext(StartupContext)



  const { ime, prezime, AUTH_USERNAME } = startupContext.user;
  const { count } = startupContext;
  logHelper.log(AUTH_USERNAME)



  const keys = Keys[0]; //za da naoga textovi od JSON FIle
  const dispatch = useDispatch()
  const classes = useStyles();

  const dataReducer = useSelector(state => state.ManageUsersReducer);

  const [Record, setRecord] = useState(dataReducer.Record);
  const [Records, setRecords] = useState(dataReducer.Records);

  const [ScholarshipCountries, setScholarshipCountries] = useState(dataReducer.ScholarshipCountries);
  const [QuickSearch, setQuickSearch] = useState('');

  const [Provinces, setProvinces] = useState(dataReducer.Provinces);
  const [ProvincesByCountry, setProvincesByCountry] = useState([]);
  const [StartupTypes, setStartupTypes] = useState([]);
  const [LookupData, setLookupData] = useState([]);


  const lookupTypeIDs = [constants.Lookup_SectorOfBusiness];


  useEffect(() => {
    let payload = { stTypeID: constants.TYPEID_STARTUP, LookupTypeIDs: lookupTypeIDs }

    dispatch(allActions.ManageUsersActions.Init(payload)); //vraka na Initial State

    return () => {
      //dispatch(allActions.SearchStartupActions.Reset()); //vraka na Initial State
    };
  }, []);   //default


  //ke se zapali smao pri promena 
  useEffect(() => {

    //samo mapiraj data od koga site call-ovi prema api.he4s.eu se zavrseni a toa e koga Loading === False
    if (!dataReducer.Loading) {

      setScholarshipCountries(dataReducer.ScholarshipCountries);
      setProvinces(dataReducer.Provinces);
      setStartupTypes(dataReducer.StartupTypeIDs);

      const filterProvinceByCountry = dataReducer.Provinces.filter(a => a.ParentId === dataReducer.Record.CountryID);
      setProvincesByCountry(filterProvinceByCountry);
      setRecord(dataReducer.Record);
      setRecords(dataReducer.Records);
      setLookupData(dataReducer.LookupData);
    }

  }, [dataReducer]);


  //DEFAULT 
  useEffect(() => {
    dispatch(allActions.SmeActions.Search({ "QuickSearch": "" }));
  }, []);   //default

  const onQuickSearchChange = (e) => {
    const { value } = e.target;  //vadi e.target.name i e.target.value kade sto value e vnesenata vrednost!
    dispatch(allActions.SmeActions.Search({ "QuickSearch": value }));
    setQuickSearch(value); //za da go napise text-ot vo quick search
  }



  const handleChange = e => {
    logHelper.log("od handle change");
    logHelper.log(e.target);
    const { name, value } = e.target;
    setRecord(prevState => ({ ...prevState, [name]: value }));
  };


  const handleChangeCountry = e => {
    logHelper.log("od handle change");
    logHelper.log(e.target);
    const { name, value } = e.target;  //value ==> CountryId 


    logHelper.log("value vo handle");
    logHelper.log(value);

    const filterProvinceByCountry = Provinces.filter(a => a.ParentId === value.toString());

    //da se resetira province-ot za validacija i toa vo "0" za da raboti ne 0
    setProvincesByCountry(filterProvinceByCountry);
    setRecord(prevState => ({ ...prevState, [name]: value, ["ProvinceID"]: "0" }));

  };


  function EditRecord(ItemGUID, stTypeID) {

    //setiraj ITEM GUID za selektiraniot START UP  
    AuthenticationHelper.SetStartUpItemGUID(ItemGUID);

    if (stTypeID === constants.TYPEID_BENEFECIARY) {
      history.push('/admin/benef/manage');
    }
    else if (stTypeID === constants.TYPEID_STARTUP) {
      history.push('/admin/startup/manage');
    }
    else if (stTypeID === constants.TYPEID_SME) {
      history.push('/admin/sme/manage');
    }

  }



  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          {dataReducer.hasError &&
            <TableRow>
              <TableCell colSpan="9">
                <AlertMessage {...dataReducer} />
              </TableCell>
            </TableRow>
          }

          <TableRow>
            <TableCell colSpan="7">
              <h1>Manage Users</h1>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell colspan={10}>
              <Table style={{ width: "100%", tableLayout: "auto" }}>
                <TableRow>
                  <TableCell>
                    {AUTH_USERNAME}
                    {InputTextOnly("UserFullName", Record.UserFullName, handleChange, "Search by Full Name", dataReducer.Loading)}
                  </TableCell>
                  <TableCell>
                    {count}
                    {InputTextOnly("UserName", Record.UserName, handleChange, "Search by User Name", dataReducer.Loading)}
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" className={classes.buttonStyle}
                      onClick={() => dispatch(allActions.ManageUsersActions.AdvancedSearch(Record))}
                      disabled={dataReducer.Loading}
                    >
                      Search
                  </Button>
                  </TableCell>
                </TableRow>
              </Table>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell colspan={20}>
              <b>Total Records found: {!dataReducer.Loading && Records.length}</b>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell><b>#</b></TableCell>
            <TableCell ><b>Full Name</b></TableCell>
            <TableCell ><b>Country</b></TableCell>
            <TableCell><b>User Name</b></TableCell>
            <TableCell ><b>Enabled (Yes / No)</b></TableCell>
            <TableCell ><b>Edit User</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataReducer.Loading &&
            <TableRow>
              <TableCell colSpan="5">
                <CircularProgress color="inherit" />
              </TableCell>
            </TableRow>
          }
          {!dataReducer.Loading && Records.map((row, index) => (
            <TableRow key={row.stStartupID}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell >{row.UserFullName}</TableCell>
              <TableCell >{row.coName}</TableCell>
              <TableCell >{row.UserName}</TableCell>
              <TableCell >{row.UserEnabledYesNo}</TableCell>
              <TableCell >
                <Button size="small" color="primary" onClick={() => EditRecord(row.stItemGUID, row.stTypeID.toString())}> Edit</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  );
}
