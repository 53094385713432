import { restHelper } from '../helpers/rest.helper';

const Save = async (payload) => {
    let response = await restHelper.post('api/Benef/Save', payload);
    return response;
}

const Edit = async (payload) => {
    let response = await restHelper.post('api/Benef/Edit', payload);
    return response;
}


const New = async (payload) => {
    let response = await restHelper.post('api/Benef/New', payload);
    return response;
}

const Search = async (payload) => {
    let response = await restHelper.post('api/Benef/Search', payload);
    return response;
}



const EditFounders = async (payload) => {
    let response = await restHelper.post('api/StartupFounder/Edit', payload);
    return response;
}


export default {
    Edit,
    New,
    EditFounders,
    Save,
    Search
}