import axios from 'axios';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import {restHelper} from '../../helpers/rest.helper'; 

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function CustomAutocomplete(args) {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState('N/A');

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    //<CustomAutocomplete url="api/Table/Search" id="SearchByName" criteria={{EntityTypeId:"1"}}/>
    //zima criteria tag i dodava uset eden tag: Name: xxxx kade xxxx e vnesenata vrednost so Spread operator
    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax
    var payload = ({ ...args.criteria, Name: inputValue });
    
    restHelper.post(args.url, payload).then((response) => {
      setOptions(response.data || []);
    }).catch((err) => {
      
    });

    return () => {
      active = false;
    };
  }, [inputValue]);

  return (
    <React.Fragment>
      {selectedValue}
      <Autocomplete
        id="google-map-demo"
        style={args.style}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.Name)}
        onChange={(event, newValue) => { setSelectedValue(newValue.EntityId); }}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search by Class"
            variant="outlined"
            fullWidth
            onChange={handleChange}
          />
        )}
        renderOption={(option) => {
          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {option.Name}
              </Grid>
            </Grid>
          );
        }}
      />
    </React.Fragment>
  );
}