import { restHelper } from '../helpers/rest.helper';

const SignIn = async (userName, password) => {
    const payload = { "username": userName, "password": password, grant_type: "password" }
    let  response = restHelper.login("token", payload);
    return response;
}

export default {
    SignIn
}