import LookupDataService from '../services/LookupDataService'
import StartupFounderService from '../services/StartupFounderService'
import CountryProvinceService from '../services/CountryProvinceService'
import CountryService from '../services/CountryService'
import ServiceLookupData from '../services/LookupDataService'
import ServiceLookupType from '../services/LookupTypeService'
import { validatorHelper, responseHelper, history } from '../helpers';
import Keys from '../json/ResourceStartup.json'

// responseHelper.validateResponses(responses);
//Validate Response validira responses dali ima nekakvi greski 
//responses = [] --niza
//Iterira niz nea i ako e TRUE togas gi vraka samite responses[] koj sto bile prateni za inspekcija
//ako e FALSE vraka response od ERROR objekt (ne niza, samo eden objekt)
async function EditAsync(args) {
    let responseStartup = await StartupFounderService.Edit({ "stTypeID": args.stTypeID, "stItemGUID":args.stItemGUID });
    let responseLookupData = await LookupDataService.SearchByTypeIDs({ "LookupTypeIDs": args.LookupTypeIDs });
    let responses = [responseStartup, responseLookupData];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function DeleteAsync(Record) {
    let responseEntity = await StartupFounderService.Delete(Record);
    let responses = [responseEntity];
    return responseHelper.validateResponses(responses);
};


async function SaveAsync(Records) {
    let responseEntity = await StartupFounderService.Save(Records);
    let responses = [responseEntity];
    return responseHelper.validateResponses(responses);
};


const Edit = (args) => {

    return dispatch => {
        dispatch({ type: "STARTUPFOUNDER_START" });

        EditAsync(args).then((responses) => {
            if (responses.error) {
                dispatch({ type: "STARTUPFOUNDER_ERROR", error: responses.error_description });
            }
            else {
                dispatch({
                    type: "STARTUPFOUNDER_EDIT",
                    Records: responses[0].data,
                    LookupData: responses[1].data
                });
            }
        });
    }
}


const Reset = () => {
    return dispatch => {
        dispatch({ type: "STARTUPFOUNDER_RESET" });
    }
}


//OVOJ SEUSTE NE E NAPRAVEN DA RABOTI SO SERVICE
const Save = (Records) => {

    return dispatch => {
        dispatch({ type: "STARTUPFOUNDER_START" }); //da se refreshira GUI-to so SPINNER-ot
        let errMsg = null;
 
        //validacija
        //da se dodade
        if (errMsg !== null) {
            dispatch({ type: "STARTUPFOUNDER_SAVE_ERROR", 
                        error: errMsg,
                        Records : Records }); //da ne prekucuva, toa sto e od client toa da mu vrati
        }
        else {
            SaveAsync(Records).then((responses) => {

                if (responses.error) {
                    dispatch({ type: "STARTUPFOUNDER_SAVE_ERROR", 
                                error: responses.error_description, //od server gresakta
                                Records : Records }); //da ne prekucuva, toa sto e od client toa da mu vrati
                }
                else {
                    //response[0] == Entity Record
                    //response[1] == True ili Exception ako ne pomine uploaded 
                    dispatch({ type: "STARTUPFOUNDER_SAVE_SUCCESS", Records: responses[0].data });
                }

            });
        }
    }
}

const Delete = (Record) => {

    return dispatch => {
        dispatch({ type: "STARTUPFOUNDER_START" }); //da se refreshira GUI-to so SPINNER-ot
        
        DeleteAsync(Record).then((responses) => {

            if (responses.error) {
                dispatch({ type: "STARTUPFOUNDER_DELETE_ERROR", 
                            error: responses.error_description  //na DELETE nema sto da vrati identicno si e s'e
                        }); //da ne prekucuva, toa sto e od client toa da mu vrati
            }
            else {
                dispatch({ type: "STARTUPFOUNDER_DELETE_SUCCESS", Records: responses[0].data });
            }

        });
    }
}


export default {
    Save,
    Edit,
    Reset,
    Delete
}