import { restHelper } from '../helpers/rest.helper';
import * as localStorageHelper from '../helpers/localstorage.helper';

const GetScholarshipCountries = async () => {

    const url = 'api/Country/GetScholarshipCountries';
    let response = localStorageHelper.GetJSONResponse(url);
    if (!response)
    { 
        response = await restHelper.post(url, null);
        if (!response.error)  //ako nema greska stavi go vo Storage vo sprotivno vrati ja greska nazad kako Response
        {
            localStorageHelper.SetValueByKey(url, JSON.stringify(response.data));
        }
    }
    return response;
    // let response = await restHelper.post('api/Country/GetScholarshipCountries', null);
    // return response;
}

const GetAllCountries = async () => {
    const url = 'api/Country/GetAllCountries';
    let response = localStorageHelper.GetJSONResponse(url);
    if (!response)
    { 
        response = await restHelper.post(url, null);
        if (!response.error)  //ako nema greska stavi go vo Storage vo sprotivno vrati ja greska nazad kako Response
        {
            localStorageHelper.SetValueByKey(url, JSON.stringify(response.data));
        }
    }
    return response;
}

export default {
    GetScholarshipCountries,
    GetAllCountries
}