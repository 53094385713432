import Service from '../services/LookupDataService'
import ServiceLookupType from '../services/LookupTypeService'
import { responseHelper, history } from '../helpers';

// responseHelper.validateResponses(responses);
//Validate Response validira responses dali ima nekakvi greski 
//responses = [] --niza
//Iterira niz nea i ako e TRUE togas gi vraka samite responses[] koj sto bile prateni za inspekcija
//ako e FALSE vraka response od ERROR objekt (ne niza, samo eden objekt)
async function EditAsync(args) {
    let responseLoad = await Service.Edit({ "ldLookupDataID": args.ldLookupDataID });
    let responseLookupType = await ServiceLookupType.Edit({"ltLookupTypeID" : args.ldTypeID});
    let responses = [responseLoad,responseLookupType];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function CreateAsync(args) {
    let responseLookupType = await ServiceLookupType.Edit({"ltLookupTypeID" : args.ldTypeID});
    let responses = [responseLookupType];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function DeleteAsync(args) {
    let response = await Service.Delete(args);
    let responses = [response];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function SearchAsync(args) {
    let response = await Service.Search(args);
    let responseLookupType = await ServiceLookupType.Edit({"ltLookupTypeID" : args.ldTypeID});
    let responses = [response, responseLookupType];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function SaveAsync(EntityObject) {
    let responseEntity = await Service.Save(EntityObject);
    let responses = [responseEntity];
    return responseHelper.validateResponses(responses);
};


const Edit = (args) => {

    return dispatch => {
        dispatch({ type: "LOOKUPDATA_START" });

        EditAsync(args).then((responses) => {
            if (responses.error) {
                dispatch({ type: "LOOKUPDATA_ERROR", error: responses.error_description });
            }
            else {
                dispatch({
                    type: "LOOKUPDATA_EDIT",
                    payload: responses[0].data,
                    payload_LookupType : responses[1].data
                });
            }
        });
    }
}

const Create = (args) => {

    return dispatch => {
        dispatch({ type: "LOOKUPDATA_START" });

        CreateAsync(args).then((responses) => {
            if (responses.error) {
                dispatch({ type: "LOOKUPDATA_ERROR", error: responses.error_description });
            }
            else {
                dispatch({
                    type: "LOOKUPDATA_CREATE",
                    payload_LookupType : responses[0].data
                });
            }
        });
    }
}


const Delete = (args) => {

    return dispatch => {
        dispatch({ type: "LOOKUPDATA_START" });
        
        DeleteAsync(args).then((responses) => {
            if (responses.error) {
                dispatch({ type: "LOOKUPDATA_ERROR", error: responses.error_description });
            }
            else {
                history.push("/lookupdata/find/" + args.ldTypeID);
            }
        });
    }
}


const Search = (args) => {
    return dispatch => {
        dispatch({ type: "LOOKUPDATA_START" });

        SearchAsync(args).then((responses) => {

            if (responses.error) {
                dispatch({ type: "LOOKUPDATA_ERROR", error: responses.error_description });
            }
            else {
                dispatch({ type: "LOOKUPDATA_LIST", 
                                payload: responses[0].data,
                                payload_LookupType : responses[1].data});
            }
        });
    }
}


//OVOJ SEUSTE NE E NAPRAVEN DA RABOTI SO SERVICE
const Save = (entityObject) => {

    return dispatch => {
        dispatch({ type: "LOOKUPDATA_START" }); //da se refreshira GUI-to so SPINNER-ot

        //validacija
        //da se dodade
        SaveAsync(entityObject).then((responses) => {

            if (responses.error) {
                dispatch({ type: "LOOKUPDATA_ERROR", error: responses.error_description });
            }
            else {
                //response[0] == Entity Record
                //response[1] == True ili Exception ako ne pomine uploaded 
                dispatch({ type: "LOOKUPDATA_SAVE", payload: responses[0].data });
            }

        })
    }
}

export default {
    Save,
    Edit,
    Create,
    Search,
    Delete
}