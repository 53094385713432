
//ova se pali na F5 ili na inicijalen LOAD
const initialState = {
    UserProfile: {
        IsSigned:false,
        HasRole: false,

        FullName: '',
        Email:'',
        UserName: '',
        IsSysAdmin: false,
        IsOfficer: false,
        IsUser: false
    }
}

const UserProfileReducer = (state = initialState, action) => {

   
    switch (action.type) {
        case "USERPROFILE_SUCCESS":
            
            return {
                ...state,       //se drugo e vo STATE-ot
                UserProfile : action.payload,
                }
        case "USERPROFILE_RESET":
            
            state = initialState; //go resetira na iniital state
            return {
                ...state
            }
        
            default:
            return state
    }
}
export default UserProfileReducer;