import resourceManager from "../helpers/constant.helper";
import constants from "../helpers/constant.helper";

//sekoe novo properti dodadi go ovde so DEFAULT value
const initialState = {
  Record: {
    StartupID: "",
    ItemGUID: "",
    OwnerID: "", //ovoj e KLUC za od admin ili officers da bira raznlicni startups
    TypeID: "",
    CountryID: "0",
    ProvinceID: "0",
    Name: "",
    RegistryNumber: "",
    Address: "",
    City: "",
    MobilePhone: "",
    HomePhone: "",
    Email: "",
    Web: "",
    SectorID: "0",
    StartDate: "2020-06-26",
    RegistrationDate: "2020-06-26",
    InitStageID: "0",
    ProvinceOperationID: "0",
    CurrentStatusID: "0",
    CurrentStageID: "0",
    DateCreated: "2020-06-26",
    CreatedBy: "",
    InitFullEmployees: "",
    InitPartEmployees: "",
    FirstName: "",
    LastName: "",

    SectorIDs: "",
    ActivityCategoryIDs: "",
    ActivityTypeIDs: "",
    DonorIDs: "",
    DonorParentIDs: "",
    DonorReportIDs: "",
    acFrom: "",
    acTo: "",

    // 834	1012	Startups with scholarships (from SIS)
    // 835	1012	Startups without scholarships
    HasScholarshipFL: "0",
  },

  LookupData: [], //Table: LookupData
  ScholarshipCountries: [], //Table: Country
  Provinces: [], //Table: CountryProvince
  StartupTypeIDs: [], //SME, BENEF, Startup vo LookupData Model
  Records: [],
  Partners: [],

  Message: "",
  Loading: true,
  hasError: false,
};

const SearchActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SEARCH_ACTIVITY_START":
      return {
        ...state,
        Loading: true,
        hasError: false,
        type: action.type,
      };

    case "SEARCH_ACTIVITY_ERROR":
      return {
        ...state,
        Message: action.error,
        Loading: false,
        hasError: true,
        type: action.type,
      };

    case "SEARCH_ACTIVITY_INIT":
      return {
        ...state,
        //ako od server vratil NULL togas setiraj inital state vo sprotivno ne raboti state-ot
        LookupData: action.LookupData,
        ScholarshipCountries: action.ScholarshipCountries,
        Provinces: action.Provinces,
        StartupTypeIDs: action.StartupTypeIDs,
        Nationalities: action.Nationalities,
        Partners: action.Partners,
        Message: "",
        Loading: false,
        hasError: false,
        type: action.type,
      };

    //se koristi vo find
    case "SEARCH_ACTIVITY_SEARCH":
      return {
        ...state,
        Records: action.Records, //action.payload == List<Entity>
        Message: "",
        Loading: false,
        hasError: false,
        type: action.type,
      };

    //se koristi vo find
    case "SEARCH_ACTIVITY_RESET":
      state = initialState; //go resetira na iniital state
      return {
        ...state,
        type: action.type,
      };

    case "SEARCH_ACTIVITY_RESULTS":
      return {
        ...state,
        Records: action.Records,
        Record: action.Record, //argumentite
        Message: "",
        Loading: false,
        hasError: false,
        type: action.type,
      };
    default:
      return state;
  }
};
export default SearchActivityReducer;
