import { restHelper } from '../helpers/rest.helper';


const Save = async (payload) => {
    let response = await restHelper.post('api/SmeEmployee/Save', payload);
    return response;
}


const Edit = async (payload) => {
    let response = await restHelper.post('api/SmeEmployee/Edit', payload);
    return response;
}

const GetPeriods = async (payload) => {
    let response = await restHelper.post('api/SmeEmployee/GetPeriodsForStartupID', payload);
    return response;
}


const SearchHistory = async (payload) => {
    let response = await restHelper.post('api/SmeEmployee/SearchHistory', payload);
    return response;
}


export default {
    Edit,
    Save,
    SearchHistory,
    GetPeriods
}