
//sekoe novo properti dodadi go ovde so DEFAULT value
const initialState = {
    Message: '',
    Loading: false,
    hasError: false,

    Record: {
        Email: '',
        Password: '',
        ConfirmPassword: '',
        FirstName: '',
        LastName: '',
        CountryID: '0',
    },
    Countries: []
}

const RegisterUserReducer = (state = initialState, action) => {

    switch (action.type) {
        case "REGISTERUSER_START":
            return {
                ...state,
                Message: '',    //za da izbrise prethoden error ako go imalo
                Loading: true,
                hasError: false
            }

        case "REGISTERUSER_EDIT":
            return {
                ...state,
                Message: '',    //za da izbrise prethoden error ako go imalo
                Countries: action.Countries,
                Loading: false,
                hasError: false
            }


        case "REGISTERUSER_ERROR":
            return {
                ...state,
                Message: action.error,
                Loading: false,
                hasError: true
            }

        //se koristi vo find
        case "REGISTERUSER_RESET":
            state = initialState; //go resetira na iniital state
            return {
                ...state
            }
        default:
            return state
    }
}
export default RegisterUserReducer;