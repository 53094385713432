import Service from '../services/LookupTypeService'
import { responseHelper, history } from '../helpers';

// responseHelper.validateResponses(responses);
//Validate Response validira responses dali ima nekakvi greski 
//responses = [] --niza
//Iterira niz nea i ako e TRUE togas gi vraka samite responses[] koj sto bile prateni za inspekcija
//ako e FALSE vraka response od ERROR objekt (ne niza, samo eden objekt)
async function EditAsync(RecordId) {
    let responseLoad = await Service.Edit({ "ltLookupTypeId": RecordId });
    let responses = [responseLoad];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};


async function DeleteAsync(RecordId) {
    let response = await Service.Delete({"ltLookupTypeId": RecordId });
    let responses = [response];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function SearchAsync(args) {
    let response = await Service.Search(args);
    let responses = [response];
    return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
};

async function SaveAsync(EntityObject) {
    let responseEntity = await Service.Save(EntityObject);
    let responses = [responseEntity];
    return responseHelper.validateResponses(responses);
};


const Edit = (RecordId) => {

    return dispatch => {
        dispatch({ type: "LOOKUPTYPE_START" });

        EditAsync(RecordId).then((responses) => {
            if (responses.error) {
                dispatch({ type: "LOOKUPTYPE_ERROR", error: responses.error_description });
            }
            else {
                dispatch({
                    type: "LOOKUPTYPE_EDIT",
                    payload: responses[0].data
                });
            }
        });
    }
}


const Delete = (EntityId) => {

    return dispatch => {
        dispatch({ type: "LOOKUPTYPE_START" });
        
        DeleteAsync(EntityId).then((responses) => {
            if (responses.error) {
                dispatch({ type: "LOOKUPTYPE_ERROR", error: responses.error_description });
            }
            else {
                history.push("../../class/find");
            }
        });
    }
}

const Reset = ()  => {
    return dispatch => {
        dispatch({ type: "LOOKUPTYPE_RESET" }); //da se refreshira GUI-to so SPINNER-ot
    }
}

const Search = (args) => {
    return dispatch => {
        dispatch({ type: "LOOKUPTYPE_START" });

        SearchAsync(args).then((responses) => {


            if (responses.error) {
                dispatch({ type: "LOOKUPTYPE_ERROR", error: responses.error_description });
            }
            else {
                dispatch({ type: "LOOKUPTYPE_LIST", payload: responses[0].data });
            }
        });
    }
}


//OVOJ SEUSTE NE E NAPRAVEN DA RABOTI SO SERVICE
const Save = (entityObject) => {

    return dispatch => {
        dispatch({ type: "LOOKUPTYPE_START" }); //da se refreshira GUI-to so SPINNER-ot

        //validacija
        //da se dodade
        SaveAsync(entityObject).then((responses) => {

            if (responses.error) {
                dispatch({ type: "LOOKUPTYPE_ERROR", error: responses.error_description });
            }
            else {
                //response[0] == Entity Record
                //response[1] == True ili Exception ako ne pomine uploaded 
                dispatch({ type: "LOOKUPTYPE_SAVE", payload: responses[0].data });
            }

        })
    }
}

export default {
    Save,
    Edit,
    Search,
    Delete,
    Reset
}