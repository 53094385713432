module.exports = Object.freeze({
  MSG_SAVE: "Record has been saved",
  MSG_IMPORT_FINISHED: "Import succsefully finished",
  MSG_SENDEMAIL_FINISHED: "Email succsefully sent",
  MSG_SENDSMS_FINISHED: "SMS succsefully sent",
  MSG_EXCELEXPORT_FINISHED: "Excel export file succsefully created",
  MSG_DELETED: "Record has been deleted",
  MSG_SAVE_FAILED: "Unable to save record",

  MSG_LOGIN_FAILED: "Unable to login",
  MSG_PLEASE_WAIT: "Please wait",

  Entity_TABLES: "1",
  Entity_COLUMNS: "2",
  Entity_COLUMN_DATATYPE: "3",

  Entity_Class: "55",
  Entity_Meeting: "56",
  Entity_Assignment: "57",
  Entity_AssignmentDocs: "58",
  Relation_Assignment_AssignmentDocs: "59",
  Entity_Person: "60",
  Relation_Person_Class: "61",
  Relation_Class_Assignment: "62",
  Relation_Teacher_Student: "63",

  TYPEID_STARTUP: "1",
  TYPEID_BENEFECIARY: "2",
  TYPEID_SME: "3",

  Lookup_SectorOfBusiness: 1001,
  Lookup_IniitialBusinessStage: 1002,
  Lookup_Gender: 101,
  Lookup_Disability: 103,
  Lookup_StartupWithScholarships: 1012,
  Lookup_EmploymentStatus: 1004,
  Lookup_BusinessOperationLocation: 1005,
  Lookup_BussinessFinancial: 1006,
  Lookup_PersonActivity: 300,
  Lookup_PersonActivityRole: 301,
  Lookup_ActivityAttendance: 508,
  Lookup_FundedBy: 128,
  Lookup_ParentDonor: 1016,
  Lookup_ParentDonorReport: 1016,
  Lookup_CurrentBusinessStatus: 1007,
  Lookup_CurrentBusinessStatusSubSahara: 1020,
  Lookup_FoundersNationality: 1008,
  Lookup_NumberDailyClient: 1009,
  Lookup_BusinessLoan: 103,
  Lookup_BusinessInsurance: 103,
  Lookup_BusinessRevenueEnough: 103,
  Lookup_Income: 1010,
  Lookup_FinancialYearClosingBusiness: 1011,
  Lookup_Quarters: 1013,
  Lookup_Pagination: 1015,
  Lookup_Psychological: 1016,
  Lookup_SubSector: 1017,
  Lookup_AttendedTraining: 103,
  Lookup_BusinessKeepingRecord: 103,
  Lookup_BusinessPlanStatus: 1018,
  Lookup_LoanStatus: 1019,
  Lookup_HaveBankAccount: 103,
  Lookup_IsLegallyRegistered: 103,

  LookupData_PersonActivity_EconomicEMpowerment: 284,

  //SearchStartupsArgs1 - Startup
  //SearchStartupsArgs2 - Benef
  //SearchStartupsArgs3 - SME
  SearchStartupsArgs_LocalStorageKey: "SearchStartupsArgs",
  SearchStartupsActivityArgs_LocalStorageKey: "SearchStartupsActivityArgs",
});
