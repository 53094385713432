import { restHelper } from '../helpers/rest.helper';


const Save = async (payload) => {
    let response = await restHelper.post('api/SmeFounder/Save', payload);
    return response;
}

const Delete = async (payload) => {
    let response = await restHelper.post('api/SmeFounder/Delete', payload);
    return response;
}

const Edit = async (payload) => {
    let response = await restHelper.post('api/SmeFounder/Edit', payload);
    return response;
}


export default {
    Edit,
    Save,
    Delete
}