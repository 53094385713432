import resourceManager from '../helpers/constant.helper';
import constants from '../helpers/constant.helper';

//sekoe novo properti dodadi go ovde so DEFAULT value
const initialState = {

    Record: {
        EntityId: 0,
        EntityTypeId: constants.TABLE_TABLES,
        ParentEntityId: null,
        Name: "MyTable",
        Name2: "MyTables",
        Description: "Info for MyTables",
        PrimaryColumnName: 'MyTableId',
        APIName: 'MyTable'
    },

    Records: [],

    Lookup: {
        Teachers: [],
        Classes: []
    },

    Message: '',
    Loading: false,
    hasError: false,
}

const TableReducer = (state = initialState, action) => {
    switch (action.type) {
        case "TABLE_START":
            return {
                ...state,
                Message: resourceManager.MSG_PLEASE_WAIT,    //za da izbrise prethoden error ako go imalo
                Loading: true,
                hasError: false
            }

        case "TABLE_ERROR":
            return {
                ...state,
                Message: action.error,
                Loading: false,
                hasError: true
            }


        case "TABLE_SAVE_ERROR":
            return {
                ...state,
                Message: action.error,
                Record: action.payload,  //istite vrednosti gi vraka tie sto gi vnesol
                Loading: false,
                hasError: true
            }

        case "TABLE_SAVE_SUCCESS":
            return {
                //...state,
                ...state,       //se drugo e vo STATE-ot
                Record: action.payload,  //samo noviot record ako e NOV, ako e update si ostanuva IST
                Message: resourceManager.MSG_SAVE,
                Loading: false,
                hasError: false
            }


        case "TABLE_EDIT":
            return {
                ...state,
                Lookup: { Teachers: action.Teachers }, //niza od objekti
                Record: action.payload,
                Message: "",
                Loading: false,
                hasError: false
            }

        case "TABLE_CREATE":
            return {
                ...state,
                Record: initialState.Record,    //za da se resetira
                Lookup: { Teachers: action.Teachers }, //niza od objekti
                Message: "",
                Loading: false,
                hasError: false
            }

        //se koristi vo find
        case "TABLE_SEARCH":
            return {
                ...state,
                Records: action.payload,  //action.payload == List<Entity>
                Message: "",
                Loading: false,
                hasError: false
            }

        //se koristi vo find
        case "TABLE_RESET":
            state = initialState; //go resetira na iniital state
            return {
                ...state
            }

        default:
            return state
    }
}
export default TableReducer;