import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Axios from "axios";
import * as AuthenticationHelper from '../../helpers/authentication.helper'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import allActions from '../../actions';
import { history } from '../../helpers';
import constants from '../../helpers/constant.helper';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AlertMessage from '../AlertMessage'
import CustomAutocomplete from '../Shared/CustomAutocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

// DEFINIRANJE NA STILOVI
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    flexGrow: 1,
  },
  media: {
    height: 140,
  },
  table: {
    minWidth: 650,
  },
}));

export default function LookupDataList(args) {

  const dispatch = useDispatch()

  const classes = useStyles();

  const dataReducer = useSelector(state => state.LookupDataReducer);
  const [EntityObjects, setEntityObject] = useState(dataReducer.Records);
  const [LookupType, setLookupType] = useState(dataReducer.LookupType);
  const [QuickSearch, setQuickSearch] = useState('');

  //sekogas mora da go ima pa so PROPTYPES ke treba da frlame exception so ogled deka kolonite se vrzani za toj TableID
  const LookupTypeId = args.match.params.lookupTypeId;



  useEffect(() => {
    setEntityObject(dataReducer.Records);
    setLookupType(dataReducer.LookupType);
  }, [dataReducer]);

  //DEFAULT 
  useEffect(() => {
    dispatch(allActions.LookupDataActions.Search({ "ldTypeID": LookupTypeId, "QuickSearch": ""}));
  }, []);   //default

  const onQuickSearchChange = (e) => {
    const { value } = e.target;  //vadi e.target.name i e.target.value kade sto value e vnesenata vrednost!
    dispatch(allActions.LookupDataActions.Search({"ldTypeID": LookupTypeId, "QuickSearch": value}));
    setQuickSearch(value); //za da go napise text-ot vo quick search
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          {dataReducer.hasError &&
            <TableRow>
              <TableCell colSpan="5">
                <AlertMessage {...dataReducer} />
              </TableCell>
            </TableRow>
          }

          <TableRow>
            <TableCell colSpan="2">
              <h1>List of Lookup Data - {LookupType.ltName}</h1>
            </TableCell>
            <TableCell >
              <TextField name="QuickSearch" margin="dense" id="filled-basic" placeholder="Quick Find" type="text" fullWidth variant="outlined" onChange={onQuickSearchChange} value={QuickSearch} />
            </TableCell>
            <TableCell align="center">
              <Button size="small" color="primary" onClick={() => history.push('/lookupdata/create/' + LookupTypeId)}>
                Create Lookup Data
                    </Button>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell style={{"width":"10%"}} align="center"><b>Id</b></TableCell>
            <TableCell align="center"><b>Value</b></TableCell>
            <TableCell align="center"><b>Value 2</b></TableCell>
            <TableCell align="center"><b>Actions</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataReducer.Loading &&
            <TableRow>
              <TableCell colSpan="5">
                <CircularProgress color="inherit" />
              </TableCell>
            </TableRow>
          }

          {!dataReducer.Loading && EntityObjects.map((row, index) => (
            <TableRow key={row.ldLookupDataID}>
              <TableCell component="th" scope="row">
                {row.ldLookupDataID}
              </TableCell>
              <TableCell>{row.ldValue}</TableCell>
              <TableCell>{row.ldValue2}</TableCell>
              <TableCell align="center">
                <Button size="small" color="primary" onClick={() => history.push('/lookupdata/edit/' + row.ldTypeID + '/' + row.ldLookupDataID)}>
                  Edit
                    </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  );
}
