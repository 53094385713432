const initialState = {
    HasStartupRecord: false,
    Record : {},
    Loading: true
}

//TABOVITE SE POJAVUVAAT SAMO I SAMO AKO IMA STARTUP RECORD  
//A TOA SE ODLUCUVA SAMO I SAMO PREKU Edit i isto taka na SAVE kade sto se vika GLOBAL_REFRESH 
//koj pak go osvezuva ovoj modul Header.jS 

const GlobalReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GLOBAL_REFRESH":
            //alert("GLOBAL_REFRESH");
            return {
                Record: action.Record,
                HasStartupRecord : !(action.Record === null),
                Loading: false
            }

        //se koristi vo find
        case "GLOBAL_RESET":
            //alert("GLOBAL_RESET");
            return {
                Record: null,
                HasStartupRecord : false,
                Loading: true
            }

        default:
            return state
    }
}
export default GlobalReducer;