import resourceManager from '../../helpers/constant.helper';
import constants from '../../helpers/constant.helper';

//sekoe novo properti dodadi go ovde so DEFAULT value
const initialState = {

    PromotionalCode: '',
    SMSContent: '',
    StartupIDs: '',

    Message: '',
    ValidationMessages: [],
    Loading: false, //so ogled deka ne loadirame nisto od SERVER
    hasError: false,
}

const SendSMSReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SendSMS_START":
            return {
                ...state,
                Loading: true,
                hasError: false
            }

        case "SendSMS_ERROR":
            return {
                ...state,
                Message: action.error,
                Loading: false,
                hasError: true
            }

        case "SendSMS_SUCCESS":
            return {
                //...state,
                ...state,       //se drugo e vo STATE-ot
                Message: resourceManager.MSG_SENDSMS_FINISHED,
                ValidationMessages: [], //resetirj clientski greski
                Loading: false,
                hasError: false
            }


        //se koristi vo find
        case "SendSMS_RESET":
            state = initialState; //go resetira na iniital state
            return {
                ...state
            }

        default:
            return state
    }
}
export default SendSMSReducer;