import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paperbase from "./Paperbase";
import SignIn from "./SignIn";

import SignUp from "./SignUp";
import { Router, Redirect, Switch, Route, Link } from "react-router-dom";
import { history } from "../helpers";
import * as AuthenticationHelper from "../helpers/authentication.helper";
import TableList from "./EntityTable/TableList";
import LookupTypeList from "./LookupType/LookupTypeList";
import LookupTypeManage from "./LookupType/LookupTypeManage";
import TableManage from "./EntityTable/TableManage";
import ColumnList from "./EntityColumn/ColumnList";
import BackContainerRoute from "./BackContainerRoute";
import ColumnManage from "./EntityColumn/ColumnManage";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Charts from "./Charts";

import ConfigureUserProfile from "./ConfigureUserProfile";
import ClassicPreview from "./Preview/ClassicPreview";
import Test from "./Preview/Test";
import LookupDataList from "./LookupData/LookupDataList";
import LookupDataManage from "./LookupData/LookupDataManage";
import { ContextOne } from "../ContextOne";
import RefreshToken from "./RefreshToken";
import allActions from "../actions";
import Dashboard from "./Dashboard";
import StatupManage from "./Startup/StatupManage";
import StartupFounderManage from "./Startup/StartupFounderManage";
import StartupActivityManage from "./Startup/StartupActivityManage";
import StartupList from "./Startup/_____StartupList";

import BenefManage from "../components/Benef/BenefManage";

import BenefList from "./Benef/BenefList";
import SmeManage from "../components/SME/SmeManage";
import SmeEmployeeManage from "../components/SME/SmeEmployeeManage";
import ImportActivity from "./Administration/ImportActivity";
//import ImportTemplate from "./ImportTemplate";
import ImportTemplate from "./Administration/ImportTemplate";
import SearchStartup from "./Search/SearchStartup";
import SearchActivity from "./Search/SearchActivity";
import ManageUsers from "./ManageUsers/ManageUsers";
import StartupState from "../Context/startupState";
import Header from "./Header";
import Manual from "../../src/components/Manual";

const App = () => {
  return (
    <Router history={history}>
      {/* <!--default page --> */}

      <Route path="/" exact component={SignIn} />
      <Route path="/Page1" exact component={Page1} />
      <Route path="/Page2" exact component={Charts} />
      <Route path="/Page3" exact component={Test} />
      <Route path="/Preview/:id" component={ClassicPreview} />

      <Route path="/signIn" exact component={SignIn} />

      <Route path="/refreshtoken" exact component={RefreshToken} />

      {/* <!--USER ROUTES--> */}
      <BackContainerRoute
        menu_section="user_home"
        exact
        path="/user/dashboard"
        component={Dashboard}
      />
      {/* //ako nema exact togas raboti so contains i primer ako odi  /user/startup/tab2 se resolvira so /user/startup */}
      <BackContainerRoute
        menu_section="user_home"
        exact
        path="/user/startup"
        component={StatupManage}
      />

      {/* za da resolvira http://localhost:3000/user/startup/manage?CreateStartupFromBenef */}
      <BackContainerRoute
        menu_section="user_home"
        exact
        path="/user/startup/manage"
        component={StatupManage}
      />
      <BackContainerRoute
        menu_section="user_home"
        exact
        path="/user/startup/tab2"
        component={StartupFounderManage}
      />
      <BackContainerRoute
        menu_section="user_home"
        exact
        path="/user/startup/tab3"
        component={StartupActivityManage}
      />

      {/* Benefeciary */}
      <BackContainerRoute
        menu_section="user_home"
        exact
        path="/user/benef"
        component={BenefManage}
      />
      <BackContainerRoute
        menu_section="user_home"
        exact
        path="/user/benef/tab2"
        component={StartupActivityManage}
      />

      {/* SMS's */}
      <BackContainerRoute
        menu_section="user_home"
        exact
        path="/user/sme"
        component={SmeManage}
      />
      <BackContainerRoute
        menu_section="user_home"
        exact
        path="/user/sme/manage"
        component={SmeManage}
      />
      <BackContainerRoute
        menu_section="user_home"
        exact
        path="/user/sme/tab2"
        component={StartupFounderManage}
      />
      <BackContainerRoute
        menu_section="user_home"
        exact
        path="/user/sme/tab3"
        component={StartupActivityManage}
      />
      <BackContainerRoute
        menu_section="user_home"
        exact
        path="/user/sme/tab4"
        component={SmeEmployeeManage}
      />

      {/* ADMIN ROUTES */}
      {/* Ova ruta raboti so Admin / Privilegii no radi Container-ot e staven so Route */}
      <Route path="/signUp" exact component={SignUp} />
      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/dashboard"
        component={Dashboard}
      />

      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/SearchActivity"
        component={SearchActivity}
      />

      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/SearchStartup"
        component={SearchStartup}
      />
      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/SearchBenef"
        component={SearchStartup}
      />
      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/SearchSME"
        component={SearchStartup}
      />

      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/dashboard"
        component={SearchStartup}
      />

      {/* <BackContainerRoute menu_section="admin_home" exact path="/admin/startup/list/" component={StartupList} /> */}

      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/startup/new"
        component={StatupManage}
      />
      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/startup/manage"
        component={StatupManage}
      />

      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/startup/manage/tab2"
        component={StartupFounderManage}
      />
      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/startup/manage/tab3"
        component={StartupActivityManage}
      />

      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/ManageUsers"
        component={ManageUsers}
      />

      {/* BENEF */}
      {/* <BackContainerRoute menu_section="admin_home" exact path="/admin/benef/list/" component={BenefList} /> */}
      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/benef/new"
        component={BenefManage}
      />
      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/benef/manage"
        component={BenefManage}
      />
      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/benef/manage/tab2"
        component={StartupActivityManage}
      />

      {/* SMS's */}
      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/sme/new"
        component={SmeManage}
      />
      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/sme/manage"
        component={SmeManage}
      />
      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/sme/manage/tab2"
        component={StartupFounderManage}
      />
      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/sme/manage/tab3"
        component={StartupActivityManage}
      />
      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/sme/manage/tab4"
        component={SmeEmployeeManage}
      />

      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/charts"
        component={Charts}
      />

      {/* Admin */}
      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/admin/importactivity"
        component={ImportActivity}
      />
      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/template"
        component={ImportTemplate}
      />

      <BackContainerRoute
        menu_section="admin_configure"
        exact
        path="/lookuptype/find"
        component={LookupTypeList}
      />
      <BackContainerRoute
        menu_section="admin_configure"
        exact
        path="/lookuptype/create"
        component={LookupTypeManage}
      />
      <BackContainerRoute
        menu_section="admin_configure"
        exact
        path="/lookuptype/edit/:id"
        component={LookupTypeManage}
      />


      {/* Lookup data definition */}
      <BackContainerRoute
        menu_section="admin_configure"
        exact
        path="/lookupdata/find/:lookupTypeId"
        component={LookupDataList}
      />
      <BackContainerRoute
        menu_section="admin_configure"
        exact
        path="/lookupdata/create/:lookupTypeId"
        component={LookupDataManage}
      />
      <BackContainerRoute
        menu_section="admin_configure"
        exact
        path="/lookupdata/edit/:lookupTypeId/:lookupDataId"
        component={LookupDataManage}
      />

      {/* HelpMenu */}

      <BackContainerRoute
        menu_section="admin_home"
        exact
        path="/help"
        component={Manual}
      />
      {/* <p>
            Current count: <b>{state.count}</b>
            </p> */}
      {/* <button onClick={inc}>Increment!</button> */}
    </Router>
  );
};

export default App;
