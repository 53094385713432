import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Radio } from '@material-ui/core';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import * as AuthenticationHelper from './authentication.helper'
import Link from '@material-ui/core/Link';
import { history} from '../helpers';
import * as LanguageHelper from '../helpers/language.helper';

//samo ednas da se citaat
const keys = LanguageHelper.GetLanguageKeys();

export const ShowLinkedRecords = (Record) => {

  function EditBenefRecord(ItemGUID) {
    AuthenticationHelper.SetStartUpItemGUID(ItemGUID);
    history.push('/admin/benef/manage');
  }


  function EditStartupRecord(ItemGUID) {
    AuthenticationHelper.SetStartUpItemGUID(ItemGUID);
    history.push('/admin/startup/manage');
  }

  function EditSMERecord(ItemGUID) {
    AuthenticationHelper.SetStartUpItemGUID(ItemGUID);
    history.push('/admin/sme/manage');
  }

  function ShowBenefPanel(Record) {
    
    return (
      <React.Fragment>
        {Record.REF_STARTUPID === 0 &&
          <Link href={AuthenticationHelper.IsUser() ?  "/user/startup/manage?CreateStartupFromBenef" : "/admin/startup/manage?CreateStartupFromBenef"} variant="body2">{keys["ReceivedSupportToCreateStartup"]} </Link>
        }
      </React.Fragment>
    )
  }

  function ShowStartupPanel(Record) {
    return (
      <React.Fragment>
        <br/>
        {Record.REF_SMEID === 0 &&
          <Link href={AuthenticationHelper.IsUser() ?  "/user/sme/manage?CreateSMEFromStartup" : "/admin/sme/manage?CreateSMEFromStartup"} variant="body2">{keys["ReceivedSupportToCreateSME"]}<br/> </Link>
        }
      </React.Fragment>
    )
  }

  function ShowSMEPanel(Record) {
    return "";
    // return (
    //   <React.Fragment>
    //     <br/>
    //     {Record.REF_BENEFID !== 0 &&
    //       <Button size="small" color="primary" onClick={() => EditBenefRecord(Record.REF_BENEF_ITEMGUID)}> Linked Benef. Profile #{Record.REF_BENEFID} </Button>
    //     }

    //     {Record.REF_STARTUP  !== 0 &&
    //       <Button size="small" color="primary" onClick={() => EditStartupRecord(Record.REF_STARTUP_ITEMGUID)}> Linked Startup Profile #{Record.REF_STARTUPID} </Button>
    //     }
    //   </React.Fragment>
    // )
  }

  return (
    <React.Fragment>
        {Record.IsBenef && ShowBenefPanel(Record)}
        {Record.IsStartup && ShowStartupPanel(Record)}
        {Record.IsSME && ShowSMEPanel(Record)}
    </React.Fragment>
  )
}