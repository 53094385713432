import { restHelper } from "../helpers/rest.helper";

const Import = async (payload) => {
  var formData = new FormData();
  formData.append("StartupIDs", payload.StartupIDs); //ImageUrl ke ocekuva na server od ImageStream
  formData.append("acName", payload.acName);
  formData.append("acDescription", payload.acDescription);
  formData.append("acFrom", payload.acFrom);
  formData.append("acTo", payload.acTo);
  formData.append("acComment", payload.acComment);
  formData.append("acDoc1", payload.acDoc1);
  formData.append("acDoc2", payload.acDoc2);
  formData.append("acDoc2", payload.acDoc2);
  formData.append("acCategoryID", payload.acCategoryID);
  formData.append("acActivityTypeID", payload.acActivityTypeID);
  // formData.append("acDonorID", payload.acDonorID);
  formData.append("acPartnerID", payload.acPartnerID);
  formData.append("sadDonorParentId", payload.sadDonorParentId);
  formData.append("DonorParentReportId", payload.DonorParentReportId);
  let response = await restHelper.postFile(
    "api/StartupActivity/UploadDoc",
    formData
  );
  return response;
};

const Preview = async (payload) => {
  let response = await restHelper.post("api/StartupActivity/Preview", payload);
  return response;
};

const DeleteActivity = async (payload) => {
  let response = await restHelper.post("api/StartupActivity/Delete", payload);
  return response;
};

export default {
  Import,
  Preview,
  DeleteActivity,
};
