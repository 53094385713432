import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Radio } from '@material-ui/core';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PreviewService from '../services/PreviewService.js';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import * as PreviewComponents from '../components/Shared/PreviewComponents';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { logHelper } from '../helpers/log.helper';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 348,
  },
  formElement: {},
  formLabel: {
    color: '#aaaaa9',
    marginTop: 10,
    '&:hover': {
      background: '#F3F3F2',
    }
  },
  header: {
    backgroundColor: '#F3F3F2',
    borderRadius: 4,
    paddingLeft: 5,
  },
  subHeader: {
    marginTop: 15,
    marginBottom: 1,
    fontWeight: 700
  },
  buttonStyle: {
    margin: theme.spacing(1),
    textTransform: 'none'
  },
  xs12: {
    width: '50%',
  },
  columnLabel: { width: '10%' },
  columnInput: { width: '70%' },
}));

export default function Page2(args) {

  const classes = useStyles();

  //da se stavi hooks [], i da se vikne Fetch a na server 

  //inicijalno stavi go samo kako niza od objekti[]
  const [EntityRecords, setControl] = useState(null);
  const [jsonData, setJsonData] = useState(null);


  //Handle Change
  const handleChange = e => {
    logHelper.log("od handle change");
    logHelper.log(e.target);
    const { name, value } = e.target;

    logHelper.log("value vo handle");
    logHelper.log(value);

    setControl(prevState => ({ ...prevState, [name]: value }));
  };


  //DEFAULT 
  useEffect(() => {
    let TableId = args.match.params.id;
    if (!TableId) TableId = 1156;
    const payLoad = { EntityId: TableId }  //1060: Startup
    PreviewService.Load(payLoad).then((response) => {
      logHelper.log(response.data);
      setJsonData(response.data);
      // jsonData = response.data;
      // logHelper.log(response.data);  
      //RenderState();
    });



  }, []);   //default



  function RenderHeaders() {
    // Here we build the form's groups elements dynamically
    return jsonData.Headers.map(control => {
      if (control.DataType === "Header") {
        return PreviewComponents.CustomHeader(control);
      }
      else if (control.DataType === "SubHeader") {
        return PreviewComponents.CustomSubHeader(control);
      };
    })
  }


  function RenderState() {
    let Records = [];
    jsonData.Elements.map(control => {
      Records = { ...Records, [control.Name]: control.Value }
    })
    logHelper.log(Records);

    //NAPOLNI GO STATE-OT ZA EntityRecords so Records (gornata niza)
    setControl(Records);
  }

  function RenderSchema() {
    return (
      <React.Fragment>
        <input type="text" id="TableName" value={jsonData.Schema.TableName} />
        <input type="text" id="Action" value={jsonData.Schema.Action} />
        <input type="text" id="PrimaryKeyName" value={jsonData.Schema.PrimaryKeyName} />
        <input type="text" id="PrimaryKeyValue" value={jsonData.Schema.PrimaryKeyValue} />
      </React.Fragment>
    )
  }

  function RenderRelations() {
    return jsonData.Relations.map(relation => {
      logHelper.log(relation)
      return PreviewComponents.CustomRelation(relation);
    })
  }


  function RenderElements() {
    // Here we build the form's groups elements dynamically
    return jsonData.Elements.map(control => {
      if (control.DataType === 'Pick List') {
        return PreviewComponents.CustomPickList(control);
      }
      else if (control.DataType === 'PickList (Multi-Select)') {
        return PreviewComponents.CustomPickListMultiple(control);
      } else if (control.DataType === 'Datetime' || control.DataType === 'Time') {
        return PreviewComponents.CustomDatePicker(control);
      } else if (control.DataType === "CheckBox") {
        return PreviewComponents.CustomCheckBox(control);
      } else if (control.DataType === "RadioBox") {
        return PreviewComponents.CustomRadioBox(control);
      }
      else if (control.DataType === "Nvarchar" || control.DataType === "Text") {
        return PreviewComponents.CustomText(classes, control);
      }
    })
  }


  if (!jsonData) {
    return <span>Please wait</span>;
  }

  return (
    <Grid container spacing={2} justify="center" alignItems="center" direction="column" xs>
      <Grid item className={classes.xs12}>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table" style={{ width: "100%", tableLayout: "auto" }}>
              {/* {RenderSchema()} */}

              {/* Renderiraj HEADER */}
              {
                RenderHeaders()
              }

              {/* Renderiraj HTML ELEMENTI */}
              {
                RenderElements()
              }
          </Table>
        </TableContainer>

      </Grid>
      <Grid item>
        <Button variant="outlined" color="primary" className={classes.buttonStyle}>
          Cancel
      </Button>
        <Button variant="contained" color="primary" className={classes.buttonStyle}>
          Save
      </Button>
      </Grid>
      <Grid item className={classes.xs12}>
        {RenderRelations()}
      </Grid>
    </Grid>
  );
}
