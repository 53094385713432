import resourceManager from '../helpers/constant.helper';
import constants from '../helpers/constant.helper';

//sekoe novo properti dodadi go ovde so DEFAULT value
const initialState = {

    Records: [],
    Message: '',
    ValidationMessages: [],
    Loading: true,
    hasError: false,

    LookupData: [],  //Table: LookupData
    ScholarshipCountries: [],   //Table: Country
    Years:[],
    Record : {
        SearchCountryIDs:'',
        SearchYears:'',
        SearchDonorIDs:'',

    }
}

const ReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REPORT_START":
            return {
                ...state,
                Loading: true,
                hasError: false
            }

        case "REPORT_SEARCH_ARGS":
                return {
                    ...state,
                    ScholarshipCountries: action.ScholarshipCountries, 
                    Years: action.Years,
                    LookupData: action.LookupData,
                }

                
        case "REPORT_ERROR":
            return {
                ...state,
                Message: action.error,
                Loading: false,
                hasError: true
            }

        case "REPORT_SUCCESS":
            return {
                //...state,
                ...state,       //se drugo e vo STATE-ot
                Records: action.Records,
                ValidationMessages: [], //resetirj clientski greski
                Loading: false,
                hasError: false
            }

        //se koristi vo find
        case "REPORT_RESET":
            state = initialState; //go resetira na iniital state
            return {
                ...state
            }

        default:
            return state
    }
}
export default ReportReducer;