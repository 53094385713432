import LookupDataService from "../services/LookupDataService";
import BenefService from "../services/BenefService";
import CountryProvinceService from "../services/CountryProvinceService";
import CountryService from "../services/CountryService";
import ServiceLookupData from "../services/LookupDataService";
import ServiceLookupType from "../services/LookupTypeService";
import { validatorHelper, responseHelper, history } from "../helpers";
import Keys from "../json/ResourceStartup.json";
import * as AuthenticationHelper from "../helpers/authentication.helper";
import * as LanguageHelper from "../helpers/language.helper";

// responseHelper.validateResponses(responses);
//Validate Response validira responses dali ima nekakvi greski
//responses = [] --niza
//Iterira niz nea i ako e TRUE togas gi vraka samite responses[] koj sto bile prateni za inspekcija
//ako e FALSE vraka response od ERROR objekt (ne niza, samo eden objekt)
async function EditAsync(args) {
  //najdi go StartupId i po nego povleci data
  let startupId = parseInt(args.stStartupID);
  if (isNaN(startupId)) startupId = 0;

  let responseStartup = null;
  if (startupId === 0) {
    responseStartup = await BenefService.New(args);
  } else {
    responseStartup = await BenefService.Edit(args);
  }

  let responseLookupData = await LookupDataService.SearchByTypeIDs({
    LookupTypeIDs: args.LookupTypeIDs,
  });
  let responseCountriesScholarhips =
    await CountryService.GetScholarshipCountries();
  let responseCountriesProvinces =
    await CountryProvinceService.GetCountryProvincesAll();
  let responseNationalities = await CountryService.GetAllCountries();
  let responses = [
    responseStartup,
    responseLookupData,
    responseCountriesScholarhips,
    responseCountriesProvinces,
    responseNationalities,
  ];
  return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
}

async function DeleteAsync(args) {
  let response = await LookupDataService.Delete(args);
  let responses = [response];
  return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
}

async function SearchAsync(args) {
  let response = await BenefService.Search(args);
  let responses = [response];
  return responseHelper.validateResponses(responses); //procitaj najgore kako raboti
}

async function SaveAsync(Record) {
  let responseEntity = await BenefService.Save(Record);
  let responses = [responseEntity];
  return responseHelper.validateResponses(responses);
}

const Reset = () => {
  return (dispatch) => {
    dispatch({ type: "BENEF_RESET" });
    //dispatch({ type: "GLOBAL_RESET"});    //GLOBALEN CACHE ZA HEADERS DALI DA SE POKAZUVAAT ILI NE
  };
};

const Edit = (args) => {
  return (dispatch) => {
    dispatch({ type: "BENEF_START" });

    EditAsync(args).then((responses) => {
      if (responses.error) {
        dispatch({ type: "BENEF_ERROR", error: responses.error_description });
      } else {
        dispatch({
          type: "BENEF_EDIT",
          Record: responses[0].data,
          LookupData: responses[1].data,
          ScholarshipCountries: responses[2].data, //vidi gore vo komentar za definicija na niza
          Provinces: responses[3].data,
          Nationalities: responses[4].data,
        });

        dispatch({ type: "GLOBAL_REFRESH", Record: responses[0].data }); //GLOBALEN CACHE ZA HEADERS DALI DA SE POKAZUVAAT ILI NE
      }
    });
  };
};

const Delete = (args) => {
  return (dispatch) => {
    dispatch({ type: "BENEF_START" });

    DeleteAsync(args).then((responses) => {
      if (responses.error) {
        dispatch({ type: "BENEF_ERROR", error: responses.error_description });
      } else {
        history.push("/lookupdata/find/" + args.ldTypeID);
      }
    });
  };
};

const Search = (args) => {
  return (dispatch) => {
    dispatch({ type: "BENEF_START" });

    SearchAsync(args).then((responses) => {
      if (responses.error) {
        dispatch({ type: "BENEF_ERROR", error: responses.error_description });
      } else {
        dispatch({
          type: "BENEF_SEARCH",
          Records: responses[0].data,
        });
      }
    });
  };
};

//OVOJ SEUSTE NE E NAPRAVEN DA RABOTI SO SERVICE
const Save = (Record) => {
  return (dispatch) => {
    dispatch({ type: "BENEF_START" }); //da se refreshira GUI-to so SPINNER-ot

    const keys = LanguageHelper.GetLanguageKeys();
    //const keys = Keys[0]; //za da naoga textovi od JSON FIle
    let jsonInputData = [
      {
        element: "stCountryID",
        type: "SelectEmpty", //proverka na select isto kako vo MVC
        value: Record.stCountryID,
        message: keys["CountryCantBeEmpty"],
      },
      {
        element: "stProvinceID",
        type: "SelectEmpty", //proverka na select isto kako vo MVC
        value: Record.stProvinceID,
        message: keys["ProvinceCantBeEmpty"],
      },

      {
        element: "stFirstName",
        type: "required",
        value: Record.stFirstName,
        message: keys["FirstNameCantBeEmpty"],
      },
      {
        element: "stLastName",
        type: "required",
        value: Record.stLastName,
        message: keys["LastNameCantBeEmpty"],
      },
      ,
      {
        element: "stCountryNationalityIDs",
        type: "SelectEmpty", //proverka na select isto kako vo MVC
        value: Record.stCountryNationalityIDs,
        message: keys["CountryNationalityCantBeEmpty"],
      },

      {
        element: "stAddress",
        type: "required",
        value: Record.stAddress,
        message: keys["AddressCantBeEmpty"],
      },
      {
        element: "stCity",
        type: "required",
        value: Record.stCity,
        message: keys["CityCantBeEmpty"],
      },
      {
        element: "stMobilePhone",
        type: "required",
        value: Record.stMobilePhone,
        message: keys["MobilePhoneCantBeEmpty"],
      },
      {
        element: "stEmploymentStatusID",
        type: "SelectEmpty",
        value: Record.stEmploymentStatusID,
        message: keys["EmploymentStatusRequied"],
      },

      {
        element: "stDisabilityID",
        type: "SelectEmpty",
        value: Record.stDisabilityID,
        message: keys["DisabilityStatusRequied"],
      },

      {
        element: "stEmail",
        type: "email",
        value: Record.stEmail,
        message: keys["EmailCantBeEmpty"],
      },
      {
        element: "stSectorID",
        type: "SelectEmpty",
        value: Record.stSectorID,
        message: keys["SectorCantBeEmpty"],
      },
      // {
      //   element: "stBirthDate",
      //   type: "DateTimeEmpty", //proverka na select isto kako vo MVC
      //   value: Record.stBirthDate,
      //   message: keys["BirthDateEmpty"],
      // },
      {
        element: "stPassword",
        type: "password",
        value: Record.stPassword,
        message: keys["PasswordEmptyWith6Chars"],
      },
    ];
    let validationMessages = validatorHelper.validate(jsonInputData);

    //validacija
    //da se dodade
    if (validationMessages.length > 0) {
      dispatch({
        type: "BENEF_SAVE_ERROR",
        ValidationMessages: validationMessages,
        error: keys["FormCantBeSumitted"],
        Record: Record,
      }); //da ne prekucuva, toa sto e od client toa da mu vrati
    } else {
      SaveAsync(Record).then((responses) => {
        if (responses.error) {
          dispatch({
            type: "BENEF_SAVE_ERROR",
            error: responses.error_description, //od server gresakta
            Record: Record,
          }); //da ne prekucuva, toa sto e od client toa da mu vrati
        } else {
          //response[0] == Entity Record
          //response[1] == True ili Exception ako ne pomine uploaded
          const itemGUID = responses[0].data.stItemGUID;
          AuthenticationHelper.SetStartUpItemGUID(itemGUID);
          dispatch({ type: "BENEF_SAVE_SUCCESS", Record: responses[0].data });
          dispatch({ type: "GLOBAL_REFRESH", Record: responses[0].data }); //GLOBALEN CACHE ZA HEADERS DALI DA SE POKAZUVAAT ILI NE
        }
      });
    }
  };
};

export default {
  Save,
  Edit,
  Reset,
  Search,
  Delete,
};
