import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { logHelper } from '../helpers/log.helper';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  spiner: {
    marginTop: 20
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function AlertMessage(props) {
  const classes = useStyles();

  //let open = true;
  const { Loading, Message, hasError } = props;

  //inicijalno alerrt message controlata e hidden
  const [open, setOpen] = useState(false);

  const handleClose = () => { setOpen(false) }

  //ovoj event se pali pri sekoja promena na message, vnatre dokolku e poln ke se otvori ALERT-ot vo sprotivno ke se izgasi
  useEffect(() => {
    if (Message) { //ako imam greska pokazi allert
      setOpen(true);
    }
    else {
      setOpen(false);  //ako nema greska na sleden load izgasi ALERT
    }
    return () => { setOpen(false); }; //pri odenje nadvor od strana (na drug page) obezbedi da se izgasi alert-ot
  }, [Message]);   //default

  // if (!open && !Loading) {
  //   return "";
  // }

  return (
    <React.Fragment>
      {open &&
        <Alert
          severity={hasError ? "error" : Loading ? "info" : "success"}
          variant="outlined"
        // action={
        //   <IconButton
        //     aria-label="close"
        //     color="inherit"
        //     size="small"
        //     onClick={() => {
        //       handleClose();
        //     }}
        //   >
        //     <CloseIcon fontSize="inherit" />
        //   </IconButton>
        // }
        >
          {/* {Message} */}
          {parseMessage(Message)}
        </Alert>
      }
    </React.Fragment>
  );
}

function parseMessage(msg) {
  
  let arrMsgs = msg.split('\n');
  if (arrMsgs.length === 0) {
    return "";
  }
  //ako ima \n vo string-ot togas iterriraj 
  //dokolku e poslen item od array togas ne dodavaj break 
  //https://www.freecodecamp.org/forum/t/newline-in-react-string-solved/68484/6
  const output = arrMsgs.map((item, i) => {
    logHelper.log(i + ":" + arrMsgs.length + ":" + item);

    if (i === arrMsgs.length - 1) {
      return <div key={i}>{item}</div>;
    }
    else {
      return <div key={i}>{item}<br /></div>;
    }
  });
  return output;
}
